import React from 'react';
import BaseDetailPage from '../../_CustomComponents/BaseDetailPage';
import ReactToPrint from "react-to-print";
import { Navigate } from "react-router-dom";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import * as compensationEventCrud from '../../../../crud/compensationEvent.crud';
import * as quotationCrud from "../../../../crud/quotation.crud";
import * as fileCrud from '../../../../crud/file.crud';
import axios from 'axios';
import ActionModal from '../../_CustomComponents/ActionModal';
import DocumentMain from './DocumentMain';
import DocumentReply from './DocumentReply';
import QuotesRouter from '../quotes/Router';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import { Alert } from "react-bootstrap";
import { addDays } from 'date-fns';
import { ValidationSummary, ValidationGroup } from 'devextreme-react';
import moment from 'moment';
import Relations from '../../_CustomComponents/Relation/Relations';
import Common from '../../../../shared/common';
import { FormattedMessage } from "react-intl";
import PageToolbar from '../../_CustomComponents/PageToolbar';
import { withDetailPageProps } from '../../../../shared/hoc/withDetailPageProps';
import NavigationBlocker from '../../../../partials/Tools/NavigationBlocker';
import TemplateHelper from "../../../../Templates/TemplateHelper";
import * as relationshipCrud from '../../../../crud/relationship.crud';
import EmailNotifications from '../../_CustomComponents/EmailNotifications';
const initialState = {
    notification: null,
    files: null,
    showModal: false,
    saving: false,
    // isDxFormValid: true,
    isChanged: false,
    //NEW REPLY STATES
    reply: null,
    replyFiles: null,
    isAwaitingReply: false,
    modalTitle: '',
    modalText: '',
    modalMode: '',
    relationCount: 0,
    postActionUrl: null,
    relations: null,
    selectedClause: null
}

class CompensationDetailPage extends BaseDetailPage {
    constructor(props) {
        super(props);
        this.isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'DEV';
        this.isRelationsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true' || process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true';
        this.printIssueDivRef = React.createRef();
        this.printAcceptanceDivRef = React.createRef();
        //NEW REPLY
        this.validatorRef = React.createRef();
        this.validatorName = 'CompensationValidator';

        this.state = { ...initialState };


        const rejectOptionsPH = props.placeholders.find(ph => ph.replacementKey === 'ReplyRejectOptions');
        if (rejectOptionsPH) {
            this.rejectOptions = rejectOptionsPH.values.filter(item => item.isActive === true);
        }

    }

    componentDidMount() {
        this.fetchNotification();
        this.fetchFiles();
        this.fetchReplyFiles();
    }


    componentDidUpdate(prevprops, prevSatate) {
        if (this.props.params.notificationid !== prevprops.params.notificationid) {
            this.setState({ ...initialState }, () => {
                this.fetchNotification();
                this.fetchFiles();
                this.fetchReplyFiles();
            });
        }
    }

    getMailDescription = () => {
        return this.state.notification.compensationnumber;
    }
    getSelectedTypeClauseDisplay = () => {
        return this.state.notification.compensationtype;
    }
    fetchNotification = () => {
        compensationEventCrud.getCompensationEvent(this.props.params.notificationid)
            .then(response => {
                const compensation = response.data;
                if (compensation.contractid !== this.props.contract.contractid ||
                    compensation.compensationsource !== this.props.workflowItem.config.currentModelType) {
                    throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.INVALID_URL' }));
                }
                if (compensation.draftreplydate) {
                    compensation.draftreplydate = new Date(compensation.draftreplydate);
                }
                if (compensation.eventawaredate) {
                    compensation.eventawaredate = new Date(compensation.eventawaredate);
                }

                const awaitingReply = (compensation.showtasknotification === 1 && (
                    (this.props.mode === 'SUPPLIER' && ["APMR", "ADAN"].includes(compensation.status)) ||
                    (this.props.mode === 'CLIENT' && ["APMR", "ADAN"].includes(compensation.status)) ||
                    (this.props.mode === 'SHORT' && !compensation.contractorreceiveddate) ||
                    (this.props.mode === 'CLAIMWN' && !compensation.contractorreceiveddate) ||
                    (this.props.mode === 'CLIENTCWN' && !compensation.contractorreceiveddate)
                ));
                const initialStatus = compensation.draftcestatus == null ? null : Number(compensation.draftcestatus);

                const replyItem = {
                    quoterequired: initialStatus,
                    eventcomment: compensation.draftreplycomment,
                    assumption: compensation.draftassumption,
                    faultpscp: compensation.draftfaultpscp === 1,
                    notexpectnothappen: compensation.draftnotexpectnothappen === 1,
                    noteffectcostcompletion: compensation.draftnoteffectcostcompletion === 1,
                    statedcontract: compensation.draftstatedcontract === 1,
                    notnotifiedwithintimescales: compensation.draftnotnotifiedwithintimescales === 1,
                    customfieldvalues: compensation.customfieldvalues
                };

                let selectedClause = null;
                let globalResources = this.props.globalResources;
                if (compensation.compensationtype && this.props.clauses) {
                    selectedClause = this.props.clauses.find(c => c.display === compensation.compensationtype);
                    if (selectedClause) {
                        globalResources = TemplateHelper.getGlobalResourcesOverriden(globalResources, selectedClause)
                    }
                    else {
                        if (compensation.showtasknotification !== 1) {
                            compensation.compensationtype = null;
                            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_CLAUSE_MISSING' }), { variant: 'warning', });
                        }
                    }
                }
                this.setState({
                    notification: compensation,
                    reply: replyItem,
                    isAwaitingReply: awaitingReply,
                    saving: false,
                    isChanged: false,
                    addedWarnings: [],
                    removedWarnings: [],
                    relations: compensation.relations,
                    relationCount: Common.GetRelationCount(compensation, this.props.accessibleWfIds),
                    selectedClause,
                    globalResources
                }, () => { this.props.setReference(this.state.notification.referenceid); });
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                }
            });
    }

    fetchFiles = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.notificationid, this.props.documentType)
            const filesList = JSON.parse(data.aggregatelist)
            this.setState({ files: filesList });
            return filesList;
        } catch (err) {
            if (!axios.isCancel(err)) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
        }
    }
    fetchReplyFiles = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.notificationid, this.props.documentType + 'Reply')
            const filesList = JSON.parse(data.aggregatelist)
            this.setState({ replyFiles: filesList });
            return filesList;
        } catch (err) {
            if (!axios.isCancel(err)) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
        }
    }
    printHandler = () => {
        this.printIssueRef.handlePrint();
    }
    printAcceptanceHandler = () => {
        this.printAcceptanceRef.handlePrint();
    }
    validateForm = () => {
        const validationResult = this.validatorRef.current.instance.validate();
        if (!validationResult.isValid) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_MISSING_FIELDS' }), { variant: 'warning', });
        }
        return validationResult.isValid && !this.state.saving;
    }
    showModal = (mode, postActionNavigateObj) => {
        let selectedClause = null;
        if (this.state.notification.compensationtype && this.props.clauses) {
            selectedClause = this.props.clauses.find(c => c.display === this.state.notification.compensationtype);
        }
        if (mode === 'COMMUNICATE') {
            if (this.validateForm()) {
                let modalText = this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_COMMUNICATING' }, { ITEM_NAME: this.props.title, PARTY_NAME: this.props.communicateToUser });
                modalText += " " + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_COMMUNICATING_APPEND' });
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_COMMUNICATE_ITEM' }, { ITEM_NAME: this.props.title }),
                    modalText: modalText,
                    modalMode: mode,
                    postActionUrl: postActionNavigateObj,
                    selectedClause
                });
            }
        }
        else if (mode === 'SAVE_DRAFT') {
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_DRAFT_NOTIFY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
                    modalMode: mode,
                });
            }
        }
        else if (mode === 'DELETE_DRAFT') {
            this.setState({
                showModal: true,
                modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_DELETE_DRAFT_NOTIFY' }),
                modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_DELETING' }),
                modalMode: mode,
            });
        }
        else if (mode === 'REPLY_DRAFT') {
            //show reply tab
            this.tabPanel.instance.option("selectedIndex", 1);
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_REPLY_DRAFT_NOTIFY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
                    modalMode: mode,
                });
            }
        }
        else if (mode === 'REPLY_SEND') {
            //show reply tab
            this.tabPanel.instance.option("selectedIndex", 1);
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SEND_REPLY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_REPLY_SENDING' }, { PARTY_NAME: this.props.globalResources[this.props.workflowItem.notifyingParty + "_NAME"] }),
                    modalMode: mode,
                    selectedClause
                });
            }
        }
    }

    onModalResponse = (response, params) => {
        this.setState({ showModal: false, saving: response === 'OK' }, async () => {
            try {
                if (response === 'OK') {
                    if (this.state.modalMode === 'COMMUNICATE') {
                        const notification = { ...this.state.notification };
                        notification.createactions = params?.createactions;
                        notification.completeactions = params?.completeactions;
                        notification.createby = this.props.contractUser.name;
                        notification.createbyid = this.props.contractUser.accountid;
                        notification.accountid = this.props.contractUser.accountid;
                        notification.status = (this.props.mode === 'SUPPLIER' || this.props.mode === 'CLIENT' || this.props.mode === 'SHORT' || (this.props.mode === 'CLAIMWN' && this.props.workflowItem.replyEnabled) || (this.props.mode === 'CLIENTCWN' && this.props.workflowItem.replyEnabled)) ? 'APMR' : 'AQ';
                        notification.showtasknotification = 1;
                        // notification.notificationdatedisplay = moment().format('DD/MM/YYYY');
                        let eventAwareDate = this.props.fields.EventAwareDate;
                        if (this.props.mode === 'CLIENTCWN' && !eventAwareDate) {
                            let quotrationPeriodResource = "";
                            const notifiedDate = moment();
                            // Using Moment.js directly with the ISO date string
                            const momentDate = moment(notifiedDate);

                            if (!momentDate.isValid()) {
                                this.props.enqueueSnackbar(
                                    this.props.intl.formatMessage({ id: 'GENERAL.FORMS.INVALID_DATE' }),
                                    { variant: "error" }
                                );
                                return;
                            } else {
                                quotrationPeriodResource = "CWN_QUOTE_DURATION";

                                // Verify if this.state.globalResources[quotrationPeriodResource] is valid
                                const daysToAdd = this.state.globalResources[quotrationPeriodResource];
                                if (daysToAdd == null) {
                                    this.props.enqueueSnackbar(
                                        this.props.intl.formatMessage({ id: 'GENERAL.QUOTATION.PERIOD.RESOURCE_ERROR' }),
                                        { variant: "error" }
                                    );
                                    return;

                                } else {
                                    // Compute the required date
                                    const newQuoteRequiredDate = momentDate.add(daysToAdd, 'days');
                                    const notificationQuoteRequiredDate = newQuoteRequiredDate.format('DD/MM/YYYY');

                                    notification.requiredatedisplay = notificationQuoteRequiredDate;

                                }
                            }




                        }
                        else if (this.props.mode === 'CLAIMWN' && !eventAwareDate) {
                            // CWN_QUOTE_DURATION = 5 days


                            let quotrationPeriodResource = "";
                            const notifiedDate = moment();
                            const momentDate = moment(notifiedDate);

                            if (!momentDate.isValid()) {
                                this.props.enqueueSnackbar(
                                    this.props.intl.formatMessage({ id: 'GENERAL.FORMS.INVALID_DATE' }),
                                    { variant: "error" }
                                );
                                return;
                            } else {
                                quotrationPeriodResource = "CWN_QUOTE_DURATION";

                                // Verify if this.state.globalResources[quotrationPeriodResource] is valid
                                const daysToAdd = this.state.globalResources[quotrationPeriodResource];
                                if (daysToAdd == null) {
                                    this.props.enqueueSnackbar(
                                        this.props.intl.formatMessage({ id: 'GENERAL.QUOTATION.PERIOD.RESOURCE_ERROR' }),
                                        { variant: "error" }
                                    );
                                    return;

                                } else {
                                    // Compute the required date
                                    const newQuoteRequiredDate = momentDate.add(daysToAdd, 'days');
                                    const notificationQuoteRequiredDate = newQuoteRequiredDate.format('DD/MM/YYYY');
                                    notification.requiredatedisplay = notificationQuoteRequiredDate;

                                }
                            }




                        }
                        else if (this.props.mode === 'SUPPLIER' || this.props.mode === 'CLIENT') {
                            let supplierReplyPeriod = this.props.replyPeriod;
                            if (this.props.workflowItem.replyPeriodOption === "VARIATION_RESPONSE") {
                                //USE STATE RESOURCES
                                //RESOURCES MAY BE OVERRIDDEN PER CLAUSE
                                supplierReplyPeriod = this.state.globalResources.VARIATION_RESPONSE;
                            }
                            const pmReplyRequired = moment(addDays(new Date(), supplierReplyPeriod)).format('DD/MM/YYYY');
                            notification.projectmanagersigneddatedisplay = pmReplyRequired;
                        }
                        else {
                            ///START QUOTATION

                            let quoteDate = "UNKNOWN";
                            if (this.props.mode === 'SHORT' || this.props.mode === 'CLAIMWN' || this.props.mode === 'CLIENTCWN') {
                                let supplierReplyPeriodShortOrClaim = this.props.replyPeriod;
                                if (this.props.workflowItem.replyPeriodOption === "VARIATION_RESPONSE") {
                                    //USE STATE RESOURCES
                                    //RESOURCES MAY BE OVERRIDDEN PER CLAUSE
                                    supplierReplyPeriodShortOrClaim = this.state.globalResources.VARIATION_RESPONSE;
                                }
                                const pmReplyRequired = moment(addDays(new Date(), supplierReplyPeriodShortOrClaim)).format('DD/MM/YYYY');
                                notification.projectmanagersigneddatedisplay = pmReplyRequired;
                                //IN SHORT TYPE CONTRACTS SUPPLIER IS ALLOWED TO QUOTE BEFORE REPLY
                                let quotrationPeriodResource = "";
                                if (this.props.mode === 'SHORT') {
                                    quotrationPeriodResource = "QUOTE_DURATION_PRIOR_REPLY";
                                    quoteDate = moment(addDays(new Date(), this.state.globalResources[quotrationPeriodResource])).format('DD/MM/YYYY');


                                }
                                else if (this.props.mode === 'CLAIMWN' || this.props.mode === 'CLIENTCWN') {
                                    quotrationPeriodResource = "CWN_QUOTE_DURATION";
                                    quoteDate = moment(addDays(moment(notification.eventawaredate).toDate(), this.state.globalResources[quotrationPeriodResource])).format('DD/MM/YYYY');

                                }
                            }
                            else {
                                if (this.props.mode === 'CLAIM' && notification.eventawaredate) {
                                    quoteDate = moment(addDays(moment(notification.eventawaredate).toDate(), this.state.globalResources.QUOTE_DURATION)).format('DD/MM/YYYY');

                                }
                                else {
                                    quoteDate = moment(addDays(new Date(), this.state.globalResources.QUOTE_DURATION)).format('DD/MM/YYYY');
                                }
                            }
                            notification.originalquotedatedisplay = quoteDate;
                            notification.requiredatedisplay = quoteDate;
                        }



                        const docBodyConfig = await this.documentBodyConfig(notification, this.props.resources);
                        const docConfig = await this.documentConfig(notification, docBodyConfig, this.props.contractUser.name, false, null, null, this.props.workflowItem.form_NotifyCompanyName);
                        notification.pdfjsoncontent = JSON.stringify(docConfig);

                        this.saveNotification(notification, this.state.modalMode);

                    }
                    else if (this.state.modalMode === 'SAVE_DRAFT') {
                        const notification = { ...this.state.notification };
                        notification.createby = this.props.contractUser.name;
                        notification.createbyid = this.props.contractUser.accountid;
                        notification.accountid = this.props.contractUser.accountid;
                        notification.status = 'NC';
                        notification.showtasknotification = 0;
                        this.saveNotification(notification, this.state.modalMode, params ? params.userid : null);

                    }
                    else if (this.state.modalMode === 'DELETE_DRAFT') {
                        const compensationevent = { ...this.state.notification };
                        const compensationeventid = compensationevent.compensationeventid
                        this.deleteCompensationEventDraft(compensationeventid);

                    }
                    else if (this.state.modalMode === 'REPLY_DRAFT') {
                        const reply = { ...this.state.reply };
                        const comp = { ...this.state.notification };

                        comp.draftcestatus = reply.quoterequired;
                        comp.draftreplycomment = reply.eventcomment;
                        comp.draftfaultpscp = reply.faultpscp && !reply.quoterequired ? 1 : 0;
                        comp.draftnotexpectnothappen = reply.notexpectnothappen && !reply.quoterequired ? 1 : 0;
                        comp.draftnoteffectcostcompletion = reply.noteffectcostcompletion && !reply.quoterequired ? 1 : 0;
                        comp.draftstatedcontract = reply.statedcontract && !reply.quoterequired ? 1 : 0;
                        comp.draftnotnotifiedwithintimescales = reply.notnotifiedwithintimescales && !reply.quoterequired ? 1 : 0;

                        comp.draftassumption = reply.quoterequired === 1 ? reply.assumption : '';
                        comp.draftreplystatus = 'Drafted';
                        comp.draftreplybyid = this.props.contractUser.accountid;
                        if ((params || {}).userid) {
                            comp.draftreplytoid = params.userid;
                        }
                        comp.draftreplydatedisplay = moment().format('DD/MM/YYYY');
                        for (let key in reply) {
                            if (key.startsWith("CF_")) {
                                comp[key] = reply[key];
                            }
                        }
                        this.saveNotification(comp, this.state.modalMode, (params || {}).userid);

                    }
                    else if (this.state.modalMode === 'REPLY_SEND') {
                        const reply = { ...this.state.reply };
                        if (!this.replyValidation(reply)) {
                            //FINAL REPLY FIELDS CHECK FAILED
                            throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_REPLY_FIELDS_MISSING' }))
                        }

                        const comp = { ...this.state.notification };
                        comp.createactions = params?.createactions;
                        comp.completeactions = params?.completeactions;
                        comp.quoterequired = reply.quoterequired;
                        comp.eventcomment = reply.eventcomment;
                        comp.assumption = reply.quoterequired === 1 ? reply.assumption : '';
                        const rejectOptions = {
                            faultpscp: reply.faultpscp && !reply.quoterequired ? 1 : 0,
                            notexpectnothappen: reply.notexpectnothappen && !reply.quoterequired ? 1 : 0,
                            noteffectcostcompletion: reply.noteffectcostcompletion && !reply.quoterequired ? 1 : 0,
                            statedcontract: reply.statedcontract && !reply.quoterequired ? 1 : 0,
                            notnotifiedwithintimescales: reply.notnotifiedwithintimescales && !reply.quoterequired ? 1 : 0,
                        }
                        for (let key in reply) {
                            if (key.startsWith("CF_")) {
                                comp[key] = reply[key];
                            }
                        }
                        this.onSubmitReply(comp, rejectOptions);

                    }
                }
            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                this.setState({ saving: false });
            }

        });

    }
    replyValidation = (reply) => {
        if (!reply.eventcomment) { return false; }
        if (this.props.mode !== 'CLAIMWN' && this.props.mode !== 'CLIENTCWN') {
            if (reply.quoterequired === 1 && !reply.assumption) { return false; }
        }
        return true;
    }
    getInstructionURL = (notification) => {
        const { contractid, inspectiondefectid } = notification;
        return `${window.location.origin}/contract/${contractid}/instructions/pm/${inspectiondefectid}`;
    }
    getTaskURL = (notification) => {
        const { contractid, taskorderid } = notification;
        return `${window.location.origin}/contract/${contractid}/tasks/pm/${taskorderid}`;
    }

    getProjectURL = (notification) => {
        const { contractid, projectorderid } = notification;
        return `${window.location.origin}/contract/${contractid}/projects/pm/${projectorderid}`;
    }

    documentBodyConfig = async (notification, resources) => {
        const { currentModelType } = this.props.workflowItem.config;
        const config = [
            { resource: 'GENERAL.FORMS.FIELD.SUBJECT', value: notification.compensationnumber },
            { label: resources.CompensationTypeLabel, value: notification.compensationtype },
            { label: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.INSTRUCTION' }, { PM_NAME: this.state.globalResources.PM_NAME, INSTRUCTION: this.props.instructionNotificationName }), text: notification.projectmanagementinstructionsequence, valueType: 'LINK', url: this.getInstructionURL(notification) },
        ];
        if (this.props.taskOrderOptionEnabled) {
            config.push({ label: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.TASK_ORDER' }, { TASK_ORDER: this.props.taskOrderNotificationName }), text: notification.taskorderreference ? notification.taskorderreference : "", valueType: 'LINK', url: this.getTaskURL(notification) });
        }
        if (this.props.projectOrderOptionEnabled) {
            config.push({ label: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.PROJECT_ORDER' }, { PROJECT_ORDER: this.props.projectOrderNotificationName }), text: notification.projectorderreference ? notification.projectorderreference : "", valueType: 'LINK', url: this.getProjectURL(notification) });
        }
        if (currentModelType === 'CWN' || currentModelType === 'CLIENTCWN') {
            config.push({ resource: "CONTRACT.WF.COMPENSATION.AWARENESS_DATE", value: notification.eventawaredate });
        }
        if (currentModelType === 'CON' || currentModelType === 'CLIENT' || currentModelType === 'SCON' || currentModelType === 'CWN' || currentModelType === 'CLIENTCWN' || currentModelType === 'CWQ') {
            config.push({ note: resources.SupplierDescNote, valueType: 'NOTE' });
            config.push({ resource: 'GENERAL.FORMS.FIELD.DETAILS', value: notification.description, valueType: 'HTML', align: 'vertical' });
        }
        else if (currentModelType === 'PM') {
            config.push({ resource: 'GENERAL.FORMS.FIELD.DETAILS', value: notification.description, valueType: 'HTML', align: 'vertical' });
            //FD-8575
            if (resources.PmAssumptionNote) {
                config.push({ note: resources.PmAssumptionNote.replace('$date', notification.originalquotedatedisplay), valueType: 'NOTE' });
            }
            if (this.props.fields.Assumption) {
                config.push({ label: this.props.fields.Assumption, value: notification.assumption, valueType: 'HTML', align: 'vertical' });
            }
        }

        if (this.props.ewRelationEnabled) {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data: respData } = await compensationEventCrud.getCompensationWarnings(companyid, projectid, contractid, notification.compensationeventid)
            const relatedEWs = JSON.parse(respData.aggregatelist).map(ew => ({
                warningid: ew.earlywarningid,
                url: `${window.location.origin}/contract/${this.props.contract.contractid}/early-warning/${ew.earlywarningparty === "PM" ? "pm" : "supplier"}/${ew.earlywarningid}`,
                text: ew.earlywarningreferenceid + " " + ew.earlywarning
            }));

            config.push({
                label: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.LABEL.ASSOCIATED_EW' }, { EARLY_WARNING: this.props.ewNotificationName }),
                valueType: "LINKS",
                data: relatedEWs
            })
        }

        return config;
    }
    documentReplyBodyConfig = (notification, resources, replyOptions) => {
        const replyOption = replyOptions.find(opt => opt.value === notification.quoterequired);
        if (!replyOption) {
            this.props.enqueueSnackbar(`Reply option not found (${notification.quoterequired})`, { variant: 'error', });
            throw new Error();
        }
        const replyStatus = replyOption ? replyOption.display : `Reply option not found (${notification.quoterequired})`;

        const docConfig = [
            { resource: "GENERAL.FORMS.FIELD.STATUS", value: replyStatus },
        ];
        if (notification.quoterequired === 1) {
            let replyAcceptedCommentLabel = resources.ReplyAcceptedCommentLabel.replace('$reference', notification.referenceid);

            docConfig.push({ label: replyAcceptedCommentLabel, value: notification.eventcomment, valueType: 'HTML', align: 'vertical' });
            if (resources.ReplyAcceptedAssumptionNote) {
                const quoteDate = moment(addDays(new Date(), this.state.globalResources.QUOTE_DURATION)).format('DD/MM/YYYY');
                docConfig.push({ note: resources.ReplyAcceptedAssumptionNote.replace('$date', quoteDate), valueType: 'NOTE' });
            }

            if (this.props.mode !== "CLAIMWN" && this.props.mode !== "CLIENTCWN") {
                docConfig.push({ label: resources.ReplyAcceptedAssumptionLabel, value: notification.assumption, valueType: 'HTML', align: 'vertical' });
            }
        }
        else if (notification.quoterequired === 2 && (this.props.mode === "CLAIMWN" || this.props.mode === "CLIENTCWN")) {
            docConfig.push({ label: resources.ReplyRejectedCommentLabel, value: notification.eventcomment, valueType: 'HTML', align: 'vertical' });
        }
        else {
            const items = this.rejectOptions.filter(itm => (notification[itm.field] && notification[itm.field] === 1))
                .map(itm => ({ label: itm.display }))
            docConfig.push({
                label: resources.NotificationClause,
                list: items,
                valueType: 'LIST',
                align: 'vertical'
            });
            docConfig.push({ label: resources.ReplyRejectedCommentLabel, value: notification.eventcomment, valueType: 'HTML', align: 'vertical' });
        }


        return docConfig;
    }
    onSubmitReply = async (compensation, rejectOptions) => {
        const { companyid, contractid } = this.props.contract;

        // compensation.contractorreceiveddatedisplay = moment().format('DD/MM/YYYY');

        compensation.projectmanagersignedbyid = this.props.contractUser.accountid;
        if (compensation.quoterequired === 1) {
            if (this.props.mode === 'SHORT' || this.props.mode === 'CLAIMWN' || this.props.mode === 'CLIENTCWN') {
                const { data: allQuotesList } = await quotationCrud.getQuotesReduced(companyid, contractid, compensation.compensationeventid);
                const replyPendingQuotes = allQuotesList.filter(Q => Q.responsestatus === 2);
                if (replyPendingQuotes && replyPendingQuotes.length > 0) {
                    compensation.status = 'APMRQ';
                }
                else {
                    compensation.status = 'AQ';
                    // compensation.projectmanagerresponsedatedisplay = moment().format('DD/MM/YYYY');
                }
            }
            else {
                // compensation.projectmanagerresponsedatedisplay = moment().format('DD/MM/YYYY');
                compensation.status = 'AQ';
            }
            const quoteDate = moment(addDays(new Date(), this.state.globalResources.QUOTE_DURATION)).format('DD/MM/YYYY');
            compensation.requiredatedisplay = quoteDate;
            compensation.originalquotedatedisplay = quoteDate;
        } else {
            if ((this.props.mode === 'SHORT' || this.props.mode === 'CLAIMWN' || this.props.mode === 'CLIENTCWN') && compensation.status !== 'APMR') {
                ///Compensation is deemed
                ///PM has to reply to quotation
                ///Process will continue
                // compensation.status = compensation.status;
            }
            else {
                if (compensation.quoterequired === 2 && (this.props.mode === 'CLAIMWN' || this.props.mode === 'CLIENTCWN')) {
                    compensation.status = 'AQ';
                }
                else {
                    compensation.status = 'R';
                    compensation.requiredatedisplay = null;
                    compensation.originalquotedatedisplay = null;
                }

                // compensation.projectmanagerresponsedatedisplay = moment().format('DD/MM/YYYY');
            }
        }
        const newCompensation = { ...compensation, ...rejectOptions };


        // const compAssess = {
        //     companyid: companyid,
        //     projectid: projectid,
        //     contractid: contractid,
        //     compensationeventid: compensation.compensationeventid,
        //     eventcomment: compensation.eventcomment,
        //     // assessmentdatedisplay: new Date().toLocaleDateString('en-GB'),
        //     ...rejectOptions
        // }
        const docReplyBodyConfig = this.documentReplyBodyConfig(newCompensation, this.props.resources, this.props.replyOptions);
        const docReplyConfig = await this.documentConfig(newCompensation, docReplyBodyConfig, this.props.contractUser.name, true, null, null, this.props.workflowItem.form_ReplyCompanyName);
        newCompensation.pdfreplyjsoncontent = JSON.stringify(docReplyConfig);

        this.saveNotification(newCompensation, this.state.modalMode);
    }

    deleteCompensationEventDraft = async (compensationeventid) => {

        try {
            await compensationEventCrud.deleteCompensationEventDraft(compensationeventid);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_DELETED' }), { variant: 'success' });
            this.setState({ saving: false, navigateto: this.props.workflowItem.paths.listPath });

        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            this.setState({ saving: false });
        }

    }
    saveNotification = async (notification, action, notifyuserid) => {
        // const { companyid, projectid, contractid } = this.props.contract;
        const postEvents = () => {
            this.fetchNotification();
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), { variant: 'success' });
        };
        try {
            if (!notification.projectmanagersigneddatedisplay && notification.projectmanagersigneddate) {
                notification.projectmanagersigneddatedisplay = moment(new Date(notification.projectmanagersigneddate)).format('DD/MM/YYYY');
            }
            // if (!notification.notificationdatedisplay && notification.notificationdate) {
            //     notification.notificationdatedisplay = moment(new Date(notification.notificationdate)).format('DD/MM/YYYY');
            // }
            // if (!notification.officereceiveddatedisplay && notification.officereceiveddate) {
            //     notification.officereceiveddatedisplay = moment(new Date(notification.officereceiveddate)).format('DD/MM/YYYY');
            // }
            // if (!notification.projectmanagerresponsedatedisplay && notification.projectmanagerresponsedate) {
            //     notification.projectmanagerresponsedatedisplay = moment(new Date(notification.projectmanagerresponsedate)).format('DD/MM/YYYY');
            // }
            if (!notification.draftreplydatedisplay && notification.draftreplydate) {
                notification.draftreplydatedisplay = moment(notification.draftreplydate).format('DD/MM/YYYY');
            }
            if (!notification.requiredatedisplay && notification.requiredate) {
                notification.requiredatedisplay = moment(notification.requiredate).format('DD/MM/YYYY');
            }
            if (!notification.eventawaredatedisplay && notification.eventawaredate) {
                notification.eventawaredatedisplay = moment(notification.eventawaredate).format('DD/MM/YYYY');
            }

            // if (!notification.contractorreceiveddatedisplay && notification.contractorreceiveddate) {
            //     notification.contractorreceiveddatedisplay = moment(notification.contractorreceiveddate).format('DD/MM/YYYY');
            // }

            //#TODO IN CEN Update
            if (this.state.addedWarnings && this.state.addedWarnings.length > 0) {
                notification.timeextension = this.state.addedWarnings[this.state.addedWarnings.length - 1].reference;
            }

            // const promises = []
            // if (compAssess) {
            //     promises.push(compensationEventCrud.saveCompensationAssessment(compAssess));
            // }
            // if (this.state.addedWarnings && this.state.addedWarnings.length > 0) {
            //     this.state.addedWarnings.forEach(warning => {
            //         const relationObj = {
            //             companyid: companyid,
            //             projectid: projectid,
            //             contractid: contractid,
            //             compensationeventid: notification.compensationeventid,
            //             earlywarningid: warning.warningid,
            //         };
            //         promises.push(compensationEventCrud.saveCompensationEarlyWarning(relationObj));
            //     });
            // }
            // if (this.state.removedWarnings && this.state.removedWarnings.length > 0) {
            //     this.state.removedWarnings.forEach(warning => {
            //         promises.push(compensationEventCrud.deleteCompensationEarlyWarning(notification.compensationeventid, warning.warningid));
            //     });
            // }
            // if (promises.length > 0) {
            //     await axios.all(promises)
            // }
            notification.actioncode = action;
            notification.replyingparty = this.props.workflowItem.replyingParty;
            Common.PropertyConversionsBeforeNoticeSaveRequest(notification, this.props.customFields, this.props.contractUsers);
            await compensationEventCrud.saveCompensationEvent(notification);
            notification.notificationtypeforemailtemplate = notification.compensationsource;
            notification.pdfjsoncontent = notification.pdfjsoncontent?.replace("$NOW", moment().format('DD/MM/YYYY'));
            this.notify(action, notification, notifyuserid);
            if (this.state.postActionUrl) {
                this.setState({ isChanged: false, saving: false, navigateto: this.state.postActionUrl.pathname });
            } else {
                postEvents();
            }

        } catch (err) {
            this.setState({ saving: false });
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
    }
    fieldChanged = (field, value, isDxFormValid, additionalData) => {
        //SILENT STATE CHANGE
        //WE DONT WANT COMPONENT RERENDER WİTH THIS
        //this.setState({ notification: { ...this.state.notification, [field]: value } });
        if (field) {
            if (field === "assetid") {
                this.setState({
                    notification: { ...this.state.notification, [field]: value, asset: { ...additionalData } },
                    isChanged: true
                });
            }
            else if (field === 'compensationtype') {
                this.state.notification[field] = value;

                let selectedClause = null;
                let globalResources = this.props.globalResources;
                if (value) {
                    selectedClause = this.props.clauses.find(c => c.display === value);
                    if (selectedClause) {
                        globalResources = TemplateHelper.getGlobalResourcesOverriden(globalResources, selectedClause)
                    }
                }
                this.setState({
                    isChanged: true,
                    selectedClause,
                    globalResources
                })
            }
            else {
                this.state.notification[field] = value;
                if (!this.state.isChanged) {
                    this.setState({ isChanged: true });
                }
            }
        }


    }
    replyFieldChanged = (field, value, isDxReplyFormValid, isCheckBoxValue) => {
        //SILENT STATE CHANGE
        //WE DONT WANT COMPONENT RERENDER WİTH THIS
        //this.setState({ notification: { ...this.state.notification, [field]: value } });
        if (field) {
            this.state.reply[field] = value;
            if (!this.state.isChanged) {
                this.setState({ isChanged: true });
            }
        }

    }
    onCompensationChanged = () => {
        this.fetchNotification();
    }

    renderToolbar = (documentIsFinal) => {
        const saveDisabled = (this.state.saving || this.state.showModal);
        return (
            <PageToolbar
                key={this.state.notification.version}
                documentIsFinal={documentIsFinal}
                saveDisabled={saveDisabled}
                isAwaitingReply={this.state.isAwaitingReply}
                actions={{
                    showModal: this.showModal,
                    onPrintReply: this.printAcceptanceHandler,
                    onPrint: this.printHandler
                }}
                workflowItem={this.props.workflowItem}
                match={this.props.match}
                customFieldValues={this.state.notification.customfieldvalues}
                referenceid={this.state.notification.referenceid}
                contract={this.props.contract}
                relationParams={{
                    subject: this.getMailDescription(),
                    assetid: this.state.notification.assetid
                }}
                notification={this.state.notification}
                selectedClausetypeDisplay={this.getSelectedTypeClauseDisplay()}
            />
        );
    }
    onWarningsChanged = async (addedWarningID, removedWarningID) => {
        try {
            if (addedWarningID) {
                const { companyid, projectid, contractid } = this.props.contract;
                const relationObj = {
                    companyid: companyid,
                    projectid: projectid,
                    contractid: contractid,
                    compensationeventid: this.state.notification.compensationeventid,
                    earlywarningid: null,
                };
                relationObj.earlywarningid = addedWarningID;
                await compensationEventCrud.saveCompensationEarlyWarning(relationObj)
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.RELATION_ADDED' }), { variant: 'success' });
            }
            else if (removedWarningID) {
                await compensationEventCrud.deleteCompensationEarlyWarning(this.state.notification.compensationeventid, removedWarningID);
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.RELATION_REMOVED' }), { variant: 'success' });
            }
            await this.updateRelations();
        } catch (err) {
            console.log(err);
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
    }
    updateRelations = async () => {
        try {
            const response = await relationshipCrud.getRelationships(this.props.params.notificationid, this.props.workflowItem.config.referenceType, this.props.contract.contractid)
            const newRelations = JSON.parse(response.data);
            this.setState({
                relations: newRelations,
                relationCount: Common.GetRelationCount({ relations: newRelations }, this.props.accessibleWfIds),
            })
        }
        catch (err) {
            console.log(err);
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
    }
    tabDocument = () => {
        if (!this.state.files) {
            return null;
        }
        else {
            return (
                <DocumentMain
                    {...this.props}
                    onWarningsChanged={this.onWarningsChanged}
                    printDivRef={this.printIssueDivRef}
                    fieldChanged={this.fieldChanged}
                    notification={this.state.notification}
                    files={this.state.files}

                    notificationid={this.props.params.notificationid}
                />
            );
        }

    }

    tabQuotes = () => {
        return (
            <QuotesRouter
                {...this.props}
                parentWorkflowItem={this.props.workflowItem}
                parentTabPanel={this.tabPanel}
                onCompensationChanged={this.onCompensationChanged}
                compensation={this.state.notification}
            />
        )
    }
    tabReply = () => {
        if (!this.props.workflowItem.userFilters.replyNotice.communicateReplyAllowed && !this.props.workflowItem.userFilters.replyNotice.saveDraftReplyAllowed && this.state.isAwaitingReply) {
            return (
                <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                    <div style={{ width: '100%', maxWidth: '900px', margin: '20px' }}>
                        <Alert variant="warning" >
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_AWAITING_REPLY" values={{ ITEM_NAME: this.props.workflowItem.pageTitle }} />
                            </p>
                        </Alert>
                    </div>
                </div>
            )
        }
        else if (!this.state.isAwaitingReply && !this.state.notification.pdfreplyjsoncontent && this.state.notification.cendeemedacceptancedate) {
            //CEN HAS BEEN DEEM ACCEPTED
            //THERE IS NO REPLY DOCUMENT
            return (
                <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                    <div style={{ width: '100%', maxWidth: '900px', margin: '20px' }}>
                        <Alert variant="warning" >
                            <p>
                                {this.props.resources.CenReplyMissingDueToDeemAcceptance}
                            </p>
                        </Alert>
                    </div>
                </div>
            )
        }
        else {
            return (
                <DocumentReply
                    {...this.props}
                    isAwaitingReply={this.state.isAwaitingReply}
                    printDivRef={this.printAcceptanceDivRef}
                    fieldChanged={this.replyFieldChanged}
                    notification={this.state.notification}
                    reply={this.state.reply}
                    files={this.state.replyFiles}
                    notificationid={this.props.params.notificationid}
                    globalResources={this.state.globalResources}
                />
            )
        }
    }
    tabRelations = () => {
        return (
            <Relations
                ref={this.relationsComponentReference}
                contractid={this.props.contract.contractid}
                workflowItem={this.props.workflowItem}
                workflowGroupItem={this.props.workflowGroupItem}
                notificationid={Number(this.props.params.notificationid)}
                relations={this.state.relations}
                itemversion={this.state.notification.version}
                subject={this.state.notification.compensationnumber}
                onCountChange={(count) => this.setState({ relationCount: count })}
                showTags={this.props.contract.specifictags?.length > 0}
            />
        );
    }

    tabEmailNotification = () => {

        return (
            <EmailNotifications
                {...this.props}
                notificationDate={this.state.notification.notificationdate}
            />
        );

    };
    render() {
        if (!this.state.notification || !this.state.files || !this.state.replyFiles) {
            return (<PortletSpinner />);
        }

        const documentIsFinal = this.state.notification.showtasknotification === 1;
        let quotesTabEnabled = documentIsFinal && (this.props.mode === 'SHORT' || this.props.mode === 'CLAIMWN' || this.props.mode === 'CLIENTCWN'); // always available for SHORT or CWN
        quotesTabEnabled = quotesTabEnabled || (documentIsFinal && !["APMR", "ADAN", "R"].includes(this.state.notification.status));// CEN not rejected AND CEN not awaiting reply
        const replyTabEnabled = documentIsFinal && (this.props.mode === 'SUPPLIER' || this.props.mode === 'CLIENT' || this.props.mode === 'SHORT' || (this.props.mode === 'CLAIMWN' && this.props.workflowItem.replyEnabled) || (this.props.mode === 'CLIENTCWN' && this.props.workflowItem.replyEnabled));

        if (!documentIsFinal && !this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed) {
            return (<Navigate replace to={this.props.workflowItem.paths.listPath} />)
        }
        return (
            <Portlet>
                <ReactToPrint ref={el => (this.printIssueRef = el)} trigger={() => (<div style={{ display: 'none' }} ></div>)} content={() => this.printIssueDivRef.current} />
                <ReactToPrint ref={el => (this.printAcceptanceRef = el)} trigger={() => (<div style={{ display: 'none' }} ></div>)} content={() => this.printAcceptanceDivRef.current} />
                <PortletHeader
                    title={documentIsFinal ? this.props.workflowItem.pageTitle : this.props.intl.formatMessage({ id: "GENERAL.FORMS.DRAFT_TITLE" }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
                    toolbar={this.renderToolbar(documentIsFinal)} />
                <NavigationBlocker
                    when={(this.state.isChanged || this.state.saving)}
                    message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}
                />
                {this.state.navigateto &&
                    (
                        <Navigate replace to={this.state.navigateto} state={(this.state.postActionUrl || {}).state} />
                    )
                }
                <PortletBody>
                    <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
                        <div>
                            <ValidationSummary elementAttr={{ align: "right", paddingBottom: 50 }} id={this.validatorName}></ValidationSummary>
                        </div>
                        <TabPanel
                            ref={(ref) => this.tabPanel = ref}
                            width="100%"
                            deferRendering={false}
                            animationEnabled={true}
                            focusStateEnabled={true}
                            hoverStateEnabled={true}
                            swipeEnabled={false}>
                            <Item icon="file" title={this.props.title} render={this.tabDocument} />
                            {replyTabEnabled && <Item icon="rename" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_REPLY' })} render={replyTabEnabled ? this.tabReply : null} visible={replyTabEnabled} />}
                            {quotesTabEnabled && <Item icon="sorted" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_QUOTES' })} render={quotesTabEnabled ? this.tabQuotes : null} visible={quotesTabEnabled} />}
                            {
                                this.isRelationsEnabled && (
                                    <Item icon="share" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_RELATIONS' })} render={this.tabRelations} badge={this.state.relationCount > 0 ? this.state.relationCount : null} />
                                )
                            }
                            <Item
                                icon="bulletlist"
                                title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_EMAIL_NOTIFICATIONS' })}
                                render={documentIsFinal ? this.tabEmailNotification : null} visible={documentIsFinal}
                            />
                        </TabPanel>
                    </ValidationGroup>
                </PortletBody>
                <ActionModal
                    visible={this.state.showModal}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    mode={this.state.modalMode}
                    onDialogResult={this.onModalResponse}
                    workflowItem={this.props.workflowItem}
                    selectedClause={this.state.selectedClause}
                />
            </Portlet>
        );
    }
}

export default withDetailPageProps(CompensationDetailPage);
