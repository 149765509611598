import {placeholderTypes} from "../../Types/Placeholder";
const placeholders =  [
    {
        replacementKey: 'Resources', values:
            [
                { id: 1, isActive: true, field: 'InstructionClauseLabel', display: 'You are instructed under clause 41.3 with Details' },
                { id: 2, isActive: true, field: 'SupervisorClauseLabel', display: 'You are instructed under clause 41.3 with Details' },
                { id: 3, isActive: true, field: 'SupplierClauseLabel', display: 'You are instructed under clause 41.3 with Details' },
                { id: 4, isActive: true, field: 'TestsClauseLabel', display: 'You are instructed under clause 41.3 with Details' },
                { id: 5, isActive: true, field: 'DefectsWarningBanner', display: 'A Defect is not notified after the defects date.' },
                { id: 6, isActive: true, field: 'SearchWarningBannerDate', display: 'An instruction to search for a Defect is not to be issued after the Defects Date.' },
                { id: 7, isActive: true, field: 'SearchWarningBannerDetails', display: 'Details of the defect must be given along with reasons for the search.' },
                { id: 8, isActive: true, field: 'NCProposalReplyWarningBanner', display: '' },
                { id: 9, isActive: true, field: 'NCProposalNote', display: 'Z5.2(1) Proposal of the conditions with respect to a Non-Conformity, response will be required by $date' },
                { id: 10, isActive: true, field: 'ClosureReasonLabel', display: 'Reason for closure' },
                { id: 11, isActive: true, field: 'InstructionTypeLabel', display: 'Type' }
            ],
        type:placeholderTypes.RESOURCES
    },
    {
        replacementKey: 'ActionReminders', values:
            [
                { id: 1, isActive: true, field: 'Instruction', display: '$SUPERVISOR_NAME Instruction to Search awaiting sign off' },
                { id: 2, isActive: true, field: 'SuperDefect', display: '$SUPERVISOR_NAME Notification of Defect awaiting acceptance' },
                { id: 3, isActive: true, field: 'Inspection', display: '$SUPPLIER_NAME Test/Inspection awaiting sign off' },
                { id: 4, isActive: true, field: 'SupervisorTestAndInspection', display: '$SUPERVISOR_NAME Test/Inspection awaiting sign off' },
                { id: 5, isActive: true, field: 'PMTestAndInspection', display: '$PM_NAME Test/Inspection awaiting sign off' },
                { id: 6, isActive: true, field: 'PMNCProposal', display: '$PM_NAME NC Proposal awaiting Acceptance' },
                { id: 7, isActive: true, field: 'NonConformity', display: 'Non-Conformity awaiting Reply' },
                { id: 8, isActive: true, field: 'SupplierDefect', display: '$SUPPLIER_NAME Notification of Defect awaiting close' },
            ],
        type:placeholderTypes.ACTION_REMINDERS
    },
    {
        replacementKey: 'DefectPlannedClosureEnabled', values:
            [
                { id: 1, isActive: false, field: 'DefectPlannedClosureEnabled', display: 'Active-Inactive state indicates "Defect Planned Closure Date" field will be included in registers, reports and actions tab' },
            ],
        isToggle: true,
        fieldRequired: true,
        type:placeholderTypes.TOGGLE_SWITCH
    },
    {
        replacementKey: 'NCProposalReplyOptions', values:
            [
                { id: 1, isActive: true, field: 'Accepted', display: 'Accepted' },
                { id: 2, isActive: true, field: 'Not Accepted', display: 'Not Accepted' },
                { id: 3, isActive: false, field: 'Other Accepted', display: 'Other Proposal Accepted' },
                { id: 4, isActive: false, field: 'No Reply', display: 'No Reply' },
            ],
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    },
    {
        replacementKey: 'NonConformityReplyOptions', values:
            [
                { id: 1, isActive: true, field: 'Accepted', display: 'Conditions Met' },
                { id: 2, isActive: true, field: 'Not Accepted', display: 'Conditions Not Met' },
            ],
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    },
    {
        replacementKey: 'ClosureReasonOptions', values:
            [
                { id: 1, isActive: true, field: 'Accepted', display: 'Accepted' },
                { id: 2, isActive: true, field: 'Corrected', display: 'Corrected' }
            ],
        fieldRequired: true,
        type:placeholderTypes.GENERIC,
        skipDefaultLoadingIfMissing: true
    },
    {
        replacementKey: 'UnifiedClauses', values:
            [
                { id: 1, wfid: 22, isActive: true, field: '', display: 'Test Clause', },

            ],
        skipDefaultLoadingIfMissing: true,
        allowCustomReplyPeriods: false,
        allowConfigureReplyParty: false,
        type: placeholderTypes.UNIFIED_CLAUSES
    },
];

export default placeholders;