import React from "react";
import BaseCreatePage from '../../_CustomComponents/BaseCreatePage';
import {
  Portlet,
  PortletHeader,
  PortletBody,
} from "../../../../partials/content/Portlet";
import BiDynamicForm from "../../../../partials/layout/BiDynamicForm";
import * as contractInformationCrud from "../../../../crud/contractInformation.crud";
import { contractDocumentTypes } from "../../../../shared/lookup";
import { contractDocumentStatuses } from "../../../../shared/lookup";
import moment from "moment";
import { withCreatePageProps } from '../../../../shared/hoc/withCreatePageProps';

const document = {
  companyid: null,
  projectid: null,
  contractid: null,
  createbyid: null,
  issuedbyid: null,
  reference: null,
  title: null,
  type: null,
  status: null,
  issueddatedisplay: null,
};

class DocumentCreate extends BaseCreatePage {
  handleSubmit = (document, setSubmitting, clearForm) => {
    document.companyid = this.props.contract.companyid;
    document.contractid = this.props.contract.contractid;
    document.projectid = this.props.contract.projectid;
    document.issueddatedisplay = moment(document.issueddatedisplay).format(
      "DD/MM/YYYY"
    );
    setSubmitting(true);
    contractInformationCrud
      .saveDocument(document)
      .then((response) => {
        this.props.enqueueSnackbar("Contract Document successfully added", {
          variant: "success",
        });

        try {
          const createdID = Number(response.data);
          this.props.navigate(this.props.workflowItem.paths.basePath + createdID);
        } catch {
          this.props.navigate(this.props.workflowItem.paths.listPath);
        }
      })
      .catch((err) => {
        this.props.enqueueSnackbar(err.toString(), { variant: "error", key: "PersistentFormWarning", persist: true  });
      });
  };

  render() {
    const documentFormConfig = {
      buttonText: "Create Contract Document",
      fields: [
        {
          fields: [
            {
              label: "Reference",
              dataField: "reference",
              editorOptions: {
                placeholder: "Reference",
              },
              required: true,
            },
            {
              resource: "GENERAL.FORMS.FIELD.TITLE",
              dataField: "title",
              editorOptions: {
                placeholder: "Title",
              },
              required: true,
            },
            {
              label: "Type",
              dataField: "type",
              required: true,
              editorType: "dxSelectBox",
              editorOptions: { dataSource: contractDocumentTypes },
            },
            {
              resource: "GENERAL.FORMS.FIELD.STATUS",
              dataField: "status",
              required: true,
              editorType: "dxSelectBox",
              editorOptions: { dataSource: contractDocumentStatuses },
            },
            {
              label: "Issued Date",
              dataField: "issueddatedisplay",
              editorType: "dxDateBox",
              required: true,
              validation: { type: 'range', min: new Date(1900, 0, 1), max: new Date(2079, 5, 6), warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_DATE" })}
            },
          ],
        },
      ],
    };

    return (
      <Portlet>
        <PortletHeader title={`Create Contract Documents`} />
        <PortletBody style={{ maxWidth: 1000 }}>
          <BiDynamicForm
            validatorRef={this.validatorRef}
            item={document}
            config={documentFormConfig}
            onSubmitHandler={this.handleSubmit}
          />
        </PortletBody>
      </Portlet>
    );
  }
}

/*const mapStateToProps = (store) => ({
  contract: store.contract.contract,
  contractUser: store.contract.user,
});*/

export default withCreatePageProps(DocumentCreate);
