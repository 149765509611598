import React from 'react';
import BaseCreatePage from '../../_CustomComponents/BaseCreatePage';
import { connect } from "react-redux";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import { Alert } from "react-bootstrap";
import * as requestForQuoteCrud from '../../../../crud/requestForQuote.crud';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import { FormattedMessage } from "react-intl";
import moment from 'moment';
import { withCreatePageProps } from '../../../../shared/hoc/withCreatePageProps';
import { paymentPeriods } from '../../../../shared/lookup';
import Common from '../../../../shared/common';
import { actionCodes } from "../../../../shared/lookup";

class RfqCreatePage extends BaseCreatePage {
    constructor(props) {
        super(props);
        this.createDefaultSubject = (((props.location || {}).state || {}).itemCustomProps || {}).subject;
        this.createDefaultAsset = (((props.location || {}).state || {}).itemCustomProps || {}).assetid;

        this.paymentPeriods = paymentPeriods.map(p => (this.props.intl.formatMessage({ id: "GENERAL.FORMS." + p })));
       
        this.state = {
            showAlert: true,
        };
    }

    submitHandler = (rfq, setSubmitting) => {
        const postEvents = (createdid) => {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_SUCCESS' }), { variant: 'success', });
            this.props.navigate(this.props.workflowItem.paths.basePath + createdid);
        };

        const { companyid, projectid, contractid } = this.props.contract;
        
        rfq.companyid = companyid;
        rfq.contractid = contractid;
        rfq.projectid = projectid;

        if (rfq.startdate) {
            rfq.startdatedisplay = moment(rfq.startdate).format('DD/MM/YYYY');
        }

        if (rfq.completiondate) {
            rfq.completiondatedisplay = moment(rfq.completiondate).format('DD/MM/YYYY');
        }

        rfq.status = 'Not Communicated';
        rfq.showtasknotification = 0;
        rfq.actioncode = actionCodes.CREATE;
        rfq.notifyingparty = this.props.workflowItem.notifyingParty;
        this.appendRelationship(rfq);
        Common.PropertyConversionsBeforeNoticeSaveRequest(rfq, this.props.customFields, this.props.contractUsers);
        requestForQuoteCrud.saveRequestForQuote(rfq)
            .then(response => {
                const createdID = Number(response.data);
                postEvents(createdID);
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', key: "PersistentFormWarning", persist: true });
            });
    }

    render() {

        const createFormConfig = {
            buttonResource: "GENERAL.FORMS.BUTTON_CREATE_DRAFT",
            fields: [
                { resource: 'GENERAL.FORMS.FIELD.SUBJECT', dataField: 'subject', editorOptions: { defaultValue: this.createDefaultSubject } },
                { note: this.props.resources.RequestForQuoteNote.replace('$date', moment().add(this.props.globalResources.RFQ_QUOTE_DURATION, 'days').format('L')) },
                { resource: "GENERAL.FORMS.FIELD.DETAILS", dataField: 'description', editorType: 'dxHtmlEditor' }
            ]
        };

        if (this.props.configurableRFQFields) {
            if (this.props.configurableRFQFields.StartDate) {
                createFormConfig.fields.push({ label: this.props.configurableRFQFields.StartDate, dataField: 'startdate', editorType: 'dxDateBox', validation: { type: 'range', min: new Date(1900, 0, 1), max: new Date(2079, 5, 6), warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_DATE" })}})
            }
            if (this.props.configurableRFQFields.CompletionDate) {
                const completionDateField = { label: this.props.configurableRFQFields.CompletionDate, dataField: 'completiondate', editorType: 'dxDateBox', validation: { type: 'range', min: new Date(1900, 0, 1), max: new Date(2079, 5, 6), warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_DATE" })} }
                
                if (this.props.configurableRFQFields.StartDate){
                    completionDateField.validation =  { type: 'custom', validationCallback: (obj, value) => (obj.startdate <= value), warning: 'Completion date can not be earlier than start date' };
                }
                createFormConfig.fields.push(completionDateField);
            }
            if (this.props.configurableRFQFields.DelayAmount) {
                createFormConfig.fields.push({ label: this.props.configurableRFQFields.DelayAmount, dataField: 'delaydamagestotal', editorType: 'dxNumberBox' })
            }
            if (this.props.configurableRFQFields.DelayPaymentPeriod) {
                createFormConfig.fields.push({ label: this.props.configurableRFQFields.DelayPaymentPeriod, dataField: 'delaydamagestype', editorType: 'dxSelectBox', editorOptions: { dataSource: this.paymentPeriods }})
            }
        }

        return (
            <Portlet>
                <PortletHeader title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.CREATE_TITLE' }, { ITEM_NAME: this.props.workflowItem.pageTitle })} />
                <PortletBody style={{ maxWidth: 1000 }}>
                    {this.state.showAlert && (
                        <Alert variant="secondary" onClose={() => this.setState({ showAlert: false })} dismissible>
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_DRAFT" />
                            </p>
                        </Alert>
                    )}
                    {this.props.resources.PmRequestForQuoteWarningBanner && (
                        <Alert variant="warning" >
                            <p>
                                {this.props.resources.PmRequestForQuoteWarningBanner}
                            </p>
                        </Alert>
                    )}
                    <BiDynamicForm
                        onSubmitHandler={this.submitHandler}
                        config={createFormConfig}
                        valueChangeHandler={this.onValueChanged}
                        customFields={(this.props.customFields || {}).COMMUNICATION}
                        assetFieldsEnabled={this.props.workflowItem.assetFieldsEnabled}
                        assetid={this.createDefaultAsset}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    contractUsers: store.contract.users
});

export default withCreatePageProps(connect(mapStateToProps)(RfqCreatePage));
