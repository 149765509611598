import React from 'react';
import { connect } from "react-redux";
import * as fileCrud from '../../../../crud/file.crud';
import Document from '../../_CustomComponents/DocumentV2';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import BaseDocument from '../../_CustomComponents/BaseDocument';
import { Alert } from "react-bootstrap";
import { withSnackbar } from 'notistack';
import { GetCurrencyCodes } from "../../../../shared/lookup";

export const calculateSubTotal = (obj) => {
    const prc1 = obj.othercumulativeprice ? obj.othercumulativeprice : 0;
    const prc2 = obj.otheramountpaid ? obj.otheramountpaid : 0;
    return prc1 + prc2;
}
export const calculateAmountDue = (obj) => {
    const prc1 = obj.subtotalpay ? obj.subtotalpay : 0;
    const prc2 = obj.subtotalretain ? obj.subtotalretain : 0;
    return prc1 - prc2;
}
// export const calculateSubTotalPreTax = (obj,totalcertified) => {
//     const prc1 = obj.otheramountretain ? obj.otheramountretain : 0;
//     const prc2 = props.contract.totalcertified;
//     return prc1 - prc2;
// }
export const calculateSubTotalPreTaxV2 = (obj) => {
    const prc1 = obj.otheramountretain ? obj.otheramountretain : 0;
    const prc2 = obj.lastpaymentcertificateamount ? obj.lastpaymentcertificateamount : 0;
    return prc1 - prc2;
}
export const calculateTotal = (obj) => {
    const prc1 = obj.othercertifiedpretax ? obj.othercertifiedpretax : 0;
    const prc2 = obj.othertaxamount ? obj.othertaxamount : 0;
    return prc1 + prc2;
}


class NotifyDocumentPage extends BaseDocument {
    constructor(props) {
        super(props);
        this.currencyCodes = GetCurrencyCodes();
        const bottomLabel = props.notificationType === 'Application' ? props.resources.PaymentAppliedPeriodLabel : props.resources.PaymentCertifiedPeriodLabel;

        const formatCurrency = (obj) => {
            const cur = this.currencyCodes.find(c => c.code === obj.currency)?.name || obj.currency;
            return cur;
        }
        this.editorFormConfig = {
            currencyCode: this.props.contract.currency,
            fields: []
        };
        if (props.clauses) {
            this.editorFormConfig.fields.push(
                { resource: 'GENERAL.FIELD.APPLICATIONTYPE', dataField: 'paymenttype', required: false, editorType: 'dxSelectBox', editorOptions: { dataSource: this.props.clauses, valueExpr: 'field', displayExpr: 'display' } }
            );
        }
        this.editorFormConfig.fields.push(
            { label: props.resources.AssessmentDayLabel, dataField: 'assessmentdate', required: true, editorType: 'dxDateBox', validation: { type: 'range', min: new Date(1900, 0, 1), max: new Date(2079, 5, 6), warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_DATE" })} },
            { label: props.resources.SummaryDetailsLabel, dataField: 'summary', required: true, editorType: 'dxHtmlEditor' },
            { resource: "GENERAL.FORMS.CURRENCY", value: formatCurrency },
            { label: props.resources.CumulativePriceLabel, dataField: 'othercumulativeprice', required: true, editorType: 'dxNumberBox' },
            { label: props.resources.PlusAmountsSupplierLabel, dataField: 'otheramountpaid', required: true, editorType: 'dxNumberBox' },
            { resource: "CONTRACT.WF.PAYMENT.FIELD.SUB_TOTAL", dataField: 'subtotalpay', required: true, editorType: 'dxNumberBox', value: calculateSubTotal },
            { label: props.resources.LessAmountsSupplierLabel, dataField: 'subtotalretain', required: true, editorType: 'dxNumberBox', editorOptions: { min: 0 } },
            { resource: "CONTRACT.WF.PAYMENT.FIELD.AMOUNT_DUE", dataField: 'otheramountretain', required: true, editorType: 'dxNumberBox', value: calculateAmountDue },
            { label: props.resources.LessAmountsDueLabel, dataField: 'lastpaymentcertificateamount', required: true, editorType: 'dxNumberBox' },
        );
        if (props.contract.totalcertified != null) {
            const lastPaymentField = this.editorFormConfig.fields.find(itm => itm.dataField === 'lastpaymentcertificateamount');
            lastPaymentField.value = (obj) => obj.lastpaymentcertificateamount;
        }

        if (props.taxEnabled) {
            this.editorFormConfig.fields.push(
                { resource: "CONTRACT.WF.PAYMENT.FIELD.SUB_TOTAL", dataField: 'othercertifiedpretax', required: true, editorType: 'dxNumberBox', value: calculateSubTotalPreTaxV2 },
                { label: props.resources.TaxSupplierLabel, dataField: 'othertaxamount', required: true, editorType: 'dxNumberBox', },
                { label: bottomLabel, dataField: 'othercertfiedamount', required: true, editorType: 'dxNumberBox', value: calculateTotal }
            )
        }
        else {
            this.editorFormConfig.fields.push(
                { label: bottomLabel, dataField: 'othercertfiedamount', required: true, editorType: 'dxNumberBox', value: calculateSubTotalPreTaxV2 }
            )
        }

        if (props.poNumberEnabled) {
            this.editorFormConfig.fields.push(
                { resource: 'CONTRACT.FIELD.PO_NUMBER', dataField: 'purchaseordernumber', required: false }
            );
        }

    }

    renderForm = () => {

        return (
            <>
                {this.props.resources.WarningBanner && (
                    <Alert variant="warning" >
                        <p>
                            {this.props.resources.WarningBanner}
                        </p>
                    </Alert>
                )}
                <BiDynamicForm
                    item={this.props.notification}
                    valueChangeHandler={this.props.fieldChanged}
                    config={this.editorFormConfig}
                    customFields={(this.props.customFields || {}).COMMUNICATION}
                    assetFieldsEnabled={this.props.workflowItem.assetFieldsEnabled}
                />
            </>
        );
    }

    uploadFile = (data, config) => {
        const { companyid, projectid, contractid } = this.props.contract;
        return fileCrud.uploadContractNotificationFiles(companyid, projectid, contractid, this.props.documentType, this.props.notification.paymentcertificateid, data, config)
    }



    render() {
        const documentIsFinal = this.props.notification.showtasknotification === 1;
        return (
            <Document
                key={documentIsFinal}
                docConfig={this.props.notification.pdfjsoncontent}
                isFinal={documentIsFinal}
                printDivRef={this.props.printDivRef}
                workflowItem={this.props.workflowItem}
                contract={this.props.contract}
                reference={this.props.notification.referenceid}
                form={documentIsFinal ? null : this.renderForm()}
                uploadMethod={documentIsFinal ? null : this.uploadFile}
                onFilesCountChanged={this.props.onFilesCountChanged}
                files={this.props.files}
                notificationid={this.props.notificationid}
                documentType={this.props.documentType}
                customFields={(this.props.customFields || {}).COMMUNICATION}
                customFieldValues={this.props.notification.customfieldvalues}
                assetid={this.props.notification.assetid}
            />
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
    UI_VERSION: store.application.UI_VERSION,
});

export default withSnackbar(connect(mapStateToProps)(NotifyDocumentPage));
