/* eslint-disable no-self-assign */
import React from 'react';
import BaseDetailPage from '../../_CustomComponents/BaseDetailPage';
import ReactToPrint from "react-to-print";
import { Navigate } from "react-router-dom";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import * as certificateCrud from '../../../../crud/certificate.crud';
import * as fileCrud from '../../../../crud/file.crud';
import axios from 'axios';
import ActionModal from '../../_CustomComponents/ActionModal';
import DocumentMain from './DocumentMain';
import DocumentReply from './DocumentReply';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import { Alert } from "react-bootstrap";
import { ValidationSummary, ValidationGroup } from 'devextreme-react';
import moment from 'moment';
import Relations from '../../_CustomComponents/Relation/Relations';
import Common from '../../../../shared/common';
import { FormattedMessage } from "react-intl";
import PageToolbar from '../../_CustomComponents/PageToolbar';
import { addDays } from 'date-fns';
import { getConfigurableFields } from './CustomForm';
import { withDetailPageProps } from '../../../../shared/hoc/withDetailPageProps';
import NavigationBlocker from '../../../../partials/Tools/NavigationBlocker';
import EmailNotifications from '../../_CustomComponents/EmailNotifications';
const initialState = {
    notification: null,
    files: null,
    showModal: false,
    saving: false,
    // isDxFormValid: true,
    isChanged: false,
    //NEW REPLY STATES
    reply: null,
    replyFiles: null,
    isAwaitingReply: false,
    modalTitle: '',
    modalText: '',
    modalMode: '',
    relationCount: 0,
    postActionUrl: null,
    relations: null,
    selectedClause: null
}

class CertificateDetailPage extends BaseDetailPage {
    constructor(props) {
        super(props);
        this.isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'DEV';
        this.isRelationsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true' || process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true';
        this.printIssueDivRef = React.createRef();
        this.printAcceptanceDivRef = React.createRef();
        //NEW REPLY
        this.validatorRef = React.createRef();
        this.validatorName = 'CertificateValidator';
        this.state = { ...initialState };
        const replyOptionsPH = props.placeholders.find(ph => ph.replacementKey === 'ReplyOptions');
        if (replyOptionsPH) {
            this.replyOptions = replyOptionsPH.values.filter(item => item.isActive === true);
        }
    }

    componentDidMount() {
        this.fetchNotification();
        this.fetchFiles();
        this.fetchReplyFiles();
    }

    componentDidUpdate(prevprops, prevSatate) {
        if (this.props.params.notificationid !== prevprops.params.notificationid) {
            this.setState({ ...initialState }, () => {
                this.fetchNotification();
                this.fetchFiles();
                this.fetchReplyFiles();
            });
        }
    }

    getMailDescription = () => {
        return this.state.notification.subject;
    }
    getSelectedTypeClauseDisplay = () => {
        return this.state.notification.type;
    }

    fetchNotification = () => {
        certificateCrud.getCertificate(this.props.params.notificationid)
            .then(response => {
                const certificate = response.data;
                if (certificate.contractid !== this.props.contract.contractid ||
                    certificate.party !== this.props.notificationType) {
                    throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.INVALID_URL' }));
                }
                // if (certificate.draftreplydate) {
                //     certificate.draftreplydate = new Date(certificate.draftreplydate);
                // }
                if (certificate.createdate) {
                    certificate.createdate = new Date(certificate.createdate);
                }
                if (certificate.certifieddate) {
                    certificate.certifieddate = new Date(certificate.certifieddate);
                }
                if (certificate.notificationdate) {
                    certificate.notificationdate = new Date(certificate.notificationdate);
                }
                if (certificate.draftreplydate) {
                    certificate.draftreplydate = new Date(certificate.draftreplydate);
                }
                if (certificate.replyrequireddate) {
                    certificate.replyrequireddate = new Date(certificate.replyrequireddate);
                }
                if (!certificate.replyingparty) {
                    certificate.replyingparty = this.props.workflowItem.replyingParty;
                }
                let selectedClause = null;
                if (certificate.type && this.props.certTypes) {
                    selectedClause = this.props.certTypes.find(c => c.display === certificate.type);
                    if (selectedClause) {
                    }
                    else {
                        if (certificate.showtasknotification !== 1) {
                            certificate.type = null;
                            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_CLAUSE_MISSING' }), { variant: 'warning', });
                        }
                    }
                }

                const awaitingReply = (certificate.showtasknotification === 1 && certificate.status === 'Awaiting Acceptance' && certificate.isreplyrequired);
                const replyItem = {
                    status: certificate.draftreplyaction,
                    customfieldvalues: certificate.customfieldvalues
                };


                this.setState({
                    notification: certificate,
                    reply: replyItem,
                    isAwaitingReply: awaitingReply,
                    saving: false,
                    isChanged: false,
                    relations: certificate.relations,
                    relationCount: Common.GetRelationCount(certificate, this.props.accessibleWfIds),
                }, () => { this.props.setReference(this.state.notification.referenceid); });
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                }
            });
    }

    fetchFiles = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.notificationid, this.props.documentType)
            const filesList = JSON.parse(data.aggregatelist)
            this.setState({ files: filesList });
            return filesList;
        } catch (err) {
            if (!axios.isCancel(err)) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
        }
    }
    fetchReplyFiles = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.notificationid, this.props.documentType + 'Reply')
            const filesList = JSON.parse(data.aggregatelist)
            this.setState({ replyFiles: filesList });
            return filesList;
        } catch (err) {
            if (!axios.isCancel(err)) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
        }
    }
    printHandler = () => {
        this.printIssueRef.handlePrint();
    }
    printAcceptanceHandler = () => {
        this.printAcceptanceRef.handlePrint();
    }
    validateForm = () => {
        const validationResult = this.validatorRef.current.instance.validate();
        if (!validationResult.isValid) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_MISSING_FIELDS' }), { variant: 'warning', });
        }
        return validationResult.isValid && !this.state.saving;
    }
    showModal = (mode, postActionNavigateObj) => {
        let selectedClause = null;
        if (this.state.notification.type && this.props.certTypes) {
            selectedClause = this.props.certTypes.find(c => c.display === this.state.notification.type);
        }
        if (mode === 'COMMUNICATE') {
            if (this.validateForm()) {
                let modalText = this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_COMMUNICATING' }, { ITEM_NAME: this.props.title, PARTY_NAME: this.props.communicateToUser });
                modalText += " " + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_COMMUNICATING_APPEND' });
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_COMMUNICATE_ITEM' }, { ITEM_NAME: this.props.title }),
                    modalText: modalText,
                    modalMode: mode,
                    postActionUrl: postActionNavigateObj,
                    selectedClause
                });
            }
        }
        else if (mode === 'SAVE_DRAFT') {
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_DRAFT_NOTIFY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
                    modalMode: mode,
                });
            }
        }
        else if (mode === 'DELETE_DRAFT') {
            this.setState({
                showModal: true,
                modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_DELETE_DRAFT_NOTIFY' }),
                modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_DELETING' }),
                modalMode: mode,
            });
        }
        else if (mode === 'REPLY_DRAFT') {
            //show reply tab
            this.tabPanel.instance.option("selectedIndex", 1);
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_REPLY_DRAFT_NOTIFY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
                    modalMode: mode,
                });
            }
        }
        else if (mode === 'REPLY_SEND') {
            //show reply tab
            this.tabPanel.instance.option("selectedIndex", 1);
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SEND_REPLY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_REPLY_SENDING' }, { PARTY_NAME: this.props.globalResources[this.props.workflowItem.notifyingParty + "_NAME"] }),
                    modalMode: mode,
                    selectedClause
                });
            }
        }
    }

    onModalResponse = (response, params) => {
        this.setState({ showModal: false, saving: response === 'OK' }, async () => {
            try {
                if (response === 'OK') {



                    if (this.state.modalMode === 'COMMUNICATE') {

                        const notification = { ...this.state.notification };
                        notification.createactions = params?.createactions;
                        notification.completeactions = params?.completeactions;
                        notification.createbyid = this.props.contractUser.accountid;
                        notification.showtasknotification = 1;

                        if (!this.props.workflowItem.replyEnabled) {
                            //reply not possible
                            notification.isreplyrequired = false;
                            notification.status = 'Communicated';
                        }
                        else {
                            notification.isreplyrequired = true;
                            notification.status = 'Awaiting Acceptance';
                            notification.replyrequireddate = moment(addDays(new Date(), this.props.workflowItem.replyPeriod)).toDate();
                        }

                        const docBodyConfig = await this.documentBodyConfig(notification, this.props.resources);
                        const docConfig = await this.documentConfig(notification, docBodyConfig, this.props.contractUser.name, false, null, null, this.props.workflowItem.form_NotifyCompanyName);
                        notification.pdfjsoncontent = JSON.stringify(docConfig);

                        this.saveNotification(notification, this.state.modalMode);

                    }
                    else if (this.state.modalMode === 'SAVE_DRAFT') {

                        const newNotification = { ...this.state.notification };
                        this.saveNotification(newNotification, this.state.modalMode, params ? params.userid : null);

                    }
                    else if (this.state.modalMode === 'DELETE_DRAFT') {

                        const certificate = { ...this.state.notification };
                        const certificateid = certificate.certificateid
                        this.deleteCertificateDraft(certificateid);

                    }
                    else if (this.state.modalMode === 'REPLY_DRAFT') {

                        const reply = { ...this.state.reply };
                        const newNotification = { ...this.state.notification };
                        newNotification.draftreplyaction = reply.status;
                        for (let key in reply) {
                            if (key.startsWith("CF_")) {
                                newNotification[key] = reply[key];
                            }
                        }
                        this.saveNotification(newNotification, this.state.modalMode, (params || {}).userid);

                    }
                    else if (this.state.modalMode === 'REPLY_SEND') {
                        const reply = { ...this.state.reply };
                        if (!this.replyValidation(reply)) {
                            //FINAL REPLY FIELDS CHECK FAILED
                            throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_REPLY_FIELDS_MISSING' }))
                        }

                        const newNotification = { ...this.state.notification };
                        newNotification.createactions = params?.createactions;
                        newNotification.completeactions = params?.completeactions;
                        newNotification.status = reply.status;
                        for (let key in reply) {
                            if (key.startsWith("CF_")) {
                                newNotification[key] = reply[key];
                            }
                        }
                        const docReplyBodyConfig = this.documentReplyBodyConfig(newNotification, this.props.resources, this.replyOptions);
                        const docReplyConfig = await this.documentConfig(newNotification, docReplyBodyConfig, this.props.contractUser.name, true, null, null, this.props.workflowItem.form_ReplyCompanyName);
                        newNotification.pdfreplyjsoncontent = JSON.stringify(docReplyConfig);
                        this.saveNotification(newNotification, this.state.modalMode);

                    }


                }
            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                this.setState({ saving: false });
            }

        });

    }
    replyValidation = (reply) => {
        if (!reply.status) { return false; }
        return true;
    }
    getTaskURL = (notification) => {
        const { contractid, taskorderid } = notification;
        return `${window.location.origin}/contract/${contractid}/tasks/pm/${taskorderid}`;
    }

    getProjectURL = (notification) => {
        const { contractid, projectorderid } = notification;
        return `${window.location.origin}/contract/${contractid}/projects/pm/${projectorderid}`;
    }

    documentBodyConfig = async (notification, resources) => {
        const config = [
            { resource: 'GENERAL.FORMS.FIELD.SUBJECT', value: notification.subject },
            { resource: 'GENERAL.FORMS.FIELD.TYPE', value: notification.type }
        ];

        const effect = this.props.certTypes?.find(item => item.display === notification.type)?.effect;

        if (effect === 'TASK_ORDER_COMPLETION') {
            config.push({ label: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.TASKORDER' }, { TASK_ORDER: this.props.globalResources.WF_38_NOTIFICATION_NAME }), text: notification.taskorderreference ? notification.taskorderreference : "", valueType: 'LINK', url: this.getTaskURL(notification) });
        } else if (effect === 'PROJECT_ORDER_COMPLETION') {
            config.push({ label: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.PROJECTORDER' }, { PROJECT_ORDER: this.props.globalResources.WF_72_NOTIFICATION_NAME }), text: notification.projectorderreference ? notification.projectorderreference : "", valueType: 'LINK', url: this.getProjectURL(notification) });
        }

        const configurableFields = getConfigurableFields(this.props.certTypes, notification.type, this.props.placeholders)
        if (configurableFields) {
            if (configurableFields.CertifiedDate) {
                config.push({ label: configurableFields.CertifiedDate, value: notification.certifieddate });
            }
            if (configurableFields.Description) {
                config.push({ label: configurableFields.Description, value: notification.description, valueType: 'HTML', align: 'vertical' });
            }
            if (configurableFields.Works) {
                config.push({ label: configurableFields.Works, value: notification.works, valueType: 'HTML', align: 'vertical' });
            }
            if (configurableFields.Defects) {
                config.push({ label: configurableFields.Defects, value: notification.defects, valueType: 'HTML', align: 'vertical' });
            }
        }

        return config;
    }
    documentReplyBodyConfig = (notification, resources, replyOptions) => {
        let statusDisplay = "UNKNOWN";

        const selectedReply = replyOptions.find(opt => opt.field === notification.status);
        if (selectedReply) {
            statusDisplay = selectedReply.display;
        }

        const docConfig = [
            { resource: "GENERAL.FORMS.FIELD.STATUS", value: statusDisplay },
        ];

        return docConfig;
    }
    deleteCertificateDraft = async (certificateid) => {

        try {
            await certificateCrud.deleteCertificateDraft(certificateid);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_DELETED' }), { variant: 'success' });
            this.setState({ saving: false, navigateto: this.props.workflowItem.paths.listPath });

        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            this.setState({ saving: false });
        }

    }

    saveNotification = async (notification, action, notifyuserid) => {
        try {
            if (!notification.certifieddatedisplay && notification.certifieddate) {
                notification.certifieddatedisplay = moment(notification.certifieddate).format('DD/MM/YYYY');
            }
            //#TODO certificateclassstate check needs to be moved to API side
            //UI doesnt need to tell this, API can check from contracts template
            // if (this.props.certClass === 'COMPLETION' && action === 'COMMUNICATE') {
            //     if (this.props.compChangesStateEnabled) {
            //         notification.certificateclassstate = 'StateChange';
            //     } else {
            //         notification.certificateclassstate = 'Completion';
            //     }
            //     //notification.contractcompletiondate = moment(notification.certifieddate).format('DD/MM/YYYY');
            // }

            const effect = this.props.certTypes?.find(item => item.display === notification.type)?.effect;

            if (!effect) {
                delete notification.taskorderid;
                delete notification.taskorderreference;
                delete notification.projectorderid;
                delete notification.projectorderreference;
            }
            else if (effect === 'TASK_ORDER_COMPLETION') {
                delete notification.projectorderid;
                delete notification.projectorderreference;
            } else if (effect === 'PROJECT_ORDER_COMPLETION') {
                delete notification.taskorderid;
                delete notification.taskorderreference;
            }

            const configurableFields = getConfigurableFields(this.props.certTypes, notification.type, this.props.placeholders)

            if (configurableFields) {
                if (configurableFields.CertifiedDate) {
                    notification.certifieddate = notification.certifieddate;
                } else {
                    delete notification.certifieddate;
                }
                if (configurableFields.Description) {
                    notification.description = notification.description;
                } else {
                    delete notification.description;
                }
                if (configurableFields.Works) {
                    notification.works = notification.works;
                } else {
                    delete notification.works;
                }
                if (configurableFields.Defects) {
                    notification.defects = notification.defects;
                } else {
                    delete notification.defects;
                }
            }
            if (this.props.workflowItem.replyEnabled) {
                notification.replyingparty = this.props.workflowItem.replyingParty;
            }
            notification.actioncode = action;
            notification.notifyuserlist = this.getNotifyUserListForUpdate(action, notifyuserid);
            Common.PropertyConversionsBeforeNoticeSaveRequest(notification, this.props.customFields, this.props.contractUsers);
            await certificateCrud.updateCertificate(notification);
            if (this.state.postActionUrl) {
                this.setState({ isChanged: false, saving: false, navigateto: this.state.postActionUrl.pathname });
            } else {
                this.fetchNotification();
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), { variant: 'success' });
            }
        } catch (err) {
            this.setState({ saving: false });
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
    }
    fieldChanged = (field, value, isDxFormValid, additionalData) => {
        //SILENT STATE CHANGE
        //WE DONT WANT COMPONENT RERENDER WİTH THIS
        //this.setState({ notification: { ...this.state.notification, [field]: value } });
        if (field) {
            if (field === "assetid") {
                this.setState({
                    notification: { ...this.state.notification, [field]: value, asset: { ...additionalData } },
                    isChanged: true
                });
            }
            else {
                this.state.notification[field] = value;
                if (!this.state.isChanged) {
                    this.setState({ isChanged: true });
                }
            }
        }

    }
    replyFieldChanged = (field, value, isDxReplyFormValid, isCheckBoxValue) => {
        //SILENT STATE CHANGE
        //WE DONT WANT COMPONENT RERENDER WİTH THIS
        //this.setState({ notification: { ...this.state.notification, [field]: value } });
        if (field) {
            this.state.reply[field] = value;
            if (!this.state.isChanged) {
                this.setState({ isChanged: true });
            }
        }

    }
    renderToolbar = (documentIsFinal) => {
        const saveDisabled = (this.state.saving || this.state.showModal);
        return (
            <PageToolbar
                key={this.state.notification.version}
                documentIsFinal={documentIsFinal}
                saveDisabled={saveDisabled}
                isAwaitingReply={this.state.isAwaitingReply}
                actions={{
                    showModal: this.showModal,
                    onPrintReply: this.printAcceptanceHandler,
                    onPrint: this.printHandler
                }}
                workflowItem={this.props.workflowItem}
                match={this.props.match}
                customFieldValues={this.state.notification.customfieldvalues}
                referenceid={this.state.notification.referenceid}
                contract={this.props.contract}
                relationParams={{
                    subject: this.getMailDescription(),
                    assetid: this.state.notification.assetid
                }}
                notification={this.state.notification}
                selectedClausetypeDisplay={this.getSelectedTypeClauseDisplay()}
            />
        );
    }

    tabDocument = () => {
        return (
            <DocumentMain
                {...this.props}
                printDivRef={this.printIssueDivRef}
                fieldChanged={this.fieldChanged}
                notification={this.state.notification}
                files={this.state.files}

                notificationid={this.props.params.notificationid}
            />
        );
    }

    tabReply = () => {
        const { saveDraftReplyAllowed, communicateReplyAllowed } = this.props.workflowItem.userFilters.replyNotice;
        let canSeeReplyDraft = communicateReplyAllowed || saveDraftReplyAllowed;

        if (!canSeeReplyDraft && this.state.isAwaitingReply) {
            return (
                <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                    <div style={{ width: '100%', maxWidth: '900px', margin: '20px' }}>
                        <Alert variant="warning" >
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_AWAITING_REPLY" values={{ ITEM_NAME: this.props.workflowItem.pageTitle }} />
                            </p>
                        </Alert>
                    </div>
                </div>
            )
        }
        else {
            return (
                <DocumentReply
                    {...this.props}
                    isAwaitingReply={this.state.isAwaitingReply}
                    printDivRef={this.printAcceptanceDivRef}
                    fieldChanged={this.replyFieldChanged}
                    notification={this.state.notification}
                    reply={this.state.reply}

                    files={this.state.replyFiles}
                    notificationid={this.props.params.notificationid}
                    replyOptions={this.replyOptions}
                />
            )
        }
    }
    tabRelations = () => {
        return (
            <Relations
                contractid={this.props.contract.contractid}
                workflowItem={this.props.workflowItem}
                workflowGroupItem={this.props.workflowGroupItem}
                notificationid={Number(this.props.params.notificationid)}
                relations={this.state.notification.relations}
                itemversion={this.state.notification.version}
                subject={this.state.notification.subject}
                onCountChange={(count) => this.setState({ relationCount: count })}
                showTags={this.props.contract.specifictags?.length > 0}
            />
        );
    }
    tabEmailNotification = () => {

        return (
            <EmailNotifications
                {...this.props}
                notificationDate={this.state.notification.notificationdate}
            />
        );

    };
    render() {
        if (!this.state.notification || !this.state.files || !this.state.replyFiles) {
            return (<PortletSpinner />);
        }

        const documentIsFinal = this.state.notification.showtasknotification === 1;
        const showReply = documentIsFinal && this.state.notification.isreplyrequired;
        if (!documentIsFinal && !this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed) {
            return (<Navigate replace to={this.props.workflowItem.paths.defaultPath} />)
        }

        return (
            <Portlet>
                <ReactToPrint ref={el => (this.printIssueRef = el)} trigger={() => (<div style={{ display: 'none' }} ></div>)} content={() => this.printIssueDivRef.current} />
                <ReactToPrint ref={el => (this.printAcceptanceRef = el)} trigger={() => (<div style={{ display: 'none' }} ></div>)} content={() => this.printAcceptanceDivRef.current} />
                <PortletHeader
                    title={documentIsFinal ? this.props.workflowItem.pageTitle : this.props.intl.formatMessage({ id: "GENERAL.FORMS.DRAFT_TITLE" }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
                    toolbar={this.renderToolbar(documentIsFinal)} />
                <NavigationBlocker
                    when={(this.state.isChanged || this.state.saving)}
                    message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}
                />
                {this.state.navigateto &&
                    (
                        <Navigate replace to={this.state.navigateto} state={(this.state.postActionUrl || {}).state} />
                    )
                }
                <PortletBody>
                    <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
                        <div>
                            <ValidationSummary elementAttr={{ align: "right", paddingBottom: 50 }} id={this.validatorName}></ValidationSummary>
                        </div>
                        <TabPanel
                            ref={(ref) => this.tabPanel = ref}
                            width="100%"
                            deferRendering={false}
                            animationEnabled={true}
                            focusStateEnabled={true}
                            hoverStateEnabled={true}
                            swipeEnabled={false}
                        >
                            <Item icon="file" title={this.props.title} render={this.tabDocument} />
                            <Item icon="rename" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_REPLY' })} render={showReply ? this.tabReply : null} visible={showReply} />
                            {
                                this.isRelationsEnabled && (
                                    <Item icon="share" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_RELATIONS' })} render={this.tabRelations} badge={this.state.relationCount > 0 ? this.state.relationCount : null} />
                                )
                            }
                            <Item
                                icon="bulletlist"
                                title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_EMAIL_NOTIFICATIONS' })}
                                render={documentIsFinal ? this.tabEmailNotification : null} visible={documentIsFinal}
                            />
                        </TabPanel>
                    </ValidationGroup>
                </PortletBody>
                <ActionModal
                    visible={this.state.showModal}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    mode={this.state.modalMode}
                    onDialogResult={this.onModalResponse}
                    workflowItem={this.props.workflowItem}
                    selectedClause={this.state.selectedClause}
                />
            </Portlet>
        );
    }
}



export default withDetailPageProps(CertificateDetailPage);
