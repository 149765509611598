import { userFilterCategories } from "../../../shared/lookup";
const workflows = [
    {
        id: 5,
        title: '$PM_NAME Compensation Events',
        notificationName: 'Compensation Event',
        abbreviation: '$PM_ABBREVIATION-Compensation-',
        notifyingParty: 'PM',
        quotingParty: 'SUPPLIER',
        quoteReplyingParty: 'PM',
        quoteNotificationName:'Quote',
        quoteReferencePrefix:'Quote-',

        notify: true,
        financialLimitEnabled: false,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'PM Compensation Events',
            currentModel: 'CompensationEvent',
            currentModelType: 'PM',
            referenceType: 'CompensationEvent',
            quotationType: '623PM',
            quotationDocumentType: 'Document623PM',


            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: false,
            quotationPossible: true,
            configurableFinancialLimit: true,
            configurableAssets: true,
            allowOverrideResources: true,


            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.QUOTATION,
                userFilterCategories.QUOTE_REPLY,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'pm',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 6,
        title: '$PM_NAME Proposed Instructions',
        notificationName: 'Proposed Instruction',
        abbreviation: '$PM_ABBREVIATION-Instruction-',
        notifyingParty: 'PM',
        quotingParty: 'SUPPLIER',
        quoteReplyingParty: 'PM',
        quoteNotificationName:'Quote',
        quoteReferencePrefix:'Quote-',

        notify: true,
        financialLimitEnabled: false,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'PM Proposed Instructions',
            currentModel: 'CompensationEvent',
            currentModelType: 'PRO',
            referenceType: 'CompensationEvent',
            quotationType: '612PM',
            quotationDocumentType: 'Document612PMQ',


            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: false,
            quotationPossible: true,
            configurableFinancialLimit: true,
            configurableAssets: true,
            allowOverrideResources: true,


            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.QUOTATION,
                userFilterCategories.QUOTE_REPLY,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'instructions',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 7,
        title: '$SUPPLIER_NAME Compensation Events',
        notificationName: 'Compensation Event',
        abbreviation: '$SUPPLIER_ABBREVIATION-Compensation-',
        notifyingParty: 'SUPPLIER',
        replyingParty: 'PM',
        quotingParty: 'SUPPLIER',
        quoteReplyingParty: 'PM',
        quoteNotificationName:'Quote',
        quoteReferencePrefix:'Quote-',

        //REPLY
        replyEnabled: true,
        replyPeriodOption: "VARIATION_RESPONSE",
        replyPeriod: 0,

        notify: true,
        financialLimitEnabled: false,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supplier Compensation Events',
            currentModel: 'CompensationEvent',
            currentModelType: 'CON',
            referenceType: 'CompensationEvent',
            quotationType: '623CON',
            quotationDocumentType: 'Document623CON',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            quotationPossible: true,
            configurableFinancialLimit: true,
            configurableAssets: true,
            configurableReplyingParty: true,
            allowOverrideResources: true,

            replyPeriodOptions: ["VARIATION_RESPONSE", "CUSTOM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.QUOTATION,
                userFilterCategories.QUOTE_REPLY,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'supplier',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 37,
        title: '$SUPPLIER_NAME Claim',
        notificationName: 'Claim',
        abbreviation: '$SUPPLIER_ABBREVIATION-Claim-',
        notifyingParty: 'SUPPLIER',
        quotingParty: 'SUPPLIER',
        quoteReplyingParty: 'PM',
        quoteNotificationName:'Quote',
        quoteReferencePrefix:'Quote-',

        notify: true,
        financialLimitEnabled: false,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supplier Claims With Quotation',
            currentModel: 'CompensationEvent',
            currentModelType: 'CWQ',
            referenceType: 'CompensationEvent',
            quotationType: '623CWQ',
            quotationDocumentType: 'Document623CWQ',


            showInRelations: true,
            showInCustomFields: false,
            notifyPossible: true,
            replyPossible: false,
            quotationPossible: true,
            configurableFinancialLimit: true,
            configurableAssets: true,
            allowOverrideResources: true,
            
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.QUOTATION,
                userFilterCategories.QUOTE_REPLY,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'claim',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 46,
        title: '$SUPPLIER_NAME Claim',
        notificationName: 'Claim',
        abbreviation: '$SUPPLIER_ABBREVIATION-Claim-',
        notifyingParty: 'SUPPLIER',
        replyingParty: 'PM',
        //REPLY
        replyEnabled: true,
        replyPeriodOption: "VARIATION_RESPONSE",
        replyPeriod: 0,

        notify: true,
        financialLimitEnabled: false,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supplier Claims',
            currentModel: 'CompensationEvent',
            currentModelType: 'CLAIM',
            referenceType: 'CompensationEvent',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableFinancialLimit: true,
            configurableAssets: true,
            allowOverrideResources: true,

            replyPeriodOptions: ["VARIATION_RESPONSE", "CUSTOM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'claims',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 40,
        title: '$SUPPLIER_NAME Compensation Events',
        notificationName: 'Compensation Event',
        abbreviation: '$SUPPLIER_ABBREVIATION-Compensation-',
        notifyingParty: 'SUPPLIER',
        replyingParty: 'PM',
        quotingParty: 'SUPPLIER',
        quoteReplyingParty: 'PM',
        quoteNotificationName:'Quote',
        quoteReferencePrefix:'Quote-',

        //REPLY
        replyEnabled: true,
        replyPeriodOption: "VARIATION_RESPONSE",
        replyPeriod: 0,

        notify: true,
        financialLimitEnabled: false,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supplier Compensation Events(Short Term)',
            currentModel: 'CompensationEvent',
            currentModelType: 'SCON',
            referenceType: 'CompensationEvent',
            quotationType: '623SCON',
            quotationDocumentType: 'Document623SCON',


            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            quotationPossible: true,
            configurableFinancialLimit: true,
            configurableAssets: true,
            allowOverrideResources: true,

            replyPeriodOptions: ["VARIATION_RESPONSE", "CUSTOM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.QUOTATION,
                userFilterCategories.QUOTE_REPLY,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'short-supplier',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 8,
        title: '$PM_NAME Assessments',
        notificationName: 'Assessment',
        abbreviation: '$PM_ABBREVIATION-Assessment-',
        notifyingParty: 'PM',

        notify: true,
        financialLimitEnabled: false,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'PM Assessments',
            currentModel: 'InspectionDefect',
            currentModelType: '61.4PM',
            referenceType: 'InspectionDefect_Assessment',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: false,
            configurableFinancialLimit: true,
            configurableAssets: true,

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'assessment',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 50,
        title: '$PM_NAME Time Assessments',
        notificationName: 'Assessment',
        abbreviation: 'Assessment-Time-',
        notifyingParty: 'PM',

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'PM Time Assessments',
            currentModel: 'InspectionDefect',
            currentModelType: 'AssessmentTime',
            referenceType: 'InspectionDefect_Assessment',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: false,
            configurableAssets: true,

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'assessment-time',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 51,
        title: '$PM_NAME Price Assessments',
        notificationName: 'Assessment',
        abbreviation: 'Assessment-Price-',
        notifyingParty: 'PM',

        notify: true,
        financialLimitEnabled: false,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'PM Price Assessments',
            currentModel: 'InspectionDefect',
            currentModelType: 'AssessmentPrice',
            referenceType: 'InspectionDefect_Assessment',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: false,
            configurableFinancialLimit: true,
            configurableAssets: true,

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'assessment-price',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 42,
        title: '$SUPPLIER_NAME Assumptions',
        notificationName: 'Assumption',
        abbreviation: 'Assumption-',
        notifyingParty: 'SUPPLIER',
        replyingParty: 'PM',
        //REPLY
        replyEnabled: true,
        replyPeriodOption: "ASKED_ON_COMMUNICATION_FORM",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supplier Assumptions',
            currentModel: 'InspectionDefect',
            currentModelType: 'Assumptions',
            referenceType: 'Assumption',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableAssets: true,

            replyPeriodOptions: ["ASKED_ON_COMMUNICATION_FORM", "CUSTOM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'assumption',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 9,
        title: '$PM_NAME Extensions',
        notificationName: 'Extension',
        abbreviation: '$PM_ABBREVIATION-Extension-',
        notifyingParty: 'PM',
        replyingParty: 'SUPPLIER',
        //REPLY
        replyEnabled: true,
        replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'PM Extensions',
            currentModel: 'InspectionDefect',
            currentModelType: 'PmExtension',
            referenceType: 'Extension',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableAssets: true,


            replyPeriodOptions: ["SUPPLIER_REPLY_PERIOD", "CUSTOM", "ORIGINAL_DATE"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'pm-extension',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 10,
        title: '$SUPPLIER_NAME Extensions',
        notificationName: 'Extension',
        abbreviation: '$SUPPLIER_ABBREVIATION-Extension-',
        notifyingParty: 'SUPPLIER',
        replyingParty: 'PM',
        //REPLY
        replyEnabled: true,
        replyPeriodOption: "PM_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supplier Extensions',
            currentModel: 'InspectionDefect',
            currentModelType: 'SupExtension',
            referenceType: 'Extension',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableAssets: true,

            replyPeriodOptions: ["PM_REPLY_PERIOD", "CUSTOM", "ORIGINAL_DATE"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'supplier-extension',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 67,
        title: '$SUPPLIER_NAME Claims with Notice',
        notificationName: 'Notice of Claim',
        abbreviation: '$SUPPLIER_ABBREVIATION-ClaimNotice-',
        notifyingParty: 'SUPPLIER',
        replyingParty: 'PM',

        quotingParty: 'SUPPLIER',
        quoteReplyingParty: 'PM',
        quoteNotificationName:'Quote',
        quoteReferencePrefix:'Quote-',

        //REPLY
        replyEnabled: true,
        replyPeriodOption: "VARIATION_RESPONSE",
        replyPeriod: 0,

        notify: true,
        financialLimitEnabled: false,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supplier Claims with Notice',
            currentModel: 'CompensationEvent',
            currentModelType: 'CWN',
            referenceType: 'CompensationEvent',
            quotationType: '623CWN',
            quotationDocumentType: 'Document623CWN',


            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            quotationPossible: true,
            configurableFinancialLimit: true,
            configurableAssets: true,
            configurableReplyTab: true,
            allowOverrideResources: true,

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.QUOTATION,
                userFilterCategories.QUOTE_REPLY,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'claim-with-notice',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 75,
        title: '$CLIENT_NAME Claims with Notice',
        notificationName: 'Notice of Claim',
        abbreviation: '$CLIENT_ABBREVIATION-ClaimNotice-',
        notifyingParty: 'CLIENT',
        replyingParty: 'PM',

        quotingParty: 'CLIENT',
        quoteReplyingParty: 'PM',
        quoteNotificationName:'Quote',
        quoteReferencePrefix:'Quote-',

        //REPLY
        replyEnabled: true,
        replyPeriodOption: "VARIATION_RESPONSE",
        replyPeriod: 0,

        notify: true,
        financialLimitEnabled: false,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Client Claims with Notice',
            currentModel: 'CompensationEvent',
            currentModelType: 'CLIENTCWN',
            referenceType: 'CompensationEvent',
            quotationType: '623CLIENTCWN',
            quotationDocumentType: 'Document623CLIENTCWN',


            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            quotationPossible: true,
            configurableFinancialLimit: true,
            configurableAssets: true,
            configurableReplyTab: true,
            allowOverrideResources: true,

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.QUOTATION,
                userFilterCategories.QUOTE_REPLY,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'client-cwn',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 76,
        title: '$CLIENT_NAME Compensation Events',
        notificationName: 'Compensation Event',
        abbreviation: '$CLIENT_ABBREVIATION-Compensation-',
        notifyingParty: 'CLIENT',
        replyingParty: 'PM',
        quotingParty: 'PM',
        quoteReplyingParty: 'SUPERVISOR',
        quoteNotificationName:'Quote',
        quoteReferencePrefix:'Quote-',

        //REPLY
        replyEnabled: true,
        replyPeriodOption: "VARIATION_RESPONSE",
        replyPeriod: 0,

        notify: true,
        financialLimitEnabled: false,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Client Compensation Events',
            currentModel: 'CompensationEvent',
            currentModelType: 'CLIENT',
            referenceType: 'CompensationEvent',
            quotationType: '623CLIENT',
            quotationDocumentType: 'Document623CLIENT',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            quotationPossible: true,
            configurableFinancialLimit: true,
            configurableAssets: true,
            configurableReplyingParty: true,
            allowOverrideResources: true,

            replyPeriodOptions: ["VARIATION_RESPONSE", "CUSTOM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.QUOTATION,
                userFilterCategories.QUOTE_REPLY,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'client',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 11,
        title: 'Master Quote Register',
        config: {
            description: 'Master Quote Register',

            showInRelations: false,
            showInCustomFields: false,
            replyPossible: false,

            userFilterCategories: [
                userFilterCategories.ACCESS
            ],
            paths: {
                base: 'register-quote',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 12,
        title: 'Master CE Register',
        config: {
            description: 'Master CE Register',
            showInRelations: false,
            showInCustomFields: false,
            replyPossible: false,


            userFilterCategories: [
                userFilterCategories.ACCESS
            ],
            paths: {
                base: 'register',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 13,
        title: 'Implemented CE Register',
        config: {
            description: 'Implemented CE Register',

            showInRelations: false,
            showInCustomFields: false,
            replyPossible: false,


            userFilterCategories: [
                userFilterCategories.ACCESS
            ],
            paths: {
                base: 'register-implemented',
                list: 'list',
                default: 'list'
            }
        }
    },


]

export default workflows;