import React from 'react';
import { withSnackbar } from 'notistack';
import * as fileCrud from '../../../../crud/file.crud';
import Document from '../../_CustomComponents/DocumentV2';
import { connect } from "react-redux";
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import BaseDocument from '../../_CustomComponents/BaseDocument';
import { Alert } from "react-bootstrap";
import moment from 'moment';
import { injectIntl } from "react-intl";
import { paymentPeriods } from '../../../../shared/lookup';

class RfqDocument extends BaseDocument {
    constructor(props) {
        super(props);
        this.paymentPeriods = paymentPeriods.map(p => (this.props.intl.formatMessage({ id: "GENERAL.FORMS." + p })));
    }

    renderForm = () => {
        const editorFormConfig = {
            fields: [
                { resource: 'GENERAL.FORMS.FIELD.SUBJECT', dataField: 'subject' },
                { note: this.props.resources.RequestForQuoteNote.replace('$date', moment().add(this.props.globalResources.RFQ_QUOTE_DURATION, 'days').format('L')) },
                { resource: 'GENERAL.FORMS.FIELD.DETAILS', dataField: 'description', editorType: 'dxHtmlEditor' }
            ]
        };

        if (this.props.configurableRFQFields) {
            if (this.props.configurableRFQFields.StartDate) {
                const startDateField = { label: this.props.configurableRFQFields.StartDate, dataField: 'startdate', editorType: 'dxDateBox', validation: { type: 'range', min: new Date(1900, 0, 1), max: new Date(2079, 5, 6), warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_DATE" })} };
                if (this.props.configurableRFQFields.CompletionDate) {
                    startDateField.validation = { type: 'custom', validationCallback: (obj, value) => (obj.completiondate >= value), warning: 'Completion date can not be earlier than start date' }
                }
                editorFormConfig.fields.push(startDateField)
            }
            if (this.props.configurableRFQFields.CompletionDate) {
                const completionDateField = { label: this.props.configurableRFQFields.CompletionDate, dataField: 'completiondate', editorType: 'dxDateBox', validation: { type: 'range', min: new Date(1900, 0, 1), max: new Date(2079, 5, 6), warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_DATE" })} }

                if (this.props.configurableRFQFields.StartDate) {
                    completionDateField.validation = { type: 'custom', validationCallback: (obj, value) => (obj.startdate <= value), warning: 'Completion date can not be earlier than start date' }
                }

                editorFormConfig.fields.push(completionDateField);
            }
            if (this.props.configurableRFQFields.DelayAmount) {
                editorFormConfig.fields.push({ label: this.props.configurableRFQFields.DelayAmount, dataField: 'delaydamagestotal', editorType: 'dxNumberBox' })
            }
            if (this.props.configurableRFQFields.DelayPaymentPeriod) {
                editorFormConfig.fields.push({ label: this.props.configurableRFQFields.DelayPaymentPeriod, dataField: 'delaydamagestype', editorType: 'dxSelectBox', editorOptions: { dataSource: this.paymentPeriods } })
            }
        }

        return (
            <>
                {this.props.resources.PmCompensationWarningBanner && (
                    <Alert variant="warning" >
                        <p>
                            {this.props.resources.PmCompensationWarningBanner}
                        </p>
                    </Alert>
                )}
                <BiDynamicForm
                    item={this.props.notification}
                    config={editorFormConfig}
                    valueChangeHandler={this.props.fieldChanged}
                    customFields={(this.props.customFields || {}).COMMUNICATION}
                    assetFieldsEnabled={this.props.workflowItem.assetFieldsEnabled}
                />
            </>
        );
    }

    uploadFile = (data, config) => {
        const { companyid, projectid, contractid } = this.props.contract;
        return fileCrud.uploadContractNotificationFiles(companyid, projectid, contractid, this.props.documentType, this.props.notification.requestforquoteid, data, config)
    }



    render() {
        const documentIsFinal = this.props.notification.showtasknotification === 1;

        return (
            <Document
                key={documentIsFinal}
                docConfig={this.props.notification.pdfjsoncontent}
                isFinal={documentIsFinal}
                printDivRef={this.props.printDivRef}
                workflowItem={this.props.workflowItem}
                contract={this.props.contract}
                reference={this.props.notification.referenceid}
                form={documentIsFinal ? null : this.renderForm()}
                uploadMethod={documentIsFinal ? null : this.uploadFile}
                files={this.props.files}
                notificationid={this.props.notificationid}
                documentType={this.props.documentType}
                customFields={(this.props.customFields || {}).COMMUNICATION}
                customFieldValues={this.props.notification.customfieldvalues}
                assetid={this.props.notification.assetid}
            />
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract
});

export default injectIntl(withSnackbar(connect(mapStateToProps)(RfqDocument)));
