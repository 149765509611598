import React from 'react';
import BaseCreatePage from '../../_CustomComponents/BaseCreatePage';
import { connect } from "react-redux";
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import * as inspectionCrud from '../../../../crud/inspectionDefect.crud';
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import { Alert } from "react-bootstrap";
import moment from 'moment';
import { FormattedMessage } from "react-intl";
import { addYears } from 'date-fns';
import { withCreatePageProps } from '../../../../shared/hoc/withCreatePageProps';
import Common from '../../../../shared/common';
import { actionCodes } from "../../../../shared/lookup";

class IssueCreate extends BaseCreatePage {
    constructor(props) {
        super(props);        
        this.paramFieldsProvided = this.checkNecessaryParamFields();

        if (this.paramFieldsProvided) {
            const { defectReference, defectDetails, subject } = props.location.state.itemCustomProps
            const createFormConfig = {
                buttonResource: "GENERAL.FORMS.BUTTON_CREATE_DRAFT",
                fields: [
                    { resource: 'GENERAL.FORMS.FIELD.SUBJECT', dataField: 'replyactiondetail', value: () => (subject) },
                    { note: this.props.resources.NCProposalNote.replace('$date', moment().add(props.workflowItem.replyPeriod, 'days').format('L')) },
                    { resource: 'CONTRACT.WF.DEFECTS.REFERENCE', dataField: 'location', value: () => (defectReference) },
                    { resource: 'GENERAL.FORMS.FIELD.DETAILS', dataField: 'inspectiondetails', editorType: 'dxHtmlEditor', value: () => (defectDetails) },
                    { resource: 'GENERAL.FORMS.FIELD.CONDITIONS', dataField: 'assistancetestserchdefect', editorType: 'dxHtmlEditor' },
                    { resource: "CONTRACT.WF.NC_PROPOSAL.FIELD.PROP_CORRECTION_DATE", dataField: 'inspectiondate', editorType: 'dxDateBox', editorOptions: { calendarOptions: { min: new Date() }, max: addYears(new Date(), 10) }, validation: { type: 'range', min: moment().subtract(1, 'days'), max: addYears(new Date(), 10), warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_DATE" })} }

                ]
            };

            this.state = {
                showAlert: true,
                createFormConfig: createFormConfig
            };
        }
        else {
            props.enqueueSnackbar("Redirection with wrong parameters, Please use \"Create Related Item\" button in defect notification forms", { variant: 'error', key: "PersistentFormWarning", persist: true });
        }
    }
    checkNecessaryParamFields = () => {
        const { defectReference, defectDetails, subject } = ((this.props.location.state || {}).itemCustomProps || {});
        return defectReference && defectDetails && subject;
    }
    submitHandler = (notification, setSubmitting) => {
        if (notification.inspectiondate && !notification.inspectiondatedisplay && !notification.inspectiondatedisplay) {
            notification.inspectiondatedisplay = moment(notification.inspectiondate).format('DD/MM/YYYY HH:mm:ss');
        }
        notification.companyid = this.props.contract.companyid;
        notification.contractid = this.props.contract.contractid;
        notification.projectid = this.props.contract.projectid;
        notification.replystatus = 'Not Communicated';
        // notification.notificationdatedisplay = moment().format('DD/MM/YYYY'); // moment().format('DD/MM/YYYY');
        // notification.replyrequireddate = moment(addDays(new Date(), this.props.replyPeriod)).toDate();
        notification.type = this.props.notificationType;
        notification.actioncode = actionCodes.CREATE;
        notification.notifyingparty = this.props.workflowItem.notifyingParty;
        notification.replyingparty = this.props.workflowItem.replyingParty;
        this.appendRelationship(notification);
        Common.PropertyConversionsBeforeNoticeSaveRequest(notification, this.props.customFields, this.props.contractUsers);
        inspectionCrud.saveInspectionDefect(notification)
            .then(response => {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_SUCCESS' }), { variant: 'success', });
                try {
                    const createdID = Number(response.data);
                    this.props.navigate(this.props.workflowItem.paths.basePath + createdID);
                }
                catch {
                    this.props.navigate(this.props.workflowItem.paths.listPath);
                }
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', key: "PersistentFormWarning", persist: true });
            });
    }

    render() {
        if (!this.paramFieldsProvided) {
            return (<PortletSpinner />);
        }
        return (
            <Portlet>
                <PortletHeader title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.CREATE_TITLE' }, { ITEM_NAME: this.props.workflowItem.pageTitle })} />
                <PortletBody style={{ maxWidth: 1000 }}>
                    {this.state.showAlert && (
                        <Alert variant="secondary" onClose={() => this.setState({ showAlert: false })} dismissible>
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_DRAFT" />
                            </p>
                        </Alert>
                    )}
                    <BiDynamicForm
                        config={this.state.createFormConfig}
                        onSubmitHandler={this.submitHandler}
                        customFields={(this.props.customFields || {}).COMMUNICATION}
                        assetFieldsEnabled={this.props.workflowItem.assetFieldsEnabled}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    contractUsers: store.contract.users
});

export default withCreatePageProps(connect(mapStateToProps)(IssueCreate));
