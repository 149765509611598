export const RoutesConfig = {
    "/admin/companies": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator", "User Administrator",] },
    "/admin/companies/new-company": { access: ["System Owner", "System Administrator", "Site Administrator", "Site Support", "Super User", "Contract Administrator", "User Administrator",], actions: { allowEnablingCompanyUsers: ["System Owner", "System Administrator", "Site Administrator"] } },
    "/admin/companies/:id/overview": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator", "User Administrator",], actions: { allowActivateDeactivate: ["System Owner", "System Administrator", "Site Administrator", "Site Support"], allowChangeLogo: ["Site Support", "Site Administrator", "Company Administrator", "System Administrator", "System Owner"], allowEnablingCompanyUsers: ["System Owner", "System Administrator", "Site Administrator"] } },
    "/admin/companies/:id/users": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator", "User Administrator",] },
    "/admin/companies/:id/new-company-user": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator", "User Administrator",] },
    "/admin/companies/:id/users/:id2": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator", "User Administrator",], actions: { allowActivateDeactivate: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "User Administrator"], allowUpdateCompany: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support"] } },
    "/admin/companies/:id/hubs": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator", "User Administrator",], prerequisiteFlag: "REACT_APP_HUBS_USERS_ENABLED" },
    "/admin/projects": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator",] },
    "/admin/projects/new-project": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator",] },
    "/admin/projects/:id/overview": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator",], actions: { allowChangeState: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator"] } },
    "/admin/settings": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Contract Administrator",] },
    "/admin/settings/tags": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Contract Administrator",] },
    "/admin/settings/system": { access: ["System Owner",] },
    "/admin/settings/system/:id": { access: ["System Owner",] },
    "/admin/settings/custom-fields": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator",] },
    "/admin/settings/webhook": { access: ["System Owner",], prerequisiteFlag: "REACT_APP_WEBHOOKS_ENABLED" },
    "/admin/users/manage-system-users": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "User Administrator",] },
    "/admin/users/contract-add-bulk-users": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support",], prerequisiteFlag: "REACT_APP_ADD_BULK_CONTRACT_USER_ENABLED" },
    "/admin/templates/master-workflows": { access: ["System Owner", "System Administrator", "Site Administrator", "Site Support",], actions: { allowUpdate: ["System Owner", "System Administrator", "Site Administrator"], allowImportExport: ["System Owner", "System Administrator"] } },
    "/admin/templates/master-templates": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support",] },
    "/admin/templates/template-editor": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support",] },
    "/admin/templates/template-editor/:id": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support",], actions: { allowUpdate: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator"], allowImportExport: ["System Owner", "System Administrator"] } },
    "/reports/powerbi/exports": { access: ["System Owner", "System Administrator",], prerequisiteFlag: "REACT_APP_POWERBI_ENABLED", additionalAccessProperty: "allowpowerbimenus" },
    "/reports/powerbi/reports": { access: ["System Owner", "System Administrator",], prerequisiteFlag: "REACT_APP_POWERBI_ENABLED", additionalAccessProperty: "allowpowerbimenus" },
    "/reports/powerbi/registers": { access: ["System Owner", "System Administrator",], prerequisiteFlag: "REACT_APP_POWERBI_ENABLED", additionalAccessProperty: "allowpowerbimenus" },
    "/reports/contract-users": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator", "User Administrator",] },
    "/reports/contract-lead-users": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator", "User Administrator",] },
    "/reports/system-users": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator", "User Administrator",] },
    "/downloads/aggregate": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator", "View All",] },
    "/contract/:id/dashboard": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator", "User Administrator", "View All", "View Own", "View All Hub", "General User",] },
    "/contract/:id/reports/health": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator", "User Administrator", "View All", "View Own", "View All Hub", "General User",] },
    "/contract/:id/reports/rag": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator", "User Administrator", "View All", "View Own", "View All Hub", "General User",] },
    "/contract/:id/settings/overview": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator",], actions: { allowEditDemo: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support"], allowExport: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator",], allowChangeState: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator"], allowClone: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator"], allowChangeTemplate: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support"] } },
    "/contract/:id/settings/users": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator", "User Administrator", "View All", "View Own", "View All Hub", "General User",], actions: { allowEdit: ["System Owner", "System Administrator", "Site Administrator", "Site Support", "Company Administrator", "Super User", "Contract Administrator", "User Administrator"], allowSeeFinancialFields: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator"], allowSeeDashboardButtons: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator"] } },
    "/contract/:id/settings/secondary-dates": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator", "User Administrator", "View All", "View Own", "View All Hub", "General User",], contractFlag: "section", actions: { allowEdit: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator"] } },
    "/contract/:id/settings/secondary-prices": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator", "User Administrator", "View All", "View Own", "View All Hub", "General User",], prerequisiteFlag: "REACT_APP_SECONDARY_PRICES_ENABLED", contractFlag: "otherprice", actions: { allowEdit: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator"] } },
    "/contract/:id/settings/actions": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator", "User Administrator", "View All", "View Own", "View All Hub", "General User",], prerequisiteFlag: "REACT_APP_ACTIONS_ENABLED", actions: { allowEdit: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support"] } },
    "/contract/:id/settings/assets": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator", "User Administrator", "View All", "View Own", "View All Hub", "General User",], prerequisiteFlag: "REACT_APP_ASSETS_ENABLED" },
    "/contract/:id/settings/email-recipients": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator",] },
    "/contract/:id/settings/template": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator",], actions: { allowUpdate: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support"], allowImportExport: ["System Owner", "System Administrator"], allowClone: ["System Owner"], allowOverride: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support"], allowSeeHistory: ["System Owner"] } },
    "/contract/:id/settings/exports": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator",], prerequisiteFlag: "REACT_APP_EXPORT_ENABLED" },
    "/contract/:id/settings/price-list": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Contract Administrator",], prerequisiteFlag: "REACT_APP_PRICELIST_ENABLED" },
    "/contract/:id/settings/price-list/:id/price-list-items": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Contract Administrator",], prerequisiteFlag: "REACT_APP_PRICELIST_ENABLED" },
    "/contracts/new-contract": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator",], actions: { allowEditDemo: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support"] } },
    "/user/:id/overview": { access: ["System Owner", "System Administrator", "Site Administrator", "Company Administrator", "Site Support", "Super User", "Contract Administrator", "User Administrator", "View All", "View Own", "View All Hub", "General User",] },

};