/* eslint-disable no-self-assign */
import React from "react";
import BaseDetailPage from "../../_CustomComponents/BaseDetailPage";
import { Navigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import {
  Portlet,
  PortletBody,
  PortletHeader
} from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import * as quotationCrud from "../../../../crud/quotation.crud";
import * as fileCrud from "../../../../crud/file.crud";
import axios from "axios";
import ActionModal from '../../_CustomComponents/ActionModal';
import QuoteDocument, { effectTypesNoProgramReply, effectTypes } from "./Document";
import ReplyDocument from "./ReplyDocument";
import { TabPanel, Item } from "devextreme-react/tab-panel";
import { Alert } from "react-bootstrap";
import { ValidationSummary, ValidationGroup } from "devextreme-react";
import Common from "../../../../shared/common";
import moment from "moment";
import CostBreakdown from "./CostBreakdown";
import { FormattedMessage } from "react-intl";
import { CBS_ContractTypes_Quotes, customFieldsWfSubTypes } from '../../../../shared/lookup';
import PageToolbar from '../../_CustomComponents/PageToolbar';
import { withDetailPageProps } from '../../../../shared/hoc/withDetailPageProps';
import NavigationBlocker from '../../../../partials/Tools/NavigationBlocker';
import { confirm } from 'devextreme/ui/dialog';
import EmailNotifications from "../../_CustomComponents/EmailNotifications";
const initialState = {
  notification: null,
  files: null,
  showModal: false,
  saving: false,
  // isDxFormValid: true,
  isChanged: false,
  //NEW REPLY STATES
  reply: null,
  replyFiles: null,
  isAwaitingReply: false,
  modalTitle: "",
  modalText: "",
  modalMode: "",
  quotationActivitiesList: null,
  relationCount: 0
};

class QuoteDetailPage extends BaseDetailPage {
  constructor(props) {
    super(props);
    this.printIssueDivRef = React.createRef();
    this.printAcceptanceDivRef = React.createRef();
    this.isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'DEV';
    this.wbsEnabled = (process.env.REACT_APP_COST_BREAKDOWN_ENABLED === 'true' && CBS_ContractTypes_Quotes.includes(props.contract.contracttype));
    //NEW REPLY
    this.validatorRef = React.createRef();
    this.validatorName = "QuoteValidator";
    //NAVIGATE TO QUOTE TAB
    if (props.notificationType === "612PM") {
      //INSTRUCTION
      this.timer = setTimeout(() => {
        props.parentTabPanel.instance.option("selectedIndex", 1);
      }, 200);
    } else if (props.notificationType === "623CON") {
      this.timer = setTimeout(() => {
        props.parentTabPanel.instance.option("selectedIndex", 2);
      }, 200);
    }
    else if (props.notificationType === "623CLIENT") {
      this.timer = setTimeout(() => {
        props.parentTabPanel.instance.option("selectedIndex", 2);
      }, 200);
    }
    else if (props.notificationType === "623CWQ") {
      this.timer = setTimeout(() => {
        props.parentTabPanel.instance.option("selectedIndex", 1);
      }, 200);
    }
    else if (props.notificationType === '623SCON' || props.notificationType === '623CWN' || props.notificationType === '623CLIENTCWN') {
      //SUPPLIER CONPENSATION (SHORT TYPE)
      this.timer = setTimeout(() => {
        props.parentTabPanel.instance.option('selectedIndex', 2);
      }, 200);
    }
    else if (props.notificationType === "623PM") {
      //PM CONPENSATION
      this.timer = setTimeout(() => {
        props.parentTabPanel.instance.option("selectedIndex", 1);
      }, 200);
    }

    this.quoteStatuses = props.placeholders
      .find((ph) => ph.replacementKey === "QuoteStatuses")
      .values.filter((item) => item.isActive === true);


    if (props.programmeReplyEnabled) {
      this.effectTypes = effectTypes;
    }
    else {
      this.effectTypes = effectTypesNoProgramReply;
    }
    if (props.notificationType === '612PM') {
      this.proceedOptions = null;
      const proceedOptsPH = props.placeholders.find(ph => ph.replacementKey === 'InstructionReplyProceedOpts');
      if (proceedOptsPH) {
        this.proceedOptions = proceedOptsPH.values.filter(item => item.isActive === true);
      }
    }
    this.state = {
      ...initialState
    };
  }

  componentDidMount() {
    this.fetchQuote();
    this.fetchFiles();
    this.fetchReplyFiles();
    this.loadQuotationActivitiesList();
  }
  componentDidUpdate(prevprops, prevSatate) {
    if (
      this.props.params.quotationid !== prevprops.params.quotationid
    ) {
      this.setState({ ...initialState }, () => {
        this.fetchQuote();
        this.fetchFiles();
        this.fetchReplyFiles();
        this.loadQuotationActivitiesList();
      });
    }
  }
  getMailDescription = () => {
    return this.state.notification.quotereference;
  };
  getSelectedTypeClauseDisplay = () => {
    return "";
  }
  fetchQuote = async () => {
    await this.props.closeSnackbar("PersistentFormWarning");
    quotationCrud
      .getCompensationQuote(this.props.params.quotationid)
      .then((response) => {
        const quote = response.data;
        if (
          quote.contractid !== this.props.contract.contractid ||
          quote.type !== this.props.notificationType ||
          quote.compensationeventid !==
          this.props.compensation.compensationeventid
        ) {
          throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.INVALID_URL' }));
        }
        if (quote.createdate) {
          quote.createdate = new Date(quote.createdate);
        }
        if (quote.responsedate) {
          quote.responsedate = new Date(quote.responsedate);
        }
        if (quote.responsecreatedate) {
          quote.responsecreatedate = new Date(quote.responsecreatedate);
        }
        if (quote.notificationdate) {
          quote.notificationdate = new Date(quote.notificationdate);
        }
        if (quote.draftreplydate) {
          quote.draftreplydate = new Date(quote.draftreplydate);
        }
        if (quote.effecttoprogram === 1) {
          quote.effect = "noeffect";
        } else if (quote.effecttoprogrammitigated === 1) {
          quote.effect = "mitigated";
        } else if (quote.effecttoprogramnotmitigated === 1) {
          quote.effect = "notmitigated";
        }
        const awaitingReply = quote.showtasknotification === 1 && (quote.responsestatus === 2 || quote.responsestatus === 6);
        const initialStatus = quote.draftquotestatus == null ? null : Number(quote.draftquotestatus);
        const replyItem = {
          responsecomment: quote.draftreplycomment,
          responsestatus: initialStatus,
          proceed: quote.draftreplyproceed,
          customfieldvalues: quote.customfieldvalues,
          otherpricechanges: quote.otherpricechanges,
          otherdatechanges: quote.otherdatechanges
        };

        this.setState({
          notification: quote,
          reply: replyItem,
          isAwaitingReply: awaitingReply,
          saving: false,
          isChanged: false,
        }, () => { this.props.setReference(this.state.notification.referenceid); });
      })
      .catch((err) => {
        this.props.enqueueSnackbar(err.toString(), { variant: "error" });
      });
  };

  fetchFiles = async () => {
    try {
      const { companyid, projectid, contractid } = this.props.contract;
      const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.quotationid, this.props.documentType)
      const filesList = JSON.parse(data.aggregatelist)
      this.setState({ files: filesList });
      return filesList;
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
      }
    }
  }
  fetchReplyFiles = async () => {
    try {
      const { companyid, projectid, contractid } = this.props.contract;
      const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.quotationid, this.props.documentType + 'Reply')
      const filesList = JSON.parse(data.aggregatelist)
      this.setState({ replyFiles: filesList });
      return filesList;
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
      }
    }
  }


  printHandler = () => {
    this.printIssueRef.handlePrint();
  };
  printAcceptanceHandler = () => {
    this.printAcceptanceRef.handlePrint();
  };
  validateForm = () => {
    const validationResult = this.validatorRef.current.instance.validate();

    if (!validationResult.isValid) {
      this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_MISSING_FIELDS' }), {
        variant: "warning",
      });
    }

    let costBreakdownisValid = true;

    let sumIsValid = true;


    return (
      validationResult.isValid &&
      costBreakdownisValid &&
      sumIsValid &&
      !this.state.saving
    );
  };
  showModal = async (mode, postActionNavigateObj) => {
    if (mode === "COMMUNICATE") {
      if (this.validateForm()) {
        const positiveAmountConfirmed = await this.confirmPositiveAmount(this.state.notification);

        if (!positiveAmountConfirmed) {
          //if not confirmed exit save
          return;
        } else {
          this.setState({
            showModal: true,
            modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_COMMUNICATE_ITEM' }, { ITEM_NAME: this.props.title }),
            modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_COMMUNICATING' }, { ITEM_NAME: this.props.title, PARTY_NAME: this.props.communicateToUser }),
            modalMode: mode,
            postActionUrl: postActionNavigateObj
          });
        }
      }
    }
    else if (mode === 'SAVE_DRAFT') {
      if (this.validateForm()) {
        const positiveAmountConfirmed = await this.confirmPositiveAmount(this.state.notification);

        if (!positiveAmountConfirmed) {
          //if not confirmed exit save
          return;
        } else {
          this.setState({
            showModal: true,
            modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_COMMUNICATE_ITEM' }, { ITEM_NAME: this.props.title }),
            modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_COMMUNICATING' }, { ITEM_NAME: this.props.title, PARTY_NAME: this.props.communicateToUser }),
            modalMode: mode,
            postActionUrl: postActionNavigateObj
          });
        }
      }
    }
    else if (mode === 'DELETE_DRAFT') {
      this.setState({
        showModal: true,
        modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_DELETE_DRAFT_NOTIFY' }),
        modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_DELETING' }),
        modalMode: mode,
      });
    }
    else if (mode === "REPLY_DRAFT") {
      //show reply tab
      this.tabPanel.instance.option("selectedIndex", 1);
      if (this.validateForm()) {
        this.setState({
          showModal: true,
          modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_REPLY_DRAFT_NOTIFY' }),
          modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
          modalMode: mode,
        });
      }
    } else if (mode === "REPLY_SEND") {
      //show reply tab
      this.tabPanel.instance.option("selectedIndex", 1);
      if (this.validateForm()) {
        this.setState({
          showModal: true,
          modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SEND_REPLY' }),
          modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_REPLY_SENDING' }, { PARTY_NAME: this.props.globalResources[this.props.workflowItem.notifyingParty + "_NAME"] }),
          modalMode: mode,
        });
      }
    }
  };
  confirmPositiveAmount = async (notification) => {
    if (this.props.notificationType === '623CLIENTCWN' || this.props.notificationType === '623CLIENT') {
      if (notification.amount > 0) {
        const warningMessage = this.props.intl.formatMessage({ id: 'GENERAL.FORMS.POSITIVE_AMOUNT_WARNING' });
        const warningMessageLabel = this.props.intl.formatMessage({ id: 'GENERAL.FORMS.POSITIVE_AMOUNT_WARNING_LABEL' });
        let result = await confirm(`<div style="max-width:50vw;">${warningMessage}</div>`, `${warningMessageLabel}`);
        return result;
      }

      return true;
    }

    return true;
  }
  onModalResponse = (response, params) => {
    this.setState({ showModal: false, saving: response === 'OK' }, async () => {
      try {
        if (response === 'OK') {


          if (this.state.modalMode === "COMMUNICATE") {
            const quote = { ...this.state.notification };
            const docBodyConfig = this.documentBodyConfig(quote, this.props.resources);
            const docConfig = await this.documentConfig(quote, docBodyConfig, this.props.contractUser.name, false, customFieldsWfSubTypes.QUOTE, null, this.props.workflowItem.form_NotifyCompanyName);
            quote.pdfjsoncontent = JSON.stringify(docConfig);

            this.saveQuote(quote, this.state.modalMode);
          }
          else if (this.state.modalMode === 'SAVE_DRAFT') {
            const notification = { ...this.state.notification };
            this.saveQuote(notification, this.state.modalMode, params ? params.userid : null);

          }
          else if (this.state.modalMode === "REPLY_DRAFT") {
            const reply = { ...this.state.reply };
            const quote = { ...this.state.notification };
            quote.draftquotestatus = reply.responsestatus;
            quote.draftreplycomment = reply.responsecomment;
            quote.draftreplyproceed = reply.proceed;
            // quote.draftreplystatus = "Drafted";
            // quote.draftreplybyid = this.props.contractUser.accountid;
            if ((params || {}).userid) {
              quote.draftreplytoid = params.userid;
            }
            quote.draftreplydatedisplay = moment().format("DD/MM/YYYY");
            for (let key in reply) {
              if (key.startsWith("CF_")) {
                quote[key] = reply[key];
              }
            }
            this.saveQuote(quote, this.state.modalMode, (params || {}).userid);

          } else if (this.state.modalMode === "REPLY_SEND") {
            if (!this.props.baseNotificationReadyForCreate) {
              throw new Error(this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.WARNING_WRONG_STATE' }));
            }
            const reply = { ...this.state.reply };
            if (!this.replyValidation(reply)) {
              //FINAL REPLY FIELDS CHECK FAILED
              throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_REPLY_FIELDS_MISSING' }))
            }
            const quote = { ...this.state.notification };
            quote.responsecomment = reply.responsecomment;
            quote.responsestatus = reply.responsestatus;
            quote.proceed = reply.proceed;
            for (let key in reply) {
              if (key.startsWith("CF_")) {
                quote[key] = reply[key];
              }
            }

            const docReplyBodyConfig = this.documentReplyBodyConfig(quote, this.props.resources, this.replyOptions);
            const docReplyConfig = await this.documentConfig(quote, docReplyBodyConfig, this.props.contractUser.name, true, customFieldsWfSubTypes.QUOTE_REPLY, null, this.props.workflowItem.form_ReplyCompanyName);
            quote.pdfreplyjsoncontent = JSON.stringify(docReplyConfig);

            this.saveQuote(quote, this.state.modalMode);
          }
          else if (this.state.modalMode === 'DELETE_DRAFT') {
            const compensationevent = { ...this.state.notification };
            console.log(compensationevent.quotationid)
            const quotationdid = compensationevent.quotationid
            this.deleteQuoteDraft(quotationdid);
          }

        }
      } catch (err) {
        this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        this.setState({ saving: false });
      }

    });
  };

  replyValidation = (reply) => {
    if (!reply.responsecomment) { return false; }
    return true;
  }


  deleteQuoteDraft = async (quotationdid) => {

    try {
      await quotationCrud.deleteQuoteDraft(quotationdid);
      this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_DELETED' }), { variant: 'success' });
      this.setState({ saving: false, navigateto: this.props.workflowItem.paths.listPath });

    } catch (err) {
      this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
      this.setState({ saving: false });
    }

  }



  saveQuote = async (quote, action, notifyuserid) => {
    const postEvents = () => {
      // this.notify(action, quote, notifyuserid);
      this.fetchQuote();

      this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), {
        variant: "success",
      });
    };
    try {
      if (!quote.createdatedisplay && quote.createdate) {
        quote.createdatedisplay = moment(quote.createdate).format("DD/MM/YYYY");
      }
      if (!quote.responsedatedisplay && quote.responsedate) {
        quote.responsedatedisplay = moment(quote.responsedate).format(
          "DD/MM/YYYY"
        );
      }
      if (!quote.responsecreatedatedisplay && quote.responsecreatedate) {
        quote.responsecreatedatedisplay = moment(
          quote.responsecreatedate
        ).format("DD/MM/YYYY");
      }

      if (!quote.draftreplydatedisplay && quote.draftreplydate) {
        quote.draftreplydatedisplay = moment(quote.draftreplydate).format(
          "DD/MM/YYYY"
        );
      }
      quote.effecttoprogram = quote.effect === "noeffect" ? 1 : 0;
      quote.effecttoprogrammitigated = quote.effect === "mitigated" ? 1 : 0;
      quote.effecttoprogramnotmitigated = quote.effect === "notmitigated" ? 1 : 0;
      quote.replyingparty = this.props.workflowItem.replyingParty;
      quote.actioncode = action;
      quote.notifyuserlist = this.getNotifyUserListForUpdate(action, notifyuserid);

      Common.PropertyConversionsBeforeNoticeSaveRequest(quote, this.props.customFieldsCommon, this.props.contractUsers);
      await quotationCrud.updateCompensationQuote(quote);
      this.props.onCompensationChanged();
      postEvents();
    } catch (err) {
      this.props.enqueueSnackbar(err.toString(), { variant: "error" });
      this.setState({
        saving: false,
      });
    }
  };
  fieldChanged = (field, value, isDxFormValid, additionalData) => {
    //SILENT STATE CHANGE
    //WE DONT WANT COMPONENT RERENDER WİTH THIS
    //this.setState({ notification: { ...this.state.notification, [field]: value } });
    if (field) {
      if (field === "assetid") {
        this.setState({
          notification: { ...this.state.notification, [field]: value, asset: { ...additionalData } },
          isChanged: true
        });
      }
      else {
        this.state.notification[field] = value;
        if (!this.state.isChanged) {
          this.setState({ isChanged: true });
        }
      }
    }

    // if (typeof isDxFormValid !== 'undefined' && isDxFormValid !== null) {
    //     if (isDxFormValid !== this.state.isDxFormValid) {
    //         this.setState({ isDxFormValid: isDxFormValid });
    //     }
    // }
  };
  replyFieldChanged = (field, value, isDxReplyFormValid, isCheckBoxValue) => {
    //SILENT STATE CHANGE
    //WE DONT WANT COMPONENT RERENDER WİTH THIS
    //this.setState({ notification: { ...this.state.notification, [field]: value } });
    if (field) {
      this.state.reply[field] = value;
      if (!this.state.isChanged) {
        this.setState({ isChanged: true });
      }
    }
  };

  renderToolbar = (documentIsFinal) => {
    const saveDisabled = (this.state.saving || this.state.showModal);
    return (
      <PageToolbar
                key={this.state.notification.version}
        documentIsFinal={documentIsFinal}
        saveDisabled={saveDisabled}
        isAwaitingReply={this.state.isAwaitingReply}
        actions={{
          showModal: this.showModal,
          onPrintReply: this.printAcceptanceHandler,
          onPrint: this.printHandler
        }}
        workflowItem={this.props.workflowItem}
        parentWorkflowItem={this.props.parentWorkflowItem}
        match={this.props.match}
        customFieldValues={this.state.notification.customfieldvalues}
        referenceid={this.state.notification.referenceid}
        contract={this.props.contract}
        relationParams={{
          subject: this.getMailDescription(),
          assetid: this.state.notification.assetid
        }}
        notification={this.state.notification}
        selectedClausetypeDisplay={this.getSelectedTypeClauseDisplay()}
      />
    );
  };

  tabDocument = () => {
    return (
      <QuoteDocument
        {...this.props}
        validatorRef={this.validatorRef}
        printDivRef={this.printIssueDivRef}
        fieldChanged={this.fieldChanged}
        notification={this.state.notification}
        files={this.state.files}
        notificationid={this.props.params.quotationid}
      />
    );
  };

  tabCostBreakdown = () => {
    return (
      <CostBreakdown
        {...this.props}
        notification={this.state.notification}
        quotationActivitiesList={this.state.quotationActivitiesList}
        loadQuotationActivitiesList={this.loadQuotationActivitiesList}
      />
    );
  };

  documentBodyConfig = (notification, resources) => {
    const effectType = this.effectTypes.find(x => x.value === notification.effect);
    const effect = effectType ? effectType.display : 'Unknown Type - ' + notification.effect;

    const bodyConfig = [
      { resource: "GENERAL.FORMS.FIELD.TITLE", value: notification.quotereference },

    ];
    if (this.props.compensation.compensationsource === "PRO" && resources.QuoteNoteProposedInstruction) {
      bodyConfig.push(
        { note: resources.QuoteNoteProposedInstruction.replace('$reference', this.props.compensation.referenceid), valueType: 'NOTE' },
      );
    }
    else {
      if (resources.QuoteNote) {
        let quoteNote = (resources.QuoteNote || '');
        if (quoteNote.includes("$reference")) {
          //FD-8804
          quoteNote = quoteNote.replace('$reference', this.props.compensation.referenceid);
        }
        else {
          //support historic template configurations FD-8804
          quoteNote = quoteNote + ' ' + this.props.compensation.referenceid;
        }
        bodyConfig.push(
          { note: quoteNote, valueType: 'NOTE' },
        );
      }
    }
    if ((this.props.mode === "CLAIMWN" || this.props.mode === "CLIENTCWN") && this.props.interimQuotesAllowed) {
      bodyConfig.push({ label: this.props.resources.QuoteContinuingEffectLabel, value: notification.continuingeffect ? this.props.intl.formatMessage({ id: 'GENERAL.FORMS.SWITCH_YES' }) : this.props.intl.formatMessage({ id: 'GENERAL.FORMS.SWITCH_NO' }) });
    }
    bodyConfig.push(
      { resource: "CONTRACT.WF.QUOTES.FIELD.SUMMARY", value: notification.quotecomment, valueType: 'HTML', align: 'vertical' },
      { label: resources.QuoteCostEffectLabel, value: Common.FormatCurrencyNum(notification.amount, (this.props.contract || {}).currency), valueType: 'CURRENCY', currencyCode: (this.props.contract || {}).currency },
      { label: resources.QuoteDateEffectLabel, value: effect },
    );

    if (notification.effect === 'mitigated' || notification.effect === 'notmitigated') {
      bodyConfig.push(
        { resource: "CONTRACT.WF.QUOTES.FIELD.PROGRAMME_DETAILS", value: notification.effecttoprogrammitigatedcomment, valueType: 'HTML', align: 'vertical' },
      );
    }

    if (notification.effect === 'notmitigated') {
      bodyConfig.push(
        { label: resources.QuoteDaysEffectLabel, value: `${notification.days} ${this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_DAYS' })}` },
      );
    }

    if (notification.effect === 'mitigated' || notification.effect === 'notmitigated') {
      bodyConfig.push(
        { label: resources.QuoteProgrammeAttachLabel, value: notification.attachedprogramme },
      );
    }

    return bodyConfig;
  }
  documentReplyBodyConfig = (notification, resources, replyOptions) => {
    let reply = '[UNKNOWN]';
    if (notification.responsestatus !== null && typeof notification.responsestatus !== 'undefined') {
      const statusItem = this.quoteStatuses.find(x => x.field === notification.responsestatus.toString());
      if (statusItem) {
        reply = statusItem.display;
      }
    }

    const config = [
      { resource: "CONTRACT.WF.QUOTES.FIELD.TITLE", value: notification.quotereference },
      { resource: "CONTRACT.WF.QUOTES.FIELD.AMOUNT", value: Common.FormatCurrencyNum(notification.amount, (this.props.contract || {}).currency), valueType: 'CURRENCY', currencyCode: (this.props.contract || {}).currency },
      { label: resources.QuoteDaysEffectLabel, value: `${notification.days} ${this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_DAYS' })}` },
      { resource: "CONTRACT.WF.QUOTES.FIELD.ASSESSMENT_STATUS", value: reply },
      { resource: "CONTRACT.WF.QUOTES.FIELD.ASSESSMENT_COMMENT", value: notification.responsecomment, valueType: 'HTML', align: 'vertical' },
    ];
    if (this.props.notificationType === '612PM' && notification.responsestatus !== 5 && notification.proceed) {
      let proceedDisplay = "UNKNOWN";
      if (this.proceedOptions) {
        const selectedOption = this.proceedOptions.find(x => x.field === notification.proceed);
        if (selectedOption) {
          proceedDisplay = selectedOption.display;
        }
      }
      config.splice(4, 0, { resource: "CONTRACT.WF.QUOTES.FIELD.PROCEED", value: proceedDisplay });
    }

    return config;
  }

  tabReply = () => {
    if (
      !this.props.workflowItem.userFilters.replyNotice.communicateReplyAllowed &&
      !this.props.workflowItem.userFilters.replyNotice.saveDraftReplyAllowed &&
      this.state.isAwaitingReply
    ) {
      let awaitMessage = "GENERAL.FORMS.WARNING_AWAITING_REPLY";
      if (this.state.notification.responsestatus === 6) {
        awaitMessage = "GENERAL.FORMS.WARNING_AWAITING_DEEMED_ACCEPTENCE";
      }
      return (
        <div style={{ width: "100%", height: "100%", display: "flex" }}>
          <div style={{ width: "100%", maxWidth: "900px", margin: "20px" }}>
            <Alert variant="warning">
              <p>
                <FormattedMessage id={awaitMessage} values={{ ITEM_NAME: this.props.workflowItem.pageTitle }} />
              </p>
            </Alert>
          </div>
        </div>
      );
    }
    else if (this.state.notification.responsestatus === 4) {
      ///Other Quote Approved
      return (
        <div style={{ width: "100%", height: "100%", display: "flex" }}>
          <div style={{ width: "100%", maxWidth: "900px", margin: "20px" }}>
            <Alert variant="warning">
              <p>
                {this.quoteStatuses.find(x => x.field === "4").display}
              </p>
            </Alert>
          </div>
        </div>
      );
    }
    else if (this.state.notification.responsestatus === 9) {
      ///Other Quote process ended
      return (
        <div style={{ width: "100%", height: "100%", display: "flex" }}>
          <div style={{ width: "100%", maxWidth: "900px", margin: "20px" }}>
            <Alert variant="warning">
              <p>
                {this.quoteStatuses.find(x => x.field === "9") ? this.quoteStatuses.find(x => x.field === "9").display : null}
              </p>
            </Alert>
          </div>
        </div>
      );
    }
    else {
      return (
        <ReplyDocument
          {...this.props}
          quoteStatuses={this.quoteStatuses}
          isAwaitingReply={this.state.isAwaitingReply}
          printDivRef={this.printAcceptanceDivRef}
          fieldChanged={this.replyFieldChanged}
          notification={this.state.notification}
          reply={this.state.reply}
          files={this.state.replyFiles}
          notificationid={this.props.params.quotationid}
        />
      );
    }
  };

  // tabRelations = () => {
  //   return (
  //     <Relations
  //       contractid={this.props.contract.contractid}
  //       referenceType={this.props.referenceType}
  //       notificationid={Number(this.props.params.quotationid)}
  //       subject={this.state.notification.assistancetestserchdefect}
  //       onCountChange={(count) => this.setState({ relationCount: count })}
  //       relatedItems={this.state.relatedItems}
  //     />
  //   );
  // }

  loadQuotationActivitiesList = () => {
    if (this.wbsEnabled) {
      const { companyid, projectid, contractid } = this.props.contract;

      quotationCrud
        .getQuotationActivities(
          companyid,
          projectid,
          contractid,
          this.props.params.quotationid,
          "Activity"
        )
        .then((response) => {
          this.setState({ quotationActivitiesList: JSON.parse(response.data.aggregatelist) });
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            this.props.enqueueSnackbar(err.toString(), { variant: "error" });
          }
        });
    }
  };
  tabEmailNotification = () => {

    return (
      <EmailNotifications
        {...this.props}
        notificationDate={this.state.notification.notificationdate}
      />
    );

  };
  render() {
    if (
      !this.state.notification ||
      !this.state.files ||
      !this.state.replyFiles
    ) {
      return <PortletSpinner />;
    }
    const documentIsFinal = this.state.notification.showtasknotification === 1;

    if (!documentIsFinal && !this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed) {
      return <Navigate to={".."} />;
    }

    return (
      <Portlet>
        <ReactToPrint
          ref={(el) => (this.printIssueRef = el)}
          trigger={() => <div style={{ display: "none" }}></div>}
          content={() => this.printIssueDivRef.current}
        />
        <ReactToPrint
          ref={(el) => (this.printAcceptanceRef = el)}
          trigger={() => <div style={{ display: "none" }}></div>}
          content={() => this.printAcceptanceDivRef.current}
        />
        <NavigationBlocker
          when={(this.state.isChanged || this.state.saving)}
          message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}
        />
        {this.state.navigateto &&
          (
            <Navigate replace to={this.state.navigateto} state={(this.state.postActionUrl || {}).state} />
          )
        }
        <PortletHeader
          title={documentIsFinal ? this.props.workflowItem.pageTitle : this.props.intl.formatMessage({ id: "GENERAL.FORMS.DRAFT_TITLE" }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
          toolbar={this.renderToolbar(documentIsFinal)}
        />
        <PortletBody>
          <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
            <div>
              <ValidationSummary
                elementAttr={{ align: "right", paddingBottom: 50 }}
                id={this.validatorName}
              ></ValidationSummary>
            </div>
            <TabPanel
              ref={(ref) => this.tabPanel = ref}
              width="100%"
              deferRendering={false}
              animationEnabled={true}
              focusStateEnabled={true}
              hoverStateEnabled={true}
              swipeEnabled={false}
            >
              <Item
                icon="file"
                title={this.props.title}
                render={this.tabDocument}
              />
              <Item
                icon="rename"
                title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_REPLY' })}
                render={documentIsFinal ? this.tabReply : null}
                visible={documentIsFinal}
              />
              {
                this.wbsEnabled && (
                  <Item
                    icon="bulletlist"
                    title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_COST_BREAKDOWN' })}
                    render={this.tabCostBreakdown}
                  />
                )
              }
              <Item
                icon="bulletlist"
                title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_EMAIL_NOTIFICATIONS' })}
                render={documentIsFinal ? this.tabEmailNotification : null} visible={documentIsFinal}
              />
            </TabPanel>
          </ValidationGroup>
        </PortletBody>
        <ActionModal
          visible={this.state.showModal}
          title={this.state.modalTitle}
          text={this.state.modalText}
          mode={this.state.modalMode}
          onDialogResult={this.onModalResponse}
          workflowItem={this.props.workflowItem}
          userLimit={(this.props.workflowItem.financialLimitEnabled && this.state.modalMode === 'REPLY_DRAFT') ? this.state.notification.amount : null}
        />
      </Portlet>
    );
  }
}

export default withDetailPageProps(QuoteDetailPage);