import { userFilterCategories } from "../../../shared/lookup";
const workflows = [
    {
        //#TODO : Supervisor & PM "Instruction to Search" workflows needs to be seperated 
        id: 22,
        title: 'Instruction to Search',
        notificationName: 'Instruction to Search',
        abbreviation: 'Search-',
        notifyingParty: (contract) => ([3,4].includes(contract?.partystructure) ? "SUPERVISOR" : "PM"),
        replyingParty: (contract) => ([3,4].includes(contract?.partystructure) ? "SUPERVISOR" : "PM"),


        replyEnabled: true,
        replyPeriodOption: "NOTIFICATION_DATE",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'PM/Supervisor Instruction to Search',
            currentModel: 'InspectionDefect',
            currentModelType: '43.1SUP',
            referenceType: 'InstructionToSearch',


            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableAssets: true,
            configurableReplyingParty: true,

            replyPeriodOptions: ["NOTIFICATION_DATE", "CUSTOM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'instructions',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        //#TODO : Supervisor & PM "Defect Notifications" workflows needs to be seperated 
        id: 23,
        title: '$SUPERVISOR_NAME Defect Notifications',
        notificationName: 'Defect Notification',
        abbreviation: '$SUPERVISOR_NAME-Defect-',
        notifyingParty: (contract) => ([3,4].includes(contract?.partystructure) ? "SUPERVISOR" : "PM"),
        actioningParty: (contract) => ([3,4].includes(contract?.partystructure) ? "SUPERVISOR" : "PM"),
        resolveActioningParty: (contract) => ([3,4].includes(contract?.partystructure) ? "SUPERVISOR" : "PM"),

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'PM/Supervisor Defect Notifications',
            currentModel: 'InspectionDefect',
            currentModelType: '42.2SUP',
            referenceType: 'Defect',


            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: false,
            configurableAssets: true,

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.ACTION_TAB,
                userFilterCategories.NOTIFY,
                userFilterCategories.RESOLVE_ACTION_TAB
            ],
            paths: {
                base: 'supervisor',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 24,
        title: '$SUPPLIER_NAME Defect Notifications',
        notificationName: 'Defect Notification',
        abbreviation: '$SUPPLIER_ABBREVIATION-Defect-',
        notifyingParty: 'SUPPLIER',
        actioningParty: (contract) => ([3,4].includes(contract?.partystructure) ? "SUPERVISOR" : "PM"),
        resolveActioningParty: (contract) => ([3,4].includes(contract?.partystructure) ? "SUPERVISOR" : "PM"),

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supplier Defect Notifications',
            currentModel: 'InspectionDefect',
            currentModelType: '42.2CON',
            referenceType: 'Defect',


            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: false,
            configurableAssets: true,

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.ACTION_TAB,
                userFilterCategories.NOTIFY,
                userFilterCategories.RESOLVE_ACTION_TAB
            ],
            paths: {
                base: 'supplier',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 25,
        title: '$SUPPLIER_NAME Tests & Inspections',
        notificationName: 'Test / Inspection',
        abbreviation: '$SUPPLIER_ABBREVIATION-Test-',
        notifyingParty: 'SUPPLIER',
        replyingParty: 'SUPPLIER',
        //REPLY
        replyEnabled: true,
        replyPeriodOption: "NOTIFICATION_DATE",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supplier Tests & Inspections',
            currentModel: 'InspectionDefect',
            currentModelType: '41.3',
            referenceType: 'TestAndInspection',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableAssets: true,

            replyPeriodOptions: ["NOTIFICATION_DATE", "CUSTOM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'inspections',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 47,
        title: '$SUPERVISOR_NAME Tests & Inspections',
        notificationName: 'Test / Inspection',
        abbreviation: '$SUPERVISOR_NAME-Test-',
        notifyingParty: 'SUPERVISOR',
        replyingParty: 'SUPERVISOR',

        replyEnabled: true,
        replyPeriodOption: "NOTIFICATION_DATE",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supervisor Tests & Inspections',
            currentModel: 'InspectionDefect',
            currentModelType: 'SupervisorTestAndInspection',
            referenceType: 'TestAndInspection',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableAssets: true,

            replyPeriodOptions: ["NOTIFICATION_DATE", "CUSTOM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'supervisor-inspections',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 48,
        title: '$PM_NAME Tests & Inspections',
        notificationName: 'Test / Inspection',
        abbreviation: '$PM_NAME-Test-',
        notifyingParty: 'PM',
        replyingParty: 'PM',
        //REPLY
        replyEnabled: true,
        replyPeriodOption: "NOTIFICATION_DATE",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'PM Tests & Inspections',
            currentModel: 'InspectionDefect',
            currentModelType: 'PMTestAndInspection',
            referenceType: 'TestAndInspection',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableAssets: true,

            replyPeriodOptions: ["NOTIFICATION_DATE", "CUSTOM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'pm-inspections',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 26,
        title: 'Master Defect Register',
        notificationName: 'Defect Notification',
        abbreviation: 'Defect',
        config: {
            description: 'Master Defect Register',
            showInRelations: false,
            showInCustomFields: false,
            replyPossible: false,
            userFilterCategories: [
                userFilterCategories.ACCESS
            ],
            paths: {
                base: 'register',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 54,
        title: '$PM_NAME NC Proposal',
        notificationName: 'NC Proposal',
        abbreviation: 'NC-Proposal-',
        notifyingParty: 'PM',
        replyingParty: 'SUPPLIER',

        replyEnabled: true,
        replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'PM NC Proposal',
            currentModel: 'InspectionDefect',
            currentModelType: 'SubmissionPMNC',
            referenceType: 'Submission',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableAssets: true,

            replyPeriodOptions: ["SUPPLIER_REPLY_PERIOD", "CUSTOM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'pm-nc-proposal',
                create: 'create',
                list: 'list',
                default: 'list'
            }

        }
    },
    {
        id: 55,
        title: 'Non-Conformity',
        notificationName: 'Non-Conformity',
        abbreviation: 'Non-Conformity-',
        replyingParty: 'PM',

        replyEnabled: true,
        replyPeriodOption: "PM_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Non-Conformity',
            currentModel: 'InspectionDefect',
            currentModelType: 'NonConformity',
            referenceType: 'NonConformance',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableAssets: true,


            replyPeriodOptions: ["PM_REPLY_PERIOD", "CUSTOM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'conformity',
                list: 'list',
                default: 'list'
            }
        }
    },
]

export default workflows;