import React, { Component } from 'react';
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import DataGridBase from "../../../../partials/layout/DataGridBase";
import { Badge } from "react-bootstrap";
import * as rfqQuotationCrud from '../../../../crud/rfqQuotation.crud';
import Common from '../../../../shared/common';
import { notificationAggregateListTagsFilter, notificationUniqueTagsFilter } from "../../../../store/helpers/FilterHelper";
import CustomFieldHelper from "../../_Helpers/CustomFieldHelper";
import { withListPageProps } from '../../../../shared/hoc/withListPageProps';

class QuoteListPage extends Component {
    constructor(props) {
        super(props);
        this.quoteStatuses = props.placeholders
            .find(ph => ph.replacementKey === 'QuoteStatuses')
            .values.filter(item => item.isActive === true);

        this.notificationsTagsEnabled = process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true' && props.contract.specifictags?.length > 0;
        this.customFields = [];
        if (props.customFieldsCommon) {
            if (props.customFieldsCommon.RFQQUOTE && props.customFieldsCommon.RFQQUOTE.length > 0) {
                this.customFields.push(...(props.customFieldsCommon.RFQQUOTE.filter(cf => cf.includelisting)));
            }
            if (props.customFieldsCommon.RFQQUOTE_REPLY && props.customFieldsCommon.RFQQUOTE_REPLY.length > 0) {
                this.customFields.push(...(props.customFieldsCommon.RFQQUOTE_REPLY.filter(cf => cf.includelisting)));
            }
        }
        this.state = {
            notifications: null,
            tags: null
        };
    }
    formatCurrency = () => {
        return this.props.contract.currency
    }
    componentDidMount() {
        this.fetchQuotes();
    }
    componentDidUpdate(prevprops, prevSatate) {
        if (this.props.params.notificationid !== prevprops.params.notificationid) {
            this.fetchQuotes();
        }
    }
    fetchQuotes = () => {
        const { companyid, projectid, contractid } = this.props.contract;
        rfqQuotationCrud.getRfqQuotes(companyid, projectid, contractid, this.props.params.notificationid)
            .then(response => {
                let quotes = notificationAggregateListTagsFilter(JSON.parse(response.data.aggregatelist));
                quotes = (this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed === true) ?
                    quotes :
                    quotes.filter(quo => quo.showtasknotification === 1);

                let tagFilters = notificationUniqueTagsFilter(JSON.parse(response.data.uniquetags));

                this.setState({ notifications: quotes, tags: tagFilters });
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });
    }

    onTableSelectionChanged = ({ selectedRowsData }) => {
        if (selectedRowsData.length > 0) {
            const data = selectedRowsData[0];
            const url = this.calculateUrl(data, true);
            this.props.navigate(url);
        }
    }
    calculateUrl = (rowData, skipHostUrl) => {
        return (skipHostUrl ? '' : process.env.PUBLIC_URL) +this.props.workflowItem.paths.basePath + rowData.rfqquotationid;
    }
    renderStatusColumn = (cellInfo) => {
        let badgeVariant = 'secondary';
        let status = this.renderStatusDisplay(cellInfo.data);
        if (cellInfo.data.showtasknotification !== 0) {
            switch (cellInfo.data.status) {
                case 1:
                    badgeVariant = 'success';
                    break;
                case 2:
                    badgeVariant = 'warning';
                    break;
                case 0:
                case 3:
                case 4:
                case 5:
                    badgeVariant = 'danger';
                    break;
                default:
                    badgeVariant = 'secondary';
                    break;
            }
        }
        return (<Badge variant={badgeVariant}>{status}</Badge>);
    }
    renderStatusDisplay = (rowData) => {
        let resourceKey;
        if (rowData.showtasknotification === 1) {
            resourceKey = rowData.status.toString();
        } else {
            resourceKey = 'none';
        }
        const status = this.quoteStatuses.find(x => x.field === resourceKey);
        if (status) {
            return status.display;
        } else {
            return rowData.status;
        }
    }
    renderCenIdColumn = (rowData) => {
        let abbreviation = 'Rfq-'
        
        if (rowData.type === 'RFQPM') {
            abbreviation = this.props.notificationAbbreviation_RFQ;
        }

        return abbreviation + Common.Lpad(rowData.requestforquotereference, 5);
    }
    render() {
        if (!this.state.notifications) {
            return (<PortletSpinner />);
        }

        const columns = [
            { resource: "GENERAL.FORMS.FIELD.ID", dataField: "referenceid", dataType: 'string', renderReferenceidLink: true },
            { resource: "GENERAL.FORMS.FIELD.REQUESTFORQUOTE_ID", dataField: "requestforquotereference", dataType: 'string', calculateCellValue: this.renderCenIdColumn },
            { resource: "GENERAL.FORMS.FIELD.SUBJECT", dataField: 'subject' },
            { resource: "GENERAL.FORMS.FIELD.AMOUNT", dataField: 'totalprice', dataType: 'currency', currencyCode: this.props.contract.currency },
            { resource: "CONTRACT.WF.QUOTES.FIELD.QUOTE_DATE", dataField: "notificationdate", dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.ISSUED_BY", dataField: "createby" },
            { caption: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.PM_RESPONSE_REQUIRED' }, { PM_ABBREVIATION: this.props.globalResources.PM_ABBREVIATION }), dataField: "responserequireddate", dataType: 'date' },
            { caption: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.PM_RESPONSE_RECEIVED' }, { PM_ABBREVIATION: this.props.globalResources.PM_ABBREVIATION }), dataField: "responsereceiveddate", dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.STATUS", dataField: "status", cellRender: this.renderStatusColumn, calculateCellValue: this.renderStatusDisplay },
            { resource: "GENERAL.FORMS.FIELD.REPLY_DRAFT_BY", dataField: "draftreplyby", visible: false },
            { resource: "GENERAL.FORMS.FIELD.LINK", dataField: "url", calculateCellValue: this.calculateUrl, visible: false, showInColumnChooser: false },
            { resource: "GENERAL.FORMS.FIELD.CURRENCY", dataField: "currency", visible: false, calculateCellValue: this.formatCurrency },
            { resource: "PROJECT.FIELD.CODE", dataField: "projectcode", visible: false},
            { resource: "PROJECT.FIELD.NAME", dataField: "project", visible: false},
            { resource: "GENERAL.FORMS.REPLING_PARTY", dataField: "replyingparty", lookup: { dataSource: this.props.contractTemplate.parties.options, valueExpr: 'id', displayExpr: 'display' }, visible: false }

        ];

        // if (this.props.fields.TotalPrice) {
        //     columns.push(
        //         { caption: this.props.fields.TotalPrice, dataField: "taskordertotal", dataType: 'currency', currencyCode: this.props.contract.currency },
        //     );
        // }
        if (this.props.fields.StartDate) {
            columns.push(
                { caption: this.props.fields.StartDate, dataField: "startdate", dataType: 'date' },
            );
        }
        if (this.props.fields.CompletionDate) {
            columns.push(
                { caption: this.props.fields.CompletionDate, dataField: "completiondate", dataType: 'date' },
            );
        }

        if (this.notificationsTagsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.PORTFOLIO", dataField: "tags_portfolio", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.PortfolioFilter } },
                { resource: "GENERAL.FORMS.FIELD.PROGRAMME", dataField: "tags_programme", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.ProgrammeFilter } },
                { resource: "GENERAL.FORMS.FIELD.PROJECT", dataField: "tags_project", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.ProjectFilter } },
                { resource: "GENERAL.FORMS.FIELD.OTHER", dataField: "tags_other", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.OtherFilter } }
            )
        }
        columns.push(...CustomFieldHelper.MapCustomFieldsToDataGridColumns(this.customFields));
        return (
            <Portlet>
                <PortletBody>
                    <DataGridBase
                        version="1"
                        title={this.props.intl.formatMessage({ id: 'GENERAL.REGISTER.TITLE2' }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
                        createPath={(this.props.workflowItem.userFilters.mainNotice.createAllowed && this.props.baseNotificationReadyForCreate) ? this.props.workflowItem.paths.createPath : null}
                        workflowItem={this.props.workflowItem}
                        name={this.props.workflowItem.pageTitle}
                        dataSource={this.state.notifications}
                        keyExpr="rfqquotationid"
                        selection={{ mode: 'single' }}
                        onSelectionChanged={this.onTableSelectionChanged}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={false}
                        columns={columns}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}



export default withListPageProps(QuoteListPage);
