/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import { connect } from "react-redux";
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { injectIntl } from "react-intl";
import { withSnackbar } from 'notistack';
import FileManager from 'devextreme-react/file-manager';
import CustomFileSystemProvider from "devextreme/file_management/custom_provider";
import * as relationCrud from '../../../../../crud/relationship.crud';


class CommunicationPicker extends React.Component {
    constructor(props) {
        super(props);
        this.fileManagerRef = React.createRef();

        this.fileSystemProvider = new CustomFileSystemProvider({
            getItems: this.fetchItems,
        });

    }
    fetchItems = (e) => {
        return new Promise(async (resolve, reject) => {
            if (!e.key) {
                //ROOT
                const workflowGroups = this.props.contractTemplate.workflowGroups.map(wfg => ({
                    key: wfg.id.toString(),
                    path: "WFG_" + wfg.id,
                    isDirectory: true,
                    hasSubDirectories: true,
                    dataItem: wfg,
                    name: wfg.title
                }))
                resolve(workflowGroups)
            }
            else if (e.pathKeys.length === 1 && e.dataItem?.dataItem?.workflows.length > 0) {
                const workflows = e.dataItem.dataItem.workflows.map(wf => ({
                    key: wf.id.toString(),
                    path: "WF_" + wf.id,
                    isDirectory: true,
                    hasSubDirectories: false,
                    dataItem: wf,
                    name: wf.title
                }))
                resolve(workflows)
            }
            else if (e.pathKeys.length === 2 && e.hasSubDirectories === false && e.dataItem?.dataItem?.config) {
                const { companyid, projectid, contractid } = this.props.contract;
                const { data: noticeItems } = await relationCrud.getRelationItemsList(e.dataItem.dataItem.config.currentModel, companyid, projectid, contractid, e.dataItem.dataItem.config.currentModelType);
                const itemsMapped = noticeItems.map(itm => ({
                    key: itm.referenceid,
                    path: itm.referenceid,
                    isDirectory: false,
                    hasSubDirectories: false,
                    dataItem: { recordid: itm.id, workflowid: e.dataItem.dataItem.id, referenceid: itm.referenceid },
                    name: itm.referenceid,
                    subject: itm.title
                }))
                resolve(itemsMapped)

            }
            else {
                resolve([])
            }

        });

    }

    onClose = () => {
        if (this.props.visible) {
            this.props.onDialogResult();
        }
    }
    saveItem = async () => {
        try {
            if (this.fileManagerRef?.current?.instance) {
                const selectedItems = this.fileManagerRef.current.instance.getSelectedItems();
                if (selectedItems?.length === 1 && selectedItems[0].isDirectory === false) {
                    const selectedItem = selectedItems[0];
                    this.props.onDialogResult({ ...selectedItem.dataItem.dataItem });
                    return;
                }
            }
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'COMMUNICATION_PICKER.MESSAGE.SELECT_ITEM' }), { variant: 'warning', });

        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }

        return;
    }
    onSelectedFileOpened = (e) => {
        if (e?.file?.dataItem?.isDirectory === false) {
            this.saveItem();
        }
    }

    render() {
        if (!this.props.visible) {
            return null;
        }
        return (
            <Popup
                id='noticeSelectionPopup'
                visible={this.props.visible}
                onHiding={this.onClose}
                dragEnabled={true}
                hideOnOutsideClick={false}
                showTitle={true}
                title={this.props.intl.formatMessage({ id: 'COMMUNICATION_PICKER.TITLE' })}
                width={800}
                height='auto'>
                <FileManager
                    ref={this.fileManagerRef}
                    fileSystemProvider={this.fileSystemProvider}
                    rootFolderName={this.props.intl.formatMessage({ id: 'COMMUNICATION_PICKER.ROOT_FOLDER' })}
                    selectionMode='single'
                    onSelectedFileOpened={this.onSelectedFileOpened}
                    itemView={{
                        showFolders: true,
                        showParentFolder: false,
                        details: {
                            columns: ['thumbnail', 'name', { caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.SUBJECT' }), dataField: "subject" }]
                        }
                    }}
                    permissions={{
                        create: false,
                        copy: false,
                        move: false,
                        delete: false,
                        rename: false,
                        upload: false,
                        download: false,
                    }}
                    toolbar={
                        {
                            fileSelectionItems: [],
                            items: [],
                        }
                    }
                >

                </FileManager>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{ text: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_CANCEL' }), onClick: this.onClose }}>
                </ToolbarItem>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.SELECT_ITEM' }),
                        icon: 'save',
                        onClick: this.saveItem
                    }}>
                </ToolbarItem>
            </Popup >
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
    contractTemplate: store.contract.template,
});

export default connect(mapStateToProps)(withSnackbar(injectIntl(CommunicationPicker)));