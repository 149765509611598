import React from 'react';
import { connect } from "react-redux";
import * as fileCrud from '../../../../crud/file.crud';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import Document from '../../_CustomComponents/DocumentV2';
import BaseDocument from '../../_CustomComponents/BaseDocument';
import Common from '../../../../shared/common';
import { addYears } from 'date-fns';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import DataSource from 'devextreme/data/data_source';

class ActionDocumentPage extends BaseDocument {
    constructor(props) {
        super(props);
        const partyDataSource = this.getPartyDataSource();
        this.editorFormConfig = {
            fields: [
                { resource: 'GENERAL.FORMS.FIELD.SUBJECT', dataField: 'assistancetestserchdefect' },
                { label: props.resources.DelegationClause, dataField: 'inspectiondetails', editorType: 'dxHtmlEditor' },
                { resource: "CONTRACT.WF.DELEGATION.FIELD.DELEGATED_TO", dataField: 'replyactiondetail', editorType: 'dxSelectBox', editorOptions: { dataSource: partyDataSource, valueExpr: 'user', displayExpr: 'user', grouped: props.allowCrossPartyDelegationsIsEnabled } },
                {
                    resource: "CONTRACT.WF.DELEGATION.FIELD.TIME_SPAN",
                    fields: [
                        { resource: "GENERAL.FORMS.FIELD.START_DATE", dataField: 'inspectiondate', editorType: 'dxDateBox', editorOptions: { calendarOptions: { min: new Date() }, max: addYears(new Date(), 10) }, validation: { type: 'range', min: moment().subtract(1, 'days'), max: addYears(new Date(), 10), warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_DATE" }) } },
                        { resource: "GENERAL.FORMS.FIELD.END_DATE", dataField: 'inspectiontime', editorType: 'dxDateBox', editorOptions: { calendarOptions: { min: new Date() }, max: addYears(new Date(), 10) }, validation: { type: 'range', min: moment().subtract(1, 'days'), max: addYears(new Date(), 10), warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_DATE" }) } },
                    ]
                }
            ],
        };

    }
    getPartyDataSource = () => {
        if (this.props.contractUsers) {
            if (this.props.allowCrossPartyDelegationsIsEnabled) {
                const mappedContractUsers = this.props.contractUsers.map(x => ({
                    userid: x.accountid,
                    user: x.user,
                    party: Common.GetPartyDisplay(x.usergroup, this.props.globalResources)
                }));
                const groupedUserList = new DataSource({
                    store: {
                        type: 'array',
                        data: mappedContractUsers,
                        key: 'userid'
                    },
                    group: 'party'
                });
                return groupedUserList;
            }
            else {
                const mappedContractUsers = this.props.contractUsers.filter(user => user.usergroup === this.props.delegationUserParty).map(x => ({
                    userid: x.accountid,
                    user: x.user
                }))
                return mappedContractUsers;
            }
        }
        return null;
    }
    renderForm = () => {
        // this.editorFormConfig.fields[2].editorOptions.dataSource = this.props.contractUsers.filter(user => user.usergroup === this.props.delegationUserParty);
        return (
            <BiDynamicForm
                item={this.props.notification}
                config={this.editorFormConfig}
                valueChangeHandler={this.props.fieldChanged}
                customFields={(this.props.customFields || {}).COMMUNICATION}
                assetFieldsEnabled={this.props.workflowItem.assetFieldsEnabled}
            />

        );
    }
    uploadFile = (data, config) => {
        const { companyid, projectid, contractid } = this.props.contract;
        return fileCrud.uploadContractNotificationFiles(companyid, projectid, contractid, this.props.documentType, this.props.notification.inspectiondefectid, data, config)
    }
    /// A Bugfix applied at this date
    /// Check if document was communicated before Bugfix
    /// If it was before, keep generating the same (wrong field) as start date
    isBeforeBugfix = (notificationDate) => {
        const cutoffDate = moment("26/03/2022", "DD/MM/YYYY").toDate();
        const notifyDate = notificationDate ? new Date(notificationDate) : new Date(this.props.notification.notificationdate);
        if (notifyDate < cutoffDate) {
            return true;
        }
        return false;
    }



    render() {
        const documentIsFinal = this.props.notification.showtasknotification === 1;
        return (
            <Document
                key={documentIsFinal}
                docConfig={this.props.notification.pdfjsoncontent}
                isFinal={documentIsFinal}
                printDivRef={this.props.printDivRef}
                workflowItem={this.props.workflowItem}
                contract={this.props.contract}
                reference={this.props.notification.referenceid}
                form={documentIsFinal ? null : this.renderForm()}
                uploadMethod={documentIsFinal ? null : this.uploadFile}
                files={this.props.files}
                notificationid={this.props.notificationid}
                documentType={this.props.documentType}
                customFields={(this.props.customFields || {}).COMMUNICATION}
                customFieldValues={this.props.notification.customfieldvalues}
                assetid={this.props.notification.assetid}
            />
        );
    }
}

const mapStateToProps = store => ({
    contractUsers: store.contract.users,
});

export default withSnackbar(connect(mapStateToProps)(ActionDocumentPage));
