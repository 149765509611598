import React from 'react';
import BaseDetailPage from '../../_CustomComponents/BaseDetailPage';
import ReactToPrint from "react-to-print";
import { Navigate } from "react-router-dom";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import * as instructionToQuoteCrud from '../../../../crud/instructionToQuote.crud';
import * as fileCrud from '../../../../crud/file.crud';
import axios from 'axios';
import ActionModal from '../../_CustomComponents/ActionModal';
import Document from './Document';
import QuotesRouter from '../Quotes/Router';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import { addDays } from 'date-fns';
import moment from 'moment';
import { ValidationSummary, ValidationGroup } from 'devextreme-react';
import Relations from '../../_CustomComponents/Relation/Relations';
import PageToolbar from '../../_CustomComponents/PageToolbar';
import Common from '../../../../shared/common';
import { withDetailPageProps } from '../../../../shared/hoc/withDetailPageProps';
import NavigationBlocker from '../../../../partials/Tools/NavigationBlocker';
import EmailNotifications from '../../_CustomComponents/EmailNotifications';
const initialState = {
    notification: null,
    files: null,
    showModal: false,
    saving: false,
    isChanged: false,
    relationCount: 0,
    modalTitle: '',
    modalText: '',
    modalMode: '',
    relations: null
};

class ItqDetailPage extends BaseDetailPage {
    constructor(props) {
        super(props);
        this.isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'DEV';
        this.isRelationsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true' || process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true';
        this.printIssueDivRef = React.createRef();
        this.validatorRef = React.createRef();
        this.validatorName = 'ItqValidator';
        this.state = { ...initialState };
    }

    componentDidMount() {
        this.fetchNotification();
        this.fetchFiles();
    }

    componentDidUpdate(prevprops, prevSatate) {
        if (this.props.params.notificationid !== prevprops.params.notificationid) {
            this.setState({ ...initialState }, () => {
                this.fetchNotification();
                this.fetchFiles();
            });
        }
    }

    fetchNotification = () => {
        instructionToQuoteCrud.getInstructionToQuote(this.props.params.notificationid)
            .then(response => {
                const itq = response.data;
                if (itq.contractid !== this.props.contract.contractid) {
                    throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.INVALID_URL' }));
                }
                if (itq.startdate) {
                    itq.startdate = new Date(itq.startdate);
                }
                if (itq.completiondate) {
                    itq.completiondate = new Date(itq.completiondate);
                }

                this.setState({
                    notification: itq,
                    saving: false,
                    isChanged: false,
                    relations: itq.relations,
                    relationCount: Common.GetRelationCount(itq, this.props.accessibleWfIds)
                }, () => { this.props.setReference(this.state.notification.referenceid); });
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                }
            });
    }

    fetchFiles = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.notificationid, this.props.documentType)
            const filesList = JSON.parse(data.aggregatelist)
            this.setState({ files: filesList });
            return filesList;
        } catch (err) {
            if (!axios.isCancel(err)) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
        }
    }
    printHandler = () => {
        this.printIssueRef.handlePrint();
    }
    getMailDescription = () => {
        return this.state.notification.subject;
    }
    getSelectedTypeClauseDisplay = () => {
        return "";
    }
    validateForm = () => {
        const validationResult = this.validatorRef.current.instance.validate();
        if (!validationResult.isValid) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_MISSING_FIELDS' }), { variant: 'warning', });
        }
        return validationResult.isValid && !this.state.saving;
    }
    showModal = (mode, postActionNavigateObj) => {
        if (mode === 'COMMUNICATE') {
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_COMMUNICATE_ITEM' }, { ITEM_NAME: this.props.title }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_COMMUNICATING' }, { ITEM_NAME: this.props.title, PARTY_NAME: this.props.communicateToUser }),
                    modalMode: mode,
                    postActionUrl: postActionNavigateObj
                });
            }
        }
        else if (mode === 'SAVE_DRAFT') {
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_DRAFT_NOTIFY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
                    modalMode: mode,
                });
            }
        }
        else if (mode === 'DELETE_DRAFT') {
            this.setState({
                showModal: true,
                modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_DELETE_DRAFT_NOTIFY' }),
                modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_DELETING' }),
                modalMode: mode,
            });
        }
    }
    onModalResponse = (response, params) => {
        this.setState({ showModal: false, saving: response === 'OK' }, async () => {
            try {
                if (response === 'OK') {


                    if (this.state.modalMode === 'COMMUNICATE') {
                        const notification = { ...this.state.notification };
                        notification.createby = this.props.contractUser.name;
                        notification.createbyid = this.props.contractUser.accountid;
                        notification.accountid = this.props.contractUser.accountid;
                        notification.status = 'AQ';
                        notification.showtasknotification = 1;
                        // notification.notificationdatedisplay = moment().format('DD/MM/YYYY');

                        const quoteDate = moment(addDays(new Date(), this.props.globalResources.ITQ_QUOTE_DURATION)).format('DD/MM/YYYY');
                        notification.originalquoterequireddatedisplay = quoteDate;
                        notification.quoterequireddatedisplay = quoteDate;
                        if (notification.startdate) {
                            notification.startdatedisplay = moment(notification.startdate).format('DD/MM/YYYY');
                        }
                        if (notification.completiondate) {
                            notification.completiondatedisplay = moment(notification.completiondate).format('DD/MM/YYYY');
                        }
                        const docBodyConfig = this.documentBodyConfig(notification, this.props.resources);
                        const docConfig = await this.documentConfig(notification, docBodyConfig, this.props.contractUser.name, false, null, null, this.props.workflowItem.form_NotifyCompanyName);
                        notification.pdfjsoncontent = JSON.stringify(docConfig);

                        this.saveNotification(notification, this.state.modalMode);

                    }
                    else if (this.state.modalMode === 'SAVE_DRAFT') {
                        const notification = { ...this.state.notification };
                        notification.createby = this.props.contractUser.name;
                        notification.createbyid = this.props.contractUser.accountid;
                        notification.accountid = this.props.contractUser.accountid;
                        notification.status = 'NC';
                        notification.showtasknotification = 0;
                        // notification.notificationdatedisplay = moment().format('DD/MM/YYYY');
                        // const quoteDate = addDays(new Date(), this.props.globalResources.ITQ_QUOTE_DURATION).toLocaleDateString('en-GB');
                        // notification.originalquotedatedisplay = quoteDate;
                        // notification.requiredatedisplay = quoteDate;
                        this.saveNotification(notification, this.state.modalMode, params ? params.userid : null);

                    }
                    else if (this.state.modalMode === 'DELETE_DRAFT') {
                        const instructiontoquote = { ...this.state.notification };
                        const instructiontoquoteid = instructiontoquote.instructiontoquoteid
                        this.deleteInstructionToQuoteDraft(instructiontoquoteid)

                    }


                }
            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                this.setState({ saving: false });
            }

        });
    }
    documentBodyConfig = (notification, resources) => {
        const config = [
            { resource: 'GENERAL.FORMS.FIELD.SUBJECT', value: notification.subject },
            { note: resources.InstructionToQuoteNote.replace('$date', notification.originalquoterequireddatedisplay), valueType: 'NOTE' },
            { resource: 'GENERAL.FORMS.FIELD.DETAILS', value: notification.description, valueType: 'HTML', align: 'vertical' },
        ];
        if (this.props.configurableITQFields) {
            if (this.props.configurableITQFields.StartDate) {
                config.push({ label: this.props.configurableITQFields.StartDate, value: notification.startdatedisplay })
            }
            if (this.props.configurableITQFields.CompletionDate) {
                config.push({ label: this.props.configurableITQFields.CompletionDate, value: notification.completiondatedisplay })
            }
            if (this.props.configurableITQFields.DelayAmount) {
                config.push({ label: this.props.configurableITQFields.DelayAmount, value: Common.FormatCurrencyNum(notification.delaydamagestotal, this.props.contract.currency) + ' / ' + notification.delaydamagestype, currencyCode: (this.props.contract || {}).currency })
            }
            if (this.props.configurableITQFields.DelayPaymentPeriod) {
                config.push({ label: this.props.configurableITQFields.DelayPaymentPeriod, value: notification.delaydamagestype })
            }
        }

        return config;
    }
    deleteInstructionToQuoteDraft = async (instructiontoquoteid) => {

        try {
            await instructionToQuoteCrud.deleteInstructionToQuoteDraft(instructiontoquoteid);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_DELETED' }), { variant: 'success' });
            this.setState({ saving: false, navigateto: this.props.workflowItem.paths.listPath });

        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            this.setState({ saving: false });
        }
    }

    saveNotification = async (notification, action, notifyuserid) => {
        //const { companyid, projectid, contractid } = this.props.contract;
        const postEvents = () => {
            this.fetchNotification();
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), { variant: 'success' });
        };
        try {
            if (notification.startdate) {
                notification.startdatedisplay = moment(notification.startdate).format('DD/MM/YYYY');
            }
            if (notification.completiondate) {
                notification.completiondatedisplay = moment(notification.completiondate).format('DD/MM/YYYY');
            }

            notification.actioncode = action;
            notification.notifyuserlist = this.getNotifyUserListForUpdate(action, notifyuserid);
            Common.PropertyConversionsBeforeNoticeSaveRequest(notification, this.props.customFields, this.props.contractUsers);
            await instructionToQuoteCrud.updateInstructionToQuote(notification);
            //this.notify(action, notification, notifyuserid);
            if (this.state.postActionUrl) {
                this.setState({ isChanged: false, saving: false, navigateto: this.state.postActionUrl.pathname });
            } else {
                postEvents();
            }
        } catch (err) {
            this.setState({ saving: false });
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
    }
    fieldChanged = (field, value, isDxFormValid, additionalData) => {
        //SILENT STATE CHANGE
        //WE DONT WANT COMPONENT RERENDER WİTH THIS
        //this.setState({ notification: { ...this.state.notification, [field]: value } });
        if (field) {
            if (field === "assetid") {
                this.setState({
                    notification: { ...this.state.notification, [field]: value, asset: { ...additionalData } },
                    isChanged: true
                });
            }
            else {
                this.state.notification[field] = value;
                if (!this.state.isChanged) {
                    this.setState({ isChanged: true });
                }
            }
        }
    }
    onItqChanged = () => {
        this.fetchNotification();
    }

    renderToolbar = (documentIsFinal) => {
        const saveDisabled = (this.state.saving || this.state.showModal);
        return (
            <PageToolbar
                key={this.state.notification.version}
                documentIsFinal={documentIsFinal}
                saveDisabled={saveDisabled}
                actions={{
                    showModal: this.showModal,
                    onPrint: this.printHandler
                }}
                workflowItem={this.props.workflowItem}
                match={this.props.match}
                customFieldValues={this.state.notification.customfieldvalues}
                referenceid={this.state.notification.referenceid}
                contract={this.props.contract}
                relationParams={{
                    subject: this.getMailDescription(),
                    assetid: this.state.notification.assetid
                }}
                notification={this.state.notification}
                selectedClausetypeDisplay={this.getSelectedTypeClauseDisplay()}
            />
        );
    }
    tabDocument = () => {
        return (
            <Document
                {...this.props}
                printDivRef={this.printIssueDivRef}
                fieldChanged={this.fieldChanged}
                notification={this.state.notification}
                files={this.state.files}
                notificationid={this.props.params.notificationid}
            />
        );
    }
    tabQuotes = () => {
        return (
            <QuotesRouter
                {...this.props}
                customFieldsCommon={this.props.customFieldsCommon}
                resources={this.props.resources}
                placeholders={this.props.placeholders}
                parentWorkflowItem={this.props.workflowItem}
                parentTabPanel={this.tabPanel}
                onItqChanged={this.onItqChanged}
                itq={this.state.notification}
                notifyUserFilter={this.props.notifyUserFilter}

            />
        )
    }
    tabRelations = () => {
        return (
            <Relations
                contractid={this.props.contract.contractid}
                workflowItem={this.props.workflowItem}
                workflowGroupItem={this.props.workflowGroupItem}
                notificationid={Number(this.props.params.notificationid)}
                relations={this.state.notification.relations}
                itemversion={this.state.notification.version}
                subject={this.state.notification.instructiontoquotenumber}
                onCountChange={(count) => this.setState({ relationCount: count })}
                showTags={this.props.contract.specifictags?.length > 0}
            />
        );
    }
    tabEmailNotification = () => {

        return (
            <EmailNotifications
                {...this.props}
                notificationDate={this.state.notification.notificationdate}
            />
        );

    };
    render() {
        if (!this.state.notification || !this.state.files) {
            return (<PortletSpinner />);
        }

        const documentIsFinal = this.state.notification.showtasknotification === 1;
        const quotesTabEnabled = documentIsFinal && !['APMR', 'R'].includes(this.state.notification.status);

        if (!documentIsFinal && !this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed) {
            return (<Navigate replace to={this.props.workflowItem.paths.listPath} />)
        }
        return (
            <Portlet>
                <ReactToPrint
                    ref={el => (this.printIssueRef = el)}
                    trigger={() => (<div style={{ display: 'none' }} ></div>)}
                    content={() => this.printIssueDivRef.current}
                />
                <PortletHeader
                    title={documentIsFinal ? this.props.workflowItem.pageTitle : this.props.intl.formatMessage({ id: "GENERAL.FORMS.DRAFT_TITLE" }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
                    toolbar={this.renderToolbar(documentIsFinal)}
                />
                <NavigationBlocker
                    when={(this.state.isChanged || this.state.saving)}
                    message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}
                />
                {this.state.navigateto &&
                    (
                        <Navigate replace to={this.state.navigateto} state={(this.state.postActionUrl || {}).state} />
                    )
                }
                <PortletBody>
                    <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
                        <div>
                            <ValidationSummary elementAttr={{ align: "right", paddingBottom: 50 }} id={this.validatorName}></ValidationSummary>
                        </div>
                        <TabPanel
                            ref={(ref) => this.tabPanel = ref}
                            width="100%"
                            deferRendering={false}
                            animationEnabled={true}
                            focusStateEnabled={true}
                            hoverStateEnabled={true}
                            swipeEnabled={false}>
                            <Item icon="file" title={this.props.title} render={this.tabDocument} />
                            <Item icon="sorted" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_QUOTES' })} render={quotesTabEnabled ? this.tabQuotes : null} visible={quotesTabEnabled} />
                            {
                                this.isRelationsEnabled && (
                                    <Item icon="share" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_RELATIONS' })} render={this.tabRelations} badge={this.state.relationCount > 0 ? this.state.relationCount : null} />
                                )
                            }
                            <Item
                                icon="bulletlist"
                                title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_EMAIL_NOTIFICATIONS' })}
                                render={documentIsFinal ? this.tabEmailNotification : null} visible={documentIsFinal}
                            />
                        </TabPanel>
                    </ValidationGroup>
                </PortletBody>
                <ActionModal
                    visible={this.state.showModal}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    mode={this.state.modalMode}
                    onDialogResult={this.onModalResponse}
                    workflowItem={this.props.workflowItem}
                />
            </Portlet>
        );
    }
}



export default withDetailPageProps(ItqDetailPage);