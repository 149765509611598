import React from 'react';
import * as fileCrud from '../../../../crud/file.crud';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import Document from '../../_CustomComponents/DocumentV2';
import BaseDocument from '../../_CustomComponents/BaseDocument';
import { injectIntl } from "react-intl";
import { withSnackbar } from 'notistack';
import moment from 'moment';
import { addDays, addYears } from 'date-fns';

class NotifyDocumentPage extends BaseDocument {
    constructor(props) {
        super(props);
        const selectedClause = props.clauses.find(item => item.display === this.props.notification.replycomment);
        this.state = {
            selectedClause
        }
    }

    fieldChanged = (field, value, isDxFormValid, additionalData) => {
        if (field) {
            if (field === 'replycomment') {
                const selectedClause = this.props.clauses.find(item => item.display === value);
                this.setState({ selectedClause: selectedClause });
            }
        }
        this.props.fieldChanged(field, value, isDxFormValid, additionalData)
    }

    renderForm = () => {
        const editorFormConfig = {
            fields: [
                { resource: 'GENERAL.FORMS.FIELD.SUBJECT', dataField: 'assistancetestserchdefect', required: true },
                { resource: "GENERAL.FORMS.FIELD.TYPE", dataField: 'replycomment', required: true, editorType: 'dxSelectBox', editorOptions: { dataSource: this.props.clauses, valueExpr: 'display', displayExpr: 'display' } },
            ]
        };
        
        if (this.props.workflowItem.replyEnabled) {
            if (this.state.selectedClause?.noReply) {
                //Do not show Response Required Date field
            } else if (this.state.selectedClause?.applyReplyPeriod && this.state.selectedClause.hasOwnProperty("replyPeriod")) {
                const replyrequireddate = moment(addDays(new Date(), this.state.selectedClause.replyPeriod)).toDate();
                editorFormConfig.fields.push({ resource: "GENERAL.FORMS.FIELD.RESPONSE_REQUIRED", dataField: 'replyrequireddate', editorType: 'dxDateBox', editorOptions: { readOnly: true, value: replyrequireddate } })
            }
            else if (this.props.workflowItem.replyPeriodOption === "ASKED_ON_COMMUNICATION_FORM") {
                editorFormConfig.fields.push({ resource: "GENERAL.FORMS.FIELD.RESPONSE_REQUIRED", dataField: 'replyrequireddate', editorType: 'dxDateBox', editorOptions: { calendarOptions: { min: new Date() }, max: addYears(new Date(), 10) }, validation: { type: 'range', min: moment().subtract(1, 'days'), max: addYears(new Date(), 10), warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_DATE" })} });
            } else {
                const replyrequireddate = moment(addDays(new Date(), this.props.workflowItem.replyPeriod)).toDate();
                editorFormConfig.fields.push({ resource: "GENERAL.FORMS.FIELD.RESPONSE_REQUIRED", dataField: 'replyrequireddate', editorType: 'dxDateBox', editorOptions: { readOnly: true, value: replyrequireddate } })
            }
        }
        
        editorFormConfig.fields.push(
            { label: `${this.props.workflowItem.notificationName}`, dataField: 'inspectiondetails', required: true, editorType: 'dxHtmlEditor' },
        )

        return (
            <BiDynamicForm
                item={this.props.notification}
                config={editorFormConfig}
                valueChangeHandler={this.fieldChanged}
                customFields={(this.props.customFields || {}).COMMUNICATION}
                assetFieldsEnabled={this.props.workflowItem.assetFieldsEnabled}
            />

        );
    }

    uploadFile = (data, config) => {
        const { companyid, projectid, contractid } = this.props.contract;
        return fileCrud.uploadContractNotificationFiles(companyid, projectid, contractid, this.props.documentType, this.props.notification.inspectiondefectid, data, config)
    }

    render() {
        const documentIsFinal = this.props.notification.showtasknotification === 1;
        return (
            <Document
                key={documentIsFinal}
                docConfig={this.props.notification.pdfjsoncontent}
                isFinal={documentIsFinal}
                printDivRef={this.props.printDivRef}
                contract={this.props.contract}
                workflowItem={this.props.workflowItem}
                reference={this.props.notification.referenceid}
                form={documentIsFinal ? null : this.renderForm()}
                uploadMethod={documentIsFinal ? null : this.uploadFile}
                files={this.props.files}
                notificationid={this.props.notificationid}
                documentType={this.props.documentType}
                customFields={(this.props.customFields || {}).COMMUNICATION}
                customFieldValues={this.props.notification.customfieldvalues}
                assetid={this.props.notification.assetid}
            />
        );
    }
}

export default withSnackbar(injectIntl(NotifyDocumentPage));
