import React from 'react';
import { Nav } from "react-bootstrap";
import { Badge } from '@mui/material';


export default function SideBarNavigationTab(props) {
    const { replyDrafts, drafts, actions,manualactions } = props;
    const manuelActionsEnabled =  process.env.REACT_APP_ACTIONS_ENABLED === 'true';
    let notification = null;
    if (props.warningCount && props.warningCount > 0) {
        notification = props.warningCount;
    }
    if ((manuelActionsEnabled && manualactions && manualactions.length > 0) ||(actions && actions.length > 0) || (drafts && drafts.length > 0) || (replyDrafts && replyDrafts.length > 0)) {
        return (
            <Nav.Item className="nav-item">
                <Badge overlap="rectangular" badgeContent={notification} color="error">
                    <Nav.Link eventKey={props.eventKey} className="nav-link show">
                        {props.title}
                    </Nav.Link>
                </Badge>
            </Nav.Item>
        );
    }
    else {
        return null;
    }
}