import React from 'react';
import BaseDetailPage from '../../_CustomComponents/BaseDetailPage';
import { Navigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import * as compensationEventCrud from '../../../../crud/compensationEvent.crud';
import * as inspectionCrud from '../../../../crud/inspectionDefect.crud';
import * as fileCrud from '../../../../crud/file.crud';
import axios from 'axios';
import ActionModal from '../../_CustomComponents/ActionModal';
import AssessmentDocument from './Document';
import { ValidationSummary, ValidationGroup } from 'devextreme-react';
import moment from 'moment';
import Relations from '../../_CustomComponents/Relation/Relations';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import CostBreakdown from "./CostBreakdown";
import { CBS_ContractTypes_Quotes } from '../../../../shared/lookup';
import PageToolbar from '../../_CustomComponents/PageToolbar';
import Common from '../../../../shared/common';
import { withDetailPageProps } from '../../../../shared/hoc/withDetailPageProps';
import NavigationBlocker from '../../../../partials/Tools/NavigationBlocker';
import { getCompensationPathWithType } from '../compensation/ListAll';
import { confirm } from 'devextreme/ui/dialog';
import EmailNotifications from '../../_CustomComponents/EmailNotifications';
const initialState = {
    notification: null,
    compensation: null,
    files: null,
    showModal: false,
    saving: false,
    isChanged: false,
    relationCount: 0,
    modalTitle: '',
    modalText: '',
    modalMode: '',
    relations: null,
    selectedCompensationType: null,
    selectedClause: null
};

class AssessmentDetailPage extends BaseDetailPage {
    constructor(props) {
        super(props);
        this.isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'DEV';
        this.isRelationsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true' || process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true';
        this.wbsEnabled = (process.env.REACT_APP_COST_BREAKDOWN_ENABLED === 'true' && CBS_ContractTypes_Quotes.includes(props.contract.contracttype));
        this.printIssueDivRef = React.createRef();
        this.printAcceptanceDivRef = React.createRef();
        this.validatorRef = React.createRef();
        this.validatorName = 'AssessmentValidator';
        this.state = { ...initialState };
    }
    componentDidMount() {
        this.fetchInspectionDefect();
        this.fetchFiles();
    }

    componentDidUpdate(prevprops, prevSatate) {
        if (this.props.params.notificationid !== prevprops.params.notificationid) {
            this.setState({ ...initialState }, () => {
                this.fetchInspectionDefect();
                this.fetchFiles();
            });
        }
    }

    getMailDescription = () => {
        return this.state.notification.assistancetestserchdefect;
    }
    getSelectedTypeClauseDisplay = () => {
        return "";
    }
    fetchInspectionDefect = async () => {
        await this.props.closeSnackbar('PersistentFormWarning');
        inspectionCrud.getInspectionDefect(this.props.params.notificationid)
            .then(response => {
                const defect = response.data;
                if (defect.contractid !== this.props.contract.contractid ||
                    defect.type !== this.props.workflowItem.config.currentModelType) {
                    throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.INVALID_URL' }));
                }
                if (defect.inspectiondate) {
                    defect.inspectiondate = new Date(defect.inspectiondate);
                }
                if (defect.notificationdate) {
                    defect.notificationdate = new Date(defect.notificationdate);
                }
                if (defect.replyactiondetail && this.props.mode === "PM") {
                    defect.replyactiondetail = Number(defect.replyactiondetail);
                    // this.fetchSectionalDatesCEN(defect.replyactiondetail);
                    // this.fetchCompensation(defect.replyactiondetail);
                }
                if (defect.replycomment && this.props.editingProperties.price) {
                    defect.replycomment = Number(defect.replycomment);
                }

                let selectedClause = null;
                if (this.props.mode === "PM_PRICE" && defect.replyactiondetail && this.props.clauses) {
                    selectedClause = this.props.clauses.find(c => c.display === defect.replyactiondetail);
                    if (selectedClause) {
                    }
                    else {
                        if (defect.showtasknotification !== 1) {
                            defect.replyactiondetail = null;
                            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_CLAUSE_MISSING' }), { variant: 'warning', });
                        }
                    }
                }
                this.setState({
                    notification: defect,
                    saving: false,
                    isChanged: false,
                    relations: defect.relations,
                    relationCount: Common.GetRelationCount(defect, this.props.accessibleWfIds),
                    selectedClause
                }, () => { this.props.setReference(this.state.notification.referenceid); });
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });
    }

    fetchFiles = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.notificationid, this.props.documentType)
            const filesList = JSON.parse(data.aggregatelist)
            this.setState({ files: filesList });
            return filesList;
        } catch (err) {
            if (!axios.isCancel(err)) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
        }
    }

    printHandler = () => {
        this.printIssueRef.handlePrint();
    }
    validateForm = () => {
        const validationResult = this.validatorRef.current.instance.validate();
        if (!validationResult.isValid) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_MISSING_FIELDS' }), { variant: 'warning', });
        }
        return validationResult.isValid && !this.state.saving;
    }
    showModal = async (mode, postActionNavigateObj) => {
        let selectedClause = null;
        if (this.props.mode === "PM_PRICE" && this.state.notification.replyactiondetail && this.props.clauses) {
            selectedClause = this.props.clauses.find(c => c.display === this.state.notification.replyactiondetail);
        }
        if (mode === 'COMMUNICATE') {
            if (this.validateForm()) {
                const positiveAmountConfirmed = await this.confirmPositiveAmount(this.state.notification);

                if (!positiveAmountConfirmed) {
                    //if not confirmed exit save
                    return;
                } else {
                    this.setState({
                        showModal: true,
                        modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_COMMUNICATE_ITEM' }, { ITEM_NAME: this.props.title }),
                        modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_COMMUNICATING' }, { ITEM_NAME: this.props.title, PARTY_NAME: this.props.communicateToUser }),
                        modalMode: mode,
                        postActionUrl: postActionNavigateObj,
                        selectedClause
                    });
                }
            }
        }
        else if (mode === 'SAVE_DRAFT') {
            if (this.validateForm()) {
                const positiveAmountConfirmed = await this.confirmPositiveAmount(this.state.notification);

                if (!positiveAmountConfirmed) {
                    //if not confirmed exit save
                    return;
                } else {
                    this.setState({
                        showModal: true,
                        modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_DRAFT_NOTIFY' }),
                        modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
                        modalMode: mode,
                    });
                }
            }
        }
        else if (mode === 'DELETE_DRAFT') {
            this.setState({
                showModal: true,
                modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_DELETE_DRAFT_NOTIFY' }),
                modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_DELETING' }),
                modalMode: mode,
            });
        }
    }
    confirmPositiveAmount = async (notification) => {
        if (this.state.selectedCompensationType === 'CLIENTCWN' || this.state.selectedCompensationType === 'CLIENT') {
            if (notification.replycomment > 0) { // amount field
                const warningMessage = this.props.intl.formatMessage({ id: 'GENERAL.FORMS.POSITIVE_AMOUNT_WARNING' });
                const warningMessageLabel = this.props.intl.formatMessage({ id: 'GENERAL.FORMS.POSITIVE_AMOUNT_WARNING_LABEL' });
                let result = await confirm(`<div style="max-width:50vw;">${warningMessage}</div>`, `${warningMessageLabel}`);
                return result;
            }

            return true;
        }

        return true;
    }
    onModalResponse = async (response, params) => {
        this.setState({ showModal: false, saving: response === 'OK' }, async () => {
            try {
                if (response === 'OK') {

                    if (this.state.modalMode === 'COMMUNICATE') {
                        const notification = { ...this.state.notification };
                        notification.createactions = params?.createactions;
                        notification.completeactions = params?.completeactions;
                        notification.replystatus = 'Communicated';

                        const docBodyConfig = await this.documentBodyConfig(notification, this.props.resources);
                        const docConfig = await this.documentConfig(notification, docBodyConfig, this.props.contractUser.name, false, null, null, this.props.workflowItem.form_NotifyCompanyName);
                        notification.pdfjsoncontent = JSON.stringify(docConfig);
                        this.saveAssessment(notification, this.state.modalMode);

                    }
                    else if (this.state.modalMode === 'SAVE_DRAFT') {
                        const notification = { ...this.state.notification };
                        this.saveAssessment(notification, this.state.modalMode, params ? params.userid : null);
                    }
                    else if (this.state.modalMode === 'DELETE_DRAFT') {
                        const inspection = { ...this.state.notification };
                        const inspectiondefectid = inspection.inspectiondefectid
                        this.deleteInspectionDraft(inspectiondefectid);
                    }
                }
            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                this.setState({ saving: false });
            }

        });

    }
    getCompensationURL = (compensation) => {
        const compensationPath = getCompensationPathWithType(compensation.compensationsource);
        return `${window.location.origin}/contract/${this.props.contract.contractid}/${this.props.workflowItem.paths.groupPath}/${compensationPath}/${compensation.compensationeventid}`;
    }
    getTaskURL = (compensation) => {
        const { contractid, taskorderid } = compensation;
        return `${window.location.origin}/contract/${contractid}/tasks/pm/${taskorderid}`;
    }
    getProjectURL = (compensation) => {
        const { contractid, projectorderid } = compensation;
        return `${window.location.origin}/contract/${contractid}/projects/pm/${projectorderid}`;
    }
    documentBodyConfig = async (notification, resources) => {
        const config = [];
        if (this.props.mode !== "PM_TIME") {
            if (this.props.mode === "PM") {
                const { data: compensation } = await compensationEventCrud.getCompensationEvent(notification.replyactiondetail)
                config.push({ label: resources.AssessmentCompensationLabel, text: notification.replyaction, valueType: 'LINK', url: this.getCompensationURL(compensation) })
            }
            else {
                config.push({ label: resources.AssessmentCompensationLabel, value: notification.replyactiondetail });
            }
        }

        config.push({ resource: 'GENERAL.FORMS.FIELD.SUBJECT', value: notification.assistancetestserchdefect });

        if (this.props.mode === "PM_TIME") {
            if (this.props.taskOrderOptionEnabled) {
                config.push({ label: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.TASK_ORDER' }, { TASK_ORDER: this.props.taskOrderNotificationName }), text: notification.taskorderreference ? notification.taskorderreference : "", valueType: 'LINK', url: this.getTaskURL(notification) });
            }

            if (this.props.projectOrderOptionEnabled) {
                config.push({ label: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.PROJECT_ORDER' }, { PROJECT_ORDER: this.props.projectOrderNotificationName }), text: notification.projectorderreference ? notification.projectorderreference : "", valueType: 'LINK', url: this.getProjectURL(notification) });
            }
        }

        if (this.props.editingProperties.price) {
            config.push({ label: resources.AssessmentCostEffectLabel, value: Common.FormatCurrencyNum(notification.replycomment, (this.props.contract || {}).currency), valueType: 'CURRENCY', currencyCode: (this.props.contract || {}).currency });
        }

        if (this.props.editingProperties.time) {
            config.push({ label: resources.AssessmentDaysEffectLabel, value: `${notification.inspectiontime} ${this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_DAYS' })}` });
        }

        config.push({ label: resources.AssessmentDetailLabel, value: notification.inspectiondetails, valueType: 'HTML', align: 'vertical' });


        return config;
    }
    deleteInspectionDraft = async (inspectionid) => {
        try {
            await inspectionCrud.deleteInspectionDraft(inspectionid);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_DELETED' }), { variant: 'success' });
            this.setState({ saving: false, navigateto: this.props.workflowItem.paths.listPath });

        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            this.setState({ saving: false });
        }
    }
    saveAssessment = async (assessment, action, notifyuserid) => {
        try {
            const postEvents = () => {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), { variant: 'success', });

                if (this.state.postActionUrl) {
                    this.setState({ isChanged: false, saving: false, navigateto: this.state.postActionUrl.pathname });
                }
                else {
                    this.fetchInspectionDefect();

                }
            };

            if (!assessment.inspectiondatedisplay && !assessment.inspectiondatedisplay && assessment.inspectiondate) {
                assessment.inspectiondatedisplay = moment(assessment.inspectiondate).format('DD/MM/YYYY HH:mm:ss');
            }

            assessment.actioncode = action;
            assessment.notifyuserlist = this.getNotifyUserListForUpdate(action, notifyuserid);

            Common.PropertyConversionsBeforeNoticeSaveRequest(assessment, this.props.customFields, this.props.contractUsers);
            await inspectionCrud.updateInspectionDefect(assessment);
            postEvents();
        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            this.setState({ saving: false });
        }
    }
    fieldChanged = (field, value, isDxFormValid, additionalData) => {
        //SILENT STATE CHANGE
        //WE DONT WANT COMPONENT RERENDER WİTH THIS
        //this.setState({ notification: { ...this.state.notification, [field]: value } });
        if (field) {
            if (field === "assetid") {
                this.setState({
                    notification: { ...this.state.notification, [field]: value, asset: { ...additionalData } },
                    isChanged: true
                });
            }
            else {
                if (field === 'selectedCompensationType') { // added to be able to get ce type to decide whether showing the positive amount pop up
                    this.setState({ selectedCompensationType: value });
                } else {
                    this.state.notification[field] = value;
                    if (!this.state.isChanged) {
                        this.setState({ isChanged: true });
                    }
                }
            }
        }
    }
    ceTypeHandler = (type) => {
        this.setState({ selectedCompensationType: type });
    }

    renderToolbar = (documentIsFinal) => {
        const saveDisabled = (this.state.saving || this.state.showModal);
        return (
            <PageToolbar
                key={this.state.notification.version}
                documentIsFinal={documentIsFinal}
                saveDisabled={saveDisabled}
                actions={{
                    showModal: this.showModal,
                    onPrint: this.printHandler
                }}
                workflowItem={this.props.workflowItem}
                referenceid={this.state.notification.referenceid}
                contract={this.props.contract}
                match={this.props.match}
                customFieldValues={this.state.notification.customfieldvalues}
                relationParams={{
                    subject: this.getMailDescription(),
                    assetid: this.state.notification.assetid
                }}
                notification={this.state.notification}
                selectedClausetypeDisplay={this.getSelectedTypeClauseDisplay()}
            />
        );
    }

    tabRelations = () => {
        return (
            <Relations
                contractid={this.props.contract.contractid}
                workflowItem={this.props.workflowItem}
                workflowGroupItem={this.props.workflowGroupItem}
                notificationid={Number(this.props.params.notificationid)}
                relations={this.state.notification.relations}
                itemversion={this.state.notification.version}
                subject={this.state.notification.assistancetestserchdefect}
                onCountChange={(count) => this.setState({ relationCount: count })}
                showTags={this.props.contract.specifictags?.length > 0}
            />
        );
    }
    tabDocument = () => {
        return (
            <AssessmentDocument
                {...this.props}
                validatorRef={this.validatorRef}
                printDivRef={this.printIssueDivRef}
                fieldChanged={this.fieldChanged}
                notification={this.state.notification}
                files={this.state.files}
                notificationid={this.props.params.notificationid}
                ceTypeHandler={this.ceTypeHandler}
            />
        );
    }
    tabCostBreakdown = () => {
        return (
            <CostBreakdown
                {...this.props}
                notification={this.state.notification}
                quotationActivitiesList={this.state.quotationActivitiesList}
                loadCompensationEventActivitiesList={this.loadCompensationEventActivitiesList}
            />
        );
    };
    loadCompensationEventActivitiesList = () => {
        if (this.wbsEnabled) {
            const { companyid, projectid, contractid } = this.props.contract;

            compensationEventCrud
                .getCompensationEventActivities(
                    companyid,
                    projectid,
                    contractid,
                    this.state.notification.replyactiondetail,
                    "Activity"
                )
                .then((response) => {
                    this.setState({ quotationActivitiesList: JSON.parse(response.data.aggregatelist) });
                })
                .catch((err) => {
                    if (!axios.isCancel(err)) {
                        this.props.enqueueSnackbar(err.toString(), { variant: "error" });
                    }
                });
        }
    };
    tabEmailNotification = () => {

        return (
            <EmailNotifications
                {...this.props}
                notificationDate={this.state.notification.notificationdate}
            />
        );

    };
    render() {
        if (!this.state.notification || !this.state.files) {
            return (<PortletSpinner />);
        }
        const documentIsFinal = this.state.notification.showtasknotification === 1;

        if (!documentIsFinal && !this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed) {
            return (<Navigate replace to={this.props.workflowItem.paths.listPath} />)
        }

        return (
            <Portlet>
                <ReactToPrint
                    ref={el => (this.printIssueRef = el)}
                    trigger={() => (<div style={{ display: 'none' }} ></div>)}
                    content={() => this.printIssueDivRef.current}
                />
                <NavigationBlocker
                    when={(this.state.isChanged || this.state.saving)}
                    message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}
                />
                {this.state.navigateto &&
                    (
                        <Navigate replace to={this.state.navigateto} state={(this.state.postActionUrl || {}).state} />
                    )
                }
                <PortletHeader
                    title={documentIsFinal ? this.props.workflowItem.pageTitle : this.props.intl.formatMessage({ id: "GENERAL.FORMS.DRAFT_TITLE" }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
                    toolbar={this.renderToolbar(documentIsFinal)}
                />
                <PortletBody>
                    <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
                        <div>
                            <ValidationSummary elementAttr={{ align: "right", paddingBottom: 50 }} id={this.validatorName}></ValidationSummary>
                        </div>
                        <TabPanel
                            width="100%"
                            deferRendering={false}
                            animationEnabled={true}
                            focusStateEnabled={true}
                            hoverStateEnabled={true}
                            swipeEnabled={false}>
                            <Item icon="file" title={this.props.title} render={this.tabDocument} />

                            {
                                this.wbsEnabled && (
                                    <Item icon="bulletlist" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_COST_BREAKDOWN' })} render={this.tabCostBreakdown} />
                                )
                            }
                            {
                                this.isRelationsEnabled && (
                                    <Item icon="share" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_RELATIONS' })} render={this.tabRelations} badge={this.state.relationCount > 0 ? this.state.relationCount : null} />
                                )
                            }
                            <Item
                                icon="bulletlist"
                                title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_EMAIL_NOTIFICATIONS' })}
                                render={documentIsFinal ? this.tabEmailNotification : null} visible={documentIsFinal}
                            />
                        </TabPanel>

                    </ValidationGroup>
                </PortletBody>
                <ActionModal
                    visible={this.state.showModal}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    mode={this.state.modalMode}
                    onDialogResult={this.onModalResponse}
                    workflowItem={this.props.workflowItem}
                    userLimit={this.state.notification.replycomment}
                    selectedClause={this.state.selectedClause}
                />
            </Portlet>
        );
    }
}

export default withDetailPageProps(AssessmentDetailPage);