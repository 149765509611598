import React, { useEffect } from 'react';
import { LayoutSplashScreen } from "../../../../_metronic";
import { signOut } from "supertokens-auth-react/recipe/session";
import { superTokenWrapper } from "./SuperTokenHandler";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import * as authDuck from "../../../store/ducks/auth.duck";
import { connect } from "react-redux";

const SuperTokenLogoutHandler = (props) => {
  const sessionContext = useSessionContext();
  const startLogout = async () => {
    if (!sessionContext.loading && sessionContext?.doesSessionExist) {
      console.log("SuperToken Logout process started!")
      await signOut();
      console.log("SuperToken FD-Logout process started!")
      props.startLogout();
    }
  }
  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== 'PROD') {
      console.log("SuperTokenLogoutHandler Mounted!", JSON.parse(JSON.stringify(sessionContext)));
    }

    // if (!sessionContext.loading && sessionContext?.doesSessionExist) {
    //   signOut();
    // }
  }, [])

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== 'PROD') {
      console.log("ContextChanged", JSON.parse(JSON.stringify(sessionContext)));
    }
    if (!sessionContext.loading && sessionContext?.doesSessionExist) {
      startLogout();
    }
    else if (!sessionContext.loading && !sessionContext?.doesSessionExist && !props.logoutProcessing) {
      //SESSION Probably leared by another TAB
      //LETS Continue with FD Logout
      console.log("SuperToken FD-Logout process started!")
      props.startLogout();
    }
  }, [sessionContext]);



  return (
    <div style={{ margin: "auto" }}>
      <LayoutSplashScreen />
    </div>
  )
}
const mapStateToProps = state => ({
  logoutProcessing: state.auth.logoutProcessing
});
const mapDispatchToProps = {
  startLogout: authDuck.actions.startLogout
};



export default superTokenWrapper(connect(mapStateToProps, mapDispatchToProps)(SuperTokenLogoutHandler));
