import React from 'react';
import BaseDetailPage from '../../_CustomComponents/BaseDetailPage';
import ReactToPrint from "react-to-print";
import { Navigate } from "react-router-dom";
import { addDays } from 'date-fns';
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import * as programCrud from '../../../../crud/program.crud';
import * as contractCrud from '../../../../crud/contracts.crud';
import * as fileCrud from '../../../../crud/file.crud';
import axios from 'axios';
import ActionModal from '../../_CustomComponents/ActionModal';
import ProgrammeDocument from './Document';
import ReplyDocument from './DocumentReply';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import { Alert } from "react-bootstrap";
import { ValidationSummary, ValidationGroup } from 'devextreme-react';
import moment from 'moment';
import Relations from '../../_CustomComponents/Relation/Relations';
import { FormattedMessage } from "react-intl";
import PageToolbar from '../../_CustomComponents/PageToolbar';
import Common from '../../../../shared/common';
import { withDetailPageProps } from '../../../../shared/hoc/withDetailPageProps';
import NavigationBlocker from '../../../../partials/Tools/NavigationBlocker';
import EmailNotifications from '../../_CustomComponents/EmailNotifications';
const initialState = {
    notification: null,
    files: null,
    showModal: false,
    saving: false,
    // isDxFormValid: true,
    isChanged: false,
    //NEW REPLY STATES
    reply: null,
    replyFiles: null,
    isAwaitingReply: false,
    modalTitle: '',
    modalText: '',
    modalMode: '',
    relationCount: 0,
    postActionUrl: null,
    relations: null,
    selectedClause: null
};

class ProgrammeDetail extends BaseDetailPage {
    constructor(props) {
        super(props);
        this.isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'DEV';
        this.isRelationsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true' || process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true';
        this.printIssueDivRef = React.createRef();
        this.printAcceptanceDivRef = React.createRef();
        //NEW REPLY
        this.validatorRef = React.createRef();
        this.validatorName = 'ProgramValidator';

        const replyOptionsPH = props.placeholders.find(ph => ph.replacementKey === 'ReplyOptions');
        if (replyOptionsPH) {
            this.replyOptions = replyOptionsPH.values.filter(item => item.isActive === true);
        }
        const rejectOptionsPH = props.placeholders.find(ph => ph.replacementKey === 'ReplyRejectOptions');
        if (rejectOptionsPH) {
            this.rejectOptions = rejectOptionsPH.values.filter(item => item.isActive === true);
        }


        this.state = { ...initialState };
    }
    componentDidMount() {
        this.fetchProgramme();
        this.fetchFiles();
        if (this.props.programmeReplyEnabled) {
            this.fetchReplyFiles();
        }
    }

    componentDidUpdate(prevprops, prevSatate) {
        if (this.props.params.notificationid !== prevprops.params.notificationid) {
            this.setState({ ...initialState }, () => {
                this.fetchProgramme();
                this.fetchFiles();
                if (this.props.programmeReplyEnabled) {
                    this.fetchReplyFiles();
                }
            });
        }
    }

    getMailDescription = () => {
        return this.state.notification.reference;
    }
    getSelectedTypeClauseDisplay = () => {
        return this.state.notification.type;
    }
    fetchProgramme = async () => {
        await this.props.closeSnackbar('PersistentFormWarning');
        programCrud.getProgram(this.props.params.notificationid)
            .then(response => {
                const programme = response.data;
                if (programme.contractid !== this.props.contract.contractid) {
                    throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.INVALID_URL' }));
                }
                if (programme.previousplanneddate && this.props.fields.PreviousPlanned) {
                    programme.previousplanneddate = new Date(programme.previousplanneddate);
                }

                if (this.props.contract.expectedcompletiondate && this.props.fields.PreviousPlanned) {
                    const contractCompletionDate = new Date(this.props.contract.expectedcompletiondate);
                    //HANDLE THE CASE CONTRACT PLANNED DATE VALUE HAS BEEN UPDATED SINCE THIS NOTIFICATION DRAFTED
                    if (programme.showtasknotification !== 1 && !moment(contractCompletionDate).isSame(programme.previousplanneddate, 'day')) {
                        programme.previousplanneddate = contractCompletionDate;
                        this.props.enqueueSnackbar('Form fields has been updated due to contract completion date change', { variant: 'warning', key: 'PersistentFormWarning', persist: true });
                    }
                }
                if (this.props.fields.PlannedCompletion) {
                    programme.plannedcompletiondate = new Date(programme.plannedcompletiondate);
                }
                if (programme.draftreplydate) {
                    programme.draftreplydate = new Date(programme.draftreplydate);
                }

                const awaitingReply = (programme.showtasknotification === 1 &&
                    ["Awaiting Acceptance", "ADAP"].includes(programme.status) &&
                    this.props.programmeReplyEnabled);


                let selectedClause = null;
                if (programme.type && this.props.workflowItem.clauses) {
                    selectedClause = this.props.workflowItem.clauses.find(c => c.display === programme.type);
                    if (selectedClause) {
                    }
                    else {
                        if (programme.showtasknotification !== 1) {
                            programme.type = null;
                            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_CLAUSE_MISSING' }), { variant: 'warning', });
                        }
                    }
                }

                const replyItem = {
                    status: programme.draftprogrammestatus,
                    comments: programme.draftreplycomment,
                    plansnotpractical: programme.draftplansnotpractical === 1,
                    missingcontractrequires: programme.draftmissingcontractrequires === 1,
                    notrepresentplan: programme.draftnotrepresentplan === 1,
                    notcomply: programme.draftnotcomply === 1,
                    rejectcustomreason1: programme.draftrejectcustomreason1 === 1,
                    rejectcustomreason2: programme.draftrejectcustomreason2 === 1,
                    customfieldvalues: programme.customfieldvalues
                };

                this.setState({
                    notification: programme,
                    reply: replyItem,
                    isAwaitingReply: awaitingReply,
                    saving: false,
                    isChanged: false,
                    relations: programme.relations,
                    relationCount: Common.GetRelationCount(programme, this.props.accessibleWfIds)
                }, () => { this.props.setReference(this.state.notification.referenceid); });
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                }
            });
    }

    fetchFiles = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.notificationid, this.props.documentType)
            const filesList = JSON.parse(data.aggregatelist)
            this.setState({ files: filesList });
            return filesList;
        } catch (err) {
            if (!axios.isCancel(err)) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
        }
    }
    fetchReplyFiles = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.notificationid, this.props.documentType + 'Reply')
            const filesList = JSON.parse(data.aggregatelist)
            this.setState({ replyFiles: filesList });
            return filesList;
        } catch (err) {
            if (!axios.isCancel(err)) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
        }
    }
    printHandler = () => {
        this.printIssueRef.handlePrint();
    }

    printAcceptanceHandler = () => {
        this.printAcceptanceRef.handlePrint();
    }
    validateForm = () => {
        const validationResult = this.validatorRef.current.instance.validate();
        if (!validationResult.isValid) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_MISSING_FIELDS' }), { variant: 'warning', });
        }
        return validationResult.isValid && !this.state.saving;
    }

    showModal = (mode, postActionNavigateObj) => {
        let selectedClause = null;
        if (this.state.notification.type && this.props.workflowItem.clauses) {
            selectedClause = this.props.workflowItem.clauses.find(c => c.display === this.state.notification.type);
        }
        if (mode === 'COMMUNICATE') {
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_COMMUNICATE_ITEM' }, { ITEM_NAME: this.props.title }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_COMMUNICATING' }, { ITEM_NAME: this.props.title, PARTY_NAME: this.props.communicateToUser }),
                    modalMode: mode,
                    postActionUrl: postActionNavigateObj,
                    selectedClause
                });
            }
        }
        else if (mode === 'SAVE_DRAFT') {
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_DRAFT_NOTIFY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
                    modalMode: mode,
                });
            }
        }
        else if (mode === 'DELETE_DRAFT') {
            this.setState({
                showModal: true,
                modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_DELETE_DRAFT_NOTIFY' }),
                modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_DELETING' }),
                modalMode: mode,
            });
        }
        else if (mode === 'REPLY_DRAFT') {
            //show reply tab
            this.tabPanel.instance.option("selectedIndex", 1);
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_REPLY_DRAFT_NOTIFY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
                    modalMode: mode,
                });
            }
        }
        else if (mode === 'REPLY_SEND') {
            //show reply tab
            this.tabPanel.instance.option("selectedIndex", 1);
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SEND_REPLY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_REPLY_SENDING' }, { PARTY_NAME: this.props.globalResources[this.props.workflowItem.notifyingParty + "_NAME"] }),
                    modalMode: mode,
                    selectedClause
                });
            }
        }
    }

    onModalResponse = (response, params) => {
        this.setState({ showModal: false, saving: response === 'OK' }, async () => {
            try {
                if (response === 'OK') {

                    if (this.state.modalMode === 'COMMUNICATE') {
                        const programme = { ...this.state.notification };
                        programme.createactions = params?.createactions;
                        programme.completeactions = params?.completeactions;
                        if (programme.previousplanneddate && this.props.fields.PreviousPlanned) {
                            programme.previousplanneddatedisplay = moment(programme.previousplanneddate).format('DD/MM/YYYY');
                        }
                        if (this.props.fields.PlannedCompletion) {
                            programme.plannedcompletiondatedisplay = moment(programme.plannedcompletiondate).format('DD/MM/YYYY');
                        }
                        if (this.props.programmeReplyEnabled) {
                            programme.replyrequireddatedisplay = moment(addDays(new Date(), this.props.replyPeriod)).format('DD/MM/YYYY');
                        }

                        //#TODO: MOVE THIS CHECK FROM UI TO API
                        const { data: contractDetails } = await contractCrud.getContract(this.props.contract.contractid);
                        if (contractDetails.expectedcompletiondate && this.props.fields.PreviousPlanned) {
                            const contractCompletionDate = new Date(contractDetails.expectedcompletiondate);
                            if (!moment(contractCompletionDate).isSame(programme.previousplanneddate, 'day')) {
                                throw new Error(this.props.intl.formatMessage({ id: 'CONTRACT.WF.PAYMENT.WARNING.RECALCULATED_REFRESH' }))
                            }
                        }
                        programme.showtasknotification = 1;

                        if (this.props.programmeReplyEnabled) {
                            programme.status = 'Awaiting Acceptance';
                            programme.replyrequireddatedisplay = moment(addDays(new Date(), this.props.replyPeriod)).format('DD/MM/YYYY');
                        }
                        else {
                            programme.status = 'Communicated';
                        }
                        const docBodyConfig = this.documentBodyConfig(programme, this.props.resources);
                        const docConfig = await this.documentConfig(programme, docBodyConfig, this.props.contractUser.name, false, null, null, this.props.workflowItem.form_NotifyCompanyName);
                        programme.pdfjsoncontent = JSON.stringify(docConfig);

                        this.saveProgramme(programme, this.state.modalMode);

                    }
                    else if (this.state.modalMode === 'SAVE_DRAFT') {
                        const programme = { ...this.state.notification };
                        //program.createbyid = this.props.contractUser.accountid; 
                        if (programme.previousplanneddate && this.props.fields.PreviousPlanned) {
                            programme.previousplanneddatedisplay = moment(programme.previousplanneddate).format('DD/MM/YYYY');
                        }
                        if (this.props.fields.PlannedCompletion) {
                            programme.plannedcompletiondatedisplay = moment(programme.plannedcompletiondate).format('DD/MM/YYYY');
                        }
                        // programme.changeddatedisplay = moment().format('DD/MM/YYYY');
                        if (this.props.programmeReplyEnabled) {
                            programme.replyrequireddatedisplay = moment(addDays(new Date(), this.props.replyPeriod)).format('DD/MM/YYYY');
                        }

                        this.saveProgramme(programme, this.state.modalMode, params ? params.userid : null);

                    }
                    else if (this.state.modalMode === 'DELETE_DRAFT') {
                        const program = { ...this.state.notification };
                        const programid = program.programid
                        this.deleteProgramDraft(programid);

                    }
                    else if (this.state.modalMode === 'REPLY_DRAFT') {
                        const reply = { ...this.state.reply };
                        const programme = { ...this.state.notification };
                        const isAccepted = reply.status === 'Accepted';
                        programme.draftprogrammestatus = reply.status;
                        programme.draftreplycomment = reply.comments;

                        programme.draftplansnotpractical = reply.plansnotpractical && !isAccepted ? 1 : 0;
                        programme.draftmissingcontractrequires = reply.missingcontractrequires && !isAccepted ? 1 : 0;
                        programme.draftnotrepresentplan = reply.notrepresentplan && !isAccepted ? 1 : 0;
                        programme.draftnotcomply = reply.notcomply && !isAccepted ? 1 : 0;
                        programme.draftrejectcustomreason1 = reply.rejectcustomreason1 && !isAccepted ? 1 : 0;
                        programme.draftrejectcustomreason2 = reply.rejectcustomreason2 && !isAccepted ? 1 : 0;

                        // programme.draftreplystatus = 'Drafted';
                        // programme.draftreplybyid = this.props.contractUser.accountid;
                        if ((params || {}).userid) {
                            programme.draftreplytoid = params.userid;
                        }
                        programme.draftreplydatedisplay = moment().format('DD/MM/YYYY');
                        for (let key in reply) {
                            if (key.startsWith("CF_")) {
                                programme[key] = reply[key];
                            }
                        }
                        this.saveProgramme(programme, this.state.modalMode, (params || {}).userid);

                    }
                    else if (this.state.modalMode === 'REPLY_SEND') {
                        const reply = { ...this.state.reply };
                        if (!this.replyValidation(reply)) {
                            //FINAL REPLY FIELDS CHECK FAILED
                            throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_REPLY_FIELDS_MISSING' }))
                        }
                        const programme = { ...this.state.notification };
                        const isAccepted = reply.status === 'Accepted';
                        programme.createactions = params?.createactions;
                        programme.completeactions = params?.completeactions;
                        programme.status = reply.status;
                        programme.comments = reply.comments;

                        const rejectOptions = {
                            plansnotpractical: reply.plansnotpractical && !isAccepted ? 1 : 0,
                            missingcontractrequires: reply.missingcontractrequires && !isAccepted ? 1 : 0,
                            notrepresentplan: reply.notrepresentplan && !isAccepted ? 1 : 0,
                            notcomply: reply.notcomply && !isAccepted ? 1 : 0,
                            rejectcustomreason1: reply.rejectcustomreason1 && !isAccepted ? 1 : 0,
                            rejectcustomreason2: reply.rejectcustomreason2 && !isAccepted ? 1 : 0,
                        }


                        if (programme.previousplanneddate && this.props.fields.PreviousPlanned) {
                            programme.previousplanneddatedisplay = moment(programme.previousplanneddate).format('DD/MM/YYYY');
                        }
                        if (this.props.fields.PlannedCompletion) {
                            programme.plannedcompletiondatedisplay = moment(programme.plannedcompletiondate).format('DD/MM/YYYY');
                        }

                        programme.replyrequireddatedisplay = moment(new Date(programme.replyrequireddate)).format('DD/MM/YYYY');
                        // programme.replybyid = this.props.contractUser.accountid;
                        // programme.replydatedisplay = moment().format('DD/MM/YYYY');
                        programme.notcomply = rejectOptions.notcomply;
                        programme.notrepresentplan = rejectOptions.notrepresentplan;
                        programme.missingcontractrequires = rejectOptions.missingcontractrequires;
                        programme.plansnotpractical = rejectOptions.plansnotpractical;
                        programme.rejectcustomreason1 = rejectOptions.rejectcustomreason1;
                        programme.rejectcustomreason2 = rejectOptions.rejectcustomreason2;

                        for (let key in reply) {
                            if (key.startsWith("CF_")) {
                                programme[key] = reply[key];
                            }
                        }
                        const docReplyBodyConfig = this.documentReplyBodyConfig(programme, this.props.resources, this.replyOptions);
                        const docReplyConfig = await this.documentConfig(programme, docReplyBodyConfig, this.props.contractUser.name, true, null, null, this.props.workflowItem.form_ReplyCompanyName);
                        programme.pdfreplyjsoncontent = JSON.stringify(docReplyConfig);

                        this.saveProgramme(programme, this.state.modalMode);

                    }

                }
            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                this.setState({ saving: false });
            }

        });
    }
    replyValidation = (reply) => {
        if (!reply.status) { return false; }
        if (!reply.comments) { return false; }
        return true;
    }
    documentBodyConfig = (notification, resources) => {
        const config = [];
        if (resources.NotificationClause) {
            config.push({ value: resources.NotificationClause });
        }
        config.push({ label: this.props.fields.Reference, value: notification.reference });

        if (this.props.fields.Type) {
            config.push({ label: this.props.fields.Type, value: notification.type });
        }

        if (notification.previousplanneddate && this.props.fields.PreviousPlanned) {
            config.push({ label: this.props.fields.PreviousPlanned, value: notification.previousplanneddate });
        }
        if (this.props.fields.PlannedCompletion) {
            config.push({ label: this.props.fields.PlannedCompletion, value: notification.plannedcompletiondate });
        }
        if (this.props.fields.Reason) {
            config.push({ label: this.props.fields.Reason, value: notification.reasonchanged, valueType: 'HTML', align: 'vertical' });
        }
        if (this.props.fields.Change) {
            config.push({ label: this.props.fields.Change, value: notification.details, valueType: 'HTML', align: 'vertical' });
        }
        if (this.props.fields.MainProgress) {
            config.push({ label: this.props.fields.MainProgress, value: notification.mainprogress, valueType: 'HTML', align: 'vertical' });
        }
        if (this.props.fields.KeyActivities) {
            config.push({ label: this.props.fields.KeyActivities, value: notification.keyactivities, valueType: 'HTML', align: 'vertical' });
        }
        if (this.props.fields.Summary) {
            config.push({ label: this.props.fields.Summary, value: notification.summarycritical, valueType: 'HTML', align: 'vertical' });
        }
        if (this.props.fields.Changes) {
            config.push({ label: this.props.fields.Changes, value: notification.mainchanges, valueType: 'HTML', align: 'vertical' });
        }
        if (this.props.fields.Compensation) {
            config.push({ label: this.props.fields.Compensation, value: notification.compensationevents, valueType: 'HTML', align: 'vertical' });
        }

        return config;
    }
    documentReplyBodyConfig = (notification, resources, replyOptions) => {
        let statusDisplay = "UNKNOWN";
        const selectedReply = replyOptions.find(opt => opt.field === notification.status);
        if (selectedReply) {
            statusDisplay = selectedReply.display;
        }
        const config = [
            { label: this.props.fields.Reference, value: notification.reference, },
            { resource: "GENERAL.FORMS.FIELD.STATUS", value: statusDisplay },
        ];
        if (notification.status !== 'Accepted') {
            const items = this.rejectOptions.filter(itm => (notification[itm.field] && notification[itm.field] === 1))
                .map(itm => ({ label: itm.display }))
            config.push({
                label: resources.ReplyNotificationClause + ' ' + this.props.intl.formatMessage({ id: 'CONTRACT.WF.PROGRAMME.REPLY.NOTE' }, { REFERENCE: notification.reference, STATUS: statusDisplay }),
                list: items,
                valueType: 'LIST',
                align: 'vertical'
            });
        }
        config.push(
            { resource: 'GENERAL.FORMS.FIELD.DETAILS', value: notification.comments, valueType: 'HTML', align: 'vertical' },
        )
        return config;
    }
    deleteProgramDraft = async (programid) => {

        try {
            await programCrud.deleteProgramDraft(programid);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_DELETED' }), { variant: 'success' });
            this.setState({ saving: false, navigateto: this.props.workflowItem.paths.listPath });

        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            this.setState({ saving: false });
        }
    }
    saveProgramme = async (programme, action, notifyuserid) => {
        try {
            if (!programme.plannedcompletiondatedisplay && programme.plannedcompletiondate) {
                programme.plannedcompletiondatedisplay = moment(programme.plannedcompletiondate).format('DD/MM/YYYY');
            }
            if (!programme.previousplanneddatedisplay && programme.previousplanneddate) {
                programme.previousplanneddatedisplay = moment(programme.previousplanneddate).format('DD/MM/YYYY');
            }

            if (!programme.draftreplydatedisplay && programme.draftreplydate) {
                programme.draftreplydatedisplay = moment(programme.draftreplydate).format('DD/MM/YYYY');
            }

            if (!programme.replyrequireddatedisplay && programme.replyrequireddate) {
                programme.replyrequireddatedisplay = moment(programme.replyrequireddate).format('DD/MM/YYYY');
            }
            if (this.props.workflowItem.replyEnabled) {
                programme.replyingparty = this.props.workflowItem.replyingParty;
            }
            programme.actioncode = action;
            programme.notifyuserlist = this.getNotifyUserListForUpdate(action, notifyuserid);
            Common.PropertyConversionsBeforeNoticeSaveRequest(programme, this.props.customFields, this.props.contractUsers);
            await programCrud.updateProgram(programme);

            if (this.state.postActionUrl) {
                this.setState({ isChanged: false, saving: false, navigateto: this.state.postActionUrl.pathname });
            }
            else {
                this.fetchProgramme();

                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), { variant: 'success', });

            }

        } catch (err) {
            this.setState({ saving: false });
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
    }

    fieldChanged = (field, value, isDxFormValid, additionalData) => {
        //SILENT STATE CHANGE
        //WE DONT WANT COMPONENT RERENDER WİTH THIS
        //this.setState({ notification: { ...this.state.notification, [field]: value } });
        if (field) {
            if (field === "assetid") {
                this.setState({
                    notification: { ...this.state.notification, [field]: value, asset: { ...additionalData } },
                    isChanged: true
                });
            }
            else {
                this.state.notification[field] = value;
                if (!this.state.isChanged) {
                    this.setState({ isChanged: true });
                }
            }
        }

    }
    replyFieldChanged = (field, value, isDxReplyFormValid, isCheckBoxValue) => {
        //SILENT STATE CHANGE
        //WE DONT WANT COMPONENT RERENDER WİTH THIS
        //this.setState({ notification: { ...this.state.notification, [field]: value } });
        if (field) {
            this.state.reply[field] = value;
            if (!this.state.isChanged) {
                this.setState({ isChanged: true });
            }
        }

    }

    renderToolbar = (documentIsFinal) => {
        const saveDisabled = (this.state.saving || this.state.showModal);
        return (
            <PageToolbar
                key={this.state.notification.version}
                documentIsFinal={documentIsFinal}
                saveDisabled={saveDisabled}
                isAwaitingReply={this.state.isAwaitingReply}
                actions={{
                    showModal: this.showModal,
                    onPrintReply: this.printAcceptanceHandler,
                    onPrint: this.printHandler
                }}
                workflowItem={this.props.workflowItem}
                match={this.props.match}
                customFieldValues={this.state.notification.customfieldvalues}
                referenceid={this.state.notification.referenceid}
                contract={this.props.contract}
                relationParams={{
                    subject: this.getMailDescription(),
                    assetid: this.state.notification.assetid
                }}
                notification={this.state.notification}
                selectedClausetypeDisplay={this.getSelectedTypeClauseDisplay()}
            />
        );
    }
    tabDocument = () => {
        return (
            <ProgrammeDocument
                {...this.props}
                fields={this.props.fields}
                printDivRef={this.printIssueDivRef}
                fieldChanged={this.fieldChanged}
                notification={this.state.notification}
                files={this.state.files}

                notificationid={this.props.params.notificationid}
            />
        );
    }
    tabReply = () => {
        if (!this.props.workflowItem.userFilters.replyNotice.communicateReplyAllowed && !this.props.workflowItem.userFilters.replyNotice.saveDraftReplyAllowed && this.state.isAwaitingReply) {
            return (
                <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                    <div style={{ width: '100%', maxWidth: '900px', margin: '20px' }}>
                        <Alert variant="warning" >
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_AWAITING_REPLY" values={{ ITEM_NAME: this.props.workflowItem.pageTitle }} />
                            </p>
                        </Alert>
                    </div>
                </div>
            )
        }
        else if (!this.state.isAwaitingReply && !this.state.notification.pdfreplyjsoncontent && this.state.notification.deemedacceptancedate) {
            //CEN HAS BEEN DEEM ACCEPTED
            //THERE IS NO REPLY DOCUMENT
            return (
                <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                    <div style={{ width: '100%', maxWidth: '900px', margin: '20px' }}>
                        <Alert variant="warning" >
                            <p>
                                {this.props.resources.ProgReplyMissingDueToDeemAcceptance}
                            </p>
                        </Alert>
                    </div>
                </div>
            )
        }
        else {
            return (
                <ReplyDocument
                    {...this.props}
                    isAwaitingReply={this.state.isAwaitingReply}
                    printDivRef={this.printAcceptanceDivRef}
                    fieldChanged={this.replyFieldChanged}
                    notification={this.state.notification}
                    reply={this.state.reply}

                    files={this.state.replyFiles}
                    notificationid={this.props.params.notificationid}
                />
            )
        }
    }
    tabRelations = () => {
        return (
            <Relations
                contractid={this.props.contract.contractid}
                workflowItem={this.props.workflowItem}
                workflowGroupItem={this.props.workflowGroupItem}
                notificationid={Number(this.props.params.notificationid)}
                relations={this.state.notification.relations}
                itemversion={this.state.notification.version}
                subject={this.state.notification.reference}
                onCountChange={(count) => this.setState({ relationCount: count })}
                showTags={this.props.contract.specifictags?.length > 0}
            />
        );
    }
    tabEmailNotification = () => {

        return (
            <EmailNotifications
                {...this.props}
                notificationDate={this.state.notification.notificationdate}
            />
        );

    };
    render() {
        if (!this.state.notification || !this.state.files || (this.props.programmeReplyEnabled && !this.state.replyFiles)) {
            return (<PortletSpinner />);
        }
        const documentIsFinal = this.state.notification.showtasknotification === 1;

        if (!documentIsFinal && !this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed) {
            return (<Navigate replace to={this.props.workflowItem.paths.listPath} />)
        }
        return (
            <Portlet>
                <ReactToPrint
                    ref={el => (this.printIssueRef = el)}
                    trigger={() => (<div style={{ display: 'none' }} ></div>)}
                    content={() => this.printIssueDivRef.current}
                />
                <ReactToPrint
                    ref={el => (this.printAcceptanceRef = el)}
                    trigger={() => (<div style={{ display: 'none' }} ></div>)}
                    content={() => this.printAcceptanceDivRef.current}
                />
                <NavigationBlocker
                    when={(this.state.isChanged || this.state.saving)}
                    message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}
                />
                {this.state.navigateto &&
                    (
                        <Navigate replace to={this.state.navigateto} state={(this.state.postActionUrl || {}).state} />
                    )
                }
                <PortletHeader
                    title={documentIsFinal ? this.props.workflowItem.pageTitle : this.props.intl.formatMessage({ id: "GENERAL.FORMS.DRAFT_TITLE" }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
                    toolbar={this.renderToolbar(documentIsFinal)}
                />
                <PortletBody>
                    <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
                        <div>
                            <ValidationSummary elementAttr={{ align: "right", paddingBottom: 50 }} id={this.validatorName}></ValidationSummary>
                        </div>
                        <TabPanel
                            ref={(ref) => this.tabPanel = ref}
                            width="100%"
                            deferRendering={false}
                            animationEnabled={true}
                            focusStateEnabled={true}
                            hoverStateEnabled={true}
                            swipeEnabled={false}
                        >
                            <Item icon="file" title={this.props.title} render={this.tabDocument} />
                            {
                                this.props.programmeReplyEnabled && (
                                    <Item icon="rename" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_REPLY' })} render={documentIsFinal ? this.tabReply : null} visible={documentIsFinal} />
                                )
                            }
                            {
                                this.isRelationsEnabled && (
                                    <Item icon="share" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_RELATIONS' })} render={this.tabRelations} badge={this.state.relationCount > 0 ? this.state.relationCount : null} />
                                )
                            }
                            <Item
                                icon="bulletlist"
                                title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_EMAIL_NOTIFICATIONS' })}
                                render={documentIsFinal ? this.tabEmailNotification : null} visible={documentIsFinal}
                            />
                        </TabPanel>
                    </ValidationGroup>
                </PortletBody>
                <ActionModal
                    visible={this.state.showModal}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    mode={this.state.modalMode}
                    onDialogResult={this.onModalResponse}
                    workflowItem={this.props.workflowItem}
                    selectedClause={this.state.selectedClause}
                />
            </Portlet>
        );
    }
}


export default withDetailPageProps(ProgrammeDetail);
