import React from 'react';
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import DataGridBase from "../../../../partials/layout/DataGridBase";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from 'moment';
import CustomFieldHelper from "../../_Helpers/CustomFieldHelper";
import * as compensationEventCrud from '../../../../crud/compensationEvent.crud';
import { notificationAggregateListTagsFilter, notificationUniqueTagsFilter } from "../../../../store/helpers/FilterHelper";
import { withListPageProps } from '../../../../shared/hoc/withListPageProps';

class CompensationListPage extends React.Component {
    constructor(props) {
        super(props);
        this.compensationStatuses = props.placeholders
            .find(ph => ph.replacementKey === 'CompensationStatuses')
            .values.filter(item => item.isActive === true);

        this.notificationsTagsEnabled = process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true' && props.contract.specifictags?.length > 0;
        this.notificationsRelsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true';


        if (this.props.workflowItem.replyingParty !== "PM") {
            this.responseRequiredDateCaption = this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.RESPONSE_REQUIRED' })
            this.responseReceivedDateCaption = this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.RESPONSE_RECEIVED' })
        }
        else {
            this.responseRequiredDateCaption = this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.PM_RESPONSE_REQUIRED' }, { PM_ABBREVIATION: this.props.globalResources.PM_ABBREVIATION })
            this.responseReceivedDateCaption = this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.PM_RESPONSE_RECEIVED' }, { PM_ABBREVIATION: this.props.globalResources.PM_ABBREVIATION })
        }


        this.customFields = [];
        if (props.customFields) {
            if (props.customFields.COMMUNICATION && props.customFields.COMMUNICATION.length > 0) {
                this.customFields.push(...(props.customFields.COMMUNICATION.filter(cf => cf.includelisting)));
            }
            if (props.customFields.REPLY && props.customFields.REPLY.length > 0) {
                this.customFields.push(...(props.customFields.REPLY.filter(cf => cf.includelisting)));
            }
        }
        this.state = {
            notifications: null,
            tags: null
        };
    }
    componentDidMount() {
        this.fetchCompensationEvents();
    }
    fetchCompensationEvents = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data: responseObj } = await compensationEventCrud.getCompansationEventsReduced(companyid, projectid, contractid, this.props.workflowItem.config.currentModelType, this.props.accessibleWfIds);
            let listData = notificationAggregateListTagsFilter(responseObj.aggregatelist);
            listData = (this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed === true) ?
                listData :
                listData.filter(ntf => ntf.showtasknotification === 1);
            let tagFilters = notificationUniqueTagsFilter(responseObj.uniquetags);
            this.setState({ notifications: listData, tags: tagFilters });
        } catch (error) {
            console.log(error)
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }
    }
    formatCurrency = () => {
        return this.props.contract.currency
    }


    onTableSelectionChanged = ({ selectedRowsData }) => {
        if (selectedRowsData.length > 0) {
            const data = selectedRowsData[0];
            const url = this.calculateUrl(data, true);
            this.props.navigate(url);
        }
    }
    calculateUrl = (rowData, skipHostUrl) => {
        return (skipHostUrl ? '' : process.env.PUBLIC_URL) + this.props.workflowItem.paths.basePath + rowData.compensationeventid;
    }
    renderStatusColumn = (cellInfo) => {
        let badgeVariant = 'secondary';
        let status = this.renderStatusDisplay(cellInfo.data);
        switch (cellInfo.data.status) {
            case 'R':
            case 'APMA':
            case 'ARQ':
            case 'DNG':
                badgeVariant = 'danger';
                break;
            case 'AM':
            case 'QA':
            case 'DA':
                badgeVariant = 'success';
                break;
            case 'APMRQ':
            case 'APMR':
            case 'AQ':
            case 'ADAQ':
            case 'ADAN':
                badgeVariant = 'warning';
                break;
            case 'NC':
                badgeVariant = 'secondary';
                break;
            default:
                badgeVariant = 'secondary';
                break;
        }
        return (<Badge variant={badgeVariant}>{status}</Badge>);
    }
    renderInstructionColumn = (cellInfo) => {
        return (
            <Link to={`/contract/${this.props.contract.contractid}/instructions/pm/${cellInfo.data.inspectiondefectid}`} >
                {'Instruction'}
            </Link>
        );

    }
    renderStatusDisplay = (rowData) => {
        const status = this.compensationStatuses.find(x => x.field === rowData.status);
        if (status) {
            return status.display;
        } else {
            return rowData.status;
        }
    }

    renderRequiredReceivedCell = (RequiredDate, ReceivedDate) => {
        if (RequiredDate && ReceivedDate) {
            let badgeVariant = 'secondary';
            const reqDate = new Date(RequiredDate);
            const recDate = new Date(ReceivedDate);

            const reqDateSet = new Date(reqDate);
            reqDateSet.setHours(0, 0, 0, 0);

            const recDateSet = new Date(recDate);
            recDateSet.setHours(0, 0, 0, 0);

            if (reqDateSet < recDateSet) {
                badgeVariant = 'danger';

            } else {
                badgeVariant = 'success';
            }
            return (<Badge variant={badgeVariant}>{moment(ReceivedDate).format('L')}</Badge>);
        }
        else if (ReceivedDate) {
            return (<div>{moment(ReceivedDate).format('L')}</div>);
        }
    }


    render() {
        if (!this.state.notifications) {
            return (<PortletSpinner />);
        }


        const columns = [
            { resource: "GENERAL.FORMS.FIELD.ID", dataField: "referenceid", dataType: 'string', renderReferenceidLink: true },
            { resource: "GENERAL.FORMS.FIELD.SUBJECT", dataField: 'compensationnumber' },
            { resource: "GENERAL.FORMS.FIELD.CLAUSE", dataField: "compensationtype" },
            { resource: "GENERAL.FORMS.FIELD.NOTIFIED_DATE", dataField: "notificationdate", dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.NOTIFIED_BY", dataField: "createby" },
        ];
        if (this.props.mode === 'SUPPLIER' || this.props.mode === 'CLIENT' || this.props.mode === 'SHORT' || this.props.mode === 'CLAIMWN' || this.props.mode === 'CLIENTCWN') {
            columns.push(
                { resource: "CONTRACT.WF.COMPENSATION.FIELD.NOTICE_RESPONSE_REQUIRED", dataField: "noticeresponserequireddate", dataType: 'date' },
                { resource: "CONTRACT.WF.COMPENSATION.FIELD.NOTICE_RESPONSE_RECEIVED", dataField: "contractorreceiveddate", dataType: 'date', cellRender: (cellInfo) => (this.renderRequiredReceivedCell(cellInfo.data.noticeresponserequireddate, cellInfo.data.contractorreceiveddate)) },
                { resource: "GENERAL.FORMS.REPLING_PARTY", dataField: "replyingparty", lookup: { dataSource: this.props.contractTemplate.parties.options, valueExpr: 'id', displayExpr: 'display' }, visible: false }
            );
        }
        columns.push(
            { resource: "CONTRACT.WF.COMPENSATION.FIELD.QUOTE_REQUIRED", dataField: "requiredate", dataType: 'date' },
            { resource: "CONTRACT.WF.COMPENSATION.FIELD.QUOTE_RECEIVED", dataField: "officereceiveddate", dataType: 'date', cellRender: (cellInfo) => (this.renderRequiredReceivedCell(cellInfo.data.requiredate, cellInfo.data.officereceiveddate)) },
            { resource: "CONTRACT.WF.COMPENSATION.FIELD.QUOTE_RESPONSE_REQUIRED", dataField: "quoteresponserequireddate", dataType: 'date' },
            { resource: "CONTRACT.WF.COMPENSATION.FIELD.QUOTE_RESPONSE_RECEIVED", dataField: "quoteresponsereceiveddate", dataType: 'date', cellRender: (cellInfo) => (this.renderRequiredReceivedCell(cellInfo.data.quoteresponserequireddate, cellInfo.data.quoteresponsereceiveddate)) },
            { caption: this.responseRequiredDateCaption, dataField: "projectmanagersigneddate", dataType: 'date' },
            { caption: this.responseReceivedDateCaption, dataField: "projectmanagerresponsedate", dataType: 'date', cellRender: (cellInfo) => (this.renderRequiredReceivedCell(cellInfo.data.projectmanagersigneddate, cellInfo.data.projectmanagerresponsedate)) },
            { resource: "GENERAL.FORMS.FIELD.STATUS", dataField: "status", cellRender: this.renderStatusColumn, calculateCellValue: this.renderStatusDisplay },
            { resource: "CONTRACT.WF.COMPENSATION.FIELD.IMPLEMENTED", dataField: "assessmentdate", dataType: 'date', visible: false, },
            { resource: "GENERAL.FORMS.FIELD.AMOUNT", dataField: "assessmentamount", dataType: 'currency', visible: false, currencyCode: this.props.contract.currency },
            { resource: "GENERAL.FORMS.FIELD.DAYS", dataField: "alternativequotation", dataType: 'string', visible: false, },
            { resource: "GENERAL.WF.COMPENSATION.IMPLEMENTED_REFERENCE", dataField: "implementedreferenceid", visible: false, },
            { caption: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.INSTRUCTION_ID' }, { INSTRUCTION: this.props.instructionNotificationName }), dataField: "projectmanagementinstructionsequence", visible: false, dataType: 'string' },
            { resource: "PROJECT.FIELD.CODE", dataField: "projectcode", visible: false },
            { resource: "PROJECT.FIELD.NAME", dataField: "project", visible: false }
        );
        if (this.props.globalResources.CEN_DEEMED_ACCEPTANCE_STARTS !== 'OFF') {
            columns.push(
                { resource: "CONTRACT.WF.COMPENSATION.FIELD.CEN_DEEM_DATE", dataField: "cendeemedacceptancedate", dataType: 'date' }
            );
        }
        if (this.props.globalResources.QUOTE_DEEMED_ACCEPTANCE_STARTS !== 'OFF') {
            columns.push(
                { resource: "CONTRACT.WF.COMPENSATION.FIELD.QUOTE_DEEM_DATE", dataField: "quotedeemedacceptancedate", dataType: 'date' }
            );
        }
        if (this.props.mode === 'SUPPLIER' || this.props.mode === 'CLIENT' || this.props.mode === 'SHORT' || this.props.mode === 'CLAIMWN' || this.props.mode === 'CLIENTCWN') {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.REPLY_DRAFT_BY", dataField: "draftreplyby", visible: false }
            );
        }
        if (this.props.ewRelationEnabled) {
            columns.push(
                { caption: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.EW_ID' }, { EARLY_WARNING: this.props.ewNotificationName }), dataField: "timeextension", visible: false, dataType: 'string' },
            );
        }
        columns.push(
            { resource: "GENERAL.FORMS.FIELD.DETAILS", dataField: "description", isHtml: true },
        );
        if (this.props.fields.Assumption) {
            columns.push(
                { resource: "CONTRACT.WF.COMPENSATION.FIELD.ASSUMPTIONS", dataField: "assumption", isHtml: true }
            );
        }
        if (this.props.taskOrderOptionEnabled) {
            columns.push(
                { caption: this.props.taskOrderNotificationName, dataField: "taskorderreference", isHtml: true }
            );
        }
        if (this.props.projectOrderOptionEnabled) {
            columns.push(
                { caption: this.props.projectOrderNotificationName, dataField: "projectorderreference", isHtml: true }
            );
        }
        columns.push(
            { resource: "GENERAL.FORMS.FIELD.LINK", dataField: "url", calculateCellValue: this.calculateUrl, visible: false, showInColumnChooser: false },
            { resource: "GENERAL.FORMS.FIELD.CURRENCY", dataField: "currency", visible: false, calculateCellValue: this.formatCurrency },
        );
        if (this.props.workflowItem.assetFieldsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.ASSET_NAME", dataField: "assetname", visible: false },
                { resource: "GENERAL.FORMS.FIELD.ASSET_TYPE", dataField: "assettype", visible: false },
            );
        }
        if (this.notificationsTagsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.PORTFOLIO", dataField: "tags_portfolio", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.PortfolioFilter } },
                { resource: "GENERAL.FORMS.FIELD.PROGRAMME", dataField: "tags_programme", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.ProgrammeFilter } },
                { resource: "GENERAL.FORMS.FIELD.PROJECT", dataField: "tags_project", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.ProjectFilter } },
                { resource: "GENERAL.FORMS.FIELD.OTHER", dataField: "tags_other", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.OtherFilter } }
            )
        }
        if (this.notificationsRelsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.TAB_RELATIONS", dataField: "relatednotices", visible: false },
            )
        }
        columns.push(...CustomFieldHelper.MapCustomFieldsToDataGridColumns(this.customFields));

        return (
            <Portlet>
                <PortletBody>
                    <DataGridBase
                        version="2"
                        title={this.props.intl.formatMessage({ id: 'GENERAL.REGISTER.TITLE2' }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
                        createPath={this.props.workflowItem.userFilters.mainNotice.createAllowed ? this.props.workflowItem.paths.createPath : null}
                        workflowItem={this.props.workflowItem}
                        name={this.props.workflowItem.pageTitle}
                        dataSource={this.state.notifications}
                        keyExpr="compensationeventid"
                        selection={{ mode: 'single' }}
                        onSelectionChanged={this.onTableSelectionChanged}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={false}
                        columns={columns}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}



export default withListPageProps(CompensationListPage);
