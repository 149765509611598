import axios from '../shared/axios';
// import qs from 'querystring';
import store from "../store/store";
import * as contractDuck from "../store/ducks/contract.duck";

export function create(data) {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(`/api/Action/Create`, data);
            store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
            resolve(result);
        }
        catch (err) {
            reject(err)
        }
    });
}

export function update(data) {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.put(`/api/Action/Update`, data);
            store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
            resolve(result);
        }
        catch (err) {
            reject(err)
        }
    });
}

export function importActions(data) {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(`/api/Action/Import`, data);
            store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
            resolve(result);
        }
        catch (err) {
            reject(err)
        }
    });
}

export function complete(data) {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.put(`/api/Action/Complete`, data);
            store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
            resolve(result);
        }
        catch (err) {
            reject(err)
        }
    });
}
export function deleteAction(data) {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.delete(`/api/Action/Delete`, { data: data });
            store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
            resolve(result);
        }
        catch (err) {
            reject(err)
        }
    });
}

export function getRegisterList(contractid) {
    return axios.get(`/api/Action/RegisterList`, { params: { contractid } });
}

export function loadClearActionOptions(contractid, workflowid, recordid, actionTypes) {
    return axios.post(`/api/Action/LoadClearActionOptions`, actionTypes, { params: { contractid, workflowid, recordid } });
    // return axios.post(`/api/Tag/GetTagsForCategory`,categories, { params: { companyid } });

}