import React from 'react';
import { connect } from "react-redux";
import TagBox from 'devextreme-react/tag-box';
import { Alert } from "react-bootstrap";
import DataSource from 'devextreme/data/data_source';
import RelationModal from './RelationModal';
import RelationChain from './RelationChain';
import { Button } from 'devextreme-react/button';
import TreeView from 'devextreme-react/tree-view';
import { Badge } from "react-bootstrap";
// import DefaultWorkflows from '../../../../shared/config/DefaultWorkflows';
// import DefaultResources from '../../../../shared/config/DefaultResources';
import { Link } from "react-router-dom";
import { withSnackbar } from 'notistack';
import * as tagCrud from '../../../../crud/tag.crud';
import * as relationshipCrud from '../../../../crud/relationship.crud';
import Spinner from '../../../../partials/layout/Spinner';
import { FormattedMessage, injectIntl } from "react-intl";
import moment from 'moment';
import ErrorBoundary from "../../../..//partials/layout/ErrorBoundary";

const isViewRelationChainButtonEnabled = process.env.REACT_APP_VIEW_RELATION_CHAIN_BUTTON_ENABLED === "true";

class RelationPage extends React.Component {
    constructor(props) {
        super(props);
        this.treeViewRef = React.createRef();

        this.tags = new DataSource({
            store: this.props.communicationTags,
            key: 'tagid',
            group: 'category'
        });
        this.isRelationsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true';

        const isTagsEnabled = process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true';
        const isHideTagsIfNoTagFeatureEnabled = process.env.REACT_APP_HIDE_TAGS_IF_NO_TAG_FEATURE_ENABLED === 'true';

        this.shouldShowTags = isTagsEnabled && (!isHideTagsIfNoTagFeatureEnabled || props.showTags);

        this.availableWorkflowIds = props.workflowGroups.reduce((arr, wfg) => {
            wfg.workflows.forEach(wf => {
                if (wf.config.showInRelations) {
                    arr.push(wf.id);
                }
            });
            return arr;
        }, []);

        this.referenceType = props.workflowItem.config.referenceType;
        // this.defaultValuesTriggered = false;

        this.state = {
            isModalOpen: false,
            isChainModalOpen: false,
            treeItems: null,
            relations: null,
            tagValue: null,
            allTagValues: null,
            loading: false
        };
    }
    componentDidMount() {
        this.getContractTagAssociations();
        this.getRelations();
    }
    componentDidUpdate(prevprops, prevSatate) {
        if (this.props.workflowItem.id !== prevprops.workflowItem.id ||
            this.props.notificationid !== prevprops.notificationid ||
            this.props.itemversion !== prevprops.itemversion ||
            this.props.relations !== prevprops.relations) {


            this.getContractTagAssociations();
            this.getRelations();
        }

    }
    getContractTagAssociations = () => {
        tagCrud.getTagAssociation(this.props.notificationid, this.referenceType)
            .then(response => {
                const allTagValues = JSON.parse(response.data);
                const tagValue = allTagValues.map(x => x.tagid);
                this.setState({ tagValue, allTagValues });
            })
            .catch(err => {
                console.log(err);
            });
    }

    getRelations = () => {
        const relations = this.props.relations ? this.props.relations
            .filter(item => (this.availableWorkflowIds.includes(item.workflowid)))
            .map(item => ({
                referencetype: item.referencetype,
                disabled: item.creationsource === 2 ? false : true,
                id: item.displayreference,
                itemType: 'NOTIFICATION',
                notificationid: item.referenceid,
                path: item.path,
                relatednotificationid: this.props.notificationid,
                relatedwfgid: this.props.workflowGroupItem.id,
                relatedwfid: this.props.workflowItem.id,
                selected: true,
                wfgid: item.workflowgroupid,
                wfid: item.workflowid,
                createdate: item.createdate,
                creationsource: item.creationsource
            }
            )) : [];
        const treeItems = this.generateTreeIteems(relations);
        this.setState({ treeItems, relations });
    }
    // fetchRelations = () => {
    //     relationshipCrud.getRelationships(this.props.notificationid, this.referenceType, this.props.contractid)
    //         .then(response => {
    //             console.log("Direct fetch result", JSON.parse(response.data))
    //             // const relations = JSON.parse(response.data)
    //             //     .filter(item => (this.availableWorkflowIds.includes(item.workflowid)))
    //             //     .map(item => ({
    //             //         referencetype: item.referencetype,
    //             //         disabled: item.creationsource === 1 ? true : false,
    //             //         id: item.displayreference,
    //             //         itemType: 'NOTIFICATION',
    //             //         notificationid: item.referenceid,
    //             //         path: item.path,
    //             //         relatednotificationid: this.props.notificationid,
    //             //         relatedwfgid: this.props.workflowGroupItem.id,
    //             //         relatedwfid: this.props.workflowItem.id,
    //             //         selected: true,
    //             //         wfgid: item.workflowgroupid,
    //             //         wfid: item.workflowid,
    //             //         createdate: item.createdate,
    //             //         creationsource: item.creationsource
    //             //     }
    //             //     ));
    //             // const treeItems = this.generateTreeIteems(relations);
    //             // this.setState({ treeItems, relations });
    //         })
    //         .catch(err => {
    //             console.log(err);
    //         });
    // }
    createContractTagAssociation = async (tagId) => {
        const formData = {
            tagid: tagId,
            referenceid: this.props.notificationid,
            referencetype: this.referenceType,
            contractid: this.props.contractid
        };

        await tagCrud.createTagAssociation(formData)
            .then(() => {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_TAG_CREATED' }), { variant: 'success', });
                this.getContractTagAssociations();
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });
    }
    removeContractTagAssociation = async (tagAssociationId) => {
        const formData = {
            tagassociationid: tagAssociationId
        };

        await tagCrud.removeTagAssociation(formData)
            .then(() => {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_TAG_REMOVED' }), { variant: 'success', });
                this.getContractTagAssociations();
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });
    }
    noRelationMessage = () => {
        return (
            <div style={{ width: '60%', height: '100%', display: 'flex' }}>
                <div style={{ width: '100%', maxWidth: '900px' }}>
                    <Alert variant="secondary" >
                        <p>
                            <FormattedMessage id="GENERAL.FORMS.WARNING_NO_RELATIONS" />
                        </p>
                    </Alert>
                </div>
            </div>
        );
    }
    handleTagChange = (e) => {
        if (this.state.tagValue === e.value) { return; }
        this.setState({ loading: true });
        let createOrRemove = true;

        if (this.state.tagValue != null) {
            createOrRemove = e.value.filter(x => !this.state.tagValue.includes(x)).length > 0;
        }

        if (createOrRemove) {
            let tagId;

            if (this.state.tagValue == null) {
                tagId = e.value[0];
            } else {
                tagId = e.value.filter(x => !this.state.tagValue.includes(x))[0];
            }
            this.setState({ tagValue: e.value }, () => { this.createContractTagAssociation(tagId) });
        } else {
            const tagId = this.state.tagValue.filter(x => !e.value.includes(x))[0];
            const removedAssociationTagId = this.state.allTagValues.find(x => x.tagid === tagId).tagassociationid;
            this.setState({ tagValue: e.value }, () => { this.removeContractTagAssociation(removedAssociationTagId) });
        }

        this.setState({ loading: false });
    }
    onModalClose = async (relations) => {
        this.setState({ isModalOpen: false });
        try {
            if (relations) {
                const newRelations = {
                    referenceid: this.props.notificationid,
                    referencetype: this.referenceType,
                    path: this.props.workflowItem.paths.basePath + this.props.notificationid,
                    workflowgroupid: this.props.workflowGroupItem.id,
                    workflowid: this.props.workflowItem.id,
                    relationships: relations.map(item => ({
                        referenceid: item.notificationid,
                        referencetype: item.referenceType,
                        path: item.path,
                        workflowgroupid: item.wfgid,
                        workflowid: item.wfid,
                        creationsource: item.creationsource ? item.creationsource : 2 // 1 = Create related button, 2 = UserEditor, 3 = Form field relation
                    }))
                }
                await relationshipCrud.saveRelationships(this.props.contractid, newRelations);
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_RELATIONSHIP_SUCCESS' }), { variant: 'success', });
                const newRelationsSideA = relations.map(item => ({ ...item, relatedwfgid: this.props.workflowGroupItem.id, relatedwfid: this.props.workflowItem.id, relatednotificationid: this.props.notificationid }));
                const treeItems = this.generateTreeIteems(relations);
                this.setState({ relations: newRelationsSideA, treeItems: treeItems });
                this.props.onCountChange(newRelationsSideA.length);
                // this.defaultValuesTriggered = true;
            }
        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error' });
            console.log(err);
        }
    }
    onChainModalClose = async () => {
        this.setState({ isChainModalOpen: false });
    }
    generateTreeIteems = (relations) => {
        let items = this.props.workflowGroups.map(WfGroup => {
            const relatedWorkflows = WfGroup.workflows.filter(wf => (relations.findIndex(itm => itm.wfid === wf.id) > -1));
            return {
                id: WfGroup.id,
                title: WfGroup.title,
                itemType: 'WFG',
                expanded: true,
                items: relatedWorkflows.map(workflow => {
                    //to set all the items' disable field to false to make them selectable in treeview
                    let wfChildren = relations.filter(itm => itm.wfid === workflow.id).map(item => ({ ...item, disabled: false }));
                    return {
                        parentid: WfGroup.id,
                        id: workflow.id,
                        title: workflow.title,
                        itemType: 'WF',
                        model: workflow.config.currentModel,
                        type: workflow.config.currentModelType,
                        path: workflow.paths.basePath,
                        expanded: true,
                        items: wfChildren
                    };
                })
            };
        });
        items = items.filter(WfGroup => WfGroup.items.length > 0);
        return items;
    }
    renderTreeViewItem = (item) => {
        let style = {};
        let badge = null;
        let displayText = '';

        if (item.itemType === 'WFG') {
            displayText = item.title;
            if (item.items && item.items.length > 0) {
                const wfgChildrenCount = item.items.reduce((total, wfItem) => total + (wfItem.items ? wfItem.items.length : 0), 0);
                if (wfgChildrenCount > 0) {
                    badge = <Badge variant="secondary">{wfgChildrenCount}</Badge>;
                }
            }
        }
        else if (item.itemType === 'WF') {
            displayText = item.title;
            if (item.items && item.items.length) {
                badge = <Badge variant="secondary">{item.items.length}</Badge>;
            }
        }
        else if (item.itemType === 'NOTIFICATION') {
            displayText = item.id;
            style = { color: '#337ab7', fontWeight: 600 };
            return (
                <Link to={item.path}>
                    {item.createdate ? `${moment(item.createdate).format('DD/MM/YYYY')} : ${displayText}` : displayText}
                </Link>
            )
        }
        return (
            <div style={style} className="relationTreeRow">
                {`${displayText}`}<div style={{ marginLeft: 5 }}>{badge}</div>
            </div>
        );
    }

    render() {
        if (!this.state.allTagValues || !this.state.tagValue || !this.state.relations) {
            return (<Spinner />);
        }
        return (
            <ErrorBoundary>
                <div style={{ margin: '20px' }}>
                    {
                        this.shouldShowTags && (
                            <>
                                <div style={{ marginTop: 20 }}>
                                    <div className="dx-fieldset-header" style={{ borderBottom: '1px solid #ddd' }}>
                                        <span className="dx-widget dx-form-group-caption" style={{ marginBottom: 5 }}>
                                            <FormattedMessage id="GENERAL.FORMS.LABEL_TAGS" />
                                        </span>
                                    </div>
                                </div>
                                <TagBox
                                    style={{ marginBottom: "10px" }}
                                    onValueChanged={this.handleTagChange}
                                    placeholder={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_START_TAGS' })}
                                    searchEnabled
                                    // hideSelectedItems
                                    // showSelectionControls
                                    // applyValueMode="useButtons"
                                    value={this.state.tagValue}
                                    dataSource={this.tags}
                                    displayExpr="name"
                                    valueExpr="tagid"
                                    grouped={true}
                                    disabled={this.state.loading}
                                />
                            </>
                        )
                    }
                    {
                        this.isRelationsEnabled && (
                            <>
                                <div style={{ marginTop: 20 }}>
                                    <div className="dx-fieldset-header" style={{ borderBottom: '1px solid #ddd', display: 'flex', alignItems: 'center' }}>
                                        <span className="dx-widget dx-form-group-caption" style={{}}>
                                            <FormattedMessage id="GENERAL.FORMS.LABEL_RELATED_COMMUNICATIONS" />
                                        </span>
                                    </div>
                                </div>
                                <div style={{ height: '100%', display: 'flex', flexDirection: 'column', flexWrap: 'nowrap' }}>
                                    {/* <Button
                                        width='60%'
                                        icon="edit"
                                        style={{ marginBottom: 10 }}
                                        text={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_ADD_EDIT' })}
                                        type="default"
                                        stylingMode="outlined"
                                        onClick={() => { this.setState({ isModalOpen: true }) }}
                                    /> */}
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '60%' }}>
                                        <Button
                                            width={isViewRelationChainButtonEnabled ? '80%' : '100%'}
                                            icon="edit"
                                            style={{ marginBottom: 10 }}
                                            text={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_ADD_EDIT' })}
                                            type="default"
                                            stylingMode="outlined"
                                            onClick={() => { this.setState({ isModalOpen: true }) }}
                                        />
                                        {isViewRelationChainButtonEnabled && (<Button
                                            width='40%'
                                            icon="find"
                                            style={{ marginBottom: 10, marginLeft: '10px' }}
                                            text={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_VIEW_RELATION_CHAIN' })}
                                            type="default"
                                            stylingMode="outlined"
                                            onClick={() => { this.setState({ isChainModalOpen: true }) }}
                                        />)}
                                    </div>
                                    {this.state.treeItems.length === 0 &&
                                        this.noRelationMessage()
                                    }
                                    {
                                        this.state.treeItems.length > 0 && (

                                            <TreeView
                                                id="relation-treeview"
                                                style={{ border: '1px solid #ccc', borderRadius: '10px' }}
                                                ref={this.treeViewRef}
                                                activeStateEnabled={true}
                                                width='60%'
                                                height='60vh'
                                                items={this.state.treeItems}
                                                selectionMode="single"
                                                itemRender={this.renderTreeViewItem}
                                                keyExpr="id"
                                                parentIdExpr="parentid"
                                                displayExpr="title"
                                            />
                                        )
                                    }
                                </div>
                                <RelationModal
                                    relatedItems={this.state.relations}
                                    contractid={this.props.contractid}
                                    isOpen={this.state.isModalOpen}
                                    onClose={this.onModalClose}
                                    workflowGroups={this.props.workflowGroups}
                                    availableWorkflowIds={this.availableWorkflowIds}
                                    currentWFid={this.props.workflowItem.id}
                                    currentNotificationID={this.props.notificationid}
                                />
                                <RelationChain
                                    contractid={this.props.contractid}
                                    isOpen={this.state.isChainModalOpen}
                                    onClose={this.onChainModalClose}
                                    currentWF={this.props.workflowItem}
                                    currentNotificationID={this.props.notificationid}
                                    referenceType={this.referenceType}
                                    contractTemplate={this.props.contractTemplate}
                                />
                            </>
                        )
                    }
                </div>
            </ErrorBoundary>
        );
    }
}
const mapStateToProps = store => ({
    contractTags: store.preferences.contractTags,
    communicationTags: store.contract.communicationTags,
    contractUser: store.contract.user,
    workflowGroups: store.contract.template.workflowGroups,
    contractTemplate: store.contract.template
});

export default injectIntl(withSnackbar(connect(mapStateToProps)(RelationPage)));
