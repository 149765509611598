import React from 'react';
import BaseCreatePage from '../../_CustomComponents/BaseCreatePage';
import { connect } from "react-redux";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import { Alert } from "react-bootstrap";
import * as paymentCertificateCrud from '../../../../crud/paymentCertificate.crud';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import moment from 'moment';
import { FormattedMessage } from "react-intl";
import { withCreatePageProps } from '../../../../shared/hoc/withCreatePageProps';
import Common from '../../../../shared/common';
import { actionCodes, GetCurrencyCodes } from "../../../../shared/lookup";

class PaymentCreatePage extends BaseCreatePage {
    constructor(props) {
        super(props);
        this.currencyCodes = GetCurrencyCodes();
        const calculateSubTotal = (obj) => {
            const prc1 = obj.othercumulativeprice ? obj.othercumulativeprice : 0;
            const prc2 = obj.otheramountpaid ? obj.otheramountpaid : 0;
            return prc1 + prc2;
        }
        const calculateAmountDue = (obj) => {
            const prc1 = obj.subtotalpay ? obj.subtotalpay : 0;
            const prc2 = obj.subtotalretain ? obj.subtotalretain : 0;
            return prc1 - prc2;
        }
        const calculateSubTotalPreTax = (obj) => {
            const prc1 = obj.otheramountretain ? obj.otheramountretain : 0;
            const prc2 = props.contract.totalcertified;
            return prc1 - prc2;
        }
        const calculateSubTotalPreTaxV2 = (obj) => {
            const prc1 = obj.otheramountretain ? obj.otheramountretain : 0;
            const prc2 = obj.lastpaymentcertificateamount ? obj.lastpaymentcertificateamount : 0;
            return prc1 - prc2;
        }
        const calculateTotal = (obj) => {
            const prc1 = obj.othercertifiedpretax ? obj.othercertifiedpretax : 0;
            const prc2 = obj.othertaxamount ? obj.othertaxamount : 0;
            return prc1 + prc2;
        }
        const formatCurrency = () => {
            const cur = this.currencyCodes.find(currency => currency.code === this.props.contract.currency)?.name || this.props.contract.currency;
            return cur;
        }

        const bottomLabel = props.notificationType === 'Application' ? props.resources.PaymentAppliedPeriodLabel : props.resources.PaymentCertifiedPeriodLabel;
        const createFormConfig = {
            currencyCode: this.props.contract.currency,
            buttonResource: "GENERAL.FORMS.BUTTON_CREATE_DRAFT",
            fields: []
        };
        if (props.clauses) {
            createFormConfig.fields.push(
                { resource: "GENERAL.FIELD.APPLICATIONTYPE", dataField: 'paymenttype', editorType: 'dxSelectBox', editorOptions: { dataSource: this.props.clauses, valueExpr: 'field', displayExpr: 'display' } })
        }
        createFormConfig.fields.push(
            { label: props.resources.AssessmentDayLabel, dataField: 'assessmentdate', required: true, editorType: 'dxDateBox', validation: { type: 'range', min: new Date(1900, 0, 1), max: new Date(2079, 5, 6), warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_DATE" })} },
            { label: props.resources.SummaryDetailsLabel, dataField: 'summary', required: true, editorType: 'dxHtmlEditor' },
            { resource: "GENERAL.FORMS.CURRENCY", value: formatCurrency },
            { label: props.resources.CumulativePriceLabel, dataField: 'othercumulativeprice', required: true, editorType: 'dxNumberBox' },
            { label: props.resources.PlusAmountsSupplierLabel, dataField: 'otheramountpaid', required: true, editorType: 'dxNumberBox' },
            { resource: "CONTRACT.WF.PAYMENT.FIELD.SUB_TOTAL", dataField: 'subtotalpay', required: true, editorType: 'dxNumberBox', value: calculateSubTotal },
            { label: props.resources.LessAmountsSupplierLabel, dataField: 'subtotalretain', required: true, editorType: 'dxNumberBox', editorOptions: { min: 0 } },
            { resource: "CONTRACT.WF.PAYMENT.FIELD.AMOUNT_DUE", dataField: 'otheramountretain', required: true, editorType: 'dxNumberBox', value: calculateAmountDue },
            { label: props.resources.LessAmountsDueLabel, dataField: 'lastpaymentcertificateamount', required: true, editorType: 'dxNumberBox' },
        )
        if (props.contract.totalcertified != null) {
            const lastPaymentField = createFormConfig.fields.find(itm => itm.dataField === 'lastpaymentcertificateamount');
            lastPaymentField.value = () => props.contract.totalcertified;
        }

        if (props.taxEnabled) {
            createFormConfig.fields.push(
                { resource: "CONTRACT.WF.PAYMENT.FIELD.SUB_TOTAL", dataField: 'othercertifiedpretax', required: true, editorType: 'dxNumberBox', value: props.contract.totalcertified != null ? calculateSubTotalPreTax : calculateSubTotalPreTaxV2 },
                { label: props.resources.TaxSupplierLabel, dataField: 'othertaxamount', required: true, editorType: 'dxNumberBox', },
                { label: bottomLabel, dataField: 'othercertfiedamount', required: true, editorType: 'dxNumberBox', value: calculateTotal }
            )
        }
        else {
            createFormConfig.fields.push(
                { label: bottomLabel, dataField: 'othercertfiedamount', required: true, editorType: 'dxNumberBox', value: props.contract.totalcertified != null ? calculateSubTotalPreTax : calculateSubTotalPreTaxV2 }
            )
        }

        if (props.poNumberEnabled) {
            createFormConfig.fields.push(
                { resource: 'CONTRACT.FIELD.PO_NUMBER', dataField: 'purchaseordernumber', required: false, editorOptions: { defaultValue: props.contract.versionnumber } }
            )
        }



        this.state = {
            showAlert: true,
            createFormConfig: createFormConfig
        };
    }


    submitHandler = (payment) => {

        payment.companyid = this.props.contract.companyid;
        payment.contractid = this.props.contract.contractid;
        payment.projectid = this.props.contract.projectid;
        payment.assessmentdatedisplay = moment(payment.assessmentdate).format('DD/MM/YYYY');
        // payment.paymentcertificateissueddatedisplay = moment().format('DD/MM/YYYY');
        payment.type = this.props.notificationType;
        payment.status = 'Not Communicated';
        payment.createdate = new Date();
        payment.createdatedisplay = moment().format('DD/MM/YYYY');
        payment.currency = this.props.contract.currency;

        // payment.applicationtype = this.props.applicationTypes;
        // payment.paymentduedatedisplay = moment(addWeeks(new Date(payment.assessmentdate), 2)).format('DD/MM/YYYY');
        // payment.paymentreceivedatedisplay = moment(addDays(new Date(payment.assessmentdate), this.props.globalResources.PAYMENT_PERIOD)).format('DD/MM/YYYY');
        payment.actioncode = actionCodes.CREATE;
        payment.notifyingparty = this.props.workflowItem.notifyingParty;
        this.appendRelationship(payment);
        Common.PropertyConversionsBeforeNoticeSaveRequest(payment, this.props.customFields, this.props.contractUsers);
        paymentCertificateCrud.savePaymentCertificate(payment)
            .then(response => {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_SUCCESS' }), { variant: 'success', });
                try {
                    const createdID = Number(response.data);
                    this.props.navigate(this.props.workflowItem.paths.basePath + createdID);
                }
                catch {
                    this.props.navigate(this.props.workflowItem.paths.listPath);
                }
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', key: "PersistentFormWarning", persist: true });
            });

    }

    render() {

        const { assetid } = ((this.props.location.state || {}).itemCustomProps || {});
        return (
            <Portlet>
                <PortletHeader title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.CREATE_TITLE' }, { ITEM_NAME: this.props.workflowItem.pageTitle })} />
                <PortletBody style={{ maxWidth: 1000 }}>
                    {this.state.showAlert && (
                        <Alert variant="secondary" onClose={() => this.setState({ showAlert: false })} dismissible>
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_DRAFT" />
                            </p>
                        </Alert>
                    )}
                    {this.props.resources.WarningBanner && (
                        <Alert variant="warning" >
                            <p>
                                {this.props.resources.WarningBanner}
                            </p>
                        </Alert>
                    )}

                    <BiDynamicForm
                        onSubmitHandler={this.submitHandler}
                        config={this.state.createFormConfig}
                        customFields={(this.props.customFields || {}).COMMUNICATION}
                        assetFieldsEnabled={this.props.workflowItem.assetFieldsEnabled}
                        assetid={assetid}
                    />

                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    contractUsers: store.contract.users
});

export default withCreatePageProps(connect(mapStateToProps)(PaymentCreatePage));
