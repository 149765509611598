import React from 'react';
import BaseCreatePage from '../../_CustomComponents/BaseCreatePage';
import { connect } from "react-redux";
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import { Alert } from "react-bootstrap";
import * as taskOrderCrud from '../../../../crud/taskOrder.crud';
import * as requestForQuoteCrud from '../../../../crud/requestForQuote.crud';
import * as rfqQuotationCrud from '../../../../crud/rfqQuotation.crud';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import moment from 'moment';
import { FormattedMessage } from "react-intl";
import { withCreatePageProps } from '../../../../shared/hoc/withCreatePageProps';
import { paymentPeriods } from '../../../../shared/lookup';
import Common from '../../../../shared/common';
import { actionCodes } from "../../../../shared/lookup";
class TaskCreatePage extends BaseCreatePage {
    constructor(props) {
        super(props);
        const { subject } = ((props.location.state || {}).itemCustomProps || {});
        const { state } = props.location;
        this.createDefaultSubject = subject;
        this.createDefaultFields = state;

        this.paymentPeriods = paymentPeriods.map(p => (this.props.intl.formatMessage({ id: "GENERAL.FORMS." + p })));

        this.state = {
            showAlert: true,
            rfqs: null,
            rfq: null,
        };
    }

    calculateTotal = (obj) => {
        if (Object.keys(obj).length === 0 && this.state.rfq) {
            return this.state.rfq.totallistprice + this.state.rfq.totalassessed;
        } else {
            const prc1 = obj.pricelisttotal ? obj.pricelisttotal : 0;
            const prc2 = obj.compensationeventtotal ? obj.compensationeventtotal : 0;
            return prc1 + prc2;
        }
    }

    fetchRequestForQuotes = () => {
        const { companyid, projectid, contractid } = this.props.contract;
        const toStates = ['APMA', 'ARQ', 'APMRQ', 'AQ', 'QA', 'APMR'];
        requestForQuoteCrud.getContractRequestForQuotes(companyid, projectid, contractid)
            .then(response => {
                let comps = response.data.aggregatelist;
                comps = comps.filter(ntf => {
                    //PRIOR LOGIC FOR ASSESSMENT
                    // if (ntf.status === 'APMA') { return true; }
                    // if (ntf.status === 'AQ' || ntf.status === 'ARQ') {
                    //     if (new Date(ntf.requiredate) < new Date()) { return true; }
                    // }
                    // return false;
                    //NEW LOGIC FOR ASSESSMENT
                    if (toStates.includes(ntf.status)) {
                        return true;
                    }
                    else return false;
                })
                    .map(itm => {
                        const reference = itm.referenceid;
                        let reason = null;
                        if (itm.status === 'APMA') {
                            reason = 'Not Accepted';
                        }
                        else if (itm.status === 'AQ' || itm.status === 'ARQ') {
                            if (new Date(itm.quoterequireddate) < new Date()) {
                                reason = 'Expired';
                            }
                        }

                        return {
                            value: itm.requestforquoteid,
                            reference: reference,
                            reason: reason,
                            title: itm.subject,
                            display: `${reference}   {${itm.subject}}` + (reason ? ` [${this.props.intl.formatMessage({ id: "OPTIONS.STATUS." + reason })}]` : '')
                        }
                    });
                this.setState({ rfqs: comps });
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });
    }

    fetchRequestForQuote = (requestforquoteid) => {
        const { companyid, projectid, contractid } = this.props.contract;
        rfqQuotationCrud.getRfqQuotes(companyid, projectid, contractid, requestforquoteid)
            .then(response => {
                const rfqs = JSON.parse(response.data.aggregatelist);
                const rfq = rfqs ? rfqs.filter(r => r.status === 1) : null;
                this.setState({ rfq: rfq && rfq.length ? rfq[0] : rfq });
            })
            .catch(err => {
                //this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });
    }

    submitHandler = (task) => {
        task.companyid = this.props.contract.companyid;
        task.contractid = this.props.contract.contractid;
        task.projectid = this.props.contract.projectid;
        if (task.startdate) {
            task.startdatedisplay = moment(task.startdate).format('DD/MM/YYYY');
        }
        if (task.completiondate) {
            task.completiondatedisplay = moment(task.completiondate).format('DD/MM/YYYY');
        }

        task.status = 'Not Communicated';
        task.createdate = new Date();

        if (this.props.rfqRelationEnabled && task.requestforquoteid) {
            task.requestforquotereferenceid = this.state.rfqs.find(rfq => rfq.value === task.requestforquoteid).reference;
        }
        task.actioncode = actionCodes.CREATE;
        task.notifyingparty = this.props.workflowItem.notifyingParty;
        this.appendRelationship(task);
        Common.PropertyConversionsBeforeNoticeSaveRequest(task, this.props.customFields, this.props.contractUsers);
        taskOrderCrud.saveTaskOrder(task)
            .then(response => {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_SUCCESS' }), { variant: 'success', });
                try {
                    const createdID = Number(response.data);
                    this.props.navigate(this.props.workflowItem.paths.basePath + createdID);
                }
                catch {
                    this.props.navigate(this.props.workflowItem.paths.listPath);
                }
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', key: "PersistentFormWarning", persist: true });
            });
    }

    componentDidMount() {
        if (this.createDefaultFields && this.createDefaultFields.itemCustomProps && this.createDefaultFields.itemCustomProps.hasOwnProperty("assetid")) {//if it comes from Create Related Items
            this.fetchRequestForQuote(this.createDefaultFields.itemBaseProps.refid);
        } else if (this.createDefaultFields) {//if it comes from rfqQuotation's 'Send Reply & Create Associated Task Order' button
            this.setState({ rfq: this.createDefaultFields.itemCustomProps });
        }

        if (this.props.rfqRelationEnabled) {
            this.fetchRequestForQuotes();
        }
    }

    render() {
        if ((this.props.rfqRelationEnabled && !this.state.rfqs) || (this.createDefaultFields && this.createDefaultFields.itemBaseProps && !this.state.rfq)) {
            return (<PortletSpinner />);
        }

        const { assetid } = ((this.props.location.state || {}).itemCustomProps || {});
        const createFormConfig = {
            currencyCode: this.props.contract.currency,
            buttonResource: "GENERAL.FORMS.BUTTON_CREATE_DRAFT",
            fields: [
                { resource: 'GENERAL.FORMS.FIELD.SUBJECT', dataField: 'subject', editorOptions: { defaultValue: this.state.rfq ? this.state.rfq.subject : this.createDefaultSubject } },
                { note: this.props.resources.Note }
            ]
        };

        if (this.props.rfqRelationEnabled) {
            //AUTO-SELECT RFQ DROPDOWN (IF REDIRECTED FROM CREATE-RELATED BUTTON)
            let defaultRfqSelection = null;
            if (this.createDefaultFields?.itemBaseProps?.reftype === 'Rfq' && this.state.rfqs) {
                const rfqOption = this.state.rfqs.find(x => x.value == this.createDefaultFields.itemBaseProps.refid);
                if (rfqOption) {
                    defaultRfqSelection = rfqOption.value;
                }
            }

            const selectEditorOptions = { dataSource: this.state.rfqs, valueExpr: 'value', displayExpr: 'display', defaultValue: defaultRfqSelection };

            createFormConfig.fields.push({ label: this.props.rfqNotificationName, dataField: 'requestforquoteid', required: false, editorType: 'dxSelectBox', editorOptions: selectEditorOptions /*{ dataSource: this.state.rfqs, valueExpr: 'value', displayExpr: 'display', defaultValue: (this.state.rfq||{}).requestforquoteid }*/ });
        }

        if (this.props.fields.Description) {
            createFormConfig.fields.push({ label: this.props.fields.Description, dataField: 'description', editorType: 'dxHtmlEditor', editorOptions: { defaultValue: (this.state.rfq||{}).description } })
        }
        if (this.props.fields.Comments) {
            createFormConfig.fields.push({ label: this.props.fields.Comments, dataField: 'comments', editorType: 'dxHtmlEditor', editorOptions: { defaultValue: (this.state.rfq||{}).comments } })
        }
        if (this.props.fields.ItemsList) {
            createFormConfig.fields.push({ label: this.props.fields.ItemsList, dataField: 'pricelistdescription', editorOptions: { defaultValue: (this.state.rfq||{}).itemslist } })
        }
        if (this.props.fields.TotalListPrice) {
            createFormConfig.fields.push({ label: this.props.fields.TotalListPrice, dataField: 'pricelisttotal', editorType: 'dxNumberBox', editorOptions: { defaultValue: (this.state.rfq||{}).totallistprice } })
        }
        if (this.props.fields.CompensationDescription) {
            createFormConfig.fields.push({ label: this.props.fields.CompensationDescription, dataField: 'compensationeventdescripton', editorOptions: { defaultValue: (this.state.rfq||{}).requestforquotedescription } })
        }
        if (this.props.fields.TotalAssessed) {
            createFormConfig.fields.push({ label: this.props.fields.TotalAssessed, dataField: 'compensationeventtotal', editorType: 'dxNumberBox', editorOptions: { defaultValue: (this.state.rfq||{}).totalassessed } })
        }
        if (this.props.fields.TotalPrice) {
            createFormConfig.fields.push({ label: this.props.fields.TotalPrice, dataField: 'taskordertotal', editorType: 'dxNumberBox', value: this.calculateTotal, editorOptions: { defaultValue: (this.state.rfq||{}).totalprice } })
        }
        if (this.props.fields.StartDate) {
            createFormConfig.fields.push({ label: this.props.fields.StartDate, dataField: 'startdate', editorType: 'dxDateBox', editorOptions: { defaultValue: (this.state.rfq||{}).startdate } });
        }
        if (this.props.fields.CompletionDate) {
            const completionDateField = { label: this.props.fields.CompletionDate, dataField: 'completiondate', editorType: 'dxDateBox', editorOptions: { defaultValue: (this.state.rfq||{}).completiondate }, validation: { type: 'range', min: new Date(1900, 0, 1), max: new Date(2079, 5, 6), warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_DATE" })} }
            if (this.props.fields.StartDate) {
                completionDateField.validation = { type: 'custom', validationCallback: (obj, value) => (obj.startdate <= value), warning: 'Completion date can not be earlier than start date' }
            }
            createFormConfig.fields.push(completionDateField);
        }
        if (this.props.fields.DelayAmount) {
            createFormConfig.fields.push({ label: this.props.fields.DelayAmount, dataField: 'delaydamagestotal', editorType: 'dxNumberBox', editorOptions: { defaultValue: (this.state.rfq||{}).delayamount } })
        }
        if (this.props.fields.DelayPaymentPeriod) {
            createFormConfig.fields.push({ label: this.props.fields.DelayPaymentPeriod, dataField: 'delaydamagestype', editorType: 'dxSelectBox', editorOptions: { dataSource: this.paymentPeriods, defaultValue: (this.state.rfq||{}).delaypaymentperiod } })
        }

        return (
            <Portlet>
                <PortletHeader title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.CREATE_TITLE' }, { ITEM_NAME: this.props.workflowItem.pageTitle })} />
                <PortletBody style={{ maxWidth: 1000 }}>
                    {this.state.showAlert && (
                        <Alert variant="secondary" onClose={() => this.setState({ showAlert: false })} dismissible>
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_DRAFT" />
                            </p>
                        </Alert>
                    )}
                    {this.props.resources.WarningBanner && (
                        <Alert variant="warning" >
                            <p>
                                {this.props.resources.WarningBanner}
                            </p>
                        </Alert>
                    )}
                    <BiDynamicForm
                        onSubmitHandler={this.submitHandler}
                        config={createFormConfig}
                        customFields={(this.props.customFields || {}).COMMUNICATION}
                        assetFieldsEnabled={this.props.workflowItem.assetFieldsEnabled}
                        assetid={assetid}
                    />

                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    contractUsers: store.contract.users
});

export default withCreatePageProps(connect(mapStateToProps)(TaskCreatePage));
