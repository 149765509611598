/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import { connect } from "react-redux";
import { Nav, Tab } from "react-bootstrap";
import SideBarNavigationTab from './CustomControl/SideBarNavigationTab';
import TabNotify from './CustomControl/TabNotify';
const manuelActionsEnabled =  process.env.REACT_APP_ACTIONS_ENABLED === 'true';
class ContractSidePanelNotify extends React.Component {
    state = { selectedTab: "1" };
    setTab = _tabName => {
        this.setState({ selectedTab: _tabName });
    };

    // shouldComponentUpdate(nextProps, nextState) {
    //     //PANEL OPEN CLOSE
    //     if (this.props.isOpen !== nextProps.isOpen) {
    //         return true;
    //     }
    //     // DASHBOARD REFRESHED
    //     else if (this.props.contractDashboard !== nextProps.contractDashboard) {
    //         return true;
    //     }
    //     else {
    //         return false;
    //     }
    // }
    render() {
        if (!this.props.contractDashboard || !this.props.contractTemplate || !this.props.sidePanel) {
            return null;
        }
        const { contractDashboard, contractTemplate, sidePanel } = this.props;

        return (
            <Tab.Container defaultActiveKey={this.state.selectedTab}>
                <div style={{ marginTop: manuelActionsEnabled ? "2rem" : 0 }} className="kt-quick-panel__nav">
                    <Nav
                        onSelect={this.setTab}
                        className="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand kt-notification-item-padding-x"
                    >
                        <SideBarNavigationTab
                            title={contractTemplate.resources.SUPPLIER_NAME}
                            eventKey="1"
                            warningCount={contractDashboard.warningCountSupplier}
                            manualactions={sidePanel.supplierActions}
                            actions={sidePanel.supplierItems}
                            drafts={sidePanel.supplierDrafts}
                            replyDrafts={sidePanel.supplierReplyDrafts}
                        />
                        <SideBarNavigationTab
                            title={contractTemplate.resources.PM_NAME}
                            eventKey="2"
                            warningCount={contractDashboard.warningCountPm}
                            manualactions={sidePanel.pmActions}
                            actions={sidePanel.pmItems}
                            drafts={sidePanel.pmDrafts}
                            replyDrafts={sidePanel.pmReplyDrafts}
                        />
                        <SideBarNavigationTab
                            title={contractTemplate.resources.SUPERVISOR_NAME}
                            eventKey="3"
                            warningCount={contractDashboard.warningCountSupervisor}
                            manualactions={sidePanel.supervisorActions}
                            actions={sidePanel.supervisorItems}
                            drafts={sidePanel.supervisorDrafts}
                            replyDrafts={sidePanel.supervisorReplyDrafts}
                        />
                        <SideBarNavigationTab
                            title={contractTemplate.resources.CLIENT_NAME}
                            eventKey="4"
                            warningCount={contractDashboard.warningCountClient}
                            actions={sidePanel.clientItems}
                            manualactions={sidePanel.clientActions}
                            drafts={sidePanel.clientDrafts}
                            replyDrafts={sidePanel.clientReplyDrafts}
                        />
                    </Nav>
                </div>
                <div className="kt-quick-panel__content">
                    <Tab.Content>
                        <Tab.Pane eventKey="1">
                            <TabNotify
                                panelCloseBtnRef={this.props.panelCloseBtnRef}
                                contractid={this.props.contractid}
                                isOpen={this.props.isOpen}
                                dashboard={contractDashboard}
                                manualactions={sidePanel.supplierActions}
                                manualactionscount={sidePanel.supplierActionsCount}
                                actions={sidePanel.supplierItems}
                                drafts={sidePanel.supplierDrafts}
                                replyDrafts={sidePanel.supplierReplyDrafts}
                                party="SUPPLIER"
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="2">
                            <TabNotify
                                panelCloseBtnRef={this.props.panelCloseBtnRef}
                                contractid={this.props.contractid}
                                isOpen={this.props.isOpen}
                                dashboard={contractDashboard}
                                manualactions={sidePanel.pmActions}
                                manualactionscount={sidePanel.pmActionsCount}
                                actions={sidePanel.pmItems}
                                drafts={sidePanel.pmDrafts}
                                replyDrafts={sidePanel.pmReplyDrafts}
                                party="PM"
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="3">
                            <TabNotify
                                panelCloseBtnRef={this.props.panelCloseBtnRef}
                                contractid={this.props.contractid}
                                isOpen={this.props.isOpen}
                                dashboard={contractDashboard}
                                manualactions={sidePanel.supervisorActions}
                                manualactionscount={sidePanel.supervisorActionsCount}
                                actions={sidePanel.supervisorItems}
                                drafts={sidePanel.supervisorDrafts}
                                replyDrafts={sidePanel.supervisorReplyDrafts}
                                party="SUPERVISOR"
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="4">
                            <TabNotify
                                panelCloseBtnRef={this.props.panelCloseBtnRef}
                                contractid={this.props.contractid}
                                isOpen={this.props.isOpen}
                                dashboard={contractDashboard}
                                manualactions={sidePanel.clientActions}
                                manualactionscount={sidePanel.clientActionsCount}
                                actions={sidePanel.clientItems}
                                drafts={sidePanel.clientDrafts}
                                replyDrafts={sidePanel.clientReplyDrafts}
                                party="CLIENT"
                            />
                        </Tab.Pane>
                    </Tab.Content>
                </div>
            </Tab.Container>
        );
    }
}

const mapStateToProps = store => ({
    contractDashboard: store.contract.dashboard,
    contractTemplate: store.contract.template,
    sidePanel: store.contract.sidePanel,
});

export default connect(mapStateToProps)(ContractSidePanelNotify)
