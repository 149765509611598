import React from 'react';
import BaseDetailPage from '../../_CustomComponents/BaseDetailPage';
import ReactToPrint from "react-to-print";
import { Navigate } from "react-router-dom";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import * as forecastCrud from '../../../../crud/forecast.crud';
import * as fileCrud from '../../../../crud/file.crud';
import axios from 'axios';
import ActionModal from '../../_CustomComponents/ActionModal';
import ForecastDocument from './Document';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import { ValidationSummary, ValidationGroup } from 'devextreme-react';
import moment from 'moment';
import Relations from '../../_CustomComponents/Relation/Relations';
import PageToolbar from '../../_CustomComponents/PageToolbar';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import DxDialogModal from '../../../../partials/content/dxControls/dxDialogModal';
import Common from '../../../../shared/common';
import { withDetailPageProps } from '../../../../shared/hoc/withDetailPageProps';
import NavigationBlocker from '../../../../partials/Tools/NavigationBlocker';
import EmailNotifications from '../../_CustomComponents/EmailNotifications';
const initialState = {
    notification: null,
    files: null,
    showModal: false,
    saving: false,
    isChanged: false,
    relationCount: 0,
    modalTitle: '',
    modalText: '',
    modalMode: '',
    minValueAccepted: false,
    minValueDecided: false,
    relations: null,
    selectedClause: null
};

class ForecastDetail extends BaseDetailPage {
    constructor(props) {
        super(props);
        this.isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'DEV';
        this.isRelationsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true' || process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true';
        this.printIssueDivRef = React.createRef();
        this.validatorRef = React.createRef();
        this.validatorName = 'DefectValidator';
        this.state = {
            ...initialState,
            showModalMinValue: false,
            modalTitleMinValue: 'Cost Forecast Value',
            modalTextMinValue: `The value entered is less than ${Common.FormatCurrencyNum(1, this.props.contract.currency)}. Do you want to continue?`,
            modalModeMinValue: 'FORECAST',
        };

    }
    componentDidMount() {
        this.fetchForecast();
        this.fetchFiles();
    }



    componentDidUpdate(prevprops, prevSatate) {
        if (this.props.params.notificationid !== prevprops.params.notificationid) {
            this.setState({ ...initialState }, () => {
                this.fetchForecast();
                this.fetchFiles();
            });
        }
    }
    getMailDescription = () => {
        return this.state.notification.subject;
    }
    getSelectedTypeClauseDisplay = () => {
        return "";
    }
    fetchForecast = () => {
        forecastCrud.getForecast(this.props.params.notificationid)
            .then(response => {
                const forecast = response.data;
                if (forecast.contractid !== this.props.contract.contractid ||
                    forecast.type !== this.props.notificationType) {
                    throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.INVALID_URL' }));
                }
                if (forecast.notificationdate) {
                    forecast.notificationdate = new Date(forecast.notificationdate);
                }
                if (forecast.forecastdate) {
                    forecast.forecastdate = new Date(forecast.forecastdate);
                }

                let selectedClause = null;
                if (forecast.replycomment && this.props.clauses) {
                    selectedClause = this.props.clauses.find(c => c.display === forecast.replycomment);
                    if (selectedClause) {
                    }
                    else {
                        if (forecast.showtasknotification !== 1) {
                            forecast.replycomment = null;
                            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_CLAUSE_MISSING' }), { variant: 'warning', });
                        }
                    }
                }

                this.setState({
                    notification: forecast,
                    saving: false,
                    isChanged: false,
                    relations: forecast.relations,
                    relationCount: Common.GetRelationCount(forecast, this.props.accessibleWfIds)
                }, () => { this.props.setReference(this.state.notification.referenceid); });
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                }
            });
    }

    fetchFiles = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.notificationid, this.props.documentType)
            const filesList = JSON.parse(data.aggregatelist)
            this.setState({ files: filesList });
            return filesList;
        } catch (err) {
            if (!axios.isCancel(err)) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
        }
    }
    printHandler = () => {
        this.printIssueRef.handlePrint();
    }

    validateForm = () => {
        const validationResult = this.validatorRef.current.instance.validate();
        if (!validationResult.isValid) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_MISSING_FIELDS' }), { variant: 'warning', });
        }
        return validationResult.isValid && !this.state.saving;
    }

    calculateVariance = (obj) => {
        const prc1 = obj.target ? obj.target : 0;
        const prc2 = obj.newcarbonforecast ? obj.newcarbonforecast : 0;
        return prc2 - prc1;
    }

    renderForm = () => {
        const minAmount = !this.state.minValueAccepted && this.state.minValueDecided ? 1 : 0;

        const editorFormConfig = {
            fields: [
                { resource: 'GENERAL.FORMS.FIELD.SUBJECT', dataField: 'subject' },
                { resource: 'GENERAL.FORMS.FIELD.FORECASTDATE', dataField: 'forecastdate', editorType: 'dxDateBox', required: true, validation: { type: 'range', min: new Date(1900, 0, 1), max: new Date(2079, 5, 6), warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_DATE" })} },
                //{ resource: "GENERAL.FORMS.FIELD.TYPE", dataField: 'replycomment', editorType: 'dxSelectBox', editorOptions: { dataSource: this.props.clauses, valueExpr: 'display', displayExpr: 'display' } },
                { resource: 'GENERAL.FORMS.FIELD.DETAILS', dataField: 'description', editorType: 'dxHtmlEditor' },
            ]
        };

        if (this.props.typeFieldEnabled) {
            editorFormConfig.fields.splice(2, 0, ({ resource: "GENERAL.FORMS.FIELD.TYPE", dataField: 'replycomment', editorType: 'dxSelectBox', editorOptions: { dataSource: this.props.clauses, valueExpr: 'display', displayExpr: 'display' } }));
        }

        if (this.props.notificationType === 'CostForecast') {
            editorFormConfig.fields.push({ resource: "GENERAL.FORMS.FIELD.COSTFORECAST", dataField: 'newcostforecast', editorType: 'dxNumberBox', validation: { type: 'range', min: minAmount, warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.MINIMUM_VALUE" }) + " " + Common.FormatCurrencyNum(minAmount, this.props.contract.currency) } });
        }

        if (this.props.notificationType === 'CarbonForecast' && this.props.configurableCarbonFields) {
            if (this.props.configurableCarbonFields.Target) {
                editorFormConfig.fields.push({ label: this.props.configurableCarbonFields.Target, dataField: 'target', editorType: 'dxNumberBox', editorOptions: { format: '#,##0.00 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_TONNES_CO2' }), placeholder: '0 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_TONNES_CO2' }) } });
            }
            if (this.props.configurableCarbonFields.Forecast) {
                editorFormConfig.fields.push({ label: this.props.configurableCarbonFields.Forecast, dataField: 'newcarbonforecast', editorType: 'dxNumberBox', editorOptions: { min: 0, format: '#,##0.00 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_TONNES_CO2' }), placeholder: '0 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_TONNES_CO2' }) } });
            }
            if (this.props.configurableCarbonFields.Actual) {
                editorFormConfig.fields.push({ label: this.props.configurableCarbonFields.Actual, dataField: 'actual', editorType: 'dxNumberBox', editorOptions: { format: '#,##0.00 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_TONNES_CO2' }), placeholder: '0 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_TONNES_CO2' }) } });
            }
            if (this.props.configurableCarbonFields.Variance) {
                editorFormConfig.fields.push({ label: this.props.configurableCarbonFields.Variance, dataField: 'variance', editorType: 'dxNumberBox', value: this.calculateVariance, editorOptions: { format: '#,##0.00 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_TONNES_CO2' }), placeholder: '0 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_TONNES_CO2' }) } });
            }
        }

        return (
            <>
                <BiDynamicForm
                    item={this.state.notification}
                    config={editorFormConfig}
                    valueChangeHandler={this.fieldChanged}
                    customFields={(this.props.customFields || {}).COMMUNICATION}
                />
            </>
        );
    }

    showModal = (mode, postActionNavigateObj) => {
        let selectedClause = null;
        if (this.state.notification.replycomment && this.props.clauses) {
            selectedClause = this.props.clauses.find(c => c.display === this.state.notification.replycomment);
        }
        const forecast = { ...this.state.notification };

        if (mode === 'COMMUNICATE') {
            if (this.validateForm()) {
                if (this.props.notificationType === 'CostForecast' && !this.state.minValueDecided && forecast.newcostforecast < 1) {
                    this.setState({ showModalMinValue: true });
                } else {
                    this.setState({
                        showModal: true,
                        modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_COMMUNICATE_ITEM' }, { ITEM_NAME: this.props.title }),
                        modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_COMMUNICATING' }, { ITEM_NAME: this.props.title, PARTY_NAME: this.props.communicateToUser }),
                        modalMode: mode,
                        postActionUrl: postActionNavigateObj,
                        selectedClause
                    });
                }
            }
        }
        else if (mode === 'SAVE_DRAFT') {
            if (this.validateForm()) {
                if (this.props.notificationType === 'CostForecast' && !this.state.minValueDecided && forecast.newcostforecast < 1) {
                    this.setState({ showModalMinValue: true });
                } else {
                    this.setState({
                        showModal: true,
                        modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_DRAFT_NOTIFY' }),
                        modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
                        modalMode: mode,
                    });
                }
            }
        }
        else if (mode === 'DELETE_DRAFT') {
            this.setState({
                showModal: true,
                modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_DELETE_DRAFT_NOTIFY' }),
                modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_DELETING' }),
                modalMode: mode,
            });
        }
    }
    onModalResponse = (response, params) => {
        this.setState({ showModal: false, saving: response === 'OK' }, async () => {
            try {
                if (response === 'OK') {


                    if (this.state.modalMode === 'COMMUNICATE') {
                        const forecast = { ...this.state.notification };
                        forecast.createactions = params?.createactions;
                        forecast.completeactions = params?.completeactions;
                        forecast.createbyid = this.props.contractUser.accountid;
                        // forecast.notificationdatedisplay = moment().format('DD/MM/YYYY');
                        forecast.status = 'Communicated';
                        forecast.showtasknotification = 1;
                        const docBodyConfig = this.documentBodyConfig(forecast, this.props.resources);
                        const docConfig = await this.documentConfig(forecast, docBodyConfig, this.props.contractUser.name, false, null, null, this.props.workflowItem.form_NotifyCompanyName);
                        forecast.pdfjsoncontent = JSON.stringify(docConfig);

                        this.saveForecast(forecast, this.state.modalMode);


                    }
                    else if (this.state.modalMode === 'SAVE_DRAFT') {
                        const forecast = { ...this.state.notification };
                        forecast.createbyid = this.props.contractUser.accountid;
                        // forecast.notificationdatedisplay = moment().format('DD/MM/YYYY');
                        this.saveForecast(forecast, this.state.modalMode, params ? params.userid : null);

                    }
                    else if (this.state.modalMode === 'DELETE_DRAFT') {
                        const forecast = { ...this.state.notification };
                        const forecastid = forecast.forecastid
                        this.deleteForecastDraft(forecastid);

                    }


                }
            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                this.setState({ saving: false });
            }

        });
    }

    onModalResponseMinValue = (response, params) => {
        this.setState({ showModalMinValue: false, minValueDecided: true }, () => {
            if (response === 'OK') {
                this.setState({ minValueAccepted: true });
            }
        });
    }
    documentBodyConfig = (notification, resources) => {
        const config = [
            { resource: 'GENERAL.FORMS.FIELD.SUBJECT', value: notification.subject },
            { resource: 'GENERAL.FORMS.FIELD.DETAILS', value: notification.description, valueType: 'HTML', align: 'vertical' },
            //{ resource: 'GENERAL.FORMS.FIELD.TYPE', value: notification.replycomment },
        ];

        if (this.props.typeFieldEnabled) {
            config.splice(1, 0, ({ resource: 'GENERAL.FORMS.FIELD.TYPE', value: notification.replycomment }));

        }

        if (this.props.notificationType === 'CostForecast') {
            config.push(
                { resource: "GENERAL.FORMS.FIELD.COSTFORECAST", value: Common.FormatCurrencyNum(notification.newcostforecast, (this.props.contract || {}).currency), valueType: 'CURRENCY', currencyCode: (this.props.contract || {}).currency }
            );
        }

        config.push(
            { resource: "GENERAL.FORMS.FIELD.FORECASTDATE", value: notification.forecastdate },
        );

        if (this.props.notificationType === 'CarbonForecast' && this.props.configurableCarbonFields) {
            if (this.props.configurableCarbonFields.Target) {
                config.push({ label: this.props.configurableCarbonFields.Target, value: `${Common.FormatNum(notification.target)} ${this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_TONNES_CO2' })}`, editorType: 'dxNumberBox' })
            }
            if (this.props.configurableCarbonFields.Forecast) {
                config.push({ label: this.props.configurableCarbonFields.Forecast, value: `${Common.FormatNum(notification.newcarbonforecast)} ${this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_TONNES_CO2' })}` })
            }
            if (this.props.configurableCarbonFields.Actual) {
                config.push({ label: this.props.configurableCarbonFields.Actual, value: `${Common.FormatNum(notification.actual)} ${this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_TONNES_CO2' })}` })
            }
            if (this.props.configurableCarbonFields.Variance) {
                config.push({ label: this.props.configurableCarbonFields.Variance, value: `${Common.FormatNum(notification.variance)} ${this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_TONNES_CO2' })}` })
            }
        }

        return config;
    }

    deleteForecastDraft = async (forecastid) => {

        try {
            await forecastCrud.deleteForecastDraft(forecastid);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_DELETED' }), { variant: 'success' });
            this.setState({ saving: false, navigateto: this.props.workflowItem.paths.listPath });

        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            this.setState({ saving: false });
        }
    }
    saveForecast = async (forecast, action, notifyuserid) => {
        try {
            // if (!forecast.notificationdatedisplay && forecast.notificationdate) {
            //     forecast.notificationdatedisplay = moment(new Date(forecast.notificationdate)).format('DD/MM/YYYY');
            // }
            // if (!issue.createbyid && issue.createby) {
            //     issue.createbyid = issue.createby;
            // }
            if (!forecast.forecastdatedisplay && forecast.forecastdate) {
                forecast.forecastdatedisplay = moment(forecast.forecastdate).format('DD/MM/YYYY');
            }
            forecast.actioncode = action;
            Common.PropertyConversionsBeforeNoticeSaveRequest(forecast, this.props.customFields, this.props.contractUsers);
            await forecastCrud.saveForecast(forecast);
            forecast.notificationtypeforemailtemplate = forecast.type;
            forecast.pdfjsoncontent = forecast.pdfjsoncontent?.replace("$NOW", moment().format('DD/MM/YYYY'));
            this.notify(action, forecast, notifyuserid);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), { variant: 'success', });
            if (this.state.postActionUrl) {
                this.setState({ isChanged: false, saving: false, navigateto: this.state.postActionUrl.pathname });
            }
            else {
                this.fetchForecast();
            }

        } catch (err) {
            this.setState({ saving: false });
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
    }
    fieldChanged = (field, value, isDxFormValid, additionalData) => {
        //SILENT STATE CHANGE
        //WE DONT WANT COMPONENT RERENDER WİTH THIS
        //this.setState({ notification: { ...this.state.notification, [field]: value } });
        if (field) {
            if (field === "assetid") {
                this.setState({
                    notification: { ...this.state.notification, [field]: value, asset: { ...additionalData } },
                    isChanged: true
                });
            }
            else {
                this.state.notification[field] = value;
                if (!this.state.isChanged) {
                    this.setState({ isChanged: true });
                }
            }
        }
    }

    renderToolbar = (documentIsFinal) => {
        const saveDisabled = (this.state.saving || this.state.showModal);
        return (
            <PageToolbar
                key={this.state.notification.version}
                documentIsFinal={documentIsFinal}
                saveDisabled={saveDisabled}
                actions={{
                    showModal: this.showModal,
                    onPrint: this.printHandler
                }}
                workflowItem={this.props.workflowItem}
                match={this.props.match}
                customFieldValues={this.state.notification.customfieldvalues}
                referenceid={this.state.notification.referenceid}
                contract={this.props.contract}
                relationParams={{
                    subject: this.getMailDescription(),
                    assetid: this.state.notification.assetid
                }}
                notification={this.state.notification}
                selectedClausetypeDisplay={this.getSelectedTypeClauseDisplay()}
            />
        );
    }
    tabDocument = () => {
        return (
            <ForecastDocument
                {...this.props}
                printDivRef={this.printIssueDivRef}
                fieldChanged={this.fieldChanged}
                notification={this.state.notification}
                files={this.state.files}

                notificationid={this.props.params.notificationid}
                typeFieldEnabled={this.props.typeFieldEnabled}
                form={this.renderForm()}
            />
        );
    }
    onUpdateRisk = async (forecast, setSubmitting) => {
        if (forecast.replycomment && forecast.replystatus) {
            await this.saveIssue(forecast);
        }
        else {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'CONTRACT.WF.DEFECTS.WARNING.ACT_FIELDS_MISSING' }), { variant: "warning" });
        }
        setSubmitting(false);
    }
    tabRelations = () => {
        return (
            <Relations
                contractid={this.props.contract.contractid}
                workflowItem={this.props.workflowItem}
                workflowGroupItem={this.props.workflowGroupItem}
                notificationid={Number(this.props.params.notificationid)}
                relations={this.state.notification.relations}
                itemversion={this.state.notification.version}
                subject={this.state.notification.replyactiondetail}
                onCountChange={(count) => this.setState({ relationCount: count })}
                showTags={this.props.contract.specifictags?.length > 0}
            />
        );
    }
    tabEmailNotification = () => {

        return (
            <EmailNotifications
                {...this.props}
                notificationDate={this.state.notification.notificationdate}
            />
        );

    };
    render() {
        if (!this.state.notification || !this.state.files) {
            return (<PortletSpinner />);
        }

        const documentIsFinal = this.state.notification.showtasknotification === 1;

        return (
            <Portlet>
                <ReactToPrint
                    ref={el => (this.printIssueRef = el)}
                    trigger={() => (<div style={{ display: 'none' }} ></div>)}
                    content={() => this.printIssueDivRef.current}
                />
                <PortletHeader
                    title={`${this.props.title}${documentIsFinal ? '' : ' Draft'}`}
                    toolbar={this.renderToolbar(documentIsFinal)}
                />
                <NavigationBlocker
                    when={(this.state.isChanged || this.state.saving)}
                    message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}
                />
                {this.state.navigateto &&
                    (
                        <Navigate replace to={this.state.navigateto} state={(this.state.postActionUrl || {}).state} />
                    )
                }
                <PortletBody>
                    <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
                        <div>
                            <ValidationSummary elementAttr={{ align: "right", paddingBottom: 50 }} id={this.validatorName}></ValidationSummary>
                        </div>
                        <TabPanel
                            width="100%"
                            deferRendering={false}
                            animationEnabled={true}
                            focusStateEnabled={true}
                            hoverStateEnabled={true}
                            swipeEnabled={false}>
                            <Item icon="file" title={this.props.title} render={this.tabDocument} />
                            {
                                this.isRelationsEnabled && (
                                    <Item icon="share" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_RELATIONS' })} render={this.tabRelations} badge={this.state.relationCount > 0 ? this.state.relationCount : null} />
                                )
                            }
                            <Item
                                icon="bulletlist"
                                title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_EMAIL_NOTIFICATIONS' })}
                                render={documentIsFinal ? this.tabEmailNotification : null} visible={documentIsFinal}
                            />
                        </TabPanel>
                    </ValidationGroup>
                </PortletBody>
                <ActionModal
                    visible={this.state.showModal}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    mode={this.state.modalMode}
                    onDialogResult={this.onModalResponse}
                    workflowItem={this.props.workflowItem}
                    selectedClause={this.state.selectedClause}
                />
                <DxDialogModal
                    visible={this.state.showModalMinValue}
                    title={this.state.modalTitleMinValue}
                    text={this.state.modalTextMinValue}
                    mode={this.state.modalModeMinValue}
                    onDialogResult={this.onModalResponseMinValue}
                />
            </Portlet>
        );
    }
}


export default withDetailPageProps(ForecastDetail);
