import React from "react";
import { connect } from "react-redux";
import { Portlet, PortletBody, } from "../../partials/content/Portlet";
import ContractParties from "../../widgets/ContractParties";
import ContractPayments from "../../widgets/ContractPayments";
import ContractActions from "../../widgets/ContractActions";
import ContractTimeline from "../../widgets/ContractTimeline";
import ContractTimeline2 from "../../widgets/ContractTimeline2";
import ContractPrice from "../../widgets/ContractPrice";
import DonutCharts from "../../widgets/DonutCharts";
import ConrtractCalendar from "../../widgets/ConrtractCalendar";
import PortletSpinner from '../../partials/layout/PortletSpinner';

class Dashboard extends React.Component {
  constructor(props) {
    super(props); 
    
    // this.isForecastWidgetEnabled = process.env.REACT_APP_FORECAST_ENABLED === 'true' &&
    //   props.contractTemplate.workflowGroups.findIndex(wfg => wfg.id === 116) !== -1;

    // this.isTaskOrderWidgetEnabled = props.contractTemplate.workflowGroups.findIndex(wfg => wfg.id === 114) !== -1;
    // this.taskOrderName = this.isTaskOrderWidgetEnabled ? props.contractTemplate.workflowGroups.find(wfg => wfg.id === 114).title : null;

    // this.isCEWidgetEnabled = props.contractTemplate.workflowGroups.findIndex(wfg => wfg.id === 103) !== -1;
    // this.ceName = this.isCEWidgetEnabled ? props.contractTemplate.workflowGroups.find(wfg => wfg.id === 103).title : null;

    // this.isSupProposalWidgetEnabled = props.contractTemplate.workflowGroups.findIndex(wfg => wfg.id === 107) !== -1;
    // this.supProposalName = this.isSupProposalWidgetEnabled ? props.contractTemplate.workflowGroups.find(wfg => wfg.id === 107).title : null;

    // this.isCEPriceAssessmentWidgetEnabled = this.isCEWidgetEnabled ? props.contractTemplate.workflowGroups.find(wfg => wfg.id === 103).workflows.findIndex(dwf => dwf.id === 51) !== -1 : null;
    // this.cePriceAssessmentName = this.isCEPriceAssessmentWidgetEnabled ? props.contractTemplate.workflowGroups.find(wfg => wfg.id === 103).workflows.find(dwf => dwf.id === 51).title : null;

    // this.isAssessmentWidgetEnabled = this.isCEWidgetEnabled ? props.contractTemplate.workflowGroups.find(wfg => wfg.id === 103).workflows.findIndex(dwf => dwf.id === 8) !== -1 : null;
    // this.assessmentName = this.isAssessmentWidgetEnabled ? props.contractTemplate.workflowGroups.find(wfg => wfg.id === 103).workflows.find(dwf => dwf.id === 8).title : null;

    // this.isProjectOrderWidgetEnabled = props.contractTemplate.workflowGroups.findIndex(wfg => wfg.id === 118) !== -1;
    // this.projectOrderName = this.isProjectOrderWidgetEnabled ? props.contractTemplate.workflowGroups.find(wfg => wfg.id === 118).title : null;
  }

  render() {
    if (!this.props.contractDashboard || !this.props.contractTemplate) {
      return (<PortletSpinner />);
    }
    const { contract } = this.props;
   
    return (
      <>
      <Portlet style={{ marginBottom: 20 }}> 
        <PortletBody >
        <p
          style={{
            fontSize: "1.1rem",
            margin: 0,
            fontWeight: 500,
            color: "#3F96C4"
          }}
        >
          {contract.name}
      </p>
        </PortletBody>
      </Portlet>
      
        <Portlet style={{ marginBottom: 20 }}>
          
          <PortletBody fit={true}>
            <div className="row row-no-padding row-col-separator-md">
              <div className="col-md-4">
                <ContractParties userProfile={this.props.userProfile} />
              </div>
              <div className="col-md-4">
                <ContractPrice
                  contract={this.props.contract}
                  contractDashboard={this.props.contractDashboard}
                  contractTemplate={this.props.contractTemplate}
                  dashboardCurrencyCode={this.props.dashboardCurrencyCode}
                  currencyOptions={this.props.currencyOptions}
                />
              </div>
              <div className="col-md-4">
                <ContractTimeline
                  contractTemplate={this.props.contractTemplate}
                  contractid={this.props.contract.contractid}
                  startdate={this.props.contract.startdate}
                  enddate={this.props.contract.completedate}
                  currentenddate={this.props.contract.completiondate}
                  actualcompletiondate={this.props.contract.actualcompletiondate}
                  defectsdate={this.props.contract.defectsdate}
                  iscontractsectional={this.props.contract.section}
                  globalResources={this.props.contractTemplate.resources}
                />
              </div>
            </div>
          </PortletBody>
        </Portlet>
        <Portlet style={{ marginBottom: 20 }}>
          <PortletBody fit={true}>
            <div className="row row-no-padding row-col-separator-md">
              <div className="col-md-4">
                <ContractTimeline2
                  contractid={this.props.contract.contractid}
                  supplierName={this.props.contractTemplate.resources.SUPPLIER_NAME}
                  expectedcompletiondate={this.props.contract.expectedcompletiondate}
                  dashboard={this.props.contractDashboard}
                />
              </div>
              <div className="col-md-4">
                <ContractPayments
                  contract={this.props.contract}
                  globalResources={this.props.contractTemplate.resources}
                  dashboardCurrencyCode={this.props.dashboardCurrencyCode}
                />
              </div>
              <div className="col-md-4">
                <ContractActions
                  contractid={this.props.contract.contractid}
                />
              </div>
            </div>
          </PortletBody>
        </Portlet>
        <div className="row">
          <div className="col-xl-6">
            <DonutCharts
              contractid={this.props.contract.contractid}
              globalResources={this.props.contractTemplate.resources}
              workflowsQA={this.props.contractTemplate.workflowsQA}
            />
          </div>
          <div className="col-xl-6">
            <Portlet fluidHeight={true} style={{ marginBottom: 20 }}>
              {/* <PortletHeader title="Deadline Calendar" /> */}
              <PortletBody>
                <ConrtractCalendar />
              </PortletBody>
            </Portlet>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = store => ({
  contract: store.contract.contract,
  contractTemplate: store.contract.template,
  contractDashboard: store.contract.dashboard,
  dashboardCurrencyCode: store.contract.dashboardCurrencyCode,
  currencyOptions: store.contract.currencyOptions,
  userProfile: store.auth.userProfile
});

export default connect(mapStateToProps)(Dashboard);