import React from 'react';
import BaseDetailPage from '../../_CustomComponents/BaseDetailPage';
import ReactToPrint from "react-to-print";
import { Navigate } from "react-router-dom";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import * as compensationEventCrud from '../../../../crud/compensationEvent.crud';
import * as fileCrud from '../../../../crud/file.crud';
import axios from 'axios';
import ActionModal from '../../_CustomComponents/ActionModal';
import DocumentMain from './DocumentMain';
// import DocumentReply from './DocumentReply';
// import CompensationReply from './Reply';
import QuotesRouter from '../quotes/Router';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import { addDays } from 'date-fns';
import moment from 'moment';
import { ValidationSummary, ValidationGroup } from 'devextreme-react';
import Relations from '../../_CustomComponents/Relation/Relations';
import Common from '../../../../shared/common';
import PageToolbar from '../../_CustomComponents/PageToolbar';
import { withDetailPageProps } from '../../../../shared/hoc/withDetailPageProps';
import NavigationBlocker from '../../../../partials/Tools/NavigationBlocker';
import { checkIfAwareDateFieldAllowed } from '../instructions';
import TemplateHelper from "../../../../Templates/TemplateHelper";
import * as relationshipCrud from '../../../../crud/relationship.crud';
import EmailNotifications from '../../_CustomComponents/EmailNotifications';
const initialState = {
    notification: null,
    files: null,
    showModal: false,
    saving: false,
    isChanged: false,
    relationCount: 0,
    modalTitle: '',
    modalText: '',
    modalMode: '',
    relations: null,
    selectedClause: null
};

class CompensationDetailPage extends BaseDetailPage {
    constructor(props) {
        super(props);
        this.isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'DEV';
        this.isRelationsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true' || process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true';
        this.dateInstructionAllowed = checkIfAwareDateFieldAllowed(props.contract.contracttype);
        this.printIssueDivRef = React.createRef();
        this.validatorRef = React.createRef();
        this.validatorName = 'InstructionValidator';
        this.state = { ...initialState };
    }

    componentDidMount() {
        this.fetchNotification();
        this.fetchFiles();
    }



    componentDidUpdate(prevprops, prevSatate) {
        if (this.props.params.notificationid !== prevprops.params.notificationid) {
            this.setState({ ...initialState }, () => {
                this.fetchNotification();
                this.fetchFiles();
            });
        }
    }

    getMailDescription = () => {
        return this.state.notification.compensationnumber;
    }
    getSelectedTypeClauseDisplay = () => {
        return this.state.notification.compensationtype;
    }
    fetchNotification = () => {
        compensationEventCrud.getCompensationEvent(this.props.params.notificationid)
            .then(response => {
                const instruction = response.data;
                if (instruction.contractid !== this.props.contract.contractid ||
                    instruction.compensationsource !== this.props.notificationType) {
                    throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.INVALID_URL' }));
                }
                if (instruction.awaredate) {
                    instruction.awaredate = new Date(instruction.awaredate);
                }

                let selectedClause = null;
                let globalResources = this.props.globalResources;
                if (instruction.compensationtype && this.props.clauses) {
                    selectedClause = this.props.clauses.find(c => c.display === instruction.compensationtype);
                    if (selectedClause) {
                        globalResources = TemplateHelper.getGlobalResourcesOverriden(globalResources, selectedClause)
                    }
                    else {
                        if (instruction.showtasknotification !== 1) {
                            instruction.compensationtype = null;
                            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_CLAUSE_MISSING' }), { variant: 'warning', });
                        }
                    }
                }
                this.setState({
                    notification: instruction,
                    saving: false,
                    isChanged: false,
                    relations: instruction.relations,
                    relationCount: Common.GetRelationCount(instruction, this.props.accessibleWfIds),
                    selectedClause,
                    globalResources
                }, () => { this.props.setReference(this.state.notification.referenceid); });
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                }
            });
    }

    fetchFiles = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.notificationid, this.props.documentType)
            const filesList = JSON.parse(data.aggregatelist)
            this.setState({ files: filesList });
            return filesList;
        } catch (err) {
            if (!axios.isCancel(err)) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
        }
    }
    printHandler = () => {
        this.printIssueRef.handlePrint();
    }

    validateForm = () => {
        const validationResult = this.validatorRef.current.instance.validate();
        if (!validationResult.isValid) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_MISSING_FIELDS' }), { variant: 'warning', });
        }
        return validationResult.isValid && !this.state.saving;
    }
    showModal = (mode, postActionNavigateObj) => {
        let selectedClause = null;
        if (this.state.notification.compensationtype && this.props.clauses) {
            selectedClause = this.props.clauses.find(c => c.display === this.state.notification.compensationtype);
        }
        if (mode === 'COMMUNICATE') {
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_COMMUNICATE_ITEM' }, { ITEM_NAME: this.props.title }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_COMMUNICATING' }, { ITEM_NAME: this.props.title, PARTY_NAME: this.props.communicateToUser }),
                    modalMode: mode,
                    postActionUrl: postActionNavigateObj,
                    selectedClause
                });
            }
        }
        else if (mode === 'SAVE_DRAFT') {
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_DRAFT_NOTIFY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
                    modalMode: mode,
                });
            }
        }
        else if (mode === 'DELETE_DRAFT') {
            this.setState({
                showModal: true,
                modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_DELETE_DRAFT_NOTIFY' }),
                modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_DELETING' }),
                modalMode: mode,
            });
        }

    }

    onModalResponse = (response, params) => {
        this.setState({ showModal: false, saving: response === 'OK' }, async () => {
            try {
                if (response === 'OK') {
                    if (this.state.modalMode === 'COMMUNICATE') {
                        const notification = { ...this.state.notification };
                        notification.createactions = params?.createactions;
                        notification.completeactions = params?.completeactions;
                        notification.createby = this.props.contractUser.name;
                        notification.createbyid = this.props.contractUser.accountid;
                        notification.accountid = this.props.contractUser.accountid;
                        notification.status = 'AQ';
                        notification.showtasknotification = 1;
                        // notification.notificationdatedisplay = moment().format('DD/MM/YYYY');
                        const quoteDate = moment(addDays(new Date(), this.state.globalResources.PROP_QUOTE_DURATION)).format('DD/MM/YYYY');
                        notification.originalquotedatedisplay = quoteDate;
                        notification.requiredatedisplay = quoteDate;

                        const docBodyConfig = await this.documentBodyConfig(notification, this.props.resources);
                        const docResources = {
                            reference2Label: this.props.intl.formatMessage({ id: 'CONTRACT.WF.P_INSTRUCTION.FIELD.COMPENSATION_REF' }, { COMPENSATION: this.props.ceNotificationName })
                        }
                        const docConfig = await this.documentConfig(notification, docBodyConfig, this.props.contractUser.name, false, '', docResources, this.props.workflowItem.form_NotifyCompanyName);
                        notification.pdfjsoncontent = JSON.stringify(docConfig);

                        this.saveNotification(notification, this.state.modalMode);

                    }
                    else if (this.state.modalMode === 'SAVE_DRAFT') {
                        const notification = { ...this.state.notification };
                        notification.createby = this.props.contractUser.name;
                        notification.createbyid = this.props.contractUser.accountid;
                        notification.accountid = this.props.contractUser.accountid;
                        notification.status = 'NC';
                        notification.showtasknotification = 0;
                        this.saveNotification(notification, this.state.modalMode, params ? params.userid : null);

                    }
                    else if (this.state.modalMode === 'DELETE_DRAFT') {
                        const compensationevent = { ...this.state.notification };
                        const compensationeventid = compensationevent.compensationeventid
                        this.deleteProposedInstructionDraft(compensationeventid);

                    }
                }
            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                this.setState({ saving: false });
            }

        });
    }

    getTaskURL = (notification) => {
        const { contractid, taskorderid } = notification;
        return `${window.location.origin}/contract/${contractid}/tasks/pm/${taskorderid}`;
    }

    getProjectURL = (notification) => {
        const { contractid, projectorderid } = notification;
        return `${window.location.origin}/contract/${contractid}/projects/pm/${projectorderid}`;
    }

    documentBodyConfig = async (notification, resources) => {
        const config = [
            { resource: 'GENERAL.FORMS.FIELD.SUBJECT', value: notification.compensationnumber },
        ];
        if (this.props.taskOrderOptionEnabled) {
            config.push({ label: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.TASK_ORDER' }, { TASK_ORDER: this.props.taskOrderNotificationName }), text: notification.taskorderreference, valueType: 'LINK', url: this.getTaskURL(notification) });
        }
        if (this.props.projectOrderOptionEnabled) {
            config.push({ label: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.PROJECT_ORDER' }, { PROJECT_ORDER: this.props.projectOrderNotificationName }), text: notification.projectorderreference, valueType: 'LINK', url: this.getProjectURL(notification) });
        }
        if (this.typeFieldEnabled) {
            config.push(
                { resource: "GENERAL.FORMS.FIELD.TYPE", value: notification.compensationtype },
            );
        }
        if (this.dateInstructionAllowed && this.props.fields.ProposedInstructionGivenDate) {
            config.push(
                { label: this.props.fields.ProposedInstructionGivenDate, value: notification.awaredate }
            );
        }
        config.push(
            { resource: 'GENERAL.FORMS.FIELD.DETAILS', value: notification.description, valueType: 'HTML', align: 'vertical' },
        );
        if (resources.ProposedInstructionNote) {
            config.push(
                { note: resources.ProposedInstructionNote.replace('$date', notification.originalquotedatedisplay), valueType: 'NOTE' }
            )
        }
        if (this.props.fields.Assumption) {
            config.push(
                { label: this.props.fields.Assumption, value: notification.assumption, valueType: 'HTML', align: 'vertical' }
            );
        }


        if (this.props.ewRelationEnabled) {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data: respData } = await compensationEventCrud.getCompensationWarnings(companyid, projectid, contractid, notification.compensationeventid)
            const relatedEWs = JSON.parse(respData.aggregatelist).map(ew => ({
                warningid: ew.earlywarningid,
                url: `${window.location.origin}/contract/${this.props.contract.contractid}/early-warning/${ew.earlywarningparty === "PM" ? "pm" : "supplier"}/${ew.earlywarningid}`,
                text: ew.earlywarningreferenceid + " " + ew.earlywarning
            }));

            config.push({
                label: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.LABEL.ASSOCIATED_EW' }, { EARLY_WARNING: this.props.ewNotificationName }),
                valueType: "LINKS",
                data: relatedEWs
            })
        }
        return config;
    }

    deleteProposedInstructionDraft = async (compensationeventid) => {

        try {
            await compensationEventCrud.deleteCompensationEventDraft(compensationeventid);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_DELETED' }), { variant: 'success' });
            this.setState({ saving: false, navigateto: this.props.workflowItem.paths.listPath });

        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            this.setState({ saving: false });
        }

    }
    saveNotification = async (notification, action, notifyuserid) => {
        // const { companyid, projectid, contractid } = this.props.contract;
        const postEvents = () => {
            this.fetchNotification();

            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), { variant: 'success' });
        };
        try {

            if (!notification.projectmanagersigneddatedisplay && notification.projectmanagersigneddate) {
                notification.projectmanagersigneddatedisplay = moment(new Date(notification.projectmanagersigneddate)).format('DD/MM/YYYY');
            }

            if (notification.awaredate) {
                //REQUIRES US DATE FORMAT MM.DD.YYYY
                notification.awaredatedisplay = moment(notification.awaredate).format('DD/MM/YYYY');
            }
            //#TODO IN CEN Update
            if (this.state.addedWarnings && this.state.addedWarnings.length > 0) {
                notification.timeextension = this.state.addedWarnings[this.state.addedWarnings.length - 1].reference;
            }
            // const promises = []
            // if (this.state.addedWarnings && this.state.addedWarnings.length > 0) {
            //     this.state.addedWarnings.forEach(warning => {
            //         const relationObj = {
            //             companyid: companyid,
            //             projectid: projectid,
            //             contractid: contractid,
            //             compensationeventid: notification.compensationeventid,
            //             earlywarningid: warning.warningid,
            //         };
            //         promises.push(compensationEventCrud.saveCompensationEarlyWarning(relationObj));
            //     });
            // }
            // if (this.state.removedWarnings && this.state.removedWarnings.length > 0) {
            //     this.state.removedWarnings.forEach(warning => {
            //         promises.push(compensationEventCrud.deleteCompensationEarlyWarning(notification.compensationeventid, warning.warningid));
            //     });
            // }
            // if (promises.length > 0) {
            //     await axios.all(promises);
            // }
            notification.actioncode = action;

            Common.PropertyConversionsBeforeNoticeSaveRequest(notification, this.props.customFields, this.props.contractUsers);
            await compensationEventCrud.saveCompensationEvent(notification);
            notification.notificationtypeforemailtemplate = notification.compensationsource;
            notification.pdfjsoncontent = notification.pdfjsoncontent?.replace("$NOW", moment().format('DD/MM/YYYY'));
            this.notify(action, notification, notifyuserid);
            if (this.state.postActionUrl) {
                this.setState({ isChanged: false, saving: false, navigateto: this.state.postActionUrl.pathname });
            } else {
                postEvents();
            }

        } catch (err) {
            this.setState({ saving: false, });
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
    }
    fieldChanged = (field, value, isDxFormValid, additionalData) => {
        //SILENT STATE CHANGE
        //WE DONT WANT COMPONENT RERENDER WİTH THIS
        //this.setState({ notification: { ...this.state.notification, [field]: value } });
        if (field) {
            if (field === "assetid") {
                this.setState({
                    notification: { ...this.state.notification, [field]: value, asset: { ...additionalData } },
                    isChanged: true
                });
            }
            else if (field === 'compensationtype') {
                this.state.notification[field] = value;

                let selectedClause = null;
                let globalResources = this.props.globalResources;
                if (value) {
                    selectedClause = this.props.clauses.find(c => c.display === value);
                    if (selectedClause) {
                        globalResources = TemplateHelper.getGlobalResourcesOverriden(globalResources, selectedClause)
                    }
                }
                this.setState({
                    isChanged: true,
                    selectedClause,
                    globalResources
                })
            }
            else {
                this.state.notification[field] = value;
                if (!this.state.isChanged) {
                    this.setState({ isChanged: true });
                }
            }
        }
    }
    onCompensationChanged = () => {
        this.fetchNotification();
    }

    renderToolbar = (documentIsFinal) => {
        const saveDisabled = (this.state.saving || this.state.showModal);
        return (
            <PageToolbar
                key={this.state.notification.version}
                documentIsFinal={documentIsFinal}
                saveDisabled={saveDisabled}
                actions={{
                    showModal: this.showModal,
                    onPrint: this.printHandler
                }}
                workflowItem={this.props.workflowItem}
                match={this.props.match}
                customFieldValues={this.state.notification.customfieldvalues}
                referenceid={this.state.notification.referenceid}
                contract={this.props.contract}
                relationParams={{
                    subject: this.getMailDescription(),
                    assetid: this.state.notification.assetid
                }}
                notification={this.state.notification}
                selectedClausetypeDisplay={this.getSelectedTypeClauseDisplay()}
            />
        );
    }
    onWarningsChanged = async (addedWarningID, removedWarningID) => {
        try {
            if (addedWarningID) {
                const { companyid, projectid, contractid } = this.props.contract;
                const relationObj = {
                    companyid: companyid,
                    projectid: projectid,
                    contractid: contractid,
                    compensationeventid: this.state.notification.compensationeventid,
                    earlywarningid: null,
                };
                relationObj.earlywarningid = addedWarningID;
                await compensationEventCrud.saveCompensationEarlyWarning(relationObj)
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.RELATION_ADDED' }), { variant: 'success' });
            }
            else if (removedWarningID) {
                await compensationEventCrud.deleteCompensationEarlyWarning(this.state.notification.compensationeventid, removedWarningID);
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.RELATION_REMOVED' }), { variant: 'success' });
            }
            await this.updateRelations();
        } catch (err) {
            console.log(err);
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
    }
    updateRelations = async () => {
        try {
            const response = await relationshipCrud.getRelationships(this.props.params.notificationid, this.props.workflowItem.config.referenceType, this.props.contract.contractid)
            const newRelations = JSON.parse(response.data);
            this.setState({
                relations: newRelations,
                relationCount: Common.GetRelationCount({ relations: newRelations }, this.props.accessibleWfIds),
            })
        }
        catch (err) {
            console.log(err);
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
    }
    tabDocument = () => {
        if (!this.state.files) {
            return null;
        }
        else {
            return (
                <DocumentMain
                    {...this.props}
                    fields={this.props.fields}
                    onWarningsChanged={this.onWarningsChanged}
                    printDivRef={this.printIssueDivRef}
                    fieldChanged={this.fieldChanged}
                    notification={this.state.notification}
                    files={this.state.files}
                    notificationid={this.props.params.notificationid}
                />
            );
        }

    }
    tabQuotes = () => {
        return (
            <QuotesRouter
                {...this.props}
                parentWorkflowItem={this.props.workflowItem}
                parentTabPanel={this.tabPanel}
                onCompensationChanged={this.onCompensationChanged}
                instruction={this.state.notification}
            />
        )
    }
    tabRelations = () => {
        return (
            <Relations
                contractid={this.props.contract.contractid}
                workflowItem={this.props.workflowItem}
                workflowGroupItem={this.props.workflowGroupItem}
                notificationid={Number(this.props.params.notificationid)}
                relations={this.state.relations}
                itemversion={this.state.notification.version}
                subject={this.state.notification.compensationnumber}
                onCountChange={(count) => this.setState({ relationCount: count })}
                showTags={this.props.contract.specifictags?.length > 0}
            />
        );
    }
    tabEmailNotification = () => {

        return (
            <EmailNotifications
                {...this.props}
                notificationDate={this.state.notification.notificationdate}
            />
        );

    };
    render() {
        if (!this.state.notification || !this.state.files) {
            return (<PortletSpinner />);
        }

        const documentIsFinal = this.state.notification.showtasknotification === 1;
        const quotesTabEnabled = documentIsFinal && !['APMR', 'R'].includes(this.state.notification.status);

        if (!documentIsFinal && !this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed) {
            return (<Navigate replace to={this.props.workflowItem.paths.listPath} />)
        }
        return (
            <Portlet>
                <ReactToPrint
                    ref={el => (this.printIssueRef = el)}
                    trigger={() => (<div style={{ display: 'none' }} ></div>)}
                    content={() => this.printIssueDivRef.current}
                />
                <PortletHeader
                    title={documentIsFinal ? this.props.workflowItem.pageTitle : this.props.intl.formatMessage({ id: "GENERAL.FORMS.DRAFT_TITLE" }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
                    toolbar={this.renderToolbar(documentIsFinal)}
                />
                <NavigationBlocker
                    when={(this.state.isChanged || this.state.saving)}
                    message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}
                />
                {this.state.navigateto &&
                    (
                        <Navigate replace to={this.state.navigateto} state={(this.state.postActionUrl || {}).state} />
                    )
                }
                <PortletBody>
                    <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
                        <div>
                            <ValidationSummary elementAttr={{ align: "right", paddingBottom: 50 }} id={this.validatorName}></ValidationSummary>
                        </div>
                        <TabPanel
                            ref={(ref) => this.tabPanel = ref}
                            width="100%"
                            deferRendering={false}
                            animationEnabled={true}
                            focusStateEnabled={true}
                            hoverStateEnabled={true}
                            swipeEnabled={false}>
                            <Item icon="file" title={this.props.title} render={this.tabDocument} />
                            <Item icon="sorted" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_QUOTES' })} render={quotesTabEnabled ? this.tabQuotes : null} visible={quotesTabEnabled} />
                            {
                                this.isRelationsEnabled && (
                                    <Item icon="share" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_RELATIONS' })} render={this.tabRelations} badge={this.state.relationCount > 0 ? this.state.relationCount : null} />
                                )
                            }
                            <Item
                                icon="bulletlist"
                                title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_EMAIL_NOTIFICATIONS' })}
                                render={documentIsFinal ? this.tabEmailNotification : null} visible={documentIsFinal}
                            />
                        </TabPanel>
                    </ValidationGroup>
                </PortletBody>
                <ActionModal
                    visible={this.state.showModal}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    mode={this.state.modalMode}
                    onDialogResult={this.onModalResponse}
                    workflowItem={this.props.workflowItem}
                    selectedClause={this.state.selectedClause}
                />
            </Portlet>
        );
    }
}



export default withDetailPageProps(CompensationDetailPage);