import React from 'react';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import DataGrid, { Button, Column, SearchPanel, StateStoring, Export, ColumnChooser, ColumnFixing, Editing, HeaderFilter, Summary, TotalItem, Scrolling, Toolbar, Item as ToolbarItem, Selection } from 'devextreme-react/data-grid';
import { FormattedMessage, injectIntl } from "react-intl";
import moment from 'moment';
import { confirm } from 'devextreme/ui/dialog';
import { custom as CustomDialog } from 'devextreme/ui/dialog';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import ContractActionCreateModal, { RESPONSES as CreateModalResponses } from './Helpers/ContractActionCreateModal';
import ContractActionEditModal, { RESPONSES as EditModalResponses } from './Helpers/ContractActionEditModal';
import ContractActionExcelHelper from "./Helpers/ContractActionExcelHelper";
import * as actionCrud from '../../../../crud/action.crud';
import { listAccountsAllowedDeleteCompletedAction } from '../../../../router/Protector/AccessExceptions';
import { Link } from "react-router-dom";

export const typeOptions = [
    { value: 'PM_INSTRUCT', wfid: 4, display: "PM_INSTRUCT" },
    { value: 'SUPPLIER_PROG', wfid: 14, display: "SUPPLIER_PROG" },
    { value: 'PM_NOTIFICATION', wfid: 15, display: "PM_NOTIFICATION" },
    { value: 'SUPPLIER_NOTIFICATION', wfid: 16, display: "SUPPLIER_NOTIFICATION" },
    { value: 'SUPERVISOR_NOTIFICATION', wfid: 17, display: "SUPERVISOR_NOTIFICATION" },
    { value: 'SUPPLIER_SUB', wfid: 18, display: "SUPPLIER_SUB" },
    { value: 'PM_SUB', wfid: 49, display: "PM_SUB" },
    { value: 'SUPPLIER_SUB_WQ', wfid: 19, display: "SUPPLIER_SUB_WQ" },
    { value: 'PM_PAY_CERT', wfid: 20, display: "PM_PAY_CERT" },
    { value: 'SUPPLIER_PAY_APP', wfid: 21, display: "SUPPLIER_PAY_APP" },
    { value: 'SUPPLIER_COST_FORECAST', wfid: 52, display: "SUPPLIER_COST_FORECAST" },
    { value: 'SUPPLIER_CARBON_FORECAST', wfid: 53, display: "SUPPLIER_CARBON_FORECAST" },
    { value: 'PM_CERT', wfid: 68, display: "PM_CERT" },
    { value: 'SUPPLIER_CERT', wfid: 69, display: "SUPPLIER_CERT" },
    { value: 'CLIENT_CERT', wfid: 70, display: "CLIENT_CERT" },
    { value: 'SUPERVISOR_CERT', wfid: 71, display: "SUPERVISOR_CERT" },
    { value: 'PAY_ASSESS', wfid: 0, display: "Assessment Date" },
    { value: 'PAY_DUE', wfid: 0, display: "Payment Due" },
    { value: 'FINAL_DFP', wfid: 0, display: "Final Date for Payment" },
    { value: 'PAY_LESS', wfid: 0, display: "Pay Less Notice" },
    { value: 'OTHER', wfid: 0, display: "Other" },
];


class ContractActions extends React.Component {
    constructor(props) {
        super(props);
        this.allowEdit = this.props.protectedRouteProps.allowEdit;
        this.gridRef = React.createRef();
        this.fileImportInputRef = React.createRef();
        this.importExcelTemplateDownloadLinkRef = React.createRef();

        this.state = {
            isBusy: true,
            actions: null,
            showAddActionModal: false,
            showEditActionModal: false,
            editingAction: null
        }
    }

    componentDidMount() {
        this.loadActions();
    }

    loadActions = async () => {
        this.setState({ isBusy: true }, async () => {
            try {
                const { data: actions } = await actionCrud.getRegisterList(this.props.contract.contractid)
                this.setState({ actions, isBusy: false });
            } catch (err) {
                this.setState({ actions: null, isBusy: false });
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
        })
    }
    deleteActionPromise = (contractid, actionids) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestObj = {
                    contractid,
                    actionids
                };
                await actionCrud.deleteAction(requestObj);
                resolve(false);
            } catch (error) {
                console.log(error);
                reject(error)
            }
        });
    }
    //deleting row
    onRowRemoving = async (e) => {
        const { contractid } = this.props.contract;
        this.gridRef.current.instance.clearSelection();
        e.cancel = this.deleteActionPromise(contractid, [e.data.actionid]);
    }
    deleteSelectedClick = async () => {

        const selection = this.gridRef.current.instance.getSelectedRowKeys();
        if (selection.length) {
            let activateDialog = CustomDialog({
                title: this.props.intl.formatMessage({ id: 'ACTIONS.POPUP.BATCH_DELETE.TITLE' }, { COUNT: selection.length }), //this.props.intl.formatMessage({ id: 'GENERAL.USER_SHOWING_CONTRACT_DASHBOARD' }, { LENGTH: selection.length }),
                messageHtml: `<div style="max-width:60vw;">${this.props.intl.formatMessage({ id: 'ACTIONS.POPUP.BATCH_DELETE.MESSAGE' }, { COUNT: selection.length })}</div>`,
                buttons: [
                    {
                        text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_CANCEL" }),
                        onClick: (e) => { return "CANCEL" }
                    },
                    {
                        text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_DELETE" }),
                        type: "danger",
                        stylingMode: "outlined",
                        onClick: (e) => { return "CONTINUE" }
                    }
                ]
            });
            const result = await activateDialog.show();
            if (result === "CONTINUE") {
                const formData = {
                    actionids: selection,
                    contractid: this.props.contract.contractid
                };
                this.setState({ isBusy: true }, async () => {
                    try {
                        await actionCrud.deleteAction(formData);
                        this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "ACTIONS.TOAST.ACTIONS_DELETED" }), { variant: "success" });
                        this.gridRef.current.instance.clearSelection();
                        this.loadActions();
                    }
                    catch (err) {
                        this.setState({ isBusy: false })
                        this.props.enqueueSnackbar(err.toString(), { variant: "error" });
                    }
                })

            }
        }


    }
    completeSelectedClick = async () => {
        const selection = this.gridRef.current.instance.getSelectedRowKeys();
        if (selection.length) {
            let activateDialog = CustomDialog({
                title: this.props.intl.formatMessage({ id: 'ACTIONS.POPUP.BATCH_COMPLETE.TITLE' }, { COUNT: selection.length }),
                messageHtml: `<div style="max-width:60vw;">${this.props.intl.formatMessage({ id: 'ACTIONS.POPUP.BATCH_COMPLETE.MESSAGE' }, { COUNT: selection.length })}</div>`,
                buttons: [
                    {
                        text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_CANCEL" }),
                        onClick: (e) => { return "CANCEL" }
                    },
                    {
                        text: this.props.intl.formatMessage({ id: "ACTIONS.FIELD.COMPLETE" }),
                        type: "success",
                        stylingMode: "outlined",
                        onClick: (e) => { return "CONTINUE" }
                    }
                ]
            });
            const result = await activateDialog.show();
            if (result === "CONTINUE") {
                const formData = {
                    actionids: selection,
                    contractid: this.props.contract.contractid
                };
                this.setState({ isBusy: true }, async () => {
                    try {
                        await actionCrud.complete(formData);
                        this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "ACTIONS.TOAST.ACTIONS_COMPLETED" }), { variant: "success" });
                        this.gridRef.current.instance.clearSelection();
                        this.loadActions();

                    }
                    catch (err) {
                        this.setState({ isBusy: false })
                        this.props.enqueueSnackbar(err.toString(), { variant: "error" });
                    }

                })

            }
        }
    }
    downloadExcelTemplate = async () => {
        this.importExcelTemplateDownloadLinkRef.current.click();
    }
    onSelectionChanged = ({ selectedRowKeys, component }) => {
        if (selectedRowKeys.length > 0 && selectedRowKeys.length <= 100) {
            if (this.refButtonBatchComplete) { this.refButtonBatchComplete.component.option("disabled", false); }
            if (this.refButtonBatchDelete) { this.refButtonBatchDelete.component.option("disabled", false); }
        } else {
            if (this.refButtonBatchComplete) { this.refButtonBatchComplete.component.option("disabled", true); }
            if (this.refButtonBatchDelete) { this.refButtonBatchDelete.component.option("disabled", true); }
        }
        if (selectedRowKeys.length > 0) {
            if (this.refButtonClearSelection) { this.refButtonClearSelection.component.option("disabled", false); }
        } else {
            if (this.refButtonClearSelection) { this.refButtonClearSelection.component.option("disabled", true); }
        }
        if (selectedRowKeys.length > 100) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "GENERAL.USER_SELECTION_LIMIT" }), { variant: "warning" });
        }
        component.refresh(true);
    }
    onExporting = (e) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ContractUsers.xlsx');
                });
        });
    }
    onBtnAddActionClicked = () => {
        this.setState({ showAddActionModal: true });
    }
    onAddActionDialogResult = (result) => {
        if (result === CreateModalResponses.SAVE) {
            this.loadActions();
        }
        this.setState({ showAddActionModal: false });
    }
    onEditActionDialogResult = (result) => {
        if (result === EditModalResponses.SAVE) {
            this.loadActions();
        }
        this.setState({ showEditActionModal: false, editingAction: null });
    }
    completeIconClicked = async (e) => {
        let result = await confirm(`<div style="max-width:50vw;">${this.props.intl.formatMessage({ id: "ACTIONS.POPUP.COMPLETE.MESSAGE" })}</div>`, this.props.intl.formatMessage({ id: "ACTIONS.POPUP.COMPLETE.TITLE" }));
        if (result) {
            this.setState({ isBusy: true }, async () => {
                try {
                    const requestObj = {
                        contractid: this.props.contract.contractid,
                        actionids: [e.row.data.actionid]
                    }
                    const response = await actionCrud.complete(requestObj);
                    this.gridRef.current.instance.clearSelection();
                    this.loadActions();
                } catch (err) {
                    this.setState({ isBusy: false });
                    this.props.enqueueSnackbar(err.toString(), { variant: "error" });
                }
            });
        }
    }
    editIconClicked = async (e) => {
        this.setState({ editingAction: { ...e.row.data }, showEditActionModal: true });
    }

    importFileSelected = async (e) => {
        if (e.stopPropagation) { e.stopPropagation(); }
        if (e.preventDefault) { e.preventDefault(); }
        if (e.target && e.target.files && e.target.files.length > 0) {
            var file = e.target.files[0];
            if (file) {
                this.setState({ isBusy: true }, async () => {
                    try {
                        const data = await ContractActionExcelHelper.processExcelFile(file, this.props.contract.contractid, this.props.contractTemplate.availableActionTypes);
                        const requestObj = {
                            contractid: this.props.contract.contractid,
                            actions: data
                        }
                        const { data: itemCount } = await actionCrud.importActions(requestObj)
                        if (itemCount > 0) {
                            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'ACTIONS.TOAST.IMPORTED' }, { COUNT: itemCount }), { variant: 'success', });
                        }
                        else {
                            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'ACTIONS.TOAST.IMPORTED_NONE' }), { variant: 'warning', });
                        }
                        this.loadActions();
                    } catch (err) {
                        this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                        console.log(err.toString())
                        this.setState({ isBusy: false })
                    }
                    finally {
                        if (this.fileImportInputRef.current) {
                            this.fileImportInputRef.current.value = null;
                        }
                    }
                })

            }
        }
    }


    importExcelClicked = (e) => {
        if (this.fileImportInputRef.current) {
            this.fileImportInputRef.current.click();
        }
    }
    calculateSelectedRowSummary = (options) => {
        if (options.name === 'SelectedRowsCount') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            }
            const isRowSelected = options.component.isRowSelected(options.value?.actionid);
            if (options.summaryProcess === 'calculate' && isRowSelected) {
                options.totalValue += 1;
            }
        }
    };
    allowDeleting = (e) => {
        ///ACTION IS COMPLETED
        if (e.row.data.completed) {
            if (listAccountsAllowedDeleteCompletedAction.includes(this.props.userProfile.accounttype)) {
                return true;
            }
            else {
                return false;
            }
        }
        /// IF ACTION WAS CREATED BY ME
        else if (e.row.data.createbyid === this.props.userProfile.accountid) {
            return true;
        }
        /// USER TYPE CONFIG
        else {
            return this.allowEdit;
        }
    }
    isCompleteIconVisible = (e) => {
        return !e.row.data.completed;
    }
    isEditIconVisible = (e) => {
        /// ACTION WAS CREATED BY ME
        if (e.row.data.createbyid === this.props.userProfile.accountid) {
            return true;
        }
        else {
            return this.allowEdit;
        }
    }
    renderCreateByReference = (cellInfo) => {
        if (cellInfo.data.sourcereferenceid) {
            return (
                <Link to={this.props.contractTemplate.workflowsQA[cellInfo.data.sourceworkflowid].paths.basePath + cellInfo.data.sourcerecordid}>
                    {cellInfo.data.sourcereferenceid}
                </Link>
            )

        }
        return '';
    }
    renderResolvedByReference = (cellInfo) => {
        if (cellInfo.data.relatedreferenceid && cellInfo.data.relatedworkflowid) {
            return (
                <Link to={this.props.contractTemplate.workflowsQA[cellInfo.data.relatedworkflowid].paths.basePath + cellInfo.data.relatedrecordid}>
                    {cellInfo.data.relatedreferenceid}
                </Link>
            )

        }
        return '';
    }
    render() {
        return (
            <Portlet isLoading={this.state.isBusy} loadingDelay={0}>
                <PortletBody >
                    <DataGrid
                        ref={this.gridRef}
                        height={'67vh'}
                        dataSource={this.state.actions}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={false}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        remoteOperations={false}
                        allowColumnResizing={true}
                        allowColumnReordering={true}
                        columnResizingMode='widget'
                        onRowRemoving={this.onRowRemoving}
                        style={{ width: '100%', height: '100%', maxHeight: '67vh' }}
                        name="ContractActions"
                        keyExpr="actionid"
                        onSelectionChanged={this.onSelectionChanged}
                        onExporting={this.onExporting}
                        loadPanel={{ enabled: false }}
                    >
                        <Selection
                            mode="multiple"
                            selectAllMode="allPages"
                            showCheckBoxesMode="always"
                        />
                        <Editing
                            //CACADING FORM DEMO IMPLEMENTATION
                            //https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CascadingLookups/React/Light/
                            // mode="popup"
                            allowUpdating={false}
                            allowDeleting={this.allowDeleting}
                            allowAdding={false}
                            confirmDelete={true}
                            //https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxPopup/Configuration/
                            // popup={{ title: "Add action", minWidth: 600, height: "auto", width: "auto", showTitle: true }}
                            // useIcons={true}
                            //https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/editing/texts/#deleteRow
                            texts={{
                                deleteRow: this.props.intl.formatMessage({ id: "GENERAL.FORMS.REMOVE" }),
                                confirmDeleteTitle: this.props.intl.formatMessage({ id: "ACTIONS.POPUP.DELETE.TITLE" }),
                                confirmDeleteMessage: this.props.intl.formatMessage({ id: "ACTIONS.POPUP.DELETE.MESSAGE" }),
                            }}

                        >
                        </Editing>
                        <HeaderFilter visible={true} />
                        <ColumnChooser enabled={true} />
                        <ColumnFixing enabled={true} />
                        <StateStoring enabled={true} type="localStorage" storageKey={"FDR-" + process.env.REACT_APP_PRODUCT_KEY + process.env.REACT_APP_ENVIRONMENT} />

                        <Export enabled={true} fileName={this.props.intl.formatMessage({ id: 'CONTRACT.USERS.TITLE' })} />
                        <SearchPanel visible={true} highlightCaseSensitive={false} defaultText="" />
                        <Column
                            caption={this.props.intl.formatMessage({ id: "ACTIONS.FIELD.ACTION_ID" })}
                            dataField="actionid"
                            visible={false}

                        />
                        <Column
                            caption={this.props.intl.formatMessage({ id: "ACTIONS.FIELD.OWNER_PARTY" })}
                            dataField="ownerparty"
                            lookup={{ dataSource: this.props.contractTemplate.parties.options, valueExpr: 'id', displayExpr: 'display' }}
                            allowHiding={false}
                        />
                        <Column
                            caption={this.props.intl.formatMessage({ id: "ACTIONS.FIELD.SUBJECT" })}
                            dataField="subject"
                        />

                        <Column
                            caption={this.props.intl.formatMessage({ id: "ACTIONS.FIELD.CREATE_DATE" })}
                            dataField="createdate"
                            dataType="date"
                            format={(date) => moment(date).format('DD/MM/YYYY')}
                            visible={false}
                        />
                        <Column
                            caption={this.props.intl.formatMessage({ id: "ACTIONS.FIELD.CREATE_BY" })}
                            dataField="createby"
                            visible={false}
                        />
                        <Column
                            caption={this.props.intl.formatMessage({ id: "ACTIONS.FIELD.DUE_DATE" })}
                            dataField="duedate"
                            dataType="date"
                            format={(date) => moment(date).format('DD/MM/YYYY')}
                        />
                        <Column
                            caption={this.props.intl.formatMessage({ id: "ACTIONS.FIELD.COMPLETED" })}
                            dataField="completed"
                        />

                        <Column
                            caption={this.props.intl.formatMessage({ id: "ACTIONS.FIELD.OVERDUE" })}
                            dataField="overdue"
                        />
                        <Column
                            caption={this.props.intl.formatMessage({ id: "ACTIONS.FIELD.RESOLVED_DATE" })}
                            dataField="resolveddate"
                            dataType="date"
                            format={(date) => moment(date).format('DD/MM/YYYY HH:mm')}
                        />
                        <Column
                            caption={this.props.intl.formatMessage({ id: "ACTIONS.FIELD.RESOLVED_BY" })}
                            dataField="resolvedby"
                        />
                        <Column
                            caption={this.props.intl.formatMessage({ id: "ACTIONS.FIELD.TYPE" })}
                            dataField="type"
                            visible={false}
                            lookup={{ dataSource: this.props.contractTemplate.availableActionTypes, valueExpr: 'value', displayExpr: 'display' }}
                        />
                        <Column
                            caption={this.props.intl.formatMessage({ id: "ACTIONS.FIELD.LASTEDITED_DATE" })}
                            dataField="lastediteddate"
                            dataType="date"
                            visible={false}
                            format={(date) => moment(date).format('DD/MM/YYYY HH:mm')}
                        />
                        <Column
                            caption={this.props.intl.formatMessage({ id: "ACTIONS.FIELD.LASTEDITED_BY" })}
                            dataField="lasteditedby"
                            visible={false}
                        />
                        <Column
                            caption={this.props.intl.formatMessage({ id: "ACTIONS.FIELD.CREATE_BY_REFERENCE" })}
                            dataField="sourcereferenceid"
                            cellRender={this.renderCreateByReference}
                        />
                        <Column
                            caption={this.props.intl.formatMessage({ id: "ACTIONS.FIELD.RESOLVED_BY_REFERENCE" })}
                            dataField="relatedreferenceid"
                            cellRender={this.renderResolvedByReference}
                        />

                        <Column name="editButtons" type="buttons" >
                            <Button icon="trash" name="delete" />
                        </Column>
                        <Column name="completeButton" type="buttons" >
                            <Button
                                name="complete"
                                hint={this.props.intl.formatMessage({ id: "ACTIONS.FIELD.COMPLETE" })}
                                icon="check"
                                visible={this.isCompleteIconVisible}
                                disabled={false}
                                onClick={this.completeIconClicked}
                            />
                        </Column>
                        <Column name="editButton" type="buttons" >
                            <Button
                                name="edit"
                                hint={this.props.intl.formatMessage({ id: "ACTIONS.FIELD.EDIT" })}
                                icon="edit"
                                visible={this.isEditIconVisible}
                                disabled={false}
                                onClick={this.editIconClicked}
                            />
                        </Column>

                        <Scrolling mode="virtual" showScrollbar={true} useNative={true} columnRenderingMode="virtual" rowRenderingMode="virtual" />
                        <Toolbar>
                            <ToolbarItem location="before">
                                <div>
                                    <h3 style={{ fontFamily: 'Poppins, Helvetica, sans-serif', fontSize: '1.2rem', fontWeight: 500, color: '#48465b', padding: 0, margin: 0, lineHeight: 1.2 }} className="kt-portlet__head-title">
                                        <FormattedMessage id='ACTIONS.TITLE' />
                                    </h3>
                                </div>
                            </ToolbarItem>
                            <ToolbarItem location="after"
                                locateInMenu="auto"
                                name="addAction"
                                showText="always"
                                widget="dxButton"
                                options={{
                                    icon: "plus",
                                    text: this.props.intl.formatMessage({ id: "ACTIONS.BUTTON.ADD_ACTION" }),
                                    onClick: this.onBtnAddActionClicked,
                                }}
                            />
                            <ToolbarItem
                                location="after"
                                locateInMenu="auto"
                                widget="dxButton"
                                options={{
                                    onInitialized: (e) => { this.refButtonBatchDelete = e; },
                                    icon: "trash",
                                    text: this.props.intl.formatMessage({ id: "ACTIONS.BUTTON.DELETE_ACTIONS" }),
                                    disabled: true,
                                    onClick: this.deleteSelectedClick,
                                    type: "danger",
                                    stylingMode: "outlined",
                                }}
                            />
                            <ToolbarItem
                                location="after"
                                locateInMenu="auto"
                                widget="dxButton"
                                options={{
                                    onInitialized: (e) => { this.refButtonBatchComplete = e; },
                                    icon: "check",
                                    text: this.props.intl.formatMessage({ id: "ACTIONS.BUTTON.COMPLETE_ACTIONS" }),
                                    disabled: true,
                                    onClick: this.completeSelectedClick,
                                    type: "success",
                                    stylingMode: "outlined",
                                }}
                            />
                            <ToolbarItem
                                location="after"
                                locateInMenu="auto"
                                widget="dxButton"
                                options={{
                                    onInitialized: (e) => { this.refButtonClearSelection = e; },
                                    icon: "clear",
                                    text: this.props.intl.formatMessage({ id: "ACTIONS.BUTTON.CLEAR_SELECTION" }),
                                    disabled: true,
                                    onClick: () => this.gridRef.current.instance.clearSelection()
                                }}
                            />
                            <ToolbarItem
                                location="after"
                                locateInMenu="always"
                                widget="dxButton"
                                options={{
                                    icon: "download",
                                    text: this.props.intl.formatMessage({ id: "ACTIONS.BUTTON.DOWNLOAD_EXCEL_TEMPLATE" }),
                                    onClick: this.downloadExcelTemplate,
                                }}
                            />
                            <ToolbarItem
                                location="after"
                                locateInMenu="auto"
                                widget="dxButton"
                                options={{
                                    icon: "import",
                                    text: this.props.intl.formatMessage({ id: "ACTIONS.BUTTON.IMPORT_FROM_EXCEL" }),
                                    onClick: this.importExcelClicked,
                                }}
                            />
                            <ToolbarItem
                                location="after"
                                locateInMenu="always"
                                widget="dxButton"
                                options={{
                                    icon: "refresh",
                                    text: this.props.intl.formatMessage({ id: "GENERAL.BUTTON.REFRESH" }),
                                    onClick: this.loadActions,
                                }}
                            />
                            <ToolbarItem location="after"
                                locateInMenu="auto"
                                name="exportButton"
                            />
                            <ToolbarItem location="after"
                                locateInMenu="auto"
                                name="applyFilterButton"
                            />
                            <ToolbarItem location="after"
                                locateInMenu="auto"
                                name="columnChooserButton"
                            />
                            <ToolbarItem location="after"
                                locateInMenu="auto"
                                name="searchPanel"
                            />
                        </Toolbar>
                        <Summary
                            calculateCustomSummary={this.calculateSelectedRowSummary}
                            recalculateWhileEditing={true}
                        >
                            <TotalItem
                                name="SelectedRowsCount"
                                summaryType="custom"
                                // valueFormat="currency"
                                displayFormat={this.props.intl.formatMessage({ id: "ACTIONS.LABEL.SELECTED_COUNT" })}
                                showInColumn="ownerparty"
                            // column={1}
                            />

                        </Summary>
                    </DataGrid>
                </PortletBody>
                <ContractActionCreateModal
                    visible={this.state.showAddActionModal}
                    allowRecurrent={true}
                    onDialogResult={this.onAddActionDialogResult}
                />
                <ContractActionEditModal
                    visible={this.state.showEditActionModal}
                    action={this.state.editingAction}
                    onDialogResult={this.onEditActionDialogResult}
                />
                <a ref={this.importExcelTemplateDownloadLinkRef} href={process.env.PUBLIC_URL + '/files/ContractActionsImportTemplate.xlsx'} download="Contract Actions Import Template"></a>
                <input type='file' id='importFile' accept='.xlsx' ref={this.fileImportInputRef} style={{ display: 'none' }} onChange={this.importFileSelected} />
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
    contractTemplate: store.contract.template,
    userProfile: store.auth.userProfile
});



export default injectIntl(withSnackbar(connect(mapStateToProps)(ContractActions)));
