import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PaymentList from "./List";
import PaymentCreate from "./Create";
import PaymentDetail from "./Detail";

class PaymentRouter extends React.Component {
  constructor(props) {
    super(props);
    this.clauses = props.workflowItem.clauses;

    try {
      this.taxEnabled = props.placeholdersQA.TaxEnabled
        .values.find(phItem => phItem.field === 'TaxEnabled').isActive;
    } catch (error) {
      console.log("TaxEnabled placeholder not available!");
      this.taxEnabled = true;
    }

    try {
      this.mandateAttachment = props.placeholdersQA.MandateAttachment
        .values.find(phItem => phItem.field === 'MandateAttachment').isActive;
    } catch (error) {
      console.log("MandateAttachment placeholder not available!");
      this.mandateAttachment = false;
    }

    try {
      this.poNumberEnabled = props.placeholdersQA.PONumberEnabled
        .values.find(phItem => phItem.field === 'PONumberEnabled').isActive;
    } catch (error) {
      console.log("PONumberEnabled placeholder not available!");
      this.poNumberEnabled = false;
    }

    if (!this.clauses) {
      ///IF UnifiedClauses not defined use legacy placeholders
      if (props.mode === "SUPPLIER" && props.placeholdersQA.ApplicationTypes) {
        this.clauses = props.placeholdersQA.ApplicationTypes.values.filter(item => item.isActive === true);
      } else if (props.mode === "PM" && props.placeholdersQA.PaymentTypes) {
        this.clauses = props.placeholdersQA.PaymentTypes.values.filter(item => item.isActive === true);
      }
    }
    if (props.mode === "SUPPLIER") {
      this.communicateToUser = props.globalResources.PM_NAME;
      this.notificationType = "Application";
      this.documentType = "DocumentApplication";
    } else if (props.mode === "PM") {
      this.communicateToUser = props.globalResources.SUPPLIER_NAME;
      this.notificationType = "Certificate";
      this.documentType = "DocumentCertificate";
    }

    this.notifyingParty = props.workflowItem.notifyingParty;

    this.notificationName = props.workflowItem.notificationName;
    this.abbreviation = props.workflowItem.abbreviation;

  }
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const { workflowItem } = this.props;

    return (
      <>
        <Routes caseSensitive>
          <Route
            path={workflowItem.config.paths.list}
            exact
            element={
              <PaymentList
                {...this.props}
                taxEnabled={this.taxEnabled}
                title={this.notificationName}
                abbreviation={this.abbreviation}
                poNumberEnabled={this.poNumberEnabled}
                clauses={this.clauses}
                notificationType={this.notificationType}
                documentType={this.documentType}
              />
            }
          />
          <Route
            path={workflowItem.config.paths.create}
            exact
            element={
              workflowItem.userFilters.mainNotice.createAllowed ? (
                <PaymentCreate
                  {...this.props}
                  taxEnabled={this.taxEnabled}
                  poNumberEnabled={this.poNumberEnabled}
                  clauses={this.clauses}
                  notificationType={this.notificationType}
                  title={this.notificationName}
                  communicateToUser={this.communicateToUser}
                />) : (
                <Navigate replace to={workflowItem.paths.defaultPath} />
              )
            }
          />
          <Route
            path={'/:notificationid/*'}
            exact
            element={
              <PaymentDetail
                {...this.props}
                taxEnabled={this.taxEnabled}
                mandateAttachment={this.mandateAttachment}
                poNumberEnabled={this.poNumberEnabled}
                clauses={this.clauses}
                title={this.notificationName}
                abbreviation={this.abbreviation}
                documentType={this.documentType}
                communicateToUser={this.communicateToUser}
                notificationType={this.notificationType}
                notifyUserFilter={workflowItem.notifyUserFilter}
              />
            }
          />
        </Routes>
      </>
    );
  }
}


export default (PaymentRouter);
