import React from 'react';
import BaseCreatePage from '../../_CustomComponents/BaseCreatePage';
import { connect } from "react-redux";
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import { Alert } from "react-bootstrap";
import * as projectOrderCrud from '../../../../crud/projectOrder.crud';
import * as instructionToQuoteCrud from '../../../../crud/instructionToQuote.crud';
import * as itqQuotationCrud from '../../../../crud/itqQuotation.crud';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import moment from 'moment';
import { FormattedMessage } from "react-intl";
import { withCreatePageProps } from '../../../../shared/hoc/withCreatePageProps';
import { paymentPeriods, calculationPeriods } from '../../../../shared/lookup';
import Common from '../../../../shared/common';
import { actionCodes } from "../../../../shared/lookup";
class ProjectCreatePage extends BaseCreatePage {
    constructor(props) {
        super(props);
        const { subject } = ((props.location.state || {}).itemCustomProps || {});
        const { state } = props.location;
        this.createDefaultSubject = subject;
        this.createDefaultFields = state;

        this.paymentPeriods = paymentPeriods.map(p => (this.props.intl.formatMessage({ id: "GENERAL.FORMS." + p })));
        this.calculationPeriods = calculationPeriods.map(p => (this.props.intl.formatMessage({ id: "GENERAL.FORMS." + p })));

        this.state = {
            showAlert: true,
            itqs: null,
            itq: null,
            showBonusFields: false
        };
    }

    calculateTotal = (obj) => {
        if (Object.keys(obj).length === 0 && this.state.itq) {
            return this.state.itq.totallistprice + this.state.itq.totalassessed;
        } else {
            const prc1 = obj.pricelisttotal ? obj.pricelisttotal : 0;
            const prc2 = obj.compensationeventtotal ? obj.compensationeventtotal : 0;
            return prc1 + prc2;
        }
    }

    fetchInstructionToQuotes = () => {
        const { companyid, projectid, contractid } = this.props.contract;
        const toStates = ['APMA', 'ARQ', 'APMRQ', 'AQ', 'QA', 'APMR'];
        instructionToQuoteCrud.getContractInstructionToQuotes(companyid, projectid, contractid, '')
            .then(response => {
                let comps = JSON.parse(response.data.aggregatelist);
                comps = comps.filter(ntf => {
                    //NEW LOGIC FOR ASSESSMENT
                    if (toStates.includes(ntf.status)) {
                        return true;
                    }
                    else return false;
                })
                    .map(itm => {
                        const reference = itm.referenceid;
                        let reason = null;
                        if (itm.status === 'APMA') {
                            reason = 'Not Accepted';
                        }
                        else if (itm.status === 'AQ' || itm.status === 'ARQ') {
                            if (new Date(itm.quoterequireddate) < new Date()) {
                                reason = 'Expired';
                            }
                        }

                        return {
                            value: itm.instructiontoquoteid,
                            reference: reference,
                            reason: reason,
                            title: itm.subject,
                            display: `${reference}   {${itm.subject}}` + (reason ? ` [${this.props.intl.formatMessage({ id: "OPTIONS.STATUS." + reason })}]` : '')
                        }
                    });
                this.setState({ itqs: comps });
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });
    }

    fetchInstructionToQuote = (instructiontoquoteid) => {
        const { companyid, projectid, contractid } = this.props.contract;
        itqQuotationCrud.getItqQuotes(companyid, projectid, contractid, instructiontoquoteid)
            .then(response => {
                const itqs = response.data.aggregatelist;
                const itq = itqs ? itqs.filter(r => r.status === 1) : null;
                this.setState({ itq: itq && itq.length ? itq[0] : itq });
            })
            .catch(err => {
                //this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });
    }

    submitHandler = (project) => {
        project.companyid = this.props.contract.companyid;
        project.contractid = this.props.contract.contractid;
        project.projectid = this.props.contract.projectid;
        if (project.startdate) {
            project.startdatedisplay = moment(project.startdate).format('DD/MM/YYYY');
        }
        if (project.completiondate) {
            project.completiondatedisplay = moment(project.completiondate).format('DD/MM/YYYY');
        }

        project.status = 'Not Communicated';
        project.createdate = new Date();

        if (this.props.itqRelationEnabled && project.instructiontoquoteid) {
            project.instructiontoquotereferenceid = this.state.itqs.find(itq => itq.value === project.instructiontoquoteid).reference;
        }
        project.actioncode = actionCodes.CREATE;
        project.notifyingparty = this.props.workflowItem.notifyingParty;
        this.appendRelationship(project);
        Common.PropertyConversionsBeforeNoticeSaveRequest(project, this.props.customFields, this.props.contractUsers);
        projectOrderCrud.createProjectOrder(project)
            .then(response => {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_SUCCESS' }), { variant: 'success', });
                try {
                    const createdID = Number(response.data);
                    this.props.navigate(this.props.workflowItem.paths.basePath + createdID);
                }
                catch {
                    this.props.navigate(this.props.workflowItem.paths.listPath);
                }
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', key: "PersistentFormWarning", persist: true });
            });
    }

    componentDidMount() {
        if (this.createDefaultFields && this.createDefaultFields.itemCustomProps && this.createDefaultFields.itemCustomProps.hasOwnProperty("assetid")) {//if it comes from Create Related Items
            this.fetchInstructionToQuote(this.createDefaultFields.itemBaseProps.refid);
        } else if (this.createDefaultFields) {//if it comes from itqQuotation's 'Send Reply & Create Associated Project Order' button
            this.setState({ itq: this.createDefaultFields.itemCustomProps });
        }

        if (this.props.itqRelationEnabled) {
            this.fetchInstructionToQuotes();
        }
    }

    render() {
        if ((this.props.itqRelationEnabled && !this.state.itqs) || (this.createDefaultFields && this.createDefaultFields.itemBaseProps && !this.state.itq)) {
            return (<PortletSpinner />);
        }

        const { assetid } = ((this.props.location.state || {}).itemCustomProps || {});

        const createFormConfig = {
            currencyCode: this.props.contract.currency,
            buttonResource: "GENERAL.FORMS.BUTTON_CREATE_DRAFT",
            fields: [
                { resource: 'GENERAL.FORMS.FIELD.SUBJECT', dataField: 'subject', editorOptions: { defaultValue: this.state.itq ? this.state.itq.subject : this.createDefaultSubject } },
                { note: this.props.resources.Note }
            ]
        };

        if (this.props.itqRelationEnabled) {
            //AUTO-SELECT ITQ DROPDOWN (IF REDIRECTED FROM CREATE-RELATED BUTTON)
            let defaultItqSelection = null;
            if (this.createDefaultFields?.itemBaseProps?.reftype === 'Itq' && this.state.itqs) {
                const itqOption = this.state.itqs.find(x => x.value == this.createDefaultFields.itemBaseProps.refid);
                if (itqOption) {
                    defaultItqSelection = itqOption.value;
                }
            }

            const selectEditorOptions = { dataSource: this.state.itqs, valueExpr: 'value', displayExpr: 'display', defaultValue: defaultItqSelection };
            
            createFormConfig.fields.push({ label: this.props.itqNotificationName, dataField: 'instructiontoquoteid', required: false, editorType: 'dxSelectBox', editorOptions: selectEditorOptions });
        }

        if (this.props.fields.Description) {
            createFormConfig.fields.push({ label: this.props.fields.Description, dataField: 'description', editorType: 'dxHtmlEditor', editorOptions: { defaultValue: (this.state.itq||{}).description } })
        }
        if (this.props.fields.Comments) {
            createFormConfig.fields.push({ label: this.props.fields.Comments, dataField: 'comments', editorType: 'dxHtmlEditor', editorOptions: { defaultValue: (this.state.itq||{}).comments } })
        }
        if (this.props.fields.ItemsList) {
            createFormConfig.fields.push({ label: this.props.fields.ItemsList, dataField: 'pricelistdescription', editorOptions: { defaultValue: (this.state.itq||{}).itemslist } })
        }
        if (this.props.fields.TotalListPrice) {
            createFormConfig.fields.push({ label: this.props.fields.TotalListPrice, dataField: 'pricelisttotal', editorType: 'dxNumberBox', editorOptions: { defaultValue: (this.state.itq||{}).totallistprice } })
        }
        if (this.props.fields.CompensationDescription) {
            createFormConfig.fields.push({ label: this.props.fields.CompensationDescription, dataField: 'compensationeventdescripton', editorOptions: { defaultValue: (this.state.itq||{}).instructiontoquotedescription } })
        }
        if (this.props.fields.TotalAssessed) {
            createFormConfig.fields.push({ label: this.props.fields.TotalAssessed, dataField: 'compensationeventtotal', editorType: 'dxNumberBox', editorOptions: { defaultValue: (this.state.itq||{}).totalassessed } })
        }
        if (this.props.fields.TotalPrice) {
            createFormConfig.fields.push({ label: this.props.fields.TotalPrice, dataField: 'projectordertotal', editorType: 'dxNumberBox', value: this.calculateTotal, editorOptions: { defaultValue: (this.state.itq||{}).totalprice } })
        }
        if (this.props.fields.StartDate) {
            createFormConfig.fields.push({ label: this.props.fields.StartDate, dataField: 'startdate', editorType: 'dxDateBox', editorOptions: { defaultValue: (this.state.itq||{}).startdate } });
        }
        if (this.props.fields.CompletionDate) {
            const completionDateField = { label: this.props.fields.CompletionDate, dataField: 'completiondate', editorType: 'dxDateBox', editorOptions: { defaultValue: (this.state.itq||{}).completiondate }, validation: { type: 'range', min: new Date(1900, 0, 1), max: new Date(2079, 5, 6), warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_DATE" })} }
            if (this.props.fields.StartDate) {
                completionDateField.validation = { type: 'custom', validationCallback: (obj, value) => (obj.startdate <= value), warning: 'Completion date can not be earlier than start date' }
            }
            createFormConfig.fields.push(completionDateField);
        }
        if (this.props.fields.DelayAmount) {
            createFormConfig.fields.push({ label: this.props.fields.DelayAmount, dataField: 'delaydamagestotal', editorType: 'dxNumberBox', editorOptions: { defaultValue: (this.state.itq||{}).delayamount } })
        }
        if (this.props.fields.DelayPaymentPeriod) {
            createFormConfig.fields.push({ label: this.props.fields.DelayPaymentPeriod, dataField: 'delaydamagestype', editorType: 'dxSelectBox', editorOptions: { dataSource: this.paymentPeriods, defaultValue: (this.state.itq||{}).delaypaymentperiod } })
        }
        if (this.props.fields.BonusRate) {
            createFormConfig.fields.push({ label: this.props.fields.BonusRate, dataField: 'bonusrate', editorOptions: { defaultValue: (this.state.itq||{}).bonusrate } });
        }
        if (this.props.fields.BonusCalculationPeriod) {
            createFormConfig.fields.push({ label: this.props.fields.BonusCalculationPeriod, dataField: 'bonuscalculationperiod', editorType: 'dxSelectBox', editorOptions: { dataSource: this.calculationPeriods, defaultValue: (this.state.itq||{}).bonuscalculationperiod } });
        }

        return (
            <Portlet>
                <PortletHeader title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.CREATE_TITLE' }, { ITEM_NAME: this.props.workflowItem.pageTitle })} />
                <PortletBody style={{ maxWidth: 1000 }}>
                    {this.state.showAlert && (
                        <Alert variant="secondary" onClose={() => this.setState({ showAlert: false })} dismissible>
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_DRAFT" />
                            </p>
                        </Alert>
                    )}
                    {this.props.resources.WarningBanner && (
                        <Alert variant="warning" >
                            <p>
                                {this.props.resources.WarningBanner}
                            </p>
                        </Alert>
                    )}
                    <BiDynamicForm
                        onSubmitHandler={this.submitHandler}
                        config={createFormConfig}
                        customFields={(this.props.customFields || {}).COMMUNICATION}
                        valueChangeHandler={this.fieldChanged}
                        assetFieldsEnabled={this.props.workflowItem.assetFieldsEnabled}
                        assetid={assetid}
                    />

                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    contractUsers: store.contract.users
});

export default withCreatePageProps(connect(mapStateToProps)(ProjectCreatePage));
