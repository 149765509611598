import React from 'react';
import BaseCreatePage from '../../_CustomComponents/BaseCreatePage';
import { connect } from "react-redux";
import QuoteCustomForm from './CustomForm';
import * as quotationCrud from '../../../../crud/quotation.crud';
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import { Alert } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import PageToolbar from '../../_CustomComponents/PageToolbar';
import { withCreatePageProps } from '../../../../shared/hoc/withCreatePageProps';
import Common from '../../../../shared/common';
import { actionCodes } from "../../../../shared/lookup";
import { confirm } from 'devextreme/ui/dialog';

class QuoteCreatePage extends BaseCreatePage {
    constructor(props) {
        super(props);
        // props.parentTabPanel.instance.option('selectedIndex', 2);
        //NAVIGATE TO QUOTE TAB
        if (props.notificationType === '612PM') {
            //INSTRUCTION
            this.timer = setTimeout(() => {
                props.parentTabPanel.instance.option('selectedIndex', 1);
            }, 200);
        }
        else if (props.notificationType === '623CON') {

            this.timer = setTimeout(() => {
                props.parentTabPanel.instance.option('selectedIndex', 2);
            }, 200);
        }
        else if (props.notificationType === '623CLIENT') {

            this.timer = setTimeout(() => {
                props.parentTabPanel.instance.option('selectedIndex', 2);
            }, 200);
        }
        else if (props.notificationType === '623CWQ') {
            this.timer = setTimeout(() => {
                props.parentTabPanel.instance.option('selectedIndex', 1);
            }, 200);
        }
        else if (props.notificationType === '623SCON' || props.notificationType === '623CWN' || props.notificationType === '623CLIENTCWN') {
            //SUPPLIER CONPENSATION (SHORT TYPE)
            this.timer = setTimeout(() => {
                props.parentTabPanel.instance.option('selectedIndex', 2);
            }, 200);
        }
        else if (props.notificationType === '623PM') {
            //PM CONPENSATION
            this.timer = setTimeout(() => {
                props.parentTabPanel.instance.option('selectedIndex', 1);
            }, 200);

        }
        this.state = {
            showAlert: true,
        };
    }
    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }
    confirmPositiveAmount = async (notification) => {
        if (this.props.notificationType === '623CLIENTCWN' || this.props.notificationType === '623CLIENT') {
            if (notification.amount > 0) {
                const warningMessage = this.props.intl.formatMessage({ id: 'GENERAL.FORMS.POSITIVE_AMOUNT_WARNING' });
                const warningMessageLabel = this.props.intl.formatMessage({ id: 'GENERAL.FORMS.POSITIVE_AMOUNT_WARNING_LABEL' });
                let result = await confirm(`<div style="max-width:50vw;">${warningMessage}</div>`, `${warningMessageLabel}`);
                return result;
            }
            
            return true;
        }

        return true;
    }
    submitHandler = async (notification, setSubmitting) => {
        try {
            const positiveAmountConfirmed = await this.confirmPositiveAmount(notification);

            if (!positiveAmountConfirmed) {
                //if not confirmed exit save
                setSubmitting(false);
                return;
            } else {
                notification.companyid = this.props.contract.companyid;
                notification.contractid = this.props.contract.contractid;
                notification.projectid = this.props.contract.projectid;
                notification.type = this.props.notificationType;
                // notification.notificationdatedisplay = moment().format('DD/MM/YYYY');
                notification.compensationeventid = this.props.compensation.compensationeventid;
                notification.effecttoprogram = notification.effect === 'noeffect' ? 1 : 0;
                notification.effecttoprogrammitigated = notification.effect === 'mitigated' ? 1 : 0;
                notification.effecttoprogramnotmitigated = notification.effect === 'notmitigated' ? 1 : 0;
                notification.responsestatus = 2;
                notification.totalpricequotetime = this.props.contract.currentcontractvalue;
                notification.compensationreference = this.props.compensation.referenceid;
                notification.actioncode = actionCodes.CREATE;
                notification.notifyingparty = this.props.workflowItem.notifyingParty;
                notification.replyingparty = this.props.workflowItem.replyingParty;


                Common.PropertyConversionsBeforeNoticeSaveRequest(notification, (this.props.customFieldsCommon || {}), this.props.contractUsers);
                const response = await quotationCrud.saveCompensationQuote(notification);
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_SUCCESS' }), { variant: 'success', });
                const createdID = Number(response.data);
                this.props.navigate(this.props.workflowItem.paths.basePath + createdID);
            }
        }
        catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', key: "PersistentFormWarning", persist: true });
        }

    }


    render() {

        return (
            <Portlet>
                <PortletHeader title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.CREATE_TITLE' }, { ITEM_NAME: this.props.workflowItem.pageTitle })} />
                <PortletBody style={{ maxWidth: 1000 }}>
                    {this.state.showAlert && (
                        <Alert variant="secondary" onClose={() => this.setState({ showAlert: false })} dismissible>
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_DRAFT" />
                            </p>
                        </Alert>
                    )}
                    <QuoteCustomForm
                        {...this.props}
                        onSubmitHandler={this.submitHandler}
                        customFields={(this.props.customFieldsCommon || {}).QUOTE}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    contractUsers: store.contract.users
});

export default withCreatePageProps(connect(mapStateToProps)(QuoteCreatePage));
