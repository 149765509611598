import React from 'react';
import { Popup } from 'devextreme-react/popup';
import { injectIntl } from "react-intl";
import * as relationshipCrud from '../../../../crud/relationship.crud';
import DataGrid, { Scrolling, ColumnFixing, HeaderFilter, FilterPanel } from 'devextreme-react/data-grid';
import { boolOptions, contractUserPartyOptions } from '../../../../shared/lookup';
import sanitizeHtml from 'sanitize-html-react';
import Common from '../../../../shared/common';

class RelationChain extends React.Component {
    constructor() {
        super();
        this.state = {
            relations: null
        };
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.getChainedRelationships();
        }
    }

    getChainedRelationships = async () => {
        relationshipCrud.getChainedRelationships(this.props.contractid, this.props.currentNotificationID, this.props.referenceType)
            .then(response => {
                let relations = response.data;
                relations = relations.filter(r => r.Draft !== 1);
                this.setState({ relations });
            })
            .catch(err => {
                console.log(err);
            });
    }

    onClose = () => {
        this.props.onClose();
    }

    calculateHTML = (cellInfo) => {
        const cleanHTML = sanitizeHtml(cellInfo.Description);
        return Common.HtmlToString(cleanHTML);
    }

    calculateUrl = (cellElement, cellInfo) => {
        const { data } = cellInfo;
        const url = process.env.PUBLIC_URL + this.props.currentWF.paths.basePath + data.PK;
    
        cellElement.innerHTML = `<a href="${url}" style="white-space: normal;"><strong>${data.ReferenceID}</strong></a>`;
    }

    // calculateWFName = (cellInfo) => {
    //     const wfName = this.props.contractTemplate.workflowsQA[cellInfo.WorkflowID]?.title;
    //     return wfName;
    // }

    render() {
        return (
            <Popup
                visible={this.props.isOpen}
                onHiding={this.onClose}
                dragEnabled={true}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.RELATIONSHIP_CHAIN' })}
                width={'90%'}
                height='90vh'
            >
                <DataGrid
                    width='100%'
                    height='100%'
                    dataSource={this.state.relations}
                    keyExpr="PK"
                    hoverStateEnabled={false}
                    rowAlternationEnabled={true}
                    noDataText={'No Data'}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    remoteOperations={false}
                    columns={[
                        {
                        caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.REFERENCE_ID' }),
                        dataField: "ReferenceID",
                        //dataType: "string",
                        //cellRender: this.calculateUrl
                        cellTemplate: this.calculateUrl
                        },
                        // {
                        // caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.NOTIFICATION_ID' }),
                        // dataField: "PK",
                        // dataType: "number"
                        // },
                        // {
                        // caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WORKFLOW_NAME' }),
                        // dataField: "WorkflowID",
                        // dataType: "string",
                        // calculateCellValue: this.calculateWFName
                        // },
                        {
                        caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.NOTIFICATION_TITLE' }),
                        dataField: 'NotificationTitle',
                        dataType: "string"
                        },
                        {
                        caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.SUBJECT' }),
                        dataField: 'Subject',
                        dataType: "string"
                        },
                        {
                        caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.DESCRIPTION' }),
                        dataField: 'Description',
                        dataType: "string",
                        calculateCellValue: this.calculateHTML
                        },
                        {
                        caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.NOTIFIED_BY' }),
                        dataField: "NotifiedBy",
                        dataType: "string"
                        },
                        {
                        caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.DATE_NOTIFIED' }),
                        dataField: "DateNotified",
                        dataType: "date",
                        format: 'dd/MM/yyyy'
                        },
                        // {
                        // caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.SOURCE' }),
                        // dataField: "Source",
                        // dataType: "string",
                        // },
                        // {
                        // caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.DRAFT' }),
                        // dataField: "Draft",
                        // dataType: "string",
                        // lookup: { dataSource: boolOptions, valueExpr: 'value', displayExpr: 'display' }
                        // },
                        {
                        caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.REPLY' }),
                        dataField: "Reply",
                        dataType: "string",
                        lookup: { dataSource: boolOptions, valueExpr: 'value', displayExpr: 'display' }
                        },
                        {
                        caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.SENDING_PARTY' }),
                        dataField: "Party",
                        dataType: "string",
                        lookup: { dataSource: contractUserPartyOptions, valueExpr: 'id', displayExpr: 'display' }
                        }
                    ]}
                    >
                    <HeaderFilter visible={true} />
                    <FilterPanel visible={true} />
                    <ColumnFixing enabled={true} />
                    <Scrolling mode="virtual" showScrollbar={true} useNative={true} columnRenderingMode="virtual" rowRenderingMode="virtual" />
                </DataGrid>
            </Popup>
        );
    }
}

export default injectIntl(RelationChain);