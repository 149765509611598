import React from 'react';
import BaseDetailPage from '../../_CustomComponents/BaseDetailPage';
import ReactToPrint from "react-to-print";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import * as inspectionCrud from '../../../../crud/inspectionDefect.crud';
import * as fileCrud from '../../../../crud/file.crud';
import axios from 'axios';
import ActionModal from '../../_CustomComponents/ActionModal';
import NotifyDocument from './Document';
import ReplyDocument from './DocumentReply';
import { Navigate } from "react-router-dom";
import Relations from '../../_CustomComponents/Relation/Relations';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import { ValidationSummary, ValidationGroup } from 'devextreme-react';
import moment from 'moment';
import { Alert } from "react-bootstrap";
import { addDays } from 'date-fns';
import { FormattedMessage } from "react-intl";
import PageToolbar from '../../_CustomComponents/PageToolbar';
import Common from '../../../../shared/common';
import { withDetailPageProps } from '../../../../shared/hoc/withDetailPageProps';
import NavigationBlocker from '../../../../partials/Tools/NavigationBlocker';
import EmailNotifications from '../../_CustomComponents/EmailNotifications';
import { confirm } from 'devextreme/ui/dialog';

const initialState = {
    notification: null,
    files: null,
    showModal: false,
    saving: false,
    isChanged: false,
    relationCount: 0,
    showMigratedReplyMessage: false,
    //NEW REPLY STATES
    reply: null,
    replyFiles: null,
    isAwaitingReply: false,
    deleting: false,
    postActionUrl: null,
    modalTitle: '',
    modalText: '',
    modalMode: '',
    overrideReplyParty: false,
    overrideSaveDraftReplyAllowed: false,
    overrideCommunicateReplyAllowed: false,
    relations: null,
    selectedClause: null,
    oldReplyRequiredDate: ''
}

class NotifyDetailPage extends BaseDetailPage {
    constructor(props) {
        super(props);
        this.isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'DEV';
        this.isRelationsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true' || process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true';
        this.printNotificationDivRef = React.createRef();
        this.printAcceptanceDivRef = React.createRef();
        this.validatorRef = React.createRef();
        this.validatorName = 'NotificationValidator';
        this.state = { ...initialState };
        const replyOptionsPH = props.placeholders.find(ph => ph.replacementKey === 'ReplyOptions');
        if (replyOptionsPH) {
            this.replyOptions = replyOptionsPH.values.filter(item => item.isActive === true);
        }
    }
    componentDidMount() {
        this.fetchInspectionDefect();
        this.fetchFiles();
        this.fetchReplyFiles();
    }
    componentDidUpdate(prevprops, prevSatate) {
        if (this.props.params.notificationid !== prevprops.params.notificationid) {
            this.setState({ ...initialState }, () => {
                this.fetchInspectionDefect();
                this.fetchFiles();
                this.fetchReplyFiles();
            });
        }
    }
    getMailDescription = () => {
        return this.state.notification.assistancetestserchdefect;
    }
    getSelectedTypeClauseDisplay = () => {
        return this.state.notification.replycomment;
    }

    fetchInspectionDefect = () => {
        inspectionCrud.getInspectionDefect(this.props.params.notificationid)
            .then(response => {
                const notification = response.data;
                if (notification.contractid !== this.props.contract.contractid ||
                    notification.type !== this.props.notificationType) {
                    throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.INVALID_URL' }));
                }
                if (notification.createdate) {
                    notification.createdate = new Date(notification.createdate);
                }
                if (notification.inspectiondate) {
                    notification.inspectiondate = new Date(notification.inspectiondate);
                }
                if (notification.notificationdate) {
                    notification.notificationdate = new Date(notification.notificationdate);
                }
                if (notification.draftreplydate) {
                    notification.draftreplydate = new Date(notification.draftreplydate);
                }
                if (notification.replyrequireddate) {
                    notification.replyrequireddate = new Date(notification.replyrequireddate);
                }


                const awaitingReply = (notification.showtasknotification === 1 && notification.replystatus === 'Awaiting Acceptance' && notification.isreplyrequired);
                const replyItem = {
                    replystatus: notification.draftinspectionstatus,
                    replyaction: notification.draftreplyaction,
                    customfieldvalues: notification.customfieldvalues
                };

                const overrideProps = {
                    overrideReplyParty: false,
                    overrideSaveDraftReplyAllowed: false,
                    overrideCommunicateReplyAllowed: false
                }
                let selectedClause = null;
                if (notification.replycomment && this.props.clauses) {
                    selectedClause = this.props.clauses.find(c => c.display === notification.replycomment);
                    if (selectedClause) {
                    }
                    else {
                        if (notification.showtasknotification !== 1) {
                            notification.replycomment = null;
                            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_CLAUSE_MISSING' }), { variant: 'warning', });
                        }
                    }
                }
                if (this.props.workflowItem.replyEnabled) {
                    notification.replyingparty = this.props.workflowItem.replyingParty;
                    if (selectedClause && selectedClause.overrideReplyingParty) {
                        notification.replyingparty = selectedClause.replyingParty;
                        overrideProps.overrideReplyParty = true;
                        overrideProps.overrideSaveDraftReplyAllowed = this.getOverrideReplyDraftAllowed(notification.replyingparty);
                        overrideProps.overrideCommunicateReplyAllowed = this.getOverrideReplyCommunicateAllowed(notification.replyingparty);
                    }
                }

                this.setState({
                    notification: notification,
                    oldReplyRequiredDate: notification.replyrequireddate,
                    reply: replyItem,
                    isAwaitingReply: awaitingReply,
                    saving: false,
                    isChanged: false,
                    showMigratedReplyMessage: (process.env.REACT_APP_PRODUCT_KEY === 'EA' && notification.replyaction && notification.showtasknotification === 1),
                    relations: notification.relations,
                    relationCount: Common.GetRelationCount(notification, this.props.accessibleWfIds),
                    ...overrideProps
                });
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                }
            });
    }

    fetchFiles = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.notificationid, this.props.documentType)
            const filesList = JSON.parse(data.aggregatelist)
            this.setState({ files: filesList });
            return filesList;
        } catch (err) {
            if (!axios.isCancel(err)) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
        }
    }
    fetchReplyFiles = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.notificationid, this.props.documentType + 'Reply')
            const filesList = JSON.parse(data.aggregatelist)
            this.setState({ replyFiles: filesList });
            return filesList;
        } catch (err) {
            if (!axios.isCancel(err)) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
        }
    }

    printHandler = () => {
        this.printRef.handlePrint();
    }
    printAcceptanceHandler = () => {
        this.printAcceptanceRef.handlePrint();
    }
    validateForm = () => {
        const validationResult = this.validatorRef.current.instance.validate();
        if (!validationResult.isValid) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_MISSING_FIELDS' }), { variant: 'warning', });
        }
        return validationResult.isValid && !this.state.saving;
    }
    showModal = async (mode, postActionNavigateObj) => {
        let selectedClause = null;
        if (this.state.notification.replycomment && this.props.clauses) {
            selectedClause = this.props.clauses.find(c => c.display === this.state.notification.replycomment);
        }

        const notification = { ...this.state.notification };

        if (mode === 'COMMUNICATE') {
            if (this.validateForm()) {
                if (this.props.workflowItem.replyEnabled) {
                    let newReplyRequiredDate = null;
                    
                    if (selectedClause && selectedClause.applyReplyPeriod && selectedClause.hasOwnProperty("replyPeriod")) {
                        newReplyRequiredDate = moment(addDays(new Date(), selectedClause.replyPeriod)).toDate();
                    }
                    else if (this.props.workflowItem.replyPeriodOption === "ASKED_ON_COMMUNICATION_FORM") {
                        //DO NOTHING, "replyrequireddate" ALREADY SET ON FORM
                    }
                    else {
                        // Reply Period is set whats assigned on Workflow Setting
                        newReplyRequiredDate = moment(addDays(new Date(), this.props.workflowItem.replyPeriod)).toDate();
                    }

                    const date1 = moment(this.state.oldReplyRequiredDate).format('YYYY-MM-DD');
                    const date2 = moment(newReplyRequiredDate).format('YYYY-MM-DD');

                    if (notification.replyrequireddate && newReplyRequiredDate && date1 !== date2) {
                        const userApprovalToContinue = await this.confirmUpdatedReplyRequiredDate();
                        if (!userApprovalToContinue) {
                            return; //cancelled
                        }
                    }
                }
                
                let communicateToUser = this.props.globalResources[this.state.notification.replyingparty + "_NAME"];
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_COMMUNICATE_ITEM' }, { ITEM_NAME: this.props.title }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_COMMUNICATING' }, { ITEM_NAME: this.props.title, PARTY_NAME: communicateToUser }),
                    modalMode: mode,
                    postActionUrl: postActionNavigateObj,
                    selectedClause
                });
            }
        }
        else if (mode === 'SAVE_DRAFT') {
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_DRAFT_NOTIFY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
                    modalMode: mode,
                });
            }
        }
        else if (mode === 'DELETE_DRAFT') {
            this.setState({
                showModal: true,
                modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_DELETE_DRAFT_NOTIFY' }),
                modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_DELETING' }),
                modalMode: mode,
            });
        }
        else if (mode === 'REPLY_DRAFT') {
            //show reply tab
            this.tabPanel.instance.option("selectedIndex", 1);
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_REPLY_DRAFT_NOTIFY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
                    modalMode: mode,
                });
            }
        }
        else if (mode === 'REPLY_SEND') {
            //show reply tab
            this.tabPanel.instance.option("selectedIndex", 1);
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SEND_REPLY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_REPLY_SENDING' }, { PARTY_NAME: this.props.globalResources[this.props.workflowItem.notifyingParty + "_NAME"] }),
                    modalMode: mode,
                    selectedClause
                });
            }
        }
    }
    onModalResponse = (response, params) => {
        this.setState({ showModal: false, saving: response === 'OK' }, async () => {
            try {
                if (response === 'OK') {
                    if (this.state.modalMode === 'COMMUNICATE') {
                        const newNotification = { ...this.state.notification };
                        newNotification.createactions = params?.createactions;
                        newNotification.completeactions = params?.completeactions;
                        newNotification.createbyid = this.props.contractUser.accountid;
                        newNotification.showtasknotification = 1;
                        const selectedClause = this.props.clauses.find(item => item.display === newNotification.replycomment);
                        if (!selectedClause) {
                            console.log("Clause selection not found!");
                        }


                        if (!this.props.workflowItem.replyEnabled || (selectedClause && selectedClause.noReply)) {
                            //reply not possible
                            newNotification.isreplyrequired = false;
                            newNotification.replystatus = 'Communicated';
                        }
                        else if (this.props.workflowItem.replyEnabled) {
                            newNotification.isreplyrequired = true;
                            newNotification.replystatus = 'Awaiting Acceptance';
                            if (selectedClause && selectedClause.applyReplyPeriod && selectedClause.hasOwnProperty("replyPeriod")) {
                                newNotification.replyrequireddate = moment(addDays(new Date(), selectedClause.replyPeriod)).toDate();
                            }
                            else if (this.props.workflowItem.replyPeriodOption === "ASKED_ON_COMMUNICATION_FORM") {
                                //DO NOTHING, "replyrequireddate" ALREADY SET ON FORM
                            }
                            else {
                                // Reply Period is set whats assigned on Workflow Setting
                                newNotification.replyrequireddate = moment(addDays(new Date(), this.props.workflowItem.replyPeriod)).toDate();
                            }
                        }

                        const docBodyConfig = this.documentBodyConfig(newNotification, this.props.resources);
                        const docConfig = await this.documentConfig(newNotification, docBodyConfig, this.props.contractUser.name, false, null, null, this.props.workflowItem.form_NotifyCompanyName);
                        newNotification.pdfjsoncontent = JSON.stringify(docConfig);

                        this.saveNotification(newNotification, this.state.modalMode);

                    }
                    else if (this.state.modalMode === 'SAVE_DRAFT') {
                        const newNotification = { ...this.state.notification };
                        newNotification.createbyid = this.props.contractUser.accountid;
                        this.saveNotification(newNotification, this.state.modalMode, params ? params.userid : null);

                    }
                    else if (this.state.modalMode === 'DELETE_DRAFT') {
                        const inspection = { ...this.state.notification };
                        const inspectiondefectid = inspection.inspectiondefectid
                        this.deleteInspectionDraft(inspectiondefectid);

                    }
                    else if (this.state.modalMode === 'REPLY_DRAFT') {
                        const reply = { ...this.state.reply };
                        const newNotification = { ...this.state.notification };
                        newNotification.draftinspectionstatus = reply.replystatus;
                        newNotification.draftreplyaction = reply.replyaction;
                        newNotification.draftreplystatus = 'Drafted';
                        newNotification.draftreplybyid = this.props.contractUser.accountid;
                        if ((params || {}).userid) {
                            newNotification.draftreplytoid = params.userid;
                        }
                        newNotification.draftreplydatedisplay = moment().format('DD/MM/YYYY');
                        for (let key in reply) {
                            if (key.startsWith("CF_")) {
                                newNotification[key] = reply[key];
                            }
                        }
                        this.saveNotification(newNotification, this.state.modalMode, (params || {}).userid);

                    }
                    else if (this.state.modalMode === 'REPLY_SEND') {
                        const reply = { ...this.state.reply };
                        if (!this.replyValidation(reply)) {
                            //FINAL REPLY FIELDS CHECK FAILED
                            throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_REPLY_FIELDS_MISSING' }))
                        }
                        const newNotification = { ...this.state.notification };
                        newNotification.createactions = params?.createactions;
                        newNotification.completeactions = params?.completeactions;
                        newNotification.replystatus = reply.replystatus;
                        newNotification.replyaction = reply.replyaction;
                        newNotification.inspectionbyid = this.props.contractUser.accountid;
                        for (let key in reply) {
                            if (key.startsWith("CF_")) {
                                newNotification[key] = reply[key];
                            }
                        }
                        const docReplyBodyConfig = this.documentReplyBodyConfig(newNotification, this.props.resources, this.replyOptions);
                        let notifyCompanyName = this.props.workflowItem.form_ReplyCompanyName;
                        if (this.state.overrideReplyParty && this.props.workflowItem.form_CompanyLogo === 'PARTY') {
                            notifyCompanyName = Common.GetPartyOrUserCompanyName(this.props.contract, this.props.workflowItem.form_CompanyLogo, newNotification.replyingparty, this.props.userProfile)
                        }
                        const docReplyConfig = await this.documentConfig(newNotification, docReplyBodyConfig, this.props.contractUser.name, true, null, null, notifyCompanyName);
                        newNotification.pdfreplyjsoncontent = JSON.stringify(docReplyConfig);
                        this.saveNotification(newNotification, this.state.modalMode);
                    }
                }
            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                this.setState({ saving: false });
            }

        });
    }
    confirmUpdatedReplyRequiredDate = async () => {
        const warningMessage = this.props.intl.formatMessage({ id: 'GENERAL.WARNING.REPLY_REQUIRED_DATE_UPDATED' });
        const warningMessageLabel = this.props.intl.formatMessage({ id: 'GENERAL.WARNING.REPLY_REQUIRED_DATE_CONFIRMATION' });
        let result = await confirm(`<div style="max-width:50vw;">${warningMessage}</div>`, `${warningMessageLabel}`);
    
        return result;
    }
    replyValidation = (reply) => {
        if (!reply.replystatus) { return false; }
        if (reply.replystatus !== 'Reply Not Required' && !reply.replyaction) { return false; }
        return true;
    }
    documentBodyConfig = (notification, resources) => {
        const config = [
            { resource: 'GENERAL.FORMS.FIELD.SUBJECT', value: notification.assistancetestserchdefect, },
            { resource: 'GENERAL.FORMS.FIELD.CLAUSE', value: notification.replycomment },
        ];
        if (notification.isreplyrequired) {
            config.push({ resource: "GENERAL.FORMS.FIELD.RESPONSE_REQUIRED", value: notification.replyrequireddate });
        }
        config.push(
            { resource: 'GENERAL.FORMS.FIELD.DETAILS', value: notification.inspectiondetails, valueType: 'HTML', align: 'vertical' },
        );
        return config;

    }
    documentReplyBodyConfig = (notification, resources, replyOptions) => {
        let statusDisplay = "UNKNOWN";
        const selectedReply = replyOptions.find(opt => opt.field === notification.replystatus);
        if (selectedReply) {
            statusDisplay = selectedReply.display;
        }

        const config = [
            { resource: 'GENERAL.FORMS.FIELD.SUBJECT', value: notification.assistancetestserchdefect, },
            { resource: 'GENERAL.FORMS.FIELD.CLAUSE', value: notification.replycomment, },
            { resource: "GENERAL.FORMS.FIELD.REPLY", value: statusDisplay },
        ];

        if (notification.replystatus !== 'Reply Not Required') {
            config.push({ resource: "GENERAL.FORMS.FIELD.REPLY_DETAIL", value: notification.replyaction, valueType: 'HTML', align: 'vertical' });
        }
        return config;
    }
    deleteInspectionDraft = async (inspectionid) => {

        try {
            await inspectionCrud.deleteInspectionDraft(inspectionid);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_DELETED' }), { variant: 'success' });
            this.setState({ saving: false, navigateto: this.props.workflowItem.paths.listPath });

        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            this.setState({ saving: false });
        }
    }
    saveNotification = async (notification, action, notifyuserid) => {
        try {

            if (!notification.inspectiondatedisplay && !notification.inspectiondatedisplay && notification.inspectiondate) {
                notification.inspectiondatedisplay = moment(notification.inspectiondate).format('DD/MM/YYYY HH:mm:ss');
            }
            if (!notification.draftreplydatedisplay && notification.draftreplydate) {
                notification.draftreplydatedisplay = moment(notification.draftreplydate).format('DD/MM/YYYY');
            }
            notification.actioncode = action;
            notification.notifyuserlist = this.getNotifyUserListForUpdate(action, notifyuserid);
            Common.PropertyConversionsBeforeNoticeSaveRequest(notification, this.props.customFields, this.props.contractUsers);
            await inspectionCrud.updateInspectionDefect(notification)
            if (this.state.postActionUrl) {
                this.setState({ isChanged: false, saving: false, navigateto: this.state.postActionUrl.pathname });
            } else {
                this.fetchInspectionDefect();
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), { variant: 'success', });

            }
        } catch (error) {
            this.props.enqueueSnackbar(error.toString(), { variant: 'error', });
            this.setState({ saving: false });
        }
    }

    fieldChanged = (field, value, isDxFormValid, additionalData) => {
        //SILENT STATE CHANGE
        //WE DONT WANT COMPONENT RERENDER WİTH THIS
        if (field) {
            if (field === "assetid") {
                this.setState({
                    notification: { ...this.state.notification, [field]: value, asset: { ...additionalData } },
                    isChanged: true
                });
            }
            else if (field === 'replycomment' && this.props.workflowItem.replyEnabled) {
                const selectedClause = this.props.clauses.find(item => item.display === value);
                let newReplyingParty = this.props.workflowItem.replyingParty;
                if (selectedClause && selectedClause.overrideReplyingParty) {
                    newReplyingParty = selectedClause.replyingParty;
                }
                this.setState({ notification: { ...this.state.notification, [field]: value, replyingparty: newReplyingParty }, isChanged: true });
            }
            else {
                this.state.notification[field] = value;
                if (!this.state.isChanged) {
                    this.setState({ isChanged: true });
                }
            }

        }
    }
    replyFieldChanged = (field, value, isDxReplyFormValid, isCheckBoxValue) => {
        //SILENT STATE CHANGE
        //WE DONT WANT COMPONENT RERENDER WİTH THIS
        //this.setState({ notification: { ...this.state.notification, [field]: value } });
        if (field) {
            this.state.reply[field] = value;
            if (!this.state.isChanged) {
                this.setState({ isChanged: true });
            }
        }

    }

    renderToolbar = (documentIsFinal) => {
        const saveDisabled = (this.state.saving || this.state.showModal);
        return (
            <PageToolbar
                key={this.state.notification.version}
                documentIsFinal={documentIsFinal}
                saveDisabled={saveDisabled}
                isAwaitingReply={this.state.isAwaitingReply}
                actions={{
                    showModal: this.showModal,
                    onPrintReply: this.printAcceptanceHandler,
                    onPrint: this.printHandler
                }}
                workflowItem={this.props.workflowItem}
                referenceid={this.state.notification.referenceid}
                contract={this.props.contract}
                match={this.props.match}
                customFieldValues={this.state.notification.customfieldvalues}
                relationParams={{
                    subject: this.getMailDescription(),
                    assetid: this.state.notification.assetid
                }}
                overrideReplyParty={this.state.overrideReplyParty}
                overrideSaveDraftReplyAllowed={this.state.overrideSaveDraftReplyAllowed}
                overrideCommunicateReplyAllowed={this.state.overrideCommunicateReplyAllowed}
                notification={this.state.notification}
                selectedClausetypeDisplay={this.getSelectedTypeClauseDisplay()}
            />
        );

    }
    tabDocument = () => {
        return (
            <NotifyDocument
                {...this.props}
                printDivRef={this.printNotificationDivRef}
                fieldChanged={this.fieldChanged}
                notification={this.state.notification}
                files={this.state.files}
                notificationid={this.props.params.notificationid}
            />
        );
    }
    tabRelations = () => {
        return (
            <Relations
                contractid={this.props.contract.contractid}
                workflowItem={this.props.workflowItem}
                workflowGroupItem={this.props.workflowGroupItem}
                notificationid={Number(this.props.params.notificationid)}
                relations={this.state.notification.relations}
                itemversion={this.state.notification.version}
                subject={this.state.notification.assistancetestserchdefect}
                onCountChange={(count) => this.setState({ relationCount: count })}
                showTags={this.props.contract.specifictags?.length > 0}
            />
        );
    }
    // tabReply = () => {
    //     return (
    //         <div style={{ width: '100%', height: '100%', display: 'flex' }}>
    //             <div style={{ width: '100%', maxWidth: '900px', margin: '20px' }}>
    //                 <Alert variant="secondary" >
    //                     <p>
    //                         {`${this.state.notification.replyaction}`}
    //                     </p>
    //                 </Alert>
    //             </div>
    //         </div>
    //     );
    // }
    tabReply = (documentIsFinal) => {
        const { saveDraftReplyAllowed, communicateReplyAllowed } = this.props.workflowItem.userFilters.replyNotice;
        let canSeeReplyDraft = communicateReplyAllowed || saveDraftReplyAllowed;
        if (this.state.overrideReplyParty) {
            canSeeReplyDraft = this.state.overrideSaveDraftReplyAllowed || this.state.overrideCommunicateReplyAllowed;
        }
        if (!canSeeReplyDraft && this.state.isAwaitingReply) {
            return (
                <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                    <div style={{ width: '100%', maxWidth: '900px', margin: '20px' }}>
                        <Alert variant="warning" >
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_AWAITING_REPLY" values={{ ITEM_NAME: this.props.workflowItem.pageTitle }} />
                            </p>
                        </Alert>
                    </div>
                </div>
            )
        }
        else {
            return (
                <ReplyDocument
                    {...this.props}
                    isAwaitingReply={this.state.isAwaitingReply}
                    printDivRef={this.printAcceptanceDivRef}
                    fieldChanged={this.replyFieldChanged}
                    notification={this.state.notification}
                    reply={this.state.reply}
                    files={this.state.replyFiles}
                    notificationid={this.props.params.notificationid}
                    replyOptions={this.replyOptions}
                    overrideReplyParty={this.state.overrideReplyParty}
                    overrideReplyPartyValue={this.state.notification.replyingparty}
                />
            )
        }
    }
    tabEmailNotification = () => {

        return (
            <EmailNotifications
                {...this.props}
                notificationDate={this.state.notification.notificationdate}
            />
        );

    };
    render() {
        if (!this.state.notification || !this.state.files || !this.state.replyFiles) {
            return (<PortletSpinner />);
        }
        const documentIsFinal = this.state.notification.showtasknotification === 1;
        const showReply = documentIsFinal && this.state.notification.isreplyrequired;
        if (!documentIsFinal && !this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed) {
            return (<Navigate replace to={this.props.workflowItem.paths.defaultPath} />)
        }

        return (
            <Portlet>
                <ReactToPrint
                    ref={el => (this.printRef = el)}
                    trigger={() => (<div style={{ display: 'none' }} ></div>)}
                    content={() => this.printNotificationDivRef.current}
                />
                <ReactToPrint
                    ref={el => (this.printAcceptanceRef = el)}
                    trigger={() => (<div style={{ display: 'none' }} ></div>)}
                    content={() => this.printAcceptanceDivRef.current}
                />
                <NavigationBlocker
                    when={(this.state.isChanged || this.state.saving)}
                    message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}
                />
                {this.state.navigateto &&
                    (
                        <Navigate replace to={this.state.navigateto} state={(this.state.postActionUrl || {}).state} />
                    )
                }
                <PortletHeader
                    title={documentIsFinal ? this.props.workflowItem.pageTitle : this.props.intl.formatMessage({ id: "GENERAL.FORMS.DRAFT_TITLE" }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
                    toolbar={this.renderToolbar(documentIsFinal)}
                />
                <PortletBody>
                    <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
                        <div>
                            <ValidationSummary elementAttr={{ align: "right", paddingBottom: 50 }} id={this.validatorName}></ValidationSummary>
                        </div>
                        <TabPanel
                            ref={(ref) => this.tabPanel = ref}
                            width="100%"
                            deferRendering={false}
                            animationEnabled={true}
                            focusStateEnabled={true}
                            hoverStateEnabled={true}
                            swipeEnabled={false}
                        >
                            <Item icon="file" title={this.props.title} render={this.tabDocument} />
                            <Item icon="rename" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_REPLY' })} render={showReply ? this.tabReply : null} visible={showReply} />
                            {
                                this.isRelationsEnabled && (
                                    <Item icon="share" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_RELATIONS' })} render={this.tabRelations} badge={this.state.relationCount > 0 ? this.state.relationCount : null} />
                                )
                            }
                            <Item
                                icon="bulletlist"
                                title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_EMAIL_NOTIFICATIONS' })}
                                render={documentIsFinal ? this.tabEmailNotification : null} visible={documentIsFinal}
                            />
                        </TabPanel>
                    </ValidationGroup>
                </PortletBody>
                <ActionModal
                    visible={this.state.showModal}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    mode={this.state.modalMode}
                    onDialogResult={this.onModalResponse}
                    workflowItem={this.props.workflowItem}
                    overrideReplyParty={this.state.overrideReplyParty}
                    overrideReplyPartyValue={this.state.notification.replyingparty}
                    selectedClause={this.state.selectedClause}
                />
            </Portlet>
        );
    }
}




export default withDetailPageProps(NotifyDetailPage);