import React from "react";
import BaseDetailPage from "../../_CustomComponents/BaseDetailPage";
import Document from "./Document";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import * as fileCrud from "../../../../crud/file.crud";
import * as contractInformationCrud from "../../../../crud/contractInformation.crud";
import axios from "axios";
import { Button } from "react-bootstrap";
import { ValidationSummary, ValidationGroup } from "devextreme-react";
import { TabPanel, Item } from "devextreme-react/tab-panel";
import moment from "moment";
import Relations from '../../_CustomComponents/Relation/Relations';
import { FormattedMessage } from "react-intl";
import Common from '../../../../shared/common';
import { withDetailPageProps } from '../../../../shared/hoc/withDetailPageProps';

const initialState = {
  notification: null,
  files: null,
  relationCount: 0,
  relations: null
};

class DocumentDetail extends BaseDetailPage {
  constructor(props) {
    super(props);
    this.isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'DEV';
    this.isRelationsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true' || process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true';

    this.validatorRef = React.createRef();
    this.validatorName = "DocumentValidator";
    this.state = { ...initialState };
  }

  componentDidMount() {
    this.fetchDocumentDetail();
    this.fetchFiles();
  }

  componentWillUnmount() {
    this.props.closeSnackbar("PersistentFormWarning");
  }

  componentDidUpdate(prevprops, prevSatate) {
    if (
      this.props.params.notificationid !==
      prevprops.params.notificationid
    ) {
      this.setState({ ...initialState }, () => {
        this.fetchDocumentDetail();
        this.fetchFiles();
      });
    }
  }

  fetchDocumentDetail = async () => {
    await this.props.closeSnackbar("PersistentFormWarning");

    contractInformationCrud
      .getDocument(this.props.params.notificationid)
      .then((response) => {
        const document = response.data;

        if (document.issueddate) {
          document.issueddate = moment(new Date(document.issueddate)).format(
            "DD/MM/YYYY"
          );
        }

        this.setState({
          notification: document,
          relations: document.relations,
          relationCount: Common.GetRelationCount(document, this.props.accessibleWfIds)
        });
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          this.props.enqueueSnackbar(err.toString(), { variant: "error" });
        }
      });
  };

  fetchFiles = () => {
    const { companyid, projectid, contractid } = this.props.contract;

    fileCrud
      .getContractNotificationFiles(
        companyid,
        projectid,
        contractid,
        this.props.params.notificationid,
        "Document"
      )
      .then((response) => {
        this.setState({ files: JSON.parse(response.data.aggregatelist) });
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          this.props.enqueueSnackbar(err.toString(), { variant: "error" });
        }
      });
  };

  fieldChanged = (field, value, isDxFormValid) => {
    //SILENT STATE CHANGE
    //WE DONT WANT COMPONENT RERENDER WİTH THIS
    if (field) {
      this.state.notification[field] = value;
      if (!this.state.isChanged) {
        this.setState({ isChanged: true });
      }
    }
  };

  renderToolbar = () => {
    const toobarButtons = [];

    toobarButtons.push(
      <Button
        key="4"
        onClick={() => this.props.navigate(this.props.workflowItem.paths.listPath)}
        variant="secondary"
        size="sm"
      >
        <i className="fas fa-caret-square-left"></i>
        <FormattedMessage id="GENERAL.FORMS.BUTTON_BACK_REGISTER" />
      </Button>
    );

    return (
      <div
        style={{ display: "flex", flexFlow: "row-reverse", margin: "20px 0px" }}
      >
        {toobarButtons}
      </div>
    );
  };

  tabDocument = () => {
    return (
      <Document
        {...this.props}
        fieldChanged={this.fieldChanged}
        notification={this.state.notification}
        files={this.state.files}
        canNotEdit={this.props.canNotEdit}
        notificationid={this.props.params.notificationid}
      />
    );
  };

  tabRelations = () => {
    return (
      <Relations
        contractid={this.props.contract.contractid}
        workflowItem={this.props.workflowItem}
        workflowGroupItem={this.props.workflowGroupItem}
        notificationid={Number(this.props.params.notificationid)}
        relations={this.state.notification.relations}
        itemversion={this.state.notification.version}
        subject={this.state.notification.title}
        onCountChange={(count) => this.setState({ relationCount: count })}
        showTags={this.props.contract.specifictags?.length > 0}
      />
    );
  }

  render() {
    if (!this.state.notification || !this.state.files) {
      return <PortletSpinner />;
    }

    return (
      <Portlet>
        <PortletHeader
          title={`${this.props.title}`}
          toolbar={this.renderToolbar()}
        />
        <PortletBody>
          <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
            <div>
              <ValidationSummary
                elementAttr={{ align: "right", paddingBottom: 50 }}
                id={this.validatorName}
              ></ValidationSummary>
            </div>
            <TabPanel
              width="100%"
              deferRendering={false}
              animationEnabled={true}
              focusStateEnabled={true}
              hoverStateEnabled={true}
              swipeEnabled={false}
            >
              <Item
                icon="file"
                title={this.props.title}
                render={this.tabDocument}
              />
              {
                this.isRelationsEnabled && (
                  <Item icon="share" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_RELATIONS' })} render={this.tabRelations} badge={this.state.relationCount > 0 ? this.state.relationCount : null} />
                )
              }
            </TabPanel>
          </ValidationGroup>
        </PortletBody>
      </Portlet>
    );
  }
}

export default withDetailPageProps(DocumentDetail);