import axios from "../shared/axios";

export function getContractUsersReport() {
  return axios.get(
    `/api/Administrator/ListContractUsers`
  );
}

export function getContractLeadUsersReport() {
  return axios.get(
    `/api/Administrator/ListContractLeadUsers`
  );
}

export function importFile(data) {
  return axios.post('/api/Administrator/OverrideNoticeJsonContent', data);
}