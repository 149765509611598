import * as contractCrud from "../../crud/contracts.crud";
import * as contractUserCrud from "../../crud/contractUser.crud";
import * as contractOtherPriceCrud from "../../crud/contractOtherPrice.crud";
import * as activityCrud from "../../crud/activity.crud";
import * as dashboardCrud from "../../crud/dashboard.crud";
import * as userCrud from "../../crud/user.crud";
import * as alertCrud from "../../crud/alert.crud";
import * as assetCrud from "../../crud/asset.crud";
import * as authDuck from "./auth.duck";
import * as preferencesDuck from "./preferences.duck";
import Common from '../../shared/common';
import store from "../store";
import { select, put, takeLatest, all, call, } from "redux-saga/effects";
import { adjustContractTemplate } from "../../Templates/TemplateAdjust";
import TemplateHelper from "../../Templates/TemplateHelper";
// import DefaultTemplates from "../../Templates/DefaultTemplates";
import MenuItemsHelper from "../helpers/MenuItemsHelper";
import { propertySummary, actionCodeReducerToCount } from "../helpers/DashboardHelper";
import SystemHelper from "../../shared/systemHelper";
import { contractUserPartyOptionMapID } from "../../shared/lookup";
const manuelActionsEnabled = process.env.REACT_APP_ACTIONS_ENABLED === 'true';

const getContract = (store) => store.contract;
const getPreferences = (store) => store.preferences;
const getAuth = (store) => store.auth;
const getLang = (store) => store.i18n;


export const actionTypes = {
  CONTRACT_SET_PENDING: "[CONTRACT_SET_PENDING]",
  CONTRACT_LOAD_START: "[CONTRACT_LOAD_START]",
  CONTRACT_SET_CONTRACT: "[CONTRACT_SET_CONTRACT]",
  CONTRACT_SET_REFRESH: "[CONTRACT_SET_REFRESH]",
  CONTRACT_SET_USERS: "[CONTRACT_SET_USERS]",
  CONTRACT_SET_USER: "[CONTRACT_SET_USER]",
  // CONTRACT_SET_PICTURE: "[CONTRACT_SET_PICTURE]",
  // CONTRACT_REFRESH_SECONDARY_DATES: "[CONTRACT_REFRESH_SECONDARY_DATES]",
  // CONTRACT_SET_KEY_AND_COMPLETION_DATES: "[CONTRACT_SET_KEY_AND_COMPLETION_DATES]",
  CONTRACT_SET_DASHBOARD_AND_MENU: "[CONTRACT_SET_DASHBOARD_AND_MENU]",
  CONTRACT_REFRESH_DASHBOARD: "[CONTRACT_REFRESH_DASHBOARD]",
  CONTRACT_REFRESH_CONTRACT: "[CONTRACT_REFRESH_CONTRACT]",
  CONTRACT_REFRESH_USERS: "[CONTRACT_REFRESH_USERS]",
  CONTRACT_SET_DASHBOARD_CURRENCY: "[CONTRACT_SET_DASHBOARD_CURRENCY]",

  CONTRACT_FETCH_ACTIVITIES: "[CONTRACT_FETCH_ACTIVITIES]",
  CONTRACT_SET_ACTIVITIES: "[CONTRACT_SET_ACTIVITIES]",

  CONTRACT_FETCH_PAYMENT_RETENTION_LIST: "[CONTRACT_FETCH_PAYMENT_RETENTION_LIST]",
  CONTRACT_SET_PAYMENT_RETENTION_LIST: "[CONTRACT_SET_PAYMENT_RETENTION_LIST]",

  CONTRACT_FETCH_QUOTATION_ACTIVITIES_LIST: "[CONTRACT_FETCH_QUOTATION_ACTIVITIES_LIST]",
  CONTRACT_SET_QUOTATION_ACTIVITIES_LIST: "[CONTRACT_SET_QUOTATION_ACTIVITIES_LIST]",

  CONTRACT_SET_COMPANY_LOGO: "[CONTRACT_SET_COMPANY_LOGO]",

  CONTRACT_SET_TEMPLATE: "[CONTRACT_SET_TEMPLATE]",
  CONTRACT_LOGOUT: "[CONTRACT_LOGOUT] ",

  CONTRACT_SET_REFERENCE: "[CONTRACT_SET_REFERENCE]",
  CONTRACT_SET_SECOND_LEVEL_REFERENCE: "[CONTRACT_SET_SECOND_LEVEL_REFERENCE]",

  CONTRACT_REFRESH_ASSETS: "[CONTRACT_REFRESH_ASSETS]",
  CONTRACT_SET_ASSETS: "[CONTRACT_SET_ASSETS]",
};

const initialContractState = {
  contractid: null,
  contract: null,
  users: null,
  user: null,
  // picture: null,
  // companyLogos: {},
  // secondaryDates: null,
  activities: null,
  // poNumber: null,
  // sopCode: null,
  // price: null,
  // notifications: null,
  dashboard: null,
  template: null,
  lastLoadedEpochTime: null,
  pendingFetch: false,
  menuItems: null,
  quickMenuItems: null,
  sidePanel: null,
  reference: null,
  secondReference: null,
  customFields: null,
  communicationTags: null,
  assets: null,
  dashboardCurrencyCode: null,
  currencyOptions: null
};

export const reducer = (
  state = JSON.parse(JSON.stringify(initialContractState)),
  action
) => {
  switch (action.type) {
    case actionTypes.CONTRACT_SET_PENDING: {
      return {
        ...state,
        pendingFetch: true,
        contractid: action.payload.contractid,
      };
    }
    case actionTypes.CONTRACT_LOAD_START: {
      if (
        state.contract &&
        state.contract.contractid === Number(action.payload.contractid) &&
        action.payload.forceBlank !== true
      ) {
        return {
          ...state,
          pendingFetch: false,
          contractid: action.payload.contractid,
          lastLoadedEpochTime: new Date().getTime(),
        };
      } else {
        const emptyState = JSON.parse(JSON.stringify(initialContractState));
        return {
          ...emptyState,
          pendingFetch: false,
          contractid: action.payload.contractid,
          lastLoadedEpochTime: new Date().getTime(),
        };
      }
    }
    case actionTypes.CONTRACT_SET_CONTRACT: {
      return {
        ...state,
        contract: action.payload.contract,
        customFields: action.payload.customFields,
        communicationTags: action.payload.communicationTags,
        dashboardCurrencyCode: action.payload.dashboardCurrencyCode,
        currencyOptions: action.payload.currencyOptions,
      };
    }
    case actionTypes.CONTRACT_SET_REFRESH: {
      return {
        ...state,
        contract: action.payload.contract,
        customFields: action.payload.customFields,
        communicationTags: action.payload.communicationTags,
        dashboardCurrencyCode: action.payload.dashboardCurrencyCode,
        currencyOptions: action.payload.currencyOptions,
        pendingFetch: false,
        lastLoadedEpochTime: new Date().getTime(),
      };
    }
    case actionTypes.CONTRACT_SET_USERS: {
      return { ...state, users: action.payload.users };
    }
    case actionTypes.CONTRACT_SET_USER: {
      return { ...state, user: action.payload.user };
    }
    case actionTypes.CONTRACT_SET_DASHBOARD_CURRENCY: {
      return { ...state, dashboardCurrencyCode: action.payload.dashboardCurrencyCode };
    }
    // case actionTypes.CONTRACT_SET_PICTURE: {
    //   return { ...state, picture: action.payload.picture };
    // }
    // case actionTypes.CONTRACT_SET_KEY_AND_COMPLETION_DATES: {
    //   return { ...state, secondaryDates: action.payload.secondaryDates };
    // }
    case actionTypes.CONTRACT_SET_ASSETS: {
      return { ...state, assets: action.payload.assets };
    }

    case actionTypes.CONTRACT_SET_ACTIVITIES: {
      return {
        ...state,
        activities: action.payload.activities,
        pendingFetch: false,
      };
    }

    // case actionTypes.CONTRACT_SET_COMPANY_LOGO: {
    //   return {
    //     ...state,
    //     companyLogos: {
    //       ...state.companyLogos,
    //       [action.payload.id]: action.payload.data,
    //     },
    //   };
    // }

    case actionTypes.CONTRACT_SET_TEMPLATE: {
      return { ...state, template: action.payload.template };
    }

    case actionTypes.CONTRACT_SET_DASHBOARD_AND_MENU: {
      return {
        ...state,
        dashboard: action.payload.dashboard,
        pendingActions: action.payload.pendingActions,
        menuItems: action.payload.menuItems,
        quickMenuItems: action.payload.quickMenuItems,
        sidePanel: action.payload.sidePanel,
        dashboardCurrencyCode: action.payload.dashboardCurrencyCode
      };
    }
    case actionTypes.CONTRACT_LOGOUT: {
      return initialContractState;
    }
    case actionTypes.CONTRACT_SET_REFERENCE: {
      return { ...state, reference: action.payload.reference };
    }
    case actionTypes.CONTRACT_SET_SECOND_LEVEL_REFERENCE: {
      return { ...state, secondReference: action.payload.reference };
    }
    default:
      return state;
  }
};
export const scheduledTasks = () => {
  const authStore = store.getState().auth;
  const contractStore = store.getState().contract;
  if (authStore && contractStore) {
    if (authStore.authToken.token && authStore.csrfToken.token) {
      if (contractStore.lastLoadedEpochTime && contractStore.contractid) {
        const refreshIntervalSec = Number(
          process.env.REACT_APP_CONTRACT_CLOCK_INTERVAL
        );
        //CONTRACT WILL RELOAD IF LOADED BEFORE $refreshIntervalSec SECONDS
        if (
          new Date(
            contractStore.lastLoadedEpochTime + refreshIntervalSec * 1000
          ) < new Date()
        ) {
          store.dispatch(actions.refreshContract());
          store.dispatch(actions.refreshDashboard());
        }
      }
    }
  }
};
export const actions = {
  fetchStart: (contractid, forceBlank) => {
    const authStore = store.getState().auth;
    const contractStore = store.getState().contract;

    if (authStore) {
      if (!authStore.authToken.token || !authStore.csrfToken.token) {
        if (!contractStore.pendingFetch) {
          return {
            type: actionTypes.CONTRACT_SET_PENDING,
            payload: { contractid },
          };
        }
        return { type: "" };
      }
    }

    return {
      type: actionTypes.CONTRACT_LOAD_START,
      payload: { contractid, forceBlank },
    };
  },

  setContract: (contract, customFields, communicationTags, dashboardCurrencyCode, currencyOptions) => ({
    type: actionTypes.CONTRACT_SET_CONTRACT,
    payload: { contract, customFields, communicationTags, dashboardCurrencyCode, currencyOptions },
  }),
  setContractRefresh: (contract, customFields, communicationTags, dashboardCurrencyCode, currencyOptions) => ({
    type: actionTypes.CONTRACT_SET_REFRESH,
    payload: { contract, customFields, communicationTags, dashboardCurrencyCode, currencyOptions },
  }),
  setUsers: (users) => ({
    type: actionTypes.CONTRACT_SET_USERS,
    payload: { users },
  }),
  setUser: (user) => ({
    type: actionTypes.CONTRACT_SET_USER,
    payload: { user },
  }),

  // setPicture: (picture) => ({
  //   type: actionTypes.CONTRACT_SET_PICTURE,
  //   payload: { picture },
  // }),
  // setKeyAndCompletionDates: (secondaryDates) => ({
  //   type: actionTypes.CONTRACT_SET_KEY_AND_COMPLETION_DATES,
  //   payload: { secondaryDates },
  // }),
  setAssets: (assets) => ({
    type: actionTypes.CONTRACT_SET_ASSETS,
    payload: { assets },
  }),

  refreshDashboard: () => ({ type: actionTypes.CONTRACT_REFRESH_DASHBOARD }),
  refreshUsers: () => ({ type: actionTypes.CONTRACT_REFRESH_USERS }),
  refreshContract: () => ({ type: actionTypes.CONTRACT_REFRESH_CONTRACT }),
  setDashboardCurrency: (dashboardCurrencyCode) => ({
    type: actionTypes.CONTRACT_SET_DASHBOARD_CURRENCY,
    payload: { dashboardCurrencyCode },
  }),



  fetchActivities: () => {
    return { type: actionTypes.CONTRACT_FETCH_ACTIVITIES };
  },
  setActivities: (activities) => ({
    type: actionTypes.CONTRACT_SET_ACTIVITIES,
    payload: { activities },
  }),

  // setCompanyLogo: (id, data) => ({
  //   type: actionTypes.CONTRACT_SET_COMPANY_LOGO,
  //   payload: { id, data },
  // }),


  setTemplate: (template) => ({
    type: actionTypes.CONTRACT_SET_TEMPLATE,
    payload: { template },
  }),

  setDashboardAndMenu: (dashboard, pendingActions, menuItems, quickMenuItems, sidePanel, dashboardCurrencyCode) => ({
    type: actionTypes.CONTRACT_SET_DASHBOARD_AND_MENU,
    payload: { dashboard, pendingActions, menuItems, quickMenuItems, sidePanel, dashboardCurrencyCode },
  }),
  logout: () => ({ type: actionTypes.CONTRACT_LOGOUT }),
  setReference: (reference) => ({
    type: actionTypes.CONTRACT_SET_REFERENCE,
    payload: { reference },
  }),
  setSecondReference: (reference) => ({
    type: actionTypes.CONTRACT_SET_SECOND_LEVEL_REFERENCE,
    payload: { reference },
  }),
};



export const getLocalUser = (accountid, userList, optionalName) => {
  return new Promise((resolve, reject) => {
    try {
      const globalStore = store.getState();
      const localList = userList ? userList : globalStore.contract.users;


      if (localList) {
        const localUser = localList.find((user) => user.userid === accountid);
        if (localUser) {
          resolve(localUser);
          return;
        }
      }
      userCrud
        .getUser(accountid)
        .then((resp) => {

          if (resp.data) {
            const systemUser = resp.data;
            //convert to an object like "Contract User"
            resolve({
              userid: systemUser.accountid,
              user: systemUser.name,
              usercompany: systemUser.company,

            });
            return;
          } else {
            reject(`AccountID:${accountid} user not found!`);
          }
        })
        .catch((err) => {
          if (optionalName) {
            resolve({
              userid: accountid,
              user: optionalName,
              // companyname: 'Unknown'
            });
          }
          else {
            resolve({
              userid: 1,
              user: "Administrator",
              // companyname: 'Unknown'
            });
          }
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getAssets = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const globalStore = store.getState();
      const localAssets = globalStore.contract.assets;
      if (localAssets) {
        resolve(localAssets);
        return;
      }
      else {
        if (globalStore.contract.contract) {
          const { data: assetsString } = await assetCrud.getContractAssets(
            globalStore.contract.contract.companyid,
            globalStore.contract.contract.projectid,
            globalStore.contract.contract.contractid
          );
          if (assetsString) {
            const assets = JSON.parse(assetsString);
            await store.dispatch({ type: actionTypes.CONTRACT_SET_ASSETS, payload: { assets: assets } });
            resolve(assets);
            return;
          }

        }
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function* saga() {
  yield takeLatest(
    authDuck.actionTypes.AUTH_LOAD_COMPLETED,
    function* contractLoginSaga() {
      try {
        let contractStore = yield select(getContract);
        if (contractStore.pendingFetch) {
          yield put(actions.fetchStart(contractStore.contractid));
        }
      } catch (err) {
        console.log("Error in contractLoginSaga:", err);
      }
    }
  );
  yield takeLatest([actionTypes.CONTRACT_LOAD_START, actionTypes.CONTRACT_REFRESH_CONTRACT], function* contractLoadContractSaga(sagaArgs) {
    try {
      let contractStore = yield select(getContract);


      const { data: contractDetails } = yield contractCrud.getContract(contractStore.contractid);
      let currencyOptions = null;
      if (process.env.REACT_APP_SECONDARY_PRICES_ENABLED === "true") {
        const { data: currencyOptionsResp } = yield contractOtherPriceCrud.getToSelectList(contractStore.contractid);
        currencyOptions = currencyOptionsResp;
      }


      if (contractDetails.scope) {
        try {
          contractDetails.scope = Number(contractDetails.scope);
        } catch (error) { console.log('Unable to convert contract scope proberty to Number', contractDetails.scope) }
      }
      if (contractDetails.tags && contractDetails.tags.categories) {
        contractDetails.tags.categories.forEach(tagCategory => {
          switch (tagCategory.name) {
            case 'Areas':
              contractDetails.areaids = tagCategory.tags.map(tag => tag.tagid);
              break;
            case 'Hubs':
              contractDetails.hubids = tagCategory.tags.map(tag => tag.tagid);
              break;
            case 'Frameworks':
              contractDetails.frameworkids = tagCategory.tags.map(tag => tag.tagid);
              break;
            case 'Lots':
              contractDetails.lotids = tagCategory.tags.map(tag => tag.tagid);
              break;
            case 'ContractOther':
              contractDetails.othercontracttagids = tagCategory.tags.map(tag => tag.tagid);
              break;
            default:
              break;
          }
        });
      }


      let reducedCustomFields = {};
      if (contractDetails.workflowcustomfields) {
        reducedCustomFields = Common.GroupBy(contractDetails.workflowcustomfields, 'workflowid');
        for (let workflowid in reducedCustomFields) {
          reducedCustomFields[workflowid] = Common.GroupBy(reducedCustomFields[workflowid], 'subtype');
        }
      }
      contractDetails.designatedUsers = {};
      contractDetails.designatedUsers.pm = { user: contractDetails.projectmanager, userid: contractDetails.projectmanagerid, usercompany: contractDetails.userpmcompanyname, company: contractDetails.pmcompanyname };
      contractDetails.designatedUsers.supplier = { user: contractDetails.contractmanager, userid: contractDetails.contractmanagerid, usercompany: contractDetails.usercontractcompanyname, company: contractDetails.contractorcompanyname };
      contractDetails.designatedUsers.supervisor = { user: contractDetails.supervisor, userid: contractDetails.supervisorid, usercompany: contractDetails.usersupervisorcompanyname, company: contractDetails.supervisorcompanyname };
      contractDetails.designatedUsers.employer = { user: contractDetails.employer, userid: contractDetails.employerid, usercompany: contractDetails.useremployercompanyname, company: contractDetails.employercompanyname };
      if (sagaArgs.type === actionTypes.CONTRACT_LOAD_START) {
        yield put(actions.setContract(contractDetails, reducedCustomFields, contractDetails.specifictags, contractDetails.currency, currencyOptions));
      }
      else if (sagaArgs.type === actionTypes.CONTRACT_REFRESH_CONTRACT) {
        yield put(actions.setContractRefresh(contractDetails, reducedCustomFields, contractDetails.specifictags, contractDetails.currency, currencyOptions));
      }

    } catch (err) {
      console.log("Error in contractLoadContractSaga:", err);
    }
  }
  );
  // yield takeLatest(actionTypes.CONTRACT_REFRESH_CONTRACT, function* contractRefreshContractSaga() {
  //   try {
  //     let contractStore = yield select(getContract);
  //     const { data: contractDetails } = yield contractCrud.getContract(
  //       contractStore.contractid
  //     );
  //     if (contractDetails.scope) {
  //       try {
  //         contractDetails.scope = Number(contractDetails.scope);
  //       } catch (error) { console.log('Unable to convert contract scope proberty to Number', contractDetails.scope) }
  //     }
  //     if (contractDetails.tags && contractDetails.tags.categories) {
  //       contractDetails.tags.categories.forEach(tagCategory => {
  //         switch (tagCategory.name) {
  //           case 'Areas':
  //             contractDetails.areaids = tagCategory.tags.map(tag => tag.tagid);
  //             break;
  //           case 'Hubs':
  //             contractDetails.hubids = tagCategory.tags.map(tag => tag.tagid);
  //             break;
  //           case 'Frameworks':
  //             contractDetails.frameworkids = tagCategory.tags.map(tag => tag.tagid);
  //             break;
  //           case 'Lots':
  //             contractDetails.lotids = tagCategory.tags.map(tag => tag.tagid);
  //             break;
  //           case 'ContractOther':
  //             contractDetails.othercontracttagids = tagCategory.tags.map(tag => tag.tagid);
  //             break;
  //           default:
  //             break;
  //         }
  //       });
  //     }


  //     let reducedCustomFields = {};
  //     if (contractDetails.workflowcustomfields) {
  //       reducedCustomFields = Common.GroupBy(contractDetails.workflowcustomfields, 'workflowid');
  //       for (let workflowid in reducedCustomFields) {
  //         reducedCustomFields[workflowid] = Common.GroupBy(reducedCustomFields[workflowid], 'subtype');
  //       }
  //     }
  //     contractDetails.designatedUsers = {};
  //     contractDetails.designatedUsers.pm = { user: contractDetails.projectmanager, userid: contractDetails.projectmanagerid, usercompany: contractDetails.userpmcompanyname, company: contractDetails.pmcompanyname };
  //     contractDetails.designatedUsers.supplier = { user: contractDetails.contractmanager, userid: contractDetails.contractmanagerid, usercompany: contractDetails.usercontractcompanyname, company: contractDetails.contractorcompanyname };
  //     contractDetails.designatedUsers.supervisor = { user: contractDetails.supervisor, userid: contractDetails.supervisorid, usercompany: contractDetails.usersupervisorcompanyname, company: contractDetails.supervisorcompanyname };
  //     contractDetails.designatedUsers.employer = { user: contractDetails.employer, userid: contractDetails.employerid, usercompany: contractDetails.useremployercompanyname, company: contractDetails.employercompanyname };

  //     yield put(actions.setContractRefresh(contractDetails, reducedCustomFields, contractDetails.specifictags));
  //   } catch (err) {
  //     console.log("Error in contractRefreshContractSaga:", err);
  //   }
  // }
  // );
  // yield takeLatest(
  //   actionTypes.CONTRACT_LOAD_START,
  //   function* contractLoadPictureSaga() {
  //     try {
  //       let contractStore = yield select(getContract);
  //       const { data: contractPicture } = yield contractCrud.getContractPicture(
  //         contractStore.contractid
  //       );
  //       yield put(actions.setPicture(contractPicture));
  //     } catch {
  //       // console.log('contract picture coulnt be retrieved');
  //       yield put(
  //         actions.setPicture(toAbsoluteUrl("/media/logos/default.png"))
  //       );
  //     }
  //   }
  // );
  yield takeLatest(actionTypes.CONTRACT_SET_USER, function* contractLoadTemplateSaga() {
    try {
      let contractStore = yield select(getContract);
      let langStore = yield select(getLang);
      let authStore = yield select(getAuth);

      if (contractStore.user && contractStore.contract) {
        let contractTemplate = null;
        if (contractStore.contract.templatejson && contractStore.contract.templatejson.length > 0) {
          contractTemplate = TemplateHelper.templateSanitizer(JSON.parse(contractStore.contract.templatejson), contractStore.contract);
          contractTemplate = adjustContractTemplate(contractStore.contract, contractTemplate, contractStore.user, authStore.userProfile, langStore.lang);

          // const { data: pendingActions } = yield alertCrud.getContractPendingActions(
          //   contractStore.contract.companyid,
          //   contractStore.contract.projectid,
          //   contractStore.contract.contractid
          // );

          yield put(actions.setTemplate(contractTemplate));
        } else {
          console.log(
            "contract template not found, default template will be loaded for this contract!"
          );
          alert("contract template not found, default template will be loaded for this contract!");
          // contractTemplate = adjustContractTemplate(
          //   JSON.parse(JSON.stringify(DefaultTemplates[0])),
          //   contractStore.user, contractStore.contract, langStore.lang
          // );
        }

      }
    } catch (error) {
      console.log("contractLoadTemplateSaga error", error);
    }
  }
  );
  yield takeLatest([actionTypes.CONTRACT_SET_TEMPLATE, actionTypes.CONTRACT_REFRESH_DASHBOARD, preferencesDuck.actionTypes.PREFERENCES_SET_PENDING_ACTION_FILTER], function* loadDashboardAndMenuSaga() {
    try {
      let contractStore = yield select(getContract);
      let authStore = yield select(getAuth);
      const preferencesStore = yield select(getPreferences);

      //let langStore = yield select(getLang);

      if (contractStore.user && contractStore.contract && contractStore.template) {
        const dashboardItemResults = yield all([
          call(dashboardCrud.getContractDashboard, contractStore.contract.companyid, contractStore.contract.projectid, contractStore.contract.contractid),
          call(alertCrud.getContractPendingActions, contractStore.contract.companyid, contractStore.contract.projectid, contractStore.contract.contractid)
        ]);
        const { data: contractDashboard } = dashboardItemResults[0];
        let { data: pendingActions } = dashboardItemResults[1];
        //CONVERT pty PROPERTY FROM INT TO STRING
        pendingActions = pendingActions.map((act) => { act.pty = contractUserPartyOptionMapID[act.pty]; return act; });
        const ContractAsideMenu = MenuItemsHelper.getMainMenuItems(authStore.userProfile, contractStore.contract, contractStore.template);
        const QuickActionsMenu = MenuItemsHelper.getContractQuickActionsMenuItems(contractStore.template);
        const SidePanelConfig = MenuItemsHelper.getContractSidePanelConfig(contractStore.template, contractStore.contractid, pendingActions, preferencesStore.pendingActionsShowAll);

        //#region DASHBOARD RESOURCES
        const resources = {
          programmeTitle: 'Programme',
          compensationTitle: 'Compensation Events',
          earlyWarningTitle: 'Early Warnings',
          earlyWarningRiskTitle: 'Early Warnings',
          notificationTitle: 'Notification',
          pmInstructionTitle: 'Instruction',
          displayPlannedCompletionOnLatest: true,
          programmePlannedCompletionLabel: null,
        }
        const PG_WorkflowGroup = contractStore.template.workflowGroups.find(wfg => wfg.id === 104);
        if (PG_WorkflowGroup) {
          resources.programmeTitle = PG_WorkflowGroup.title.toLowerCase();
          if (PG_WorkflowGroup.placeholders) {
            try {
              resources.displayPlannedCompletionOnLatest = PG_WorkflowGroup.placeholders
                .find((ph) => ph.replacementKey === "DashboardDisplayPlannedCompletionOnLatest")
                .values.find((phItem) => phItem.field === "DashboardDisplayPlannedCompletionOnLatest").isActive;
            } catch (error) {
              console.log("DashboardDisplayPlannedCompletionOnLatest placeholder  not available!");
            }
            try {
              const rscPlannedCompletionLabel = PG_WorkflowGroup.placeholders
                .find((ph) => ph.replacementKey === "Resources")
                .values.find((phItem) => phItem.field === "DashboardPlannedCompletionLabel");
              if (rscPlannedCompletionLabel) {
                resources.programmePlannedCompletionLabel = rscPlannedCompletionLabel.display;
              }
            } catch (error) {
              console.log("DashboardPlannedCompletionLabel placeholder  not available!");
            }
          }
        }

        const EW_WorkflowGroup = contractStore.template.workflowGroups.find(wfg => wfg.id === 101);
        if (EW_WorkflowGroup) {
          resources.earlyWarningTitle = EW_WorkflowGroup.title;
        }
        const CEN_WorkflowGroup = contractStore.template.workflowGroups.find(wfg => wfg.id === 103);
        if (CEN_WorkflowGroup) {
          resources.compensationTitle = CEN_WorkflowGroup.title;

          const SupplierCWN_Workflow = CEN_WorkflowGroup.workflows.find(wf => wf.id === 67);
          if (!SupplierCWN_Workflow?.replyEnabled) {
            // Set items with actionCode "COMPENSATION_CWN_AQ" to []
            SidePanelConfig.supplierItems = SidePanelConfig.supplierItems.map(item => {
                if (item.actionCode === "COMPENSATION_CWN_AQ") {
                    item.itemsList = [];
                }
                return item;
            });
          }

          const ClientCWN_Workflow = CEN_WorkflowGroup.workflows.find(wf => wf.id === 75);
          if (!ClientCWN_Workflow?.replyEnabled) {
            // Set items with actionCode "COMPENSATION_CLIENTCWN_AQ" to []
            SidePanelConfig.clientItems = SidePanelConfig.clientItems.map(item => {
                if (item.actionCode === "COMPENSATION_CLIENTCWN_AQ") {
                    item.itemsList = [];
                }
                return item;
            });
          }
        }
        
        contractDashboard.warningCountSupplier = actionCodeReducerToCount(pendingActions, SidePanelConfig.supplierItems) + SidePanelConfig.supplierActionsCount;
        contractDashboard.warningCountSupplierDraft = propertySummary(contractDashboard, SidePanelConfig.supplierDrafts);
        contractDashboard.warningCountSupplierReplyDraft = propertySummary(contractDashboard, SidePanelConfig.supplierReplyDrafts);

        contractDashboard.warningCountPm = actionCodeReducerToCount(pendingActions, SidePanelConfig.pmItems) + SidePanelConfig.pmActionsCount;
        contractDashboard.warningCountPmDraft = propertySummary(contractDashboard, SidePanelConfig.pmDrafts);
        contractDashboard.warningCountPmReplyDraft = propertySummary(contractDashboard, SidePanelConfig.pmReplyDrafts);

        contractDashboard.warningCountSupervisor = actionCodeReducerToCount(pendingActions, SidePanelConfig.supervisorItems) + SidePanelConfig.supervisorActionsCount;
        contractDashboard.warningCountSupervisorDraft = propertySummary(contractDashboard, SidePanelConfig.supervisorDrafts);
        contractDashboard.warningCountSupervisorReplyDraft = propertySummary(contractDashboard, SidePanelConfig.supervisorReplyDrafts);

        contractDashboard.warningCountClient = actionCodeReducerToCount(pendingActions, SidePanelConfig.clientItems) + SidePanelConfig.clientActionsCount;
        contractDashboard.warningCountClientDraft = propertySummary(contractDashboard, SidePanelConfig.clientDrafts);
        contractDashboard.warningCountClientReplyDraft = propertySummary(contractDashboard, SidePanelConfig.clientReplyDrafts);
        contractDashboard.warningTotalCount = contractDashboard.warningCountSupplier + contractDashboard.warningCountPm + contractDashboard.warningCountSupervisor + contractDashboard.warningCountClient;
        contractDashboard.resources = resources;
        //#endregion




        yield put(actions.setDashboardAndMenu(contractDashboard, pendingActions, ContractAsideMenu, QuickActionsMenu, SidePanelConfig, contractStore.contract.currency));

        // yield put(actions.setDashboard(contractDashboard));


        // yield put(actions.setContractMenus(ContractAsideMenu, QuickActionsMenu, SidePanelConfig));

        // yield put(actions.setPendingActions(pendingActions));


      }
    } catch (error) {
      console.log("loadDashboardAndMenuSaga error", error);
    }
  }
  );

  yield takeLatest(actionTypes.CONTRACT_SET_CONTRACT, function* contractLoadUserSaga() {
    try {
      let contractStore = yield select(getContract);

      if (contractStore.contract) {
        const { data: contractUser } = yield userCrud.getContractUser(
          contractStore.contract.companyid,
          contractStore.contract.projectid,
          contractStore.contract.contractid
        );
        // const authStore = yield select(getAuth);
        yield put(actions.setUser(contractUser));
        SystemHelper.appcuesUpdate();
      }
    } catch (err) {
      console.log("Error in contractLoadUserSaga:", err);
    }
  }
  );
  yield takeLatest([actionTypes.CONTRACT_LOAD_START, actionTypes.CONTRACT_REFRESH_USERS], function* contractLoadUsersSaga() {
    try {
      let contractStore = yield select(getContract);
      const { data: contractUsers } = yield contractUserCrud.getContractUsers(contractStore.contractid);
      yield put(actions.setUsers(contractUsers));
    } catch (err) {
      console.log("Error in contractLoadUsersSaga:", err);
    }
  }
  );

  yield takeLatest(actionTypes.CONTRACT_FETCH_ACTIVITIES, function* contractLoadActivitiesSaga() {
    try {
      let contractStore = yield select(getContract);
      if (contractStore.contract) {
        const { data: activities } = yield activityCrud.getContractActivities(
          contractStore.contract.companyid,
          contractStore.contract.projectid,
          contractStore.contract.contractid
        );
        yield put(actions.setActivities(JSON.parse(activities.aggregatelist)));
      }
    } catch (err) {
      console.log("Error in contractLoadActivitiesSaga:", err);
    }
  }
  );



  // yield takeLatest([actionTypes.CONTRACT_SET_CONTRACT, actionTypes.CONTRACT_REFRESH_SECONDARY_DATES],
  //   function* contractLoadKeyAndCompletionDatesSaga() {
  //     try {
  //       let contractStore = yield select(getContract);
  //       if (contractStore.contract) {
  //         const { data: dates } = yield activityCrud.getContractKeyAndCompletionDates(
  //           contractStore.contract.companyid,
  //           contractStore.contract.projectid,
  //           contractStore.contract.contractid
  //         );
  //         yield put(actions.setKeyAndCompletionDates(JSON.parse(dates.aggregatelist)));
  //       }
  //     } catch (err) {
  //       console.log("Error in contractLoadKeyAndCompletionDatesSaga:", err);
  //     }
  //   }
  // );
  // yield takeLatest([actionTypes.CONTRACT_SET_CONTRACT, actionTypes.CONTRACT_REFRESH_ASSETS],
  //   function* contractLoadAssetsSaga() {
  //     if (process.env.REACT_APP_ASSETS_ENABLED === 'true') {
  //       try {
  //         let contractStore = yield select(getContract);
  //         if (contractStore.contract) {
  //           const { data: assetsString } = yield assetCrud.getContractAssets(
  //             contractStore.contract.companyid,
  //             contractStore.contract.projectid,
  //             contractStore.contract.contractid
  //           );
  //           yield put(actions.setAssets(JSON.parse(assetsString)));
  //         }
  //       } catch (err) {
  //         console.log("Error in contractLoadAssetsSaga:", err);
  //       }
  //     }
  //   }
  // );
  /*yield takeLatest(
    actionTypes.CONTRACT_SET_CONTRACT,
    function* contractLoadActivitiesSaga() {
      try {
        let contractStore = yield select(getContract);
        if (contractStore.contract) {
          const { data: activities } = yield activityCrud.getContractActivities(
            contractStore.contract.companyid,
            contractStore.contract.projectid,
            contractStore.contract.contractid
          );
          yield put(actions.setActivities(JSON.parse(activities)));
        }
      } catch (err) {
        console.log("Error in contractLoadActivitiesSaga:", err);
      }
    }
  );*/
  // yield takeLatest(
  //   actionTypes.CONTRACT_SET_CONTRACT,
  //   function* contractLoadNotificationsSaga() {
  //     try {
  //       let contractStore = yield select(getContract);
  //       if (contractStore.contract) {
  //         const {
  //           data: notifications,
  //         } = yield inspectionDefectCrud.getContractNotifications(
  //           contractStore.contract.companyid,
  //           contractStore.contract.projectid,
  //           contractStore.contract.contractid
  //         );
  //         yield put(actions.setNotifications(JSON.parse(notifications)));
  //       }
  //     } catch (err) {
  //       console.log("Error in contractLoadNotificationsSaga:", err);
  //     }
  //   }
  // );
  // yield takeLatest(
  //   actionTypes.CONTRACT_SET_CONTRACT,
  //   function* contractLoadLogosSaga() {
  //     try {
  //       let contractStore = yield select(getContract);
  //       if (contractStore.contract) {
  //         const uniqueIDs = [];
  //         const companyIDs = [
  //           contractStore.contract.contractorcompanyid,
  //           contractStore.contract.pmcompanyid,
  //           contractStore.contract.supervisorcompanyid,
  //           contractStore.contract.employercompanyid,
  //         ];
  //         companyIDs.forEach((id) => {
  //           if (!uniqueIDs.includes(id)) {
  //             uniqueIDs.push(id);
  //           }
  //         });
  //         const results = yield all(
  //           uniqueIDs.map((id) => {
  //             return call(getCompanyLogo, id);
  //           })
  //         );

  //         yield all(
  //           results.map((result, index) => {
  //             return put(actions.setCompanyLogo(uniqueIDs[index], result));
  //           })
  //         );
  //       }
  //     } catch (err) {
  //       console.log("error in contractLoadLogosSaga", err);
  //     }
  //   }
  // );
}
