import React, { Component } from 'react';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import { injectIntl } from "react-intl";
import * as taskOrderCrud from '../../../../crud/taskOrder.crud';
import * as projectOrderCrud from '../../../../crud/projectOrder.crud';
import Spinner from '../../../../partials/layout/Spinner';
import DataSource from 'devextreme/data/data_source';

const getPlaceholderActiveFields = (placeholders, key) => {
    const configurableFields = placeholders.find(ph => ph.replacementKey === key);

    const fields = {};

    if (configurableFields) {
        configurableFields.values
            .filter(fieldItem => fieldItem.isActive) // Filter out inactive field items
            .forEach(fieldItem => {
                fields[fieldItem.field] = fieldItem.display;
            });
    }

    return fields;
}

export const getConfigurableFields = (certTypes, selectedType, placeholders) => {
    const selectedClause = certTypes.find(item => item.display === selectedType);
    let fields = {};
    if (!selectedClause) {
        return fields;
    }
    switch (selectedClause.effect) {
        case 'COMPLETION':
            fields = getPlaceholderActiveFields(placeholders, 'CompletionFields');
            break;
        case 'SECTIONAL_COMPLETION':
            fields = getPlaceholderActiveFields(placeholders, 'SectionalCompletionFields');
            break;
        case 'TAKING_OVER':
            fields = getPlaceholderActiveFields(placeholders, 'TakingOverFields');
            break;
        case 'DEFECTS':
            fields = getPlaceholderActiveFields(placeholders, 'DefectsFields');
            break;
        case 'TERMINATION':
            fields = getPlaceholderActiveFields(placeholders, 'TerminationFields');
            break;
        case 'OTHER':
            fields = getPlaceholderActiveFields(placeholders, 'OtherFields');
            break;
        case 'TASK_ORDER_START':
            fields = getPlaceholderActiveFields(placeholders, 'TaskOrderStartFields');
            break;
        case 'TASK_ORDER_COMPLETION':
            fields = getPlaceholderActiveFields(placeholders, 'TaskOrderCompletionFields');
            break;
        case 'PROJECT_ORDER_START':
            fields = getPlaceholderActiveFields(placeholders, 'ProjectOrderStartFields');
            break;
        case 'PROJECT_ORDER_COMPLETION':
            fields = getPlaceholderActiveFields(placeholders, 'ProjectOrderCompletionFields');
            break;
        default:
            break;
    }
    return fields;
}

class CertificateCustomForm extends Component {
    constructor(props) {
        super(props);
        this.createDefaultAsset = (((props.location || {}).state || {}).itemCustomProps || {}).assetid;
        this.createDefaultFields = ((props.location || {}).state || {});

        let certTextKey = 'PmCertificateText';

        if (props.mode === 'SUPPLIER') {
            certTextKey = 'SupplierCertificateText';
        }
        else if (props.mode === 'CLIENT') {
            certTextKey = 'ClientCertificateText';
        }
        else if (props.mode === 'SUPERVISOR') {
            certTextKey = 'SupervisorCertificateText';
        }

        this.certText = props.placeholders
            .find(ph => ph.replacementKey === 'Resources')
            .values.find(item => item.field === certTextKey && item.isActive === true);

        this.taskOrderStartItemIsActive = props.certTypes?.find(item => item.effect === 'TASK_ORDER_START')?.isActive === true;
        this.taskOrderCompletionItemIsActive = props.certTypes?.find(item => item.effect === 'TASK_ORDER_COMPLETION')?.isActive === true;
        this.projectOrderStartItemIsActive = props.certTypes?.find(item => item.effect === 'PROJECT_ORDER_START')?.isActive === true;
        this.projectOrderCompletionItemIsActive = props.certTypes?.find(item => item.effect === 'PROJECT_ORDER_COMPLETION')?.isActive === true;

        //VARIABLE NAME NEEDS CHANGING
        let type = (props.item || {}).type;

        let taskOrderOrProjectOrder = null;

        //AUTO-SELECT TASK ORDER SETTINGS (IF REDIRECTED FROM CREATE-RELATED BUTTON)
        this.taskOrderStartIsActive = false;
        if (this.createDefaultFields?.itemBaseProps?.reftype === 'TaskOrder' && this.taskOrderStartItemIsActive) {
            this.taskOrderStartIsActive = true;
            this.defaultTaskOrderID = Number(this.createDefaultFields.itemBaseProps.refid);
            taskOrderOrProjectOrder = 'taskorder';
            type = props.certTypes.find(item => item.effect === 'TASK_ORDER_START').display;
        }

        //AUTO-SELECT TASK ORDER SETTINGS (IF REDIRECTED FROM CREATE-RELATED BUTTON)
        this.taskOrderCompletionIsActive = false;
        if (this.createDefaultFields?.itemBaseProps?.reftype === 'TaskOrder' && this.taskOrderCompletionItemIsActive) {
            this.taskOrderCompletionIsActive = true;
            this.defaultTaskOrderID = Number(this.createDefaultFields.itemBaseProps.refid);
            taskOrderOrProjectOrder = 'taskorder';
            type = props.certTypes.find(item => item.effect === 'TASK_ORDER_COMPLETION').display;
        }

        //AUTO-SELECT PROJECT ORDER SETTINGS (IF REDIRECTED FROM CREATE-RELATED BUTTON)
        this.projectOrderStartIsActive = false;
        if (this.createDefaultFields?.itemBaseProps?.reftype === 'ProjectOrder' && this.projectOrderStartItemIsActive) {
            this.projectOrderStartIsActive = true;
            this.defaultProjectOrderID = Number(this.createDefaultFields.itemBaseProps.refid);
            taskOrderOrProjectOrder = 'projectorder';
            type = props.certTypes.find(item => item.effect === 'PROJECT_ORDER_START').display;
        }

        //AUTO-SELECT PROJECT ORDER SETTINGS (IF REDIRECTED FROM CREATE-RELATED BUTTON)
        this.projectOrderCompletionIsActive = false;
        if (this.createDefaultFields?.itemBaseProps?.reftype === 'ProjectOrder' && this.projectOrderCompletionItemIsActive) {
            this.projectOrderCompletionIsActive = true;
            this.defaultProjectOrderID = Number(this.createDefaultFields.itemBaseProps.refid);
            taskOrderOrProjectOrder = 'projectorder';
            type = props.certTypes.find(item => item.effect === 'PROJECT_ORDER_COMPLETION').display;
        }

        if (props.item?.type !== undefined && (props.item?.type === props.certTypes.find(item => item.effect === 'TASK_ORDER_START')?.display || props.item?.type === props.certTypes.find(item => item.effect === 'TASK_ORDER_COMPLETION')?.display)) {
            taskOrderOrProjectOrder = 'taskorder';
        } else if (props.item?.type !== undefined && (props.item?.type === props.certTypes.find(item => item.effect === 'PROJECT_ORDER_START')?.display || props.item?.type === props.certTypes.find(item => item.effect === 'PROJECT_ORDER_COMPLETION')?.display)) {
            taskOrderOrProjectOrder = 'projectorder';
        }

        //THIS VARIABLE NAME NEEDS TO ME MORE MEANINGFUL
        //PROBABLY AUTO-SELECT DEFAULT TYPE SELECTION VALUE
        this.type = type;

        const fields = getConfigurableFields(props.certTypes, type, props.placeholders);

        this.state = {
            configurableFields: fields,
            taskOrderOptions: null,
            projectOrderOptions: null,
            taskOrderOrProjectOrderSelected: taskOrderOrProjectOrder,
            item: props.item
        }
    }

    componentDidMount() {
        if (this.props.item?.taskorderid || this.taskOrderStartIsActive || this.taskOrderCompletionIsActive) {
            this.fetchTaskOrders();
        } else if (this.props.item?.projectorderid || this.projectOrderStartIsActive || this.projectOrderCompletionIsActive) {
            this.fetchProjectOrders();
        }
    }

    fetchTaskOrders = async () => {
        try {
            const { contractid } = this.props.contract;
            const { data: responseObj } = await taskOrderCrud.getTaskOrdersToSelectList(contractid);
            //COVERTED TO DATASOURCE WITH FD-11007 DUE TO PERFORMENCE ISSUES AFTER 3K ITEMS
            const taskOrderOptions = new DataSource({
                store: {
                    type: 'array',
                    data: responseObj,
                    key: 'value'
                }
            });
            this.setState({ taskOrderOptions });
        } catch (error) {
            console.log(error)
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }
    }

    fetchProjectOrders = async () => {
        try {
            const { contractid } = this.props.contract;
            const { data: responseObj } = await projectOrderCrud.getProjectOrdersToSelectList(contractid);
            const projectOrderOptions = responseObj.map(TO => ({
                value: TO.projectorderid,
                reference: TO.referenceid,
                display: `${TO.referenceid} - ${TO.subject}`
            }));

            this.setState({ projectOrderOptions });
        } catch (error) {
            console.log(error)
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }
    }

    fieldChanged = (field, value, isDxFormValid, selectedItem) => {
        if (field) {
            if (field === 'type') {
                const selectedClause = this.props.certTypes.find(item => item.display === value);

                let taskOrderOrProjectOrderSelected = null;

                if (selectedClause) {
                    if (selectedClause.effect === 'TASK_ORDER_START' || selectedClause.effect === 'TASK_ORDER_COMPLETION') {
                        if (!this.state.taskOrderOptions) {
                            this.fetchTaskOrders();
                        }

                        taskOrderOrProjectOrderSelected = 'taskorder';
                    } else if (selectedClause.effect === 'PROJECT_ORDER_START' || selectedClause.effect === 'PROJECT_ORDER_COMPLETION') {
                        if (!this.state.projectOrderOptions) {
                            this.fetchProjectOrders();
                        }

                        taskOrderOrProjectOrderSelected = 'projectorder';
                    }
                }

                const fields = getConfigurableFields(this.props.certTypes, value, this.props.placeholders);
                this.setState({ configurableFields: fields, taskOrderOrProjectOrderSelected, item: { ...this.state.item, taskorderid: null, projectorderid: null } });
            }
            if (this.props.valueChangeHandler) {
                this.props.valueChangeHandler(field, value, isDxFormValid, selectedItem);

                if (field === 'taskorderid') {
                    if (value) {
                        this.props.valueChangeHandler('taskorderid', value, isDxFormValid, selectedItem);
                        if (selectedItem) {
                            this.props.valueChangeHandler('taskorderreference', selectedItem.referenceid, isDxFormValid, selectedItem);
                        }
                    }
                    else {
                        this.props.valueChangeHandler('taskorderid', value, isDxFormValid, selectedItem);
                        this.props.valueChangeHandler('taskorderreference', "", isDxFormValid, selectedItem);
                    }

                    this.setState({ item: { ...this.state.item, taskorderid: value, projectorderid: null } });
                }
                if (field === 'projectorderid') {
                    if (value) {
                        this.props.valueChangeHandler('projectorderid', value, isDxFormValid, selectedItem);
                        if (selectedItem) {
                            this.props.valueChangeHandler('projectorderreference', selectedItem.reference, isDxFormValid, selectedItem);
                        }
                    }
                    else {
                        this.props.valueChangeHandler('projectorderid', value, isDxFormValid, selectedItem);
                        this.props.valueChangeHandler('projectorderreference', "", isDxFormValid, selectedItem);
                    }

                    this.setState({ item: { ...this.state.item, projectorderid: value, taskorderid: null } });
                }
            }
        }
    }
    onSubmitHandler = (certificate, setSubmitting) => {
        if (this.props.onSubmitHandler) {
            this.props.onSubmitHandler(certificate, setSubmitting);
        }
    }

    render() {
        const { taskOrderOptions, projectOrderOptions, configurableFields } = this.state;

        if (((this.taskOrderStartIsActive || this.taskOrderCompletionIsActive) && !taskOrderOptions) || ((this.projectOrderStartIsActive || this.projectOrderCompletionIsActive) && !projectOrderOptions)/* || (!configurableFields)*/) {
            return (<Spinner />);
        }

        const createFormConfig = {
            buttonResource: "GENERAL.FORMS.BUTTON_CREATE_DRAFT",
            fields: [
                { resource: 'GENERAL.FORMS.FIELD.SUBJECT', dataField: 'subject' }
            ]
        };


        createFormConfig.fields.push({ resource: 'GENERAL.FORMS.FIELD.TYPE', dataField: 'type', editorType: 'dxSelectBox', editorOptions: { dataSource: this.props.certTypes,  valueExpr: 'display', displayExpr: 'display', defaultValue: this.type } });

        createFormConfig.fields.push({ label: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.TASKORDER' }, { TASK_ORDER: this.props.globalResources.WF_38_NOTIFICATION_NAME }), dataField: 'taskorderid', editorType: 'dxSelectBox', visible: this.state.taskOrderOrProjectOrderSelected === 'taskorder' || (this.state.item?.taskorderid !== null && this.state.item?.taskorderid !== undefined), editorOptions: { dataSource: this.state.taskOrderOptions,  valueExpr: 'taskorderid', displayExpr: 'display', defaultValue: this.defaultTaskOrderID } });
        createFormConfig.fields.push({ label: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.PROJECTORDER' }, { PROJECT_ORDER: this.props.globalResources.WF_72_NOTIFICATION_NAME }), dataField: 'projectorderid', editorType: 'dxSelectBox', visible: this.state.taskOrderOrProjectOrderSelected === 'projectorder' || (this.state.item?.projectorderid !== null && this.state.item?.projectorderid !== undefined), editorOptions: { dataSource: this.state.projectOrderOptions,  valueExpr: 'value', displayExpr: 'display', defaultValue: this.defaultProjectOrderID } });

        createFormConfig.fields.push({ note: this.certText.display });

        if (configurableFields) {
            createFormConfig.fields.push({ label: configurableFields.CertifiedDate, dataField: 'certifieddate', editorType: 'dxDateBox', visible: !!configurableFields.CertifiedDate, validation: { type: 'range', min: new Date(1900, 0, 1), max: new Date(2079, 5, 6), warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_DATE" })} });
            createFormConfig.fields.push({ label: configurableFields.Description, dataField: 'description', editorType: 'dxHtmlEditor', visible: !!configurableFields.Description });
            createFormConfig.fields.push({ label: configurableFields.Works, dataField: 'works', editorType: 'dxHtmlEditor', visible: !!configurableFields.Works });
            createFormConfig.fields.push({ label: configurableFields.Defects, dataField: 'defects', editorType: 'dxHtmlEditor', visible: !!configurableFields.Defects });
        }

        return (
            <BiDynamicForm
                item={this.props.item}
                config={createFormConfig}
                onSubmitHandler={this.props.onSubmitHandler ? this.onSubmitHandler : null}
                valueChangeHandler={this.fieldChanged}
                customFields={this.props.customFields}
                assetFieldsEnabled={this.props.assetFieldsEnabled}
                assetid={this.createDefaultAsset}
            />
        );
    }
}

const mapStateToProps = store => ({
    // contract: store.contract.contract,
    // globalResources: store.contract.template.resources,
});

export default injectIntl(withSnackbar(connect(mapStateToProps)(CertificateCustomForm)));
