/* eslint-disable no-eval, react/no-direct-mutation-state */
import React from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { connect } from "react-redux";
import BiDynamicForm from '../../../partials/layout/BiDynamicForm';
import { ValidationSummary, ValidationGroup } from 'devextreme-react';
import { injectIntl } from "react-intl";
import TemplateHelper from '../../../Templates/TemplateHelper';
import { CheckBox } from 'devextreme-react/check-box';
import { Form, Item as FormItem, SimpleItem, RequiredRule, GroupItem, RangeRule } from 'devextreme-react/form';
import { typeOptions as actionTypes } from "../../admin/Contracts/Contract/ContractActions";
import ActionModalPostActions from "./ActionModalPostAction";

export const RESPONSE = {
    OK: 'OK',
    CANCEL: 'CANCEL',
    CREATE: 'CREATE'
};

class ActionModal extends React.Component {
    constructor(props) {
        super(props);
        this.validatorRef = React.createRef();
        this.formRef = React.createRef();
        this.validatorName = 'ReplyDraftModalValidator';
        this.state = {
            users: null,
            accountid: null,
            saveButtonEnabled: false,
            selectedPostActions: null
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.visible !== this.props.visible) {
            if (this.props.visible) {
                this.saved = false;
                if ((this.props.mode === 'REPLY_DRAFT' || this.props.mode === 'SAVE_DRAFT')) {
                    this.loadUsers();
                }
                else {
                    this.setState({ saveButtonEnabled: true });
                }
            }
            else {
                this.setState({ saveButtonEnabled: false });
            }

        }
    }
    loadUsers = () => {
        let filteredUsers = this.props.contractUsers;
        let logicExpression = null;
        //filter if userlimit is null or greater than the entered amount
        if (this.props.workflowItem.financialLimitEnabled && this.props.userLimit) {
            //#TODO: These users should be fetched from API
            filteredUsers = this.props.contractUsers.filter(user => this.props.contract.financiallimitenabled === 1 ? (!user.contractuserlimit && user.contractuserlimit !== 0) || user.contractuserlimit > this.props.userLimit : (!user.userlimit && user.userlimit !== 0) || user.userlimit > this.props.userLimit);

        }
        if (this.props.mode === 'REPLY_DRAFT') {
            //USERS WITH REPLY_SEND PREVILIGES
            let filterStr = this.props.workflowItem.userFilters.replyNotice.communicateReplyFilter;
            if (this.props.overrideReplyParty) {
                filterStr = TemplateHelper.GetDefaultFilter_OverrideCommunicateReplyFilter(this.props.overrideReplyPartyValue);
            }
            logicExpression = TemplateHelper.ConvertDxFilterExpToLogicExp(JSON.parse(filterStr), 'user');
        }
        else if (this.props.mode === 'SAVE_DRAFT') {
            //USERS WITH COMMUNICATE PREVILIGES
            logicExpression = TemplateHelper.ConvertDxFilterExpToLogicExp(JSON.parse(this.props.workflowItem.userFilters.mainNotice.communicateFilter), 'user');
        }

        //FILTER USERS DEPENDING ON DYNAMIC LOGIC EXPRESSION OF SETTING
        filteredUsers = filteredUsers.map(u => ({ party: u.usergroup, rolepermissions: u.rolepermissions, accountid: u.accountid, display: `${u.user} (${u.useremail})` }))
            .filter(user => eval(logicExpression));

        this.setState({ users: filteredUsers.sort((a, b) => a.display.localeCompare(b.display)), accountid: null, saveButtonEnabled: true });
    }
    onClose = (e) => {
        if (this.props.visible) {
            this.props.onDialogResult(RESPONSE.CANCEL);
        }
    }

    getPostActionSelections = () => {
        if (this.props.mode === 'COMMUNICATE') {
            if (this.formRef.current?.instance) {
                const actionsFormObj = this.formRef.current.instance.option("formData");
                let selectedPostActions = [];
                if (this.props.selectedClause?.postActions?.length > 0) {
                    selectedPostActions = this.props.selectedClause.postActions.filter(act => (!!actionsFormObj[act.actionType] && act.action==="CREATE"));
                }

                let selectedClearActions = Object.entries(actionsFormObj).filter(([k, v]) => k.startsWith("CLEAR_") && v === true).map(([k, v]) => Number(k.substring(6)));
                return { createactions: selectedPostActions, completeactions: selectedClearActions };
            }
        }
    }
    saveItem = () => {

        let props;
        if (this.props.mode === 'REPLY_DRAFT' || this.props.mode === 'SAVE_DRAFT') {
            if (!this.validateForm()) {
                return;
            }
            props = { userid: this.state.accountid };
        }
        else {
            props = this.getPostActionSelections();
        }
        if (!this.saved) {
            this.saved = true;
            this.setState({ saveButtonEnabled: false }, () => {
                this.props.onDialogResult(RESPONSE.OK, props);
            });
        }
    }
    groupCaptionNamedRender = (caption, iconName) => {
        return (
            <React.Fragment>
                <i className={`dx-icon dx-icon-${iconName}`}></i>
                <span>{caption}</span>
            </React.Fragment>
        )
    };
    secondaryButtonClicked = () => {
        let props;
        if (this.props.mode === 'COMMUNICATE') {
            props = this.getPostActionSelections();
        }
        if (this.props.mode === 'SAVE_DRAFT' || this.props.mode === 'REPLY_DRAFT') {
            this.props.onDialogResult(RESPONSE.OK);
        } else if (this.props.mode === 'REPLY_SEND' && this.props.secondaryButtonEnabled) {
            this.props.onDialogResult(RESPONSE.CREATE, props);
        }
        else {
            this.props.onDialogResult(RESPONSE.CANCEL);
        }
    }
    validateForm = () => {
        const validationResult = this.validatorRef.current.instance.validate();
        return validationResult.isValid;
    }
    onValueChanged = (field, value) => {
        if (field === 'user') {
            this.state.accountid = value;
        }
    }
    generateForm = () => {
        if (this.props.mode === 'REPLY_DRAFT' || this.props.mode === 'SAVE_DRAFT') {
            const userConfig = {
                fields: [
                    { resource: "GENERAL.WARNING.SELECT_NOTIFY_USER", dataField: 'user', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.users, valueExpr: 'accountid', displayExpr: 'display' } },
                ]
            };
            return (
                <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
                    <BiDynamicForm
                        config={userConfig}
                        valueChangeHandler={this.onValueChanged}
                    />
                    <div>
                        <ValidationSummary elementAttr={{ align: "right", paddingBottom: 50 }} id={this.validatorName}></ValidationSummary>
                    </div>
                </ValidationGroup>
            )
        }
        //&& !this.props.workflowItem.replyEnabled)
        if ((this.props.mode === 'COMMUNICATE' || this.props.mode === 'REPLY_SEND')) {
            return <ActionModalPostActions
                {...this.props}
                formRef={this.formRef}
            />
        }

        else { return null; }
    }
    render() {

        let primaryButtonText;
        let primaryButtonStylingMode = "contained";
        let primaryButtonType = "success";
        let secondaryuttonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_CANCEL" });

        switch (this.props.mode) {
            case 'COMMUNICATE':
                primaryButtonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_COMMUNICATE" });
                break;
            case 'DELETE_DRAFT':
                primaryButtonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_DELETE" });
                primaryButtonStylingMode = "outlined";
                primaryButtonType = "danger";
                break;
            case 'REPLY_DRAFT':
                primaryButtonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_SAVE_REPLY_DRAFT_NOTIFY" });
                secondaryuttonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_ONLY_SAVE" });
                break;
            case 'SAVE_DRAFT':
                primaryButtonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_SAVE_AND_NOTIFY" });
                secondaryuttonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_ONLY_SAVE" });
                break;
            case 'REPLY_SEND':
                primaryButtonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_SEND_REPLY" });
                if (this.props.secondaryButtonEnabled) {
                    secondaryuttonText = `${this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_SEND_REPLY" })} & ${this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_CREATE_ASSOCIATED_NOTICE" }, { ITEM_NAME: this.props.secondaryButtonItemName })}`;
                }
                break;
            default:
                primaryButtonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_COMMUNICATE" });
                break;
        }


        return (

            <Popup
                visible={this.props.visible}
                onHiding={this.onClose}
                dragEnabled={true}
                hideOnOutsideClick={true}
                showTitle={true}
                title={this.props.title}
                width={600}
                height='auto'
                wrapperAttr={{ id: "ActionModal" }}
            >
                <div>
                    <p>
                        <span>{this.props.text}</span>
                    </p>
                    {this.props.visible && (
                        this.generateForm()
                    )
                    }
                </div>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: secondaryuttonText,
                        onClick: this.secondaryButtonClicked,
                        stylingMode: "outlined",
                        type: this.props.secondaryButtonEnabled ? "success" : "normal",
                        elementAttr: { id: "ActionModalSecondaryButton" }
                    }}>
                </ToolbarItem>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: primaryButtonText,
                        disabled: !this.state.saveButtonEnabled,
                        stylingMode: primaryButtonStylingMode,
                        type: primaryButtonType,
                        ...this.props.buttonOptions,
                        onClick: this.saveItem,
                        elementAttr: { id: "ActionModalPrimaryButton" }
                    }}>
                </ToolbarItem>
            </Popup>
        );
    }
}

const mapStateToProps = store => ({
    contractTemplate: store.contract.template,
    contractUsers: store.contract.users,
    contract: store.contract.contract
});


export default injectIntl(connect(mapStateToProps)(ActionModal));