import React from 'react';
import { connect } from "react-redux";
import * as fileCrud from '../../../../crud/file.crud';
import Document from '../../_CustomComponents/DocumentV2';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import BaseDocument from '../../_CustomComponents/BaseDocument';
import { Alert } from "react-bootstrap";
import { withSnackbar } from 'notistack';
import { paymentPeriods } from '../../../../shared/lookup';

class NotifyDocumentPage extends BaseDocument {
    constructor(props) {
        super(props);
        this.rfqRelationRequired = props.rfqRelationRequired;
        this.isAsiteEnabled = process.env.REACT_APP_ASITE_ENABLED === 'true';
        this.paymentPeriods = paymentPeriods.map(p => (this.props.intl.formatMessage({ id: "GENERAL.FORMS." + p })));
    }

    calculateTotal = (obj) => {
        const prc1 = obj.pricelisttotal ? obj.pricelisttotal : 0;
        const prc2 = obj.compensationeventtotal ? obj.compensationeventtotal : 0;
        return prc1 + prc2;
    }

    renderForm = () => {
        const editorFormConfig = {
            buttonResource: "GENERAL.FORMS.BUTTON_CREATE_DRAFT",
            fields: [
                { resource: 'GENERAL.FORMS.FIELD.SUBJECT', dataField: 'subject' },
                { note: this.props.resources.Note },
                // { label: props.resources.DescriptionLabel, dataField: 'description', editorType: 'dxHtmlEditor' },
                // { label: props.resources.ItemsListLabel, dataField: 'pricelistdescription' },
                // { label: props.resources.TotalListPriceLabel, dataField: 'pricelisttotal', editorType: 'dxNumberBox' },
                // { label: props.resources.CompensationDescriptionLabel, dataField: 'compensationeventdescripton' },
                // { label: props.resources.TotalAssessedLabel, dataField: 'compensationeventtotal', editorType: 'dxNumberBox' },
                // { label: props.resources.TotalPriceLabel, dataField: 'taskordertotal', required: true, editorType: 'dxNumberBox', value: calculateTotal },
                // { label: props.resources.StartDateLabel, dataField: 'startdate', editorType: 'dxDateBox' },
                // { label: props.resources.CompletionDateLabel, dataField: 'completiondate', editorType: 'dxDateBox' },
                // { label: props.resources.DelayAmountLabel, dataField: 'delaydamagestotal', editorType: 'dxNumberBox' },
                // { label: props.resources.DelayPaymentPeriodLabel, dataField: 'delaydamagestype', editorType: 'dxSelectBox', editorOptions: { dataSource: ['Daily', 'Weekly'] } },
            ]
        };

        if (this.props.rfqRelationEnabled) {
            const rfqRelationField = { label: this.props.rfqNotificationName, dataField: 'requestforquoteid', required: false, editorType: 'dxSelectBox', editorOptions: { dataSource: this.props.rfqs, valueExpr: 'value', displayExpr: 'display' } };

            if (this.rfqRelationRequired) {
                rfqRelationField.validation = { type: 'custom', validationCallback: (obj, value) => (value), warning: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_RFQ_REQUIRED_COMMUNICATING' }, { TASK_ORDER: this.props.toNotificationName, RFQ: this.props.rfqNotificationName }) }
            }

            editorFormConfig.fields.push(rfqRelationField);
        }
        if (this.props.fields.Description) {
            editorFormConfig.fields.push({ label: this.props.fields.Description, dataField: 'description', editorType: 'dxHtmlEditor' })
        }
        if (this.props.fields.Comments) {
            editorFormConfig.fields.push({ label: this.props.fields.Comments, dataField: 'comments', editorType: 'dxHtmlEditor' })
        }
        if (this.props.fields.ItemsList) {
            editorFormConfig.fields.push({ label: this.props.fields.ItemsList, dataField: 'pricelistdescription' })
        }
        if (this.props.fields.TotalListPrice) {
            editorFormConfig.fields.push({ label: this.props.fields.TotalListPrice, dataField: 'pricelisttotal', editorType: 'dxNumberBox' })
        }
        if (this.props.fields.CompensationDescription) {
            editorFormConfig.fields.push({ label: this.props.fields.CompensationDescription, dataField: 'compensationeventdescripton' })
        }
        if (this.props.fields.TotalAssessed) {
            editorFormConfig.fields.push({ label: this.props.fields.TotalAssessed, dataField: 'compensationeventtotal', editorType: 'dxNumberBox' })
        }
        if (this.props.fields.TotalPrice) {
            editorFormConfig.fields.push({ label: this.props.fields.TotalPrice, dataField: 'taskordertotal', editorType: 'dxNumberBox', value: this.calculateTotal })
        }
        if (this.props.fields.StartDate) {
            editorFormConfig.fields.push({ label: this.props.fields.StartDate, dataField: 'startdate', editorType: 'dxDateBox', validation: { type: 'range', min: new Date(1900, 0, 1), max: new Date(2079, 5, 6), warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_DATE" })} })
        }
        if (this.props.fields.CompletionDate) {
            const completionDateField = { label: this.props.fields.CompletionDate, dataField: 'completiondate', editorType: 'dxDateBox', validation: { type: 'range', min: new Date(1900, 0, 1), max: new Date(2079, 5, 6), warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_DATE" })} }
            if (this.props.fields.StartDate) {
                completionDateField.validation = { type: 'custom', validationCallback: (obj, value) => (obj.startdate <= value), warning: 'Completion date can not be earlier than start date' }
            }
            editorFormConfig.fields.push(completionDateField)
        }

        if (this.props.fields.DelayAmount) {
            editorFormConfig.fields.push({ label: this.props.fields.DelayAmount, dataField: 'delaydamagestotal', editorType: 'dxNumberBox' })
            editorFormConfig.fields.push({ label: this.props.fields.DelayPaymentPeriod, dataField: 'delaydamagestype', editorType: 'dxSelectBox', editorOptions: { dataSource: this.paymentPeriods } })
        }

        return (
            <>
                {this.props.resources.WarningBanner && (
                    <Alert variant="warning" >
                        <p>
                            {this.props.resources.WarningBanner}
                        </p>
                    </Alert>
                )}
                <BiDynamicForm
                    item={this.props.notification}
                    valueChangeHandler={this.props.fieldChanged}
                    config={editorFormConfig}
                    customFields={(this.props.customFields || {}).COMMUNICATION}
                    assetFieldsEnabled={this.props.workflowItem.assetFieldsEnabled}
                />
            </>
        );
    }

    uploadFile = (data, config) => {
        const { companyid, projectid, contractid } = this.props.contract;
        return fileCrud.uploadContractNotificationFiles(companyid, projectid, contractid, this.props.documentType, this.props.notification.taskorderid, data, config)
    }




    render() {
        const documentIsFinal = this.props.notification.showtasknotification === 1;
        return (
            <Document
                key={documentIsFinal}
                docConfig={this.props.notification.pdfjsoncontent}
                isFinal={documentIsFinal}
                printDivRef={this.props.printDivRef}
                workflowItem={this.props.workflowItem}
                contract={this.props.contract}
                reference={this.props.notification.referenceid}
                form={documentIsFinal ? null : this.renderForm()}
                uploadMethod={documentIsFinal ? null : this.uploadFile}
                files={this.props.files}
                notificationid={this.props.notificationid}
                documentType={this.props.documentType}
                customFields={(this.props.customFields || {}).COMMUNICATION}
                customFieldValues={this.props.notification.customfieldvalues}
                assetid={this.props.notification.assetid}
            />
        );
    }
}
const mapStateToProps = store => ({
    contract: store.contract.contract,
});

export default withSnackbar(connect(mapStateToProps)(NotifyDocumentPage));
