import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import PowerBIRouter from "./powerbi/Router";
import ContractUsersReport from "./ContractUsers";
import ContractLeadUsersReport from "./ContractLeadUsers";
import SystemUsersReport from "../admin/Users/SystemUsers";
import Protector from "../../router/Protector/Protector";

class ReportsRouter extends React.Component {
    render() {
        return (
            <Routes caseSensitive>
                <Route path="/powerbi/*" element={<PowerBIRouter refPowerBIEmbedded={this.props.refPowerBIEmbedded}/>} sensitive/>
                <Route path="/contract-users" element={<Protector><ContractUsersReport/></Protector>} sensitive/>
                <Route path="/contract-lead-users" element={<Protector><ContractLeadUsersReport/></Protector>} sensitive/>
                <Route path="/system-users" element={<Protector><SystemUsersReport mode="REPORTS" /></Protector>} sensitive/>

                <Route path="*" element={<Navigate replace to="/contracts" />} />
            </Routes>
        );
    }

}


export default ReportsRouter;