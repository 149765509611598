import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import * as companyDuck from '../../../../store/ducks/company.duck';
import CompanyEditor from './CompanyEditor';
import CompanyUsers from './CompanyUsers';
import CompanyHubs from './CompanyHubs';
import CompanyUserEditor from './CompanyUserEditor';
import ImageEditor from '../../../../partials/content/ImageEditor';
import * as companiesCrud from '../../../../crud/companies.crud';
import SecondAsideMenu from '../../../../partials/layout/SecondAsideMenu';
import { SidedPage, SidedPageLeft, SidedPageRight } from '../../../../partials/content/SidedPage';
import { withSnackbar } from 'notistack';
import CompanyNewUser from './CompanyNewUser';
import { injectIntl } from "react-intl";
import { withRouter } from '../../../../shared/hoc/withRouter';
import Protector, { GetAccessRights } from "../../../../router/Protector/Protector";
import { confirm } from 'devextreme/ui/dialog';
import PortletSpinner from '../../../../partials/layout/PortletSpinner';

class CompanySubRouter extends React.Component {
  constructor(props) {
    super(props);
    const rootPath = '/admin/companies/' + this.props.params.companyid;

    let menuItems = [
      { text: this.props.intl.formatMessage({ id: "COMPANY.MENU.OVERVIEW" }), icon: 'home', to: '/overview' }
    ];
    if (GetAccessRights(rootPath + "/users", this.props.userProfile).allowAccess) {
      menuItems.push(
        { text: this.props.intl.formatMessage({ id: "COMPANY.MENU.USERS" }), icon: 'person', to: '/users' }
      );
    }

    if (GetAccessRights(rootPath + "/hubs", this.props.userProfile).allowAccess) {
      menuItems.push(
        { text: this.props.intl.formatMessage({ id: "COMPANY.MENU.HUB_USERS" }), icon: 'hub', to: '/hubs' },
      );
    }

    menuItems.push(
      { text: this.props.intl.formatMessage({ id: "COMPANY.MENU.BACK" }), icon: 'back', toExact: '/admin/companies' }
    );

    this.secondAsideMenuConfig = {
      RootPath: rootPath,
      MenuItems: menuItems
    }

    this.state = {
      companyPicture: null
    }
  }

  componentDidMount() {
    this.getCompanyPicture();
  }

  getCompanyPicture = async () => {
    try {
      const companyPicture = (await companiesCrud.getCompanyPicture(this.props.params.companyid)).data;
      this.setState({ companyPicture });
    } catch (err) {
      this.props.enqueueSnackbar("Error fetching companyPicture: " + err.toString(), { variant: 'error', });
    }
  }

  imageChangeHandler = async (event) => {
    try {
      if (event.target.files[0] && event.target.files[0].name) {
        if (event.target.files[0].name.endsWith('.jpeg') ||
          event.target.files[0].name.endsWith('.png') ||
          event.target.files[0].name.endsWith('.JPEG') ||
          event.target.files[0].name.endsWith('.PNG')
        ) {

          //THIS IS ONLY FOR SYSTEM OWNER
          if (!this.state.companyPicture.isReplacement) {
            ///USER CONFIRMATION
            //LOGO CHANGE IS DANGEREOUS CHANGE
            const userConfirmationResult = await confirm(
              this.props.intl.formatMessage({ id: "COMPANY.WARNING.CHANGE_LOGO" }),
              this.props.intl.formatMessage({ id: "COMPANY.WARNING.CHANGE_LOGO_LABEL" })
            );

            if (!userConfirmationResult) {
              //CANCEL UPDATE
              event.preventDefault();
              event.target.value = null;
              return;
            }
          }          

          companiesCrud.setCompanyPicture(this.props.company.companyid, event.target.files[0])
            .then(result => {
              this.props.enqueueSnackbar('Picture uploaded successfully', { variant: 'success', })
              this.getCompanyPicture();
            })
            .catch(err => {
              let errMessage = 'Error uploading!  ';
              if (err.response) {
                if (err.response.status && err.response.statusText) {
                  errMessage += '\n' + err.response.status + '-' + err.response.statusText;
                }
                if (err.response.data && err.response.data.ExceptionMessage) {
                  errMessage += '  \n "' + err.response.data.ExceptionMessage + '"';
                  //Error writing MIME multipart body part to output stream
                  //https://stackoverflow.com/questions/24196280/error-writing-mime-multipart-body-part-to-output-stream
                  // probably file exist in server folder !!
                }
              }
              this.props.enqueueSnackbar(errMessage, { variant: 'error', });

            });
        }
        else {
          this.props.enqueueSnackbar('Only .png and .jpeg image extensions are supported', { variant: 'warning', });
        }
      }
    }
    catch { }
    finally {
      event.target.value = null;
    }
  }
  render() {
    if (!this.state.companyPicture) {
      return (<PortletSpinner />);
    }

    const { allowChangeLogo } = this.props.protectedRouteProps;
    let canChangeLogo = allowChangeLogo && (this.state.companyPicture.isReplacement || (!this.state.companyPicture.isReplacement && this.props.userProfile.accounttype === "System Owner"));

    //THIS IS A TEMPORARY CHANGE. WILL BE REMOVED IN THE NEXT SPRINT
    if ((process.env.REACT_APP_PRODUCT_KEY === "NG" || process.env.REACT_APP_PRODUCT_KEY === "NGEASTERNGREENLINK") && (process.env.REACT_APP_ENVIRONMENT === 'UAT' || process.env.REACT_APP_ENVIRONMENT === 'PROD')) {
      canChangeLogo = this.props.userProfile.accounttype === "System Owner";
    }

    return (
      <>
        <SidedPage>
          <SidedPageLeft>
            <SecondAsideMenu config={this.secondAsideMenuConfig} >
              <ImageEditor src={this.state.companyPicture.image} fileChangeHandler={this.imageChangeHandler} allowChangeLogo={canChangeLogo} />
            </SecondAsideMenu>
          </SidedPageLeft>
          <SidedPageRight>
            <Routes caseSensitive>
              <Route path="/" element={<Navigate to="overview" />} sensitive />
              <Route path="/overview" element={<Protector><CompanyEditor /></Protector>} sensitive />
              <Route path="/users/:userid" element={<Protector><CompanyUserEditor /></Protector>} sensitive />
              <Route path="/users" element={<Protector><CompanyUsers /></Protector>} sensitive />
              <Route path="/hubs" element={<Protector><CompanyHubs /></Protector>} sensitive />
              <Route path="/new-company-user" exact element={<Protector><CompanyNewUser /></Protector>} sensitive />
              <Route path="*" element={<Navigate replace to="/contracts" />} />
            </Routes>
          </SidedPageRight>
        </SidedPage>
      </>
    );
  }
}


const mapStateToProps = store => ({
  company: store.company.company,
  userProfile: store.auth.userProfile
});


const mapDispatchToProps = {
  reduxLoadCompany: companyDuck.actions.fetchStart,
  unloadCompany: companyDuck.actions.unload
}


export default withRouter(injectIntl(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(CompanySubRouter))));
