import React from 'react';
import BaseDetailPage from '../../_CustomComponents/BaseDetailPage';
import { Navigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import * as assessmentCrud from '../../../../crud/assessment.crud';
import ActionModal from '../../_CustomComponents/ActionModal';
import AssessmentDocument from './Document';
import { ValidationSummary, ValidationGroup } from 'devextreme-react';
import Relations from '../../_CustomComponents/Relation/Relations';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import PageToolbar from '../../_CustomComponents/PageToolbar';
import Common from '../../../../shared/common';
import { withDetailPageProps } from '../../../../shared/hoc/withDetailPageProps';
import NavigationBlocker from '../../../../partials/Tools/NavigationBlocker';
import { actionCodes } from '../../../../shared/lookup';
import EmailNotifications from '../../_CustomComponents/EmailNotifications';

const initialState = {
    notification: null,
    sectionalDateChangesForNotice: null,
    otherPriceChangesForNotice: null,
    files: null,
    showModal: false,
    saving: false,
    isChanged: false,
    relationCount: 0,
    modalTitle: '',
    modalText: '',
    modalMode: '',
    relations: null,
    isLoading: true,
    selectedClause: null
};

class AssessmentDetailPage extends BaseDetailPage {
    constructor(props) {
        super(props);
        this.isRelationsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true' || process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true';

        this.printIssueDivRef = React.createRef();
        this.printAcceptanceDivRef = React.createRef();
        this.validatorRef = React.createRef();
        this.validatorName = 'AssessmentValidator';
        this.state = { ...initialState };
    }

    componentDidMount() {
        this.pageStartUpActions();
    }
    pageStartUpActions = async () => {
        try {
            //CLEAR ANY PERSISTENT WARNINGS
            await this.props.closeSnackbar('PersistentFormWarning');

            const fetchPromises = [this.fetchAssessment()];

            if (fetchPromises.length > 0) {
                let notification = null;
                let results = await Promise.all(fetchPromises);
                results.forEach(result => {

                    if (result.type === "ASSESSMENT") {
                        notification = result.data;
                    }

                });
                //SANITY CHECK IF API IS RETURNING SOMETHING ELSE
                if (notification.contractid !== this.props.contract.contractid ||
                    notification.notificationtype !== this.props.workflowItem.config.currentModelType) {
                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.INVALID_URL' }), { variant: 'error', });
                    this.props.navigate(this.props.workflowItem.paths.listPath);
                    return;
                }
                //VIEW DRAFT ACCESS CHECK
                //REDIRECT BACK TO REGISTER IF STILL IN DRAFT_MODE AND USER HAS NO ACCESS
                if (!notification.communicated && !this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed) {
                    this.props.navigate(this.props.workflowItem.paths.listPath);
                    return;
                }

                let selectedClause = null;
                if (notification.type && this.props.clauses) {
                    selectedClause = this.props.clauses.find(c => c.display === notification.type);
                    if (selectedClause) {
                    }
                    else {
                        if (notification.showtasknotification !== 1) {
                            notification.type = null;
                            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_CLAUSE_MISSING' }), { variant: 'warning', });
                        }
                    }
                }

                this.setState({
                    isLoading: false,
                    notification,
                    files: notification.files,
                    saving: false,
                    isChanged: false,
                    relations: notification.relations,
                    relationCount: Common.GetRelationCount(notification, this.props.accessibleWfIds)
                }, () => { this.props.setReference(notification.referenceid); });
            }
            else {
                this.setState({ isLoading: false })
            }
        }
        catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
    }

    fetchAssessment = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const { data: assessmentResp } = await assessmentCrud.get(this.props.params.notificationid);
                resolve({ type: "ASSESSMENT", data: assessmentResp });
            }
            catch (err) {
                reject(err);
            }
        });
    }


    componentDidUpdate(prevprops, prevSatate) {
        if (this.props.params.notificationid !== prevprops.params.notificationid) {
            this.setState({ ...initialState }, () => {
                this.pageStartUpActions();
            });
        }
    }

    getMailDescription = () => {
        return this.state.notification.assistancetestserchdefect;
    }
    getSelectedTypeClauseDisplay = () => {
        return this.state.notification.type;
    }
    printHandler = () => {
        this.printIssueRef.handlePrint();
    }
    validateForm = () => {
        const validationResult = this.validatorRef.current.instance.validate();
        if (!validationResult.isValid) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_MISSING_FIELDS' }), { variant: 'warning', });
        }
        return validationResult.isValid && !this.state.saving;
    }
    showModal = (mode, postActionNavigateObj) => {
        let selectedClause = null;
        if (this.state.notification.type && this.props.clauses) {
            selectedClause = this.props.clauses.find(c => c.display === this.state.notification.type);
        }
        if (mode === 'COMMUNICATE') {
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_COMMUNICATE_ITEM' }, { ITEM_NAME: this.props.title }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_COMMUNICATING' }, { ITEM_NAME: this.props.title, PARTY_NAME: this.props.communicateToUser }),
                    modalMode: mode,
                    postActionUrl: postActionNavigateObj,
                    selectedClause
                });
            }
        }
        else if (mode === 'SAVE_DRAFT') {
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_DRAFT_NOTIFY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
                    modalMode: mode,
                });
            }
        }
        else if (mode === 'DELETE_DRAFT') {
            this.setState({
                showModal: true,
                modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_DELETE_DRAFT_NOTIFY' }),
                modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_DELETING' }),
                modalMode: mode,
                saving: true
            });
        }
    }
    onModalResponse = async (response, params) => {
        this.setState({ showModal: false, saving: response === 'OK' }, async () => {
            try {
                if (response === 'OK') {


                    if (this.state.modalMode === 'COMMUNICATE') {
                        const notification = { ...this.state.notification };
                        notification.createactions = params?.createactions;
                        notification.completeactions = params?.completeactions;
                        this.updateAssessment(notification, this.state.modalMode);
                    }
                    else if (this.state.modalMode === 'SAVE_DRAFT') {
                        const notification = { ...this.state.notification };
                        this.updateAssessment(notification, this.state.modalMode, params ? params.userid : null);

                    }
                    else if (this.state.modalMode === 'DELETE_DRAFT') {
                        this.deleteDraft(this.state.notification.assessmentid);
                    }

                }
            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                this.setState({ saving: false });
            }

        });
    }


    deleteDraft = async (assessmentid) => {
        try {
            await assessmentCrud.deleteDraft(assessmentid);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_DELETED' }), { variant: 'success' });
            this.setState({ saving: false, navigateto: this.props.workflowItem.paths.listPath });

        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            this.setState({ saving: false });
        }
    }
    updateAssessment = async (assessment, action, notifyuserid) => {
        try {
            if (!assessment.effectcontractprice) {
                delete assessment.otherpricechanges;
            }
            if (!assessment.effectcontractcompletion) {
                delete assessment.otherdatechanges;
            }
            Common.PropertyConversionsBeforeNoticeSaveRequest(assessment, this.props.customFields, this.props.contractUsers);
            const requestObj = {
                version: assessment.version,
                assessmentid: assessment.assessmentid,
                type: assessment.type,
                subject: assessment.subject,
                description: assessment.description,
                effectcontractcompletion: assessment.effectcontractcompletion,
                effectcontractprice: assessment.effectcontractprice,
                days: assessment.days,
                amount: assessment.amount,
                assetid: assessment.assetid,
                customfieldvalues: assessment.customfieldvalues,
                notifyuserid: notifyuserid,
                otherdatechanges: assessment.otherdatechanges,
                otherpricechanges: assessment.otherpricechanges,
                taskorderid: assessment.taskorderid,
                projectorderid: assessment.projectorderid,
                createactions: assessment.createactions
            }

            const postEvents = () => {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), { variant: 'success', });

                if (this.state.postActionUrl) {
                    this.setState({ isChanged: false, saving: false, navigateto: this.state.postActionUrl.pathname });
                }
                else {
                    this.pageStartUpActions();
                }
            };



            if (action === actionCodes.COMMUNICATE) {
                await assessmentCrud.communicate(requestObj);
            }
            if (action === actionCodes.SAVE_DRAFT) {
                await assessmentCrud.update(requestObj);
            }

            postEvents();
        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            this.setState({ saving: false });
        }
    }

    fieldChanged = (field, value, isDxFormValid, additionalData) => {
        //SILENT STATE CHANGE
        //WE DONT WANT COMPONENT RERENDER WİTH THIS
        //this.setState({ notification: { ...this.state.notification, [field]: value } });
        if (field) {
            if (field === "assetid") {
                this.setState({
                    notification: { ...this.state.notification, [field]: value, asset: { ...additionalData } },
                    isChanged: true
                });
            }
            else {
                this.state.notification[field] = value;
                if (!this.state.isChanged) {
                    this.setState({ isChanged: true });
                }
            }
        }
    }


    renderToolbar = (documentIsFinal) => {
        const saveDisabled = (this.state.saving || this.state.showModal);
        if (!this.state.isLoading) {
            return (
                <PageToolbar
                key={this.state.notification.version}
                    documentIsFinal={documentIsFinal}
                    saveDisabled={saveDisabled}
                    actions={{
                        showModal: this.showModal,
                        onPrint: this.printHandler
                    }}
                    workflowItem={this.props.workflowItem}
                    referenceid={this.state.notification.referenceid}
                    contract={this.props.contract}
                    match={this.props.match}
                    customFieldValues={this.state.notification.customfieldvalues}
                    relationParams={{
                        subject: this.getMailDescription(),
                        assetid: this.state.notification.assetid
                    }}
                    notification={this.state.notification}
                    selectedClausetypeDisplay={this.getSelectedTypeClauseDisplay()}
                />
            );
        }
        else { return null; }

    }

    tabEmailNotification = () => {

        return (
            <EmailNotifications
                {...this.props}
                notificationDate={this.state.notification.notificationdate}
            />
        );

    };

    tabRelations = () => {
        return (
            <Relations
                contractid={this.props.contract.contractid}
                workflowItem={this.props.workflowItem}
                workflowGroupItem={this.props.workflowGroupItem}
                notificationid={Number(this.props.params.notificationid)}
                relations={this.state.notification.relations}
                itemversion={this.state.notification.version}
                subject={this.state.notification.assistancetestserchdefect}
                onCountChange={(count) => this.setState({ relationCount: count })}
                showTags={this.props.contract.specifictags?.length > 0}
            />
        );
    }
    tabDocument = () => {
        return (
            <AssessmentDocument
                {...this.props}
                validatorRef={this.validatorRef}
                printDivRef={this.printIssueDivRef}
                fieldChanged={this.fieldChanged}
                notification={this.state.notification}
                files={this.state.files}
                notificationid={this.props.params.notificationid}
            />
        );
    }


    render() {
        if (this.state.isLoading) {
            return (<PortletSpinner />);
        }
        const documentIsFinal = this.state.notification.communicated;


        return (
            <Portlet isLoading={this.state.isLoading}>
                <ReactToPrint
                    ref={el => (this.printIssueRef = el)}
                    trigger={() => (<div style={{ display: 'none' }} ></div>)}
                    content={() => this.printIssueDivRef.current}
                />
                <NavigationBlocker
                    when={(this.state.isChanged || this.state.saving )}
                    message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}
                />
                {this.state.navigateto &&
                    (
                        <Navigate replace to={this.state.navigateto} state={(this.state.postActionUrl || {}).state} />
                    )
                }
                <PortletHeader
                    title={documentIsFinal ? this.props.workflowItem.pageTitle : this.props.intl.formatMessage({ id: "GENERAL.FORMS.DRAFT_TITLE" }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
                    toolbar={this.renderToolbar(documentIsFinal)}
                />
                <PortletBody>
                    <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
                        <div>
                            <ValidationSummary elementAttr={{ align: "right", paddingBottom: 50 }} id={this.validatorName}></ValidationSummary>
                        </div>
                        <TabPanel
                            width="100%"
                            deferRendering={true}
                            animationEnabled={true}
                            focusStateEnabled={true}
                            hoverStateEnabled={true}
                            swipeEnabled={false}>
                            <Item icon="file" title={this.props.title} render={this.tabDocument} />
                            {
                                this.isRelationsEnabled && (
                                    <Item icon="share" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_RELATIONS' })} render={this.tabRelations} badge={this.state.relationCount > 0 ? this.state.relationCount : null} />
                                )
                            }
                            <Item
                                icon="bulletlist"
                                title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_EMAIL_NOTIFICATIONS' })}
                                render={documentIsFinal ? this.tabEmailNotification : null} visible={documentIsFinal}
                            />
                        </TabPanel>

                    </ValidationGroup>
                </PortletBody>
                <ActionModal
                    visible={this.state.showModal}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    mode={this.state.modalMode}
                    onDialogResult={this.onModalResponse}
                    workflowItem={this.props.workflowItem}
                    userLimit={this.state.notification.amount}
                    selectedClause={this.state.selectedClause}
                />
            </Portlet>
        );
    }
}

export default withDetailPageProps(AssessmentDetailPage);