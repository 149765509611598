import React, { Component } from "react";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import BiDynamicForm from "../../../../partials/layout/BiDynamicForm";
import Spinner from "../../../../partials/layout/Spinner";
import * as compensationEventCrud from "../../../../crud/compensationEvent.crud";
import moment from 'moment';
import { addYears } from 'date-fns';
import { injectIntl } from "react-intl";

export const compensationStatusFilterSupplier = ["AQ", "ARQ"];

class AssumptionCustomForm extends Component {
  constructor(props) {
    super(props);
    this.createDefaultSubject = (((props.location || {}).state || {}).itemCustomProps || {}).subject;
    this.createDefaultAsset = (((props.location || {}).state || {}).itemCustomProps || {}).assetid;
    this.createDefaultFields = ((props.location || {}).state || {});

    this.editingProperty = "requiredate";
    this.compensationFilter = compensationStatusFilterSupplier;

    this.state = {
      compensations: null,
      compStatus: null,
    };
  }

  componentDidMount() {
    this.fetchCompensations();
  }

  fetchCompensations = async () => {
    try {
      const { contractid } = this.props.contract;
      const { data: responseObj } = await compensationEventCrud.getCompansationEventsToSelectList(contractid,this.compensationFilter);
      let comps = responseObj.map((itm) => {
          const reference = itm.referenceid;
          const display = reference + "   " + itm.compensationnumber;
          return {
            value: itm.compensationeventid,
            reference: reference,
            display: display,
            status: itm.status,
          };
        });
      let compStatus = null;
      if (this.props.item && this.props.item.inspectiontime) {
        const compOpt = comps.find(
          (x) => x.value === this.props.item.inspectiontime
        );
        if (compOpt) {
          compStatus = compOpt.status;
        }
        else {
          this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.ERROR_CEN_NOT_FOUND_IN_THE_LIST' }, { ID: this.props.item.inspectiontime }), { variant: 'warning' });
        }

      }
      this.setState({
        compensations: comps,
        compStatus: compStatus,
      });
    } catch (error) {
      console.log(error)
      this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
    }
  };

  valueChangeHandler = (field, value, isDxFormValid, additionalData) => {
    if (field) {
      if (field === 'inspectiontime' && value) {
          const comp = this.state.compensations.find(x => x.value === value);
          if (this.props.valueChangeHandler) {
              this.props.valueChangeHandler('replyaction', comp.reference, isDxFormValid, additionalData);
              this.props.valueChangeHandler('inspectiontime', value, isDxFormValid, additionalData);
          }
      }
      else {
          if (this.props.valueChangeHandler) {
              this.props.valueChangeHandler(field, value, isDxFormValid, additionalData);
          }
      }
    }
  };

  onSubmitHandler = (notification, setSubmitting) => {
    if (this.props.onSubmitHandler) {
      if (notification.inspectiontime) {
        const compOption = this.state.compensations.find(
          (x) => x.value === Number(notification.inspectiontime)
        );
        notification.replyaction = compOption.reference;
      }
      this.props.onSubmitHandler(notification, setSubmitting);
    }
  };

  render() {
    if (!this.state.compensations) {
      return <Spinner />;
    }

    //AUTO-SELECT CE DROPDOWN (IF REDIRECTED FROM CREATE-RELATED BUTTON)
    let defaultCESelection = null;
    if (this.createDefaultFields?.itemBaseProps?.reftype === 'CompensationEvent' && this.state.compensations) {
        const ceOption = this.state.compensations.find(x => x.value == this.createDefaultFields.itemBaseProps.refid);
        if (ceOption) {
            defaultCESelection = ceOption.value;
        }
    }

    const createFormConfig = {
      buttonResource: "GENERAL.FORMS.BUTTON_CREATE_DRAFT",
      fields: [
        { resource: 'GENERAL.FORMS.FIELD.SUBJECT', dataField: "replyactiondetail", editorOptions: { defaultValue: this.createDefaultSubject } },
        {
          resource: 'GENERAL.FORMS.FIELD.COMPENSATION',
          dataField: "inspectiontime",
          editorType: "dxSelectBox",
          editorOptions: {
            dataSource: this.state.compensations,
            valueExpr: "value",
            displayExpr: "display",
            defaultValue: defaultCESelection
          },
        },
      ],
    };
    if (this.props.workflowItem.replyPeriodOption === "ASKED_ON_COMMUNICATION_FORM") {
      createFormConfig.fields.push(
        {
          label: "Decision required by",
          dataField: "inspectiondate",
          editorType: "dxDateBox",
          editorOptions: { calendarOptions: { min: new Date() }, max: addYears(new Date(), 10) },
          validation: {
            type: "range",
            min: moment().subtract(1, "days"),
            max: addYears(new Date(), 10),
            warning:
              this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_DATE" })
          },
        }
      )
    }
    createFormConfig.fields.push(
      {
        resource: "GENERAL.FORMS.FIELD.DETAILS",
        dataField: "inspectiondetails",
        editorType: "dxHtmlEditor",
      }
    );

    return (
      <BiDynamicForm
        item={this.props.item}
        config={createFormConfig}
        onSubmitHandler={this.props.onSubmitHandler ? this.onSubmitHandler : null}
        valueChangeHandler={this.valueChangeHandler}
        customFields={this.props.customFields}
        assetFieldsEnabled={this.props.assetFieldsEnabled}
        assetid={this.createDefaultAsset}
      />
    );
  }
}

const mapStateToProps = (store) => ({
  // contract: store.contract.contract,
  // contractTemplate: store.contract.template,
});

export default injectIntl(withSnackbar(connect(mapStateToProps)(AssumptionCustomForm)));
