import React from "react";
import { withSnackbar } from "notistack";
import {
  Portlet,
  PortletBody,
} from "../../partials/content/Portlet";
import * as administratorCrud from "../../crud/administrator.crud";
import DataGridBase from "../../partials/layout/DataGridBase";
import PortletSpinner from "../../partials/layout/PortletSpinner";
import { injectIntl } from "react-intl";

class ContractLeadUsersReport extends React.Component {
  constructor(props) {
    super(props);
    this.gridRef = React.createRef();

    this.state = {
      usersList: null
    };

    this.columns = [
      {
        caption: "Contract ID",
        dataField: "contractid",
        dataType: "number"
      },
      { caption: "Contract Name", dataField: "contractname" },
      { caption: "Supplier Company", dataField: "suppliercompany" },
      { caption: "User Supplier Company", dataField: "usersuppliercompany" },
      { caption: "Lead Supplier", dataField: "contractmanager" },
      { caption: "Supplier Company ID", dataField: "suppliercompanyid" },
      { caption: "Supplier Company Status", dataField: "suppliercompanystatus" },
      { caption: "User Supplier Company ID", dataField: "usersuppliercompanyid" },
      { caption: "User Supplier Company Status", dataField: "usersuppliercompanystatus" },
      { caption: "Lead Supplier Account ID", dataField: "contractmanageraccountid" },
      { caption: "Lead Supplier Account Status", dataField: "contractmanageraccountstatus" },
      
      { caption: "Client Company", dataField: "clientcompany" },
      { caption: "User Client Company", dataField: "userclientcompany" },
      { caption: "Lead Client", dataField: "employer" },
      { caption: "Client Company ID", dataField: "clientcompanyid" },
      { caption: "Client Company Status", dataField: "clientcompanystatus" },
      { caption: "User Client Company ID", dataField: "userclientcompanyid" },
      { caption: "User Client Company Status", dataField: "userclientcompanystatus" },
      { caption: "Lead Client Account ID", dataField: "userclientaccountid" },
      { caption: "Lead Client Account Status", dataField: "userclientaccountstatus" },

      { caption: "PM Company", dataField: "projectmanagercompany" },
      { caption: "User PM Company", dataField: "userprojectmanagercompany" },
      { caption: "Lead PM", dataField: "projectmanager" },
      { caption: "PM Company ID", dataField: "projectmanagercompanyid" },
      { caption: "PM Company Status", dataField: "projectmanagercompanystatus" },
      { caption: "User PM Company ID", dataField: "userprojectmanagercompanyid" },
      { caption: "User PM Company Status", dataField: "userprojectmanagercompanystatus" },
      { caption: "Lead PM Account ID", dataField: "userprojectmanageraccountid" },
      { caption: "Lead PM Account Status", dataField: "userprojectmanageraccountstatus" },

      { caption: "Supervisor Company", dataField: "supervisorcompany" },
      { caption: "User Supervisor Company", dataField: "usersupervisorcompany" },
      { caption: "Lead Supervisor", dataField: "supervisor" },
      { caption: "Supervisor Company ID", dataField: "supervisorcompanyid" },
      { caption: "Supervisor Company Status", dataField: "supervisorcompanystatus" },
      { caption: "User Supervisor Company ID", dataField: "usersupervisorcompanyid" },
      { caption: "User Supervisor Company Status", dataField: "usersupervisorcompanystatus" },
      { caption: "Lead Supervisor Account ID", dataField: "usersupervisoraccountid" },
      { caption: "Lead Supervisor Account Status", dataField: "usersupervisoraccountstatus" },
      { caption: "Record Status", dataField: "recordstatus" },
      { caption: "Party Structure", dataField: "partystructuredisplay" }
    ];
  }

  componentDidMount() {
    this.loadReport();
  }

  loadReport = () => {
    administratorCrud
      .getContractLeadUsersReport()
      .then((response) => {
        this.setState({ usersList: JSON.parse(response.data) });
      })
      .catch((err) => {
        this.props.enqueueSnackbar(err.toString(), { variant: "error", });
      });
  };

  render() {
    if (!this.state.usersList) {
      return <PortletSpinner />;
    }

    return (
      <Portlet>
        <PortletBody>
          <DataGridBase
            version="4"
            title="Contract Lead Users Register"
            name="Contract Lead Users"
            dataSource={this.state.usersList}
            hoverStateEnabled={false}
            rowAlternationEnabled={true}
            columns={this.columns}
          />
        </PortletBody>
      </Portlet>
    );
  }
}

export default injectIntl(withSnackbar(ContractLeadUsersReport));
