import React from "react";
import BaseDetailPage from "../../_CustomComponents/BaseDetailPage";
import ReactToPrint from "react-to-print";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import * as paymentBatchCrud from "../../../../crud/paymentBatch.crud";
import axios from "axios";
import PaymentDocument from "./Document";
import { Navigate } from "react-router-dom";
import { TabPanel, Item } from "devextreme-react/tab-panel";
import PageToolbar from '../../_CustomComponents/PageToolbar';
import { withDetailPageProps } from '../../../../shared/hoc/withDetailPageProps';
import NavigationBlocker from '../../../../partials/Tools/NavigationBlocker';
import EmailNotifications from "../../_CustomComponents/EmailNotifications";
const initialState = {
  notification: null,
  saving: false,
  isChanged: false,
  postActionUrl: null,
};

class PaymentBatchDetailPage extends BaseDetailPage {
  constructor(props) {
    super(props);
    this.printNotificationDivRef = React.createRef();
    this.state = { ...initialState };
  }
  componentDidMount() {
    this.fetchPaymentBatch();
  }

  componentDidUpdate(prevprops, prevSatate) {
    if (
      this.props.params.notificationid !==
      prevprops.params.notificationid
    ) {
      this.setState({ ...initialState }, () => {
        this.fetchPaymentBatch();
      });
    }
  }

  getSelectedTypeClauseDisplay = () => {
    return "";
}
  fetchPaymentBatch = async () => {
    try {
      await this.props.closeSnackbar("PersistentFormWarning");
      const { data: paymentBatch } = await paymentBatchCrud.get(this.props.params.notificationid);
      if (
        paymentBatch.contractid !== this.props.contract.contractid ||
        paymentBatch.notificationtype !== this.props.workflowItem.config.currentModelType
      ) {
        throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.INVALID_URL' }));
      }


      this.setState({
        notification: paymentBatch,
        saving: false,
        isChanged: false,
      }, () => { this.props.setReference(this.state.notification.referenceid); });
    }
    catch (err) {
      if (!axios.isCancel(err)) {
        this.props.enqueueSnackbar(err.toString(), { variant: "error" });
      }
    }
  };

  printHandler = () => {
    this.printRef.handlePrint();
  };


  renderToolbar = () => {
    const saveDisabled = (this.state.saving || this.state.showModal);
    return (
      <PageToolbar
                key={this.state.notification.version}
        documentIsFinal={true}
        saveDisabled={saveDisabled}
        actions={{
          onPrint: this.printHandler
        }}
        workflowItem={this.props.workflowItem}
        match={this.props.match}
        referenceid={this.state.notification.referenceid}
        contract={this.props.contract}
        notification={this.state.notification}
        selectedClausetypeDisplay={this.getSelectedTypeClauseDisplay()}
      />
    );
  };

  tabDocument = () => {
    return (
      <PaymentDocument
        {...this.props}
        printDivRef={this.printNotificationDivRef}
        fieldChanged={this.fieldChanged}
        notification={this.state.notification}
        files={this.state.files}

        onFilesCountChanged={(fCount) => { if (this.state.fileCount !== fCount) { this.setState({ fileCount: fCount }) } }}
        notificationid={this.props.params.notificationid}
      />
    );
  };


  tabEmailNotification = () => {

    return (
      <EmailNotifications
        {...this.props}
        notificationDate={this.state.notification.notificationdate}
      />
    );

  };
  render() {
    if (!this.state.notification) {
      return <PortletSpinner />;
    }
    if (!this.state.notification.communicated) {
      return <Navigate replace to={this.props.workflowItem.paths.listPath} />;
    }
    return (
      <Portlet>
        <ReactToPrint
          ref={(el) => (this.printRef = el)}
          trigger={() => <div style={{ display: "none" }}></div>}
          content={() => this.printNotificationDivRef.current}
        />
        <NavigationBlocker
          when={(this.state.isChanged || this.state.saving)}
          message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}
        />
        {this.state.navigateto &&
          (
            <Navigate replace to={this.state.navigateto} state={(this.state.postActionUrl || {}).state} />
          )
        }
        <PortletHeader
          title={this.props.workflowItem.pageTitle}
          toolbar={this.renderToolbar()}
        />
        <PortletBody>
          <TabPanel
            width="100%"
            deferRendering={false}
            animationEnabled={true}
            focusStateEnabled={true}
            hoverStateEnabled={true}
            swipeEnabled={false}
          >
            <Item
              icon="file"
              title={this.props.title}
              render={this.tabDocument}
            />
            <Item
              icon="bulletlist"
              title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_EMAIL_NOTIFICATIONS' })}
              render={this.tabEmailNotification} />
          </TabPanel>
        </PortletBody>
      </Portlet>
    );
  }
}


export default withDetailPageProps(PaymentBatchDetailPage);
