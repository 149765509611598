import React, { Component } from 'react';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import { Portlet, PortletBody } from "../../../../../src/app/partials/content/Portlet";
import moment from 'moment';
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from '../../../../app/shared/hoc/withRouter';
import { Button } from 'devextreme-react/button';
import * as notificationCrud from '../../../../../src/app/crud/notification.crud';
import { Alert } from 'react-bootstrap';
import DataGridBase from '../../../partials/layout/DataGridBase';

class EmailNotifications extends Component {
    constructor(props) {
        super(props);
        this.validatorRef = React.createRef();

        this.emailsGeneratedDate = new Date(2024, 6, 8); //08/07/2024
        this.isRefreshEmailButtonVisible = new Date(props.notificationDate) > this.emailsGeneratedDate;

        this.columns = [
            { caption: this.props.intl.formatMessage({ id: 'CONTRACT.EMAIL_RECIPIENTNAME' }), dataField: "recipientname" },
            { caption: this.props.intl.formatMessage({ id: 'CONTRACT.EMAIL_ADDRESS' }), dataField: "recipientemail" },
            { caption: this.props.intl.formatMessage({ id: 'CONTRACT.EMAIL_CREATEDDATE' }), dataField: "timeemailwassent", dataType: "date", format: (date) => moment(date).format('YYYY-MM-DD HH:mm:ss.SSS') },
            { caption: this.props.intl.formatMessage({ id: 'CONTRACT.EMAIL_ACCEPTED' }), dataField: "accepted" },
            { caption: this.props.intl.formatMessage({ id: 'CONTRACT.EMAIL_ACCEPTEDTIMESTAMP' }), dataField: "acceptedTimestamp", dataType: "date", format: (date) => moment(date).format('YYYY-MM-DD HH:mm:ss.SSS') },
            { caption: this.props.intl.formatMessage({ id: 'CONTRACT.EMAIL_REJECTED' }), dataField: "rejected" },
            { caption: this.props.intl.formatMessage({ id: 'CONTRACT.EMAIL_REJECTEDTIMESTAMP' }), dataField: "rejectedTimestamp", dataType: "date", format: (date) => moment(date).format('YYYY-MM-DD HH:mm:ss.SSS') },
            { caption: this.props.intl.formatMessage({ id: 'CONTRACT.EMAIL_DELIVERED' }), dataField: "delivered" },
            { caption: this.props.intl.formatMessage({ id: 'CONTRACT.EMAIL_DELIVEREDTIMESTAMP' }), dataField: "deliveredTimestamp", dataType: "date", format: (date) => moment(date).format('YYYY-MM-DD HH:mm:ss.SSS') },
            { caption: this.props.intl.formatMessage({ id: 'CONTRACT.EMAIL_FAILED' }), dataField: "failed" },
            { caption: this.props.intl.formatMessage({ id: 'CONTRACT.EMAIL_FAILEDTIMESTAMP' }), dataField: "failedTimestamp", dataType: "date", format: (date) => moment(date).format('YYYY-MM-DD HH:mm:ss.SSS') }
        ];

        this.state = {
            isDataGridVisible: false,
            emailListNotifications: null
        };
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    setRefreshTimer = () => {
        this.timer = setTimeout(() => {
            if (!this.state.processing) {
                this.loadEmailList();
            }
        }, 30000);
    }

    toggleDataGridVisibility = () => {
        this.loadEmailList();
        this.setRefreshTimer();
        this.setState({
            isDataGridVisible: true
        });
    }

    loadEmailList = () => {
        const { contractid } = this.props.contract;
        const referenceType = this.props.workflowItem.config.referenceType
        const reference = this.props.params.notificationid

        try {
            const result = notificationCrud.getEmailList(contractid, referenceType, reference).then((response) => {
                this.setState({ emailListNotifications: response.data });
            })
        }
        catch (err) {
            const errorMessage = err.toString();
            this.setState({ error: errorMessage });
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
    };

    render() {
        const { isDataGridVisible } = this.state;

        // if (this.props.userProfile.accounttype === 'Super User' || 'System Administrator') {
        //     columns.push(
        //         { resource: "CONTRACT.EMAIL_CLICKED", dataField: "clicked"},
        //         { resource: "CONTRACT.EMAIL_COMPLAINED", dataField: "complained" },
        //         { resource: "CONTRACT.EMAIL_OPENED", dataField: "opened" },
        //         { resource: "CONTRACT.EMAIL_STORED", dataField: "stored" },
        //         { resource: "CONTRACT.EMAIL_UNSUBSCRIBED", dataField: "unsubscribed" },


        //     );
        // }

        return (
            <>
                {!this.isRefreshEmailButtonVisible && (
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                        <FormattedMessage id="EMAIL.NOTIFICATION.COMMUNICATED_DATE_PREDATES" />
                    </div>
                )}

                {this.isRefreshEmailButtonVisible && !isDataGridVisible && (
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                        <Button type="success" onClick={this.toggleDataGridVisibility}>Refresh Email Notifications List</Button>
                    </div>
                )}

                {isDataGridVisible && (
                    <Portlet>
                        <Alert variant="secondary">
                            <p>
                                <FormattedMessage id="EMAIL.NOTIFICATION.REGISTER_WARNING" />
                            </p>
                        </Alert>
                        <PortletBody>
                            <DataGridBase
                                version="1"
                                title={this.props.intl.formatMessage({ id: 'CONTRACT.EMAILNOTIFICATION.TITLE' })}
                                name={this.props.intl.formatMessage({ id: "CONTRACT.EMAILNOTIFICATION.TITLE" })}
                                dataSource={this.state.emailListNotifications}
                                selection={{ mode: 'single' }}
                                onSelectionChanged={this.onTableSelectionChanged}
                                hoverStateEnabled={true}
                                rowAlternationEnabled={false}
                                columns={this.columns}
                            />
                        </PortletBody>
                    </Portlet>
                )}
            </>
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
    userProfile: store.auth.userProfile
});

export default withRouter(injectIntl(withSnackbar(connect(mapStateToProps)(EmailNotifications))));